// import { Link, redirect } from "react-router-dom";
// import AuthContext from "../Components/AuthProvider";
// import { useNavigate } from "react-router-dom";
// import React, { useEffect, useState, useContext } from "react";
// import "../Components/Style.css";
// import dgdcImage from "../Images/report.jpeg";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import DatePicker from "react-datepicker";
// import Form from "react-bootstrap/Form";
// import Card from "react-bootstrap/Card";
// import axios from "axios";
// import Select from "react-select"; // Import Select component for dropdown
// import {
//   Button, ButtonToggle, CardBody, Container, Label,
//   Table, Input
// } from "reactstrap";
// import PDFReport from "./PDFReport";
// import { saveAs } from "file-saver"; // Import file-saver for triggering the download
// import * as XLSX from "xlsx"; // Import XLSX library for creating XLS files
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import ipaddress from "../Components/IpAddress";
// import { toast } from "react-toastify";
// import PartyListTable from "../Parent_Pages/PartyListTable";
// import {
//   faArrowsToEye,
//   faBolt,
//   faDatabase,
//   faFile,
//   faFileExcel,
//   faFilePdf,
//   faPrint,
//   faSave,
//   faSearch,
//   faUndo,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   Document,
//   Page,
//   Text,
//   View,
//   StyleSheet,
//   PDFViewer,
//   Image,
//   pdf,
//   Line,
//   BlobProvider,
// } from "@react-pdf/renderer";

// export default function ExportPCTM() {
//   const navigate = useNavigate();
//   const { isAuthenticated } = useContext(AuthContext);
//   const currentDate = new Date();
//   const currentDateString = currentDate.toISOString().split("T")[0];
//   const [startDate, setStartDate] = useState(new Date());
//   const [endDate, setEndDate] = useState(new Date());
//   const reactPageName = "Export PCTM Data Party";
//   const [cartingAgents, setCartingAgents] = useState([]);
//   const [tpNumbers, setTpNumbers] = useState([]);
//   const [selectedCartingAgent, setSelectedCartingAgent] = useState("");
//   const [serDate, setSerDate] = useState(new Date());
//   const [selectedCartingAgentTpNo, setSelectedCartingAgentTpNo] = useState("");
//   const [exportTpData, setExportTpData] = useState([]);
//   const [exportData, setExportData] = useState([]);
//   const [importAllData, setImportAllData] = useState([]);
//   const [showTable, setShowTable] = useState(false);
//   const [showImportDataTable, setShowImportDataTable] = useState(false);
//   const [pdfReportData, setPDFReportData] = useState([]);
//   const [totalEntries, setTotalEntries] = useState(0);
//   const [summaryDataForTPData, setSummaryDataForTPData] = useState([]); // Define the state variable
//   const [ReordList, setReordList] = useState([]);
//   const [filteredExportData, setfilteredExportData] = useState([]);
//   const [distinctMawbCount, setDistinctMawbCount] = useState(0); // Define the state variable
//   const formattedStartDate = startDate.toISOString().split('T')[0]; // Extract yyyy-MM-dd
//   const formattedEndDate = endDate.toISOString().split('T')[0];
//   const formattedSerDate = serDate.toISOString().split('T')[0];
//   const [Custodian, setCustodian] = useState("Sunil Patil");




//   const {
//     jwtToken,
//     user_Id,
//     username,
//     branchId,
//     companyid,
//     role,
//     companyname,
//     branchname,
//     login,
//     logout,
//   } = useContext(AuthContext);

 
//   const CustomHeader = () => {
//     return (
//       <View style={styles.header}>
//         <Image src={dgdcImage} style={styles.image} />
//       </View>
//     );
//   };

//   const makeFieldEmpty = () => {
//     setSerDate(new Date());
//     setExportTpData([]);
//     setExportData([]);
//     setSummaryDataForTPData([]);
//     setAllTpDataSummery([]);
//     setAllTpData([]);
//     setCustodian("Sunil Patil");
//   };


//   const styles = StyleSheet.create({
//     centeredTextContainer: {
//       flexDirection: "column",
//       alignItems: "center",
//       justifyContent: "center",
//       marginBottom:9,
//     },
//     rightText: {
//       fontSize: 12,
//       textAlign: "right", // Center text horizontally
//     },
//     centeredText: {
//       fontSize: 12,
//       textAlign: "center", // Center text horizontally
//     },
//     headerText: {
//       fontSize: 12,
//       fontWeight: "bold",
//     },
//     page: {
//       paddingTop: 18,
//       paddingBottom: 60,
//       paddingHorizontal: 30,
//     },
//     header: {
//       marginBottom: 5,
//     },
//     heading: {
//       fontSize: 9,
//       marginBottom: 0,
//       fontWeight: "bold",
//       alignItems: "center",
//     },
//     table: {
//       width: "100%",
//       borderCollapse: "collapse",
//       marginBottom:5,
//     },
//     addressContainer: {
//       display: "flex",
//       flexWrap: "wrap",
//       justifyContent: "center",
//       paddingBottom: 4,
//       flexDirection: "row",
//       textAlign: "center",
//     },
//     addressColumn: {
//       flex: 1,
//       width: "50%",
//     },
//     leftColumn: {
//       width: "100%",
//       paddingTop:9,
//     },
//     headingwithbox: {
//       fontSize: 9,
//       marginBottom: 0,
//       fontWeight: "bold",
//       alignItems: "center",
//     },
//     viewheadingwithbox: {
//       border: "1px solid black",
//       padding: 5,
//     },
//     paragraph: {
//       fontSize: 9,
//       marginBottom: 5,
//     },
//     headingwithborder: {
//       fontSize: 9,
//       marginBottom: 0,
//       fontWeight: "bold",
//       alignItems: "center",
//       borderBottom: "1px solid black",
//       // Add padding for space between text and border
//     },
//     image: {
//       width: 306,
//       height: 100,
//       marginLeft: 117,
//       justifyContent: "center",
//     },
//     dateSize: {
//       fontSize: 8,
//     },
//     normaltext: {
//       fontSize: 9,
//       marginTop: 9,
//       fontWeight: "bold",
//     },
//     line: {
//       width: "100%", // Adjust the width of the line
//       marginTop: 5, // Adjust the space above the line
//       marginBottom: 4, // Adjust the space below the line
//       borderTop: "1pt solid black", // Style the line
//     },

//     tableRow: {
//       flexDirection: "row",
//       borderBottomWidth: 0.4,
//       borderBottomColor: "black",
//       fontSize: 9,
//       borderRightWidth: 0.4,
//       borderLeftWidth: 0.4,
//       borderLeftColor: "black",
//       borderRightColor: "black",
//       flexWrap: "wrap",
//     },
//     tableCell: {
//       border: "0.4px solid black",
//       padding:1,
//       flexWrap: "wrap",
//       width: 52,
//       textAlign: "center",
//       fontSize: 7,
//     },
//     tableCellHeader: {
//       fontWeight: "bold",
//       flexWrap: "wrap",
//       width: 52,
//       border: "0.4px solid black",
//       textAlign: "center",
//       fontSize: 9,
//     },
//     tableCellHeade: {
//       flexWrap: "wrap",
//       fontWeight: "bold",
//       flexDirection: "row",
//       width: 66,
//       fontWeight: "bold",
//       fontSize: 9,
//       border: "0.4px solid black",
//       textAlign: "center",
//     },
//     tableCel: {
//       border: "0.4px solid black",
//       padding:1,
//       flexWrap: "wrap",
//       flexDirection: "row",
//       width: 66,
//       textAlign: "center",
//       fontSize: 7,
//     },
//   });


//   const formatPctmNo = (pctmNo) => {
//     // Remove leading zeros using a regular expression
//     return pctmNo.replace(/^0+/, "");
//   };

//   const formatTpNo = (tpNo) => {
//     // Remove leading zeros using a regular expression
//     return tpNo.replace(/^0+/, "");
//   };






//   const formatedDate = (inputDate) => {
//     const date = new Date(inputDate);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${day}/${month}/${year}`;
//   };
//   const sbCount = ReordList.filter(
//     (item) => item.sbNo !== null && item.sbNo !== ""
//   ).length;

//   const noOfPackages = ReordList.reduce((total, item) => {
//     if (item.noOfPackages !== null && !isNaN(item.noOfPackages)) {
//       return total + parseInt(item.noOfPackages, 10);
//     }
//     return total;
//   }, 0);

//   const handleShow = async () => {
//     // You can use this function to display the fetched data in a table or perform other actions


//     // console.log("dd", formattedStartDate);
//     // console.log("dd", formattedEndDate);

//     await axios
//       .get(
//         `https://${ipaddress}export/exportData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
//       )
//       .then((response) => {
//         const exportData = response.data;

//         setExportData(exportData);
//         console.log("EXport data ", exportData);
//         setShowTable(true);
//         setTotalEntries(exportData.length);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   };

//   const generateExportPCTM = () => {
//     axios
//       .post(
//         `https://${ipaddress}export/updatePCTMAndTPNo?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
//       )
//       .then((response) => {
//         const reportData = response.data; // Data for the selected airline
//         // Pass the data to the PDFReport component
//         setPDFReportData(pdfReportData);
//         setImportAllData(importAllData);

//         setTotalEntries(exportData.length);
//         handleShow();

//         // Display a toast notification when pctmNo is updated successfully
//         toast.success("pctmNo generated successfully", {
//           position: "top-center",
//           autoClose: 3000, // Close the notification after 3 seconds
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//         });

//         // Refresh the page
//         window.location.reload();
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);

//         // Display an error toast notification if there's an error
//         toast.error("Error fetching data", {
//           position: "bottom-right",
//           autoClose: 5000, // Close the notification after 5 seconds
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//         });
//       });
//   };

//   useEffect(() => {
//     if (!isAuthenticated) {
//       navigate(
//         "/login?message=You need to be authenticated to access this page."
//       );
//     }
//   }, [isAuthenticated, navigate]);

//   const [importAllDatalist, setImportAllDatalist] = useState([]);

//   useEffect(() => {
//     // Fetch data from your backend API
//     axios
//       .get(`https://${ipaddress}export/all/${companyid}/${branchId}`)
//       .then((response) => {
//         // Assuming your API response is an array of Import objects
//         setImportAllDatalist(importAllDatalist);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   const formatDate = (inputDate) => {
//     const date = new Date(inputDate);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${day}/${month}/${year}`;
//   };

 
// const [allTpData,setAllTpData] = useState([]);
// const [sumOfNop, setSumOfNop] = useState(0);
// const [sumOfSB, setSumOfSB] = useState(0);
// const [sumOfMAWB, setSumOfMAWB] = useState(0);

// const [allTpDataSummery,setAllTpDataSummery] = useState([]);



// //   const handleShowTPData = () => {


// //     console.log("formattedSerDate : "+formattedSerDate + " selectedCartingAgent: "+selectedCartingAgent + " selectedCartingAgentTpNo " + selectedCartingAgentTpNo);
   
// // if(!formattedSerDate || !selectedCartingAgent || !selectedCartingAgentTpNo)
// // {
// //   toast.error('Select Required Fields', {
// //     position: toast.POSITION.TOP_CENTER,
// //     autoClose: 1200,   
// // });
// // return;
// // }
// //       axios
// //         .get(
// //           `https://${ipaddress}export/getExportTpData?companyId=${companyid}&branchId=${branchId}&serDate=${formattedSerDate}&cartingAgent=${selectedCartingAgent}&tpNo=${selectedCartingAgentTpNo}`
// //         )
// //         .then((response) => {
// //           setAllTpData(response.data.tpData);
// //           setAllTpDataSummery(response.data.tpSummary);
// //           const calculatedSumOfNop = response.data.tpSummary.reduce((acc, curr) => acc + curr[2], 0);
// //           const calculatedSumOfSB = response.data.tpSummary.reduce((acc, curr) => acc + curr[3], 0);
// //           const calculatedSumOfMAWB = response.data.tpSummary.reduce((acc, curr) => acc + curr[4], 0);
// //           setSumOfNop(calculatedSumOfNop);
// //           setSumOfSB(calculatedSumOfSB);
// //           setSumOfMAWB(calculatedSumOfMAWB);
// //         })
// //         .catch((error) => {
// //           console.error("Error fetching PCTM data:", error);
// //         });
    
// //   };


// const handleShowTPData = () => {
//   console.log("formattedSerDate : " + formattedSerDate + " selectedCartingAgent: " + selectedCartingAgent + " selectedCartingAgentTpNo " + selectedCartingAgentTpNo);

//   if (!formattedSerDate || !selectedCartingAgent || !selectedCartingAgentTpNo) {
//     toast.error('Select Required Fields', {
//       position: toast.POSITION.TOP_CENTER,
//       autoClose: 1200,
//     });
//     return;
//   }

//   try {
//     axios
//       .get(
//         `https://${ipaddress}export/getExportTpData?companyId=${companyid}&branchId=${branchId}&serDate=${formattedSerDate}&cartingAgent=${selectedCartingAgent}&tpNo=${selectedCartingAgentTpNo}`
//       )
//       .then((response) => {
//         setAllTpData(response.data.tpData);
//         setAllTpDataSummery(response.data.tpSummary);
//         const calculatedSumOfNop = response.data.tpSummary.reduce((acc, curr) => acc + curr[2], 0);
//         const calculatedSumOfSB = response.data.tpSummary.reduce((acc, curr) => acc + curr[3], 0);
//         const calculatedSumOfMAWB = response.data.tpSummary.reduce((acc, curr) => acc + curr[4], 0);
//         setSumOfNop(calculatedSumOfNop);
//         setSumOfSB(calculatedSumOfSB);
//         setSumOfMAWB(calculatedSumOfMAWB);
//       })
//       .catch((error) => {
//         console.error("Error fetching PCTM data:", error);
//         toast.error("Error fetching PCTM data", {
//           position: toast.POSITION.TOP_CENTER,
//           autoClose: 2000,
//         });
//       });
//   } catch (error) {
//     console.error("Error in handleShowTPData:", error);
//     toast.error("Error in handleShowTPData", {
//       position: toast.POSITION.TOP_CENTER,
//       autoClose: 2000,
//     });
//   }
// };




//   const [totalPackages, setTotalPackages] = useState(0); // Initialize total packages count to 0
//   useEffect(() => {
//     setTotalPackages(exportTpData.length);
//   }, [exportTpData]);

//   useEffect(() => {

//     if (serDate) {
//       fetch(
//         `https://${ipaddress}export/carting-agentsTP?companyId=${companyid}&branchId=${branchId}&serDate=${formattedSerDate}`
//       )
//         .then((response) => response.json())
//         .then((data) => {
//           setCartingAgents(data);

//           console.log("Carting Agents");
//           console.log(data);
//           setSelectedCartingAgent("Select"); // Reset the selected value when serDate changes
//         })
//         .catch((error) => {
//           console.error("Error fetching carting agents:", error);
//         });
//     }
//   }, [serDate]);

//   useEffect(() => {
//     if (selectedCartingAgent) {
//       axios
//         .get(
//           `https://${ipaddress}export/tpNumbers?companyId=${companyid}&branchId=${branchId}&serDate=${formattedSerDate}&cartingAgent=${selectedCartingAgent}`
//         )
//         .then((response) => {
//           // Handle the response here, set the tpNumbers state
//           const data = response.data;
//           setTpNumbers(data);
//           setSelectedCartingAgentTpNo("Select");
//         })
//         .catch((error) => {
//           console.error("Error fetching tpNumbers:", error);
//         });
//     }
//   }, [selectedCartingAgent]);

//   function fetchCompanyName(companyId) {
//     // Make an Axios GET request to retrieve the company name based on companyId
//     return axios
//       .get(`https://${ipaddress}export/findCompanyname/${companyId}`)
//       .then(function (response) {
//         return response.data; // Return the retrieved company name
//       })
//       .catch(function (error) {
//         console.error("Error fetching company name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }

//   function fetchBranchName(companyId, branchId) {
//     // Make an Axios GET request to retrieve the branch name based on branchId
//     return axios
//       .get(
//         `https://${ipaddress}export/findBranchName/${companyId}/${branchId}`
//       )
//       .then(function (response) {
//         return response.data; // Return the retrieved branch name
//       })
//       .catch(function (error) {
//         console.error("Error fetching branch name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }

//   const handleXLSDownload = async () => {
//     const modifiedRecordList = await Promise.all(
//       ReordList.map(async (item, index) => {
//         // const companyname = await fetchCompanyName(item.companyId);
//         // const branchname = await fetchBranchName(item.companyId, item.branchId);

//         return {
//           "Sr.No": index + 1,
//           // "Company Name": companyname,
//           // "Branch Name": branchname,
//           "SER No": item.serNo || "",
//           "SER Date": formatedDate(item.serDate) || "",
//           "PARCEL TYPE": item.parcelType || "",
//           DESTINATION:
//             item.countryOfDestination && item.portOfDestination
//               ? `${item.countryOfDestination} | ${item.portOfDestination}`
//               : "", // Modify this to the actual field name
//           "NO OF PKGS": item.noOfPackages || "",

//           "SHIPPING BILL NO": item.sbNo || "",
//           "SB DATE ": formatedDate(item.sbDate) || "",
//           "NEIGHT WEIGHT": item.grossWeight || "",
//           "AIRWAY BILL NO": item.airwayBillNo || "",
//         };
//       })
//     );

//     const distanceRow = {
//       "Sr.No": "",
//       // "Company Name": "",
//       // "Branch Name": "",
//       "SER No": "",
//       "SER Date": "",
//       "PARCEL TYPE": "",
//       DESTINATION: "",
//       "NO OF PKGS": "",
//       "SHIPPING BILL NO": "",
//       "SB DATE ": "",
//       "NEIGHT WEIGHT": "",
//       "AIRWAY BILL NO": "",
//     };
//     // Add a total row

//     const workbook = XLSX.utils.book_new();
//     const worksheet = XLSX.utils.json_to_sheet([
//       distanceRow,
//       ...modifiedRecordList,
//       distanceRow, // Insert the distance row
//     ]);

//     // Add headers for all fields
//     const headers = Object.keys(modifiedRecordList[0]);
//     headers.forEach((header, index) => {
//       worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
//         t: "s",
//         v: header,
//         s: { font: { bold: true } },
//       };
//     });

//     // Set column widths based on data
//     const colWidths = headers.map((header) => ({
//       wch: header.length + 2, // You can adjust the width as needed
//     }));

//     worksheet["!cols"] = colWidths;

//     XLSX.utils.book_append_sheet(workbook, worksheet, "Import_Register");
//     const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
//     const blob = new Blob([s2ab(xlsFile)], {
//       type: "application/vnd.ms-excel",
//     });
//     saveAs(blob, "Export_pctm_report.xls");
//   };
//   const s2ab = (s) => {
//     const buf = new ArrayBuffer(s.length);
//     const view = new Uint8Array(buf);
//     for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
//     return buf;
//   };

//   const [pctmRecords, setPctmRecords] = useState({});
//   const [uniquePCTMs, setUniquePCTMs] = useState(new Set());

//   useEffect(() => {
//     const uniquePCTMSet = new Set(); // Create a new Set
//     const pctmGroupedRecords = {};

//     ReordList.forEach((item) => {
//       const { pctmNo } = item;
//       uniquePCTMSet.add(pctmNo);

//       // Add the record to the corresponding MAWB group in the object
//       if (!pctmGroupedRecords[pctmNo]) {
//         pctmGroupedRecords[pctmNo] = [];
//       }
//       pctmGroupedRecords[pctmNo].push(item);
//     });
//     setUniquePCTMs(uniquePCTMs); // Update the state with unique MAWB values
//     setPctmRecords(pctmRecords); // Update the state with grouped records
//   }, [ReordList]);

//   function numberToWords(number) {
//     const words = [
//       "",
//       "One",
//       "Two",
//       "Three",
//       "Four",
//       "Five",
//       "Six",
//       "Seven",
//       "Eight",
//       "Nine",
//       "Ten",
//       "Eleven",
//       "Twelve",
//       "Thirteen",
//       "Fourteen",
//       "Fifteen",
//       "Sixteen",
//       "Seventeen",
//       "Eighteen",
//       "Nineteen",
//     ];

//     const tensWords = [
//       "",
//       "",
//       "Twenty",
//       "Thirty",
//       "Forty",
//       "Fifty",
//       "Sixty",
//       "Seventy",
//       "Eighty",
//       "Ninety",
//     ];

//     if (number === 0) return "Zero";

//     if (number < 20) {
//       return words[number];
//     }

//     if (number < 100) {
//       const tens = Math.floor(number / 10);
//       const remainder = number % 10;
//       return tensWords[tens] + (remainder ? ` ${words[remainder]}` : "");
//     }

//     if (number < 1000) {
//       const hundreds = Math.floor(number / 100);
//       const remainder = number % 100;
//       return (
//         words[hundreds] +
//         " Hundred" +
//         (remainder ? ` ${numberToWords(remainder)}` : "")
//       );
//     }

//     if (number < 1000000) {
//       const thousands = Math.floor(number / 1000);
//       const remainder = number % 1000;
//       return (
//         numberToWords(thousands) +
//         " Thousand" +
//         (remainder ? ` ${numberToWords(remainder)}` : "")
//       );
//     }

//     return "Number is too large to convert";
//   }

//   const [external_party, SetExternal_party] = useState([]);
//   const [getexternaluserId, setGetexternaluserId] = useState({});
//   const [namesMap, setNamesMap] = useState({});
//   const [isCartingDataPresent, setIsCartingDataPresent] = useState(false);

//   const fetchCartingAgent = async () => {
//     try {

//       const response = await axios.get(
//         `https://${ipaddress}externalparty/cartingdata/${companyid}/${branchId}`
//       );
//       const data = await response.data;
//       const namesMap = {};
//       data.forEach((externalParty) => {
//         namesMap[externalParty.externaluserId] = externalParty.userName;
//       });
//       setNamesMap(namesMap);

//       setIsCartingDataPresent(data.length > 0); // Set based on data presence
//       setGetexternaluserId(namesMap);

//       SetExternal_party(data);

//     } catch (error) {
//       console.error("Error fetching party names:", error);
//     }


//   };

//   useEffect(() => {
//     fetchCartingAgent();
//   }, []);


//   const cartingAgentsOptions = external_party.map((externalParty) => ({
//     label: externalParty.userName, // Display user name in the dropdown
//     value: externalParty.externaluserId, // Use externaluserId as the value
//   }));




//   const handlePrint = () => {
//     const isoDate = new Date().toISOString();
//     const date = new Date(isoDate);
//     const day = date.getDate().toString().padStart(2, "0");
//     const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are zero-based, so we add 1.
//     const year = date.getFullYear().toString();
//     const printWindow = window.open("", "_blank");
//     printWindow.document.open();
//     const recordsPerPage = 22;
//     // const filteredExportData = ReordList.filter((data) => data.pctmNo === desiredPctmNo);
//     const uniquePctmNos = [...new Set(ReordList.map((item) => item.pctmNo))];

//     // const uniquePctmNos = [...new Set(filteredExportData.map((item) => item.pctmNo))];
//     let currentPageNumber = 1;
//     for (let pctmNo of uniquePctmNos) {
//       const filteredExportData = ReordList.filter(
//         (item) => item.pctmNo === pctmNo
//       );
//       const totalRecords = filteredExportData.length;

//       const totalNoOfPackages = filteredExportData.reduce(
//         (total, item) => total + item.noOfPackages,
//         0
//       );

//       const distinctAirwayBillCounts = {}; // Object to store distinct airwayBillNo counts for each pctmNo

//       // Iterate through ReordList to count distinct airwayBillNo values for each pctmNo
//       ReordList.forEach((item) => {
//         if (!distinctAirwayBillCounts[item.pctmNo]) {
//           distinctAirwayBillCounts[item.pctmNo] = new Set(); // Use Set to store distinct airwayBillNo values
//         }
//         distinctAirwayBillCounts[item.pctmNo].add(item.airwayBillNo);
//       });
//       // Count of distinct airwayBillNo values for the current pctmNo
//       const airwayBillCountForPctmNo = distinctAirwayBillCounts[pctmNo]
//         ? distinctAirwayBillCounts[pctmNo].size
//         : 0;

//       for (
//         let pageIndex = 0;
//         pageIndex < Math.ceil(totalRecords / recordsPerPage);
//         pageIndex++
//       ) {
//         const currentPageRecords = filteredExportData.slice(
//           pageIndex * recordsPerPage,
//           (pageIndex + 1) * recordsPerPage
//         );

//         printWindow.document.write(`
//     <!DOCTYPE html>
//     <html>
//     <head>    
//     <title>Export PCTM Report</title>
//         <style>
//         @page {
//           margin: 1cm;
//            /* Adjust this value to position the header correctly */
//       }

//       .printable-area {
//           font-family: Arial, sans-serif;
//           page-break-before: always;
//       }

//       table {
//           width: 100%;
//           border-collapse: collapse;
//       }

      
//       td {
//         border: 1px solid #dddddd;
//         text-align: center;
//         padding: 1px;
//         font-size: 10px;
//     }

//     th {
//       border: 1px solid #dddddd;
//         background-color: #f2f2f2;
//         text-align: center;
//         font-size: 12px;
//     }

//       .header img {
//           max-width: auto; /* Ensure the image doesn't exceed the page width */
//           max-height: auto; /* Adjust the maximum image height as needed */
//       }
//       .container {
//         display: flex;
//         justify-content: space-between;
//     }
//     .left-column {
//         width: 70%; /* Adjust the width as needed */
//     }
//     .page-break {
//       page-break-before: always; /* This will force a page break before the element */
//     }
//     .right-column {
//         width: 30%; /* Adjust the width as needed */
//         text-align: right; /* Align text to the right */
//     }
//       #page-header {
//           position: static;
//           top: 0;
//           left: 0;
//           right: 0;
//           text-align: center;
//       }
        
//   </style>
//     </head>
//     <body>
//     <div id="page-header">
//     <img src="${dgdcImage}" alt="Header Image" style="max-width: 306px; display: block; margin: 0 auto;"> </br>
//     <div  style="padding-bottom: 5px; font-weight: bold; font-size: 12px;text-align:'center';">
//         <div style="font-size:12px; font-weight:bold">EXPORT</br>
//         PRECIOUS CARGO TRANSFER MANIFEST</div>
//     </div>
// </div>
//     <div class="content">

//     <div class="container">
//     <div class="left-column">
//     <p style="font-size: 12px;">PCTM No:${formatPctmNo(currentPageRecords[0].pctmNo)}</br>
//     Airport Name</p>
//     </div>
//     <div class="right-column">
    
//     <p style="font-size: 12px;">TRIP No:${formatTpNo(currentPageRecords[0].tpNo)}</br>
//     Date: ${formatedDate(currentPageRecords[0].tpDate)}</br>
//     Transferred to ${currentPageRecords[0].airlineName}</br>
//     Page No:${currentPageNumber}</p>
//     </div>
// </div>
//         <table>
//             <thead>
//                 <tr>
//                 <th style={{ backgroundColor: "skyblue" }}>
//                 Sr.No
//               </th>
//               <th style={{ backgroundColor: "skyblue" }}>
//                 SER No
//               </th>
//               <th style={{ backgroundColor: "skyblue" }}>
//                 Parcel Type
//               </th>
//               <th style={{ backgroundColor: "skyblue" }}>
//                 Destination
//               </th>
//               <th style={{ backgroundColor: "skyblue" }}>
//                NOP
//               </th>
//               <th style={{ backgroundColor: "skyblue" }}>
//                 Shipping Bill No
//               </th>
//               <th style={{ backgroundColor: "skyblue" }}>
//               SB Date 
//             </th>
//               <th style={{ backgroundColor: "skyblue" }}>
//                 Neight Weight
//               </th>
//               <th style={{ backgroundColor: "skyblue" }}>
//                 Airway Bill No
//               </th>
//                 </tr>
//             </thead>
//             <tbody>
//               ${currentPageRecords
//                 .map(
//                   (item, index) => `
//                       <td>${index + 1}</td>
//                       <td>${item.serNo || ""}</td>
//                       <td>${item.serNo || ""}</td>
//                       <td>${item.countryOfDestination || ""}</td>
//                       <td>${item.noOfPackages || ""}</td>
//                       <td>${item.sbNo || ""}</td>
//                       <td>${formatDate(item.sbDate) || ""}</td>
//                       <td>${item.grossWeight || ""}</td>
//                       <td>${item.airwayBillNo || ""}</td>
//                       </tr>
//                   `
//                 )
//                 .join("")}
                
//                 <tr>
//                     <td class="bold-text">Total</td>
//                     <td></td>
//                     <td class="bold-text"></td>
//                     <td></td>
//                     <td>${totalNoOfPackages} &nbsp;(${numberToWords(
//           totalNoOfPackages
//         )})</td>
//                     <td>${totalRecords} &nbsp;(${numberToWords(
//           totalRecords
//         )})</td>
//                     <td class="bold-text"></td>
//                     <td></td>
//                     <td>${airwayBillCountForPctmNo} &nbsp;(${numberToWords(
//           airwayBillCountForPctmNo
//         )})</td>
//                     </tr>
//                 </tbody>
//                 </table>
//             </div>
//             <div>
//                <hr>
//             </div>
//             <p style="font-size: 12px;"">Copy for: A.C. SEEPZ/DGDC Sahar/Copy for Airline/Transport Agent/Custodian</p>

//             <div class="container" style="font-size: 12px;">
//             <div class="left-column" style="font-size: 12px;">
//            Transferred by</br></br>
//             _________________________</br>
//             (Name of Transferring Carrier)</br>
//             </div>

//             <div class="right-column" style="font-size: 12px;">
            
//             Above consignment(s) received in full and apparent good condition except as noted in the "remarks" column.</br>
//             Receiver's Signature __________________</br>
//             Name __________________</br>
          
//             </div>
//         </div>

   
//       <div class="page-break"></div>
     
//         <!DOCTYPE html>
//         <html>
//         <head>    
//         <title>Cover Page</title>
//         <style>
//           /* Your cover page CSS styles here */
//           @page {
//             margin: 1cm;
//           }
//           .printable-area {
//             font-family: Arial, sans-serif;
//             page-break-before: always;
//           }
//           .header img {
//             max-width: auto;
//             max-height: auto;
//           }
//           .container {
//             display: flex;
//             justify-content: space-between;
//           }
//           .left-column {
//             width: 70%;
//           }
//           .right-column {
//             width: 30%;
//             text-align: right;
//           }
//           #page-header {
//             position: static;
//             top: 0;
//             left: 0;
//             right: 0;
//             text-align: center;
//           }
//         </style>
//         </head>

//         <body>
//           <div id="page-header">
//           <img src="${dgdcImage}" alt="Header Image" style="max-width: 306px; display: block; margin: 0 auto;"> 
//             <div style="text-align: center;font-size: 12px;font-weight: bold;">
//                 <p  style="font-size:12px;font-weight:'bold';">DGDC LIMITED</p>
//             </div>
//           </div>
//           <div class="content" style="font-size: 12px;">
//             <div class="container">
//               <div class="left-column">
//                 <p style="font-size: 12px;">BRITISH AIRWAYS CARGO SECTION<br>
//            SAHAR MUMBAI - 400 099</p>
//               </div>
//               <div class="right-column">
//                 <p style="font-size: 12px;">Date: ${formatedDate(currentPageRecords[0].tpDate)}</p>
//               </div>
//             </div>
//             <div style="text-align: center;">
//               <p style="font-size: 12px;">SUB : PCTM NO &nbsp ${formatPctmNo(
//                 currentPageRecords[0].pctmNo
//               )}</p>
//             </div>
//             <p style="font-size: 12px;">Sir,</p>
//             <p style="font-size: 12px;">PLEASE ACKNOWLEDGE RECEIPT OF THE FOLLOWING DOCUMENTS BY SIGNING AND RETURNED THE DUPLICATE OF THE LETTER</p>
//             <div class="container" style="font-size: 12px;">
//               <div class="left-column">
//                 <p style="font-size: 12px;">1. A.W. BILL : ${airwayBillCountForPctmNo}</br>
//                2. SHIPPING BILL BOTH DUPLICATE </br>
//                 & EP COPY : &nbsp ${totalRecords}</br>
//                 3. TOTAL NUMBER OF PACKAGES: &nbsp ${totalNoOfPackages} </p>
//               </div>
//             </div>
//             <p style="font-size: 12px;">THE PARCEL PERTAINING TO THE ABOVE DOCUMENTS HAS BEEN DEPOSITED AT OUR SAHAR STRONG ROOM KINDLY ARRANGE TO COLLECT THE SAME</p>
//             <div style="text-align: right;padding-top:4px;">
//               <p style="font-size: 12px;">YOURS FAITHFULLY</p>
//              <p style="padding-top:9px;">FOR CUSTODIAN</p>
//             </div>
//             <p style="font-size: 12px;"><u>FOR AIRLINE USE</u></p>
//             <p style="font-size: 12px;">RECEIVED THE ABOVE MENTIONED AS DETAILED IN THE PCTM NO</p>
//             <div class="container" style="font-size: 12px;">
//               <div class="left-column" style="padding-top:5px;">
//                 <p style="font-size: 12px;">DATE :</br>
//                 SIGNATURE :</br>
//                 NAME :</p>
//               </div>
//             </div>
//           </div>
//         </body>
//         </html>
      
//         </body>
//         </html>
//     `);

//         printWindow.document.write('<div class="printable-area"></div>');

//         if (pageIndex < Math.ceil(totalRecords / recordsPerPage) - 1) {
//         }

//         currentPageNumber++;
//       }
//     }
//     printWindow.document.close();
//     printWindow.print();
//     printWindow.onafterprint = () => printWindow.close();
//   };

//   const handlePrintAcknoledge = () => {
//     const isoDate = new Date().toISOString();
//     const date = new Date(isoDate);
//     const day = date.getDate().toString().padStart(2, "0");
//     const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Note: Months are zero-based, so we add 1.
//     const year = date.getFullYear().toString();
//     const printWindow = window.open("", "_blank");
//     printWindow.document.open();
//     const recordsPerPage = 7;
//     // const filteredExportData = ReordList.filter((data) => data.pctmNo === desiredPctmNo);
//     const uniquePctmNos = [...new Set(ReordList.map((item) => item.pctmNo))];

//     // const uniquePctmNos = [...new Set(filteredExportData.map((item) => item.pctmNo))];
//     let currentPageNumber = 1;
//     for (let pctmNo of uniquePctmNos) {
//       const filteredExportData = ReordList.filter(
//         (item) => item.pctmNo === pctmNo
//       );
//       const totalRecords = filteredExportData.length;

//       const totalNoOfPackages = filteredExportData.reduce(
//         (total, item) => total + item.noOfPackages,
//         0
//       );

//       const distinctAirwayBillCounts = {}; // Object to store distinct airwayBillNo counts for each pctmNo

//       // Iterate through ReordList to count distinct airwayBillNo values for each pctmNo
//       ReordList.forEach((item) => {
//         if (!distinctAirwayBillCounts[item.pctmNo]) {
//           distinctAirwayBillCounts[item.pctmNo] = new Set(); // Use Set to store distinct airwayBillNo values
//         }
//         distinctAirwayBillCounts[item.pctmNo].add(item.airwayBillNo);
//       });
//       // Count of distinct airwayBillNo values for the current pctmNo
//       const airwayBillCountForPctmNo = distinctAirwayBillCounts[pctmNo]
//         ? distinctAirwayBillCounts[pctmNo].size
//         : 0;

//       for (
//         let pageIndex = 0;
//         pageIndex < Math.ceil(totalRecords / recordsPerPage);
//         pageIndex++
//       ) {
//         const currentPageRecords = filteredExportData.slice(
//           pageIndex * recordsPerPage,
//           (pageIndex + 1) * recordsPerPage
//         );

//         printWindow.document.write(`
//     <!DOCTYPE html>
//     <html>
//     <head>    
//     <title>Export PCTM Report</title>
//         <style>
//         @page {
//           margin: 1cm;
//            /* Adjust this value to position the header correctly */
//       }

//       .printable-area {
//           font-family: Arial, sans-serif;
//           page-break-before: always;
//       }

//       table {
//           width: 100%;
//           border-collapse: collapse;
//       }

//       td {
//         border: 1px solid #dddddd;
//         text-align: center;
//         padding:1px;
//         font-size: 10px;
//     }

//     th {
//       border: 1px solid #dddddd;
//         background-color: #f2f2f2;
//         text-align: center;
//         font-size: 12px;
//     }
//       .header img {
//           max-width: auto; /* Ensure the image doesn't exceed the page width */
//           max-height: auto; /* Adjust the maximum image height as needed */
//       }.container {
//         display: flex;
//         justify-content: space-between;
//     }
//     .left-column {
//         width: 70%; /* Adjust the width as needed */
//     }
//     .page-break {
//       page-break-before: always; /* This will force a page break before the element */
//     }
//     .custom-td {
//       height: 45px; /* You can adjust the height value as needed */
//     }

//     .right-column {
//         width: 30%; /* Adjust the width as needed */
//         text-align: right; /* Align text to the right */
//     }
//       #page-header {
//           position: static;
//           top: 0;
//           left: 0;
//           right: 0;
//           text-align: center;
//       }
        
//   </style>
//     </head>
//     <body>
//     <div id="page-header">

//     <div style="display: flex; flex-wrap: wrap; justify-content: center; padding-bottom: 18px;">
//   <div style="flex: 1; width: 50%; font-weight: bold;font-size: 13px;">
//     Airport MUMBAI<br>
//      CUSTODIAN'S OFFICE
//   </div>

//   <div style="flex: 1; width: 50%; font-weight: bold;font-size: 13px;">
//   D.G.D.C. LIMITED<br>
//   DGDC SEEPZ SEZ STRONG ROOM<br>
//   MIAL - CSI AIRPORT, AIR CARGO, SAHAR MUMBAI - 99 
// </div>

//   <div style="flex: 1;  width: 50%; font-weight: bold;font-size: 12px;">
   
//     TRIP NO. ${formatTpNo(
//       currentPageRecords[0].tpNo
//     )}	TRIP DATE :  ${formatedDate(currentPageRecords[0].tpDate)}
//         <br>
//      FLIGHT : ${currentPageRecords[0].airlineName}
//   </div>

//   <div style="flex: 1; width: 50%; font-weight: bold;font-size: 12px;">
//   PCTM No:${formatPctmNo(currentPageRecords[0].pctmNo)}<br>
//   Page No: ${currentPageNumber}
//   </div>
// </div>
// <div style="text-align: center; font-weight: bold; padding-bottom: 5px; font-size: 12px;">
//   EXPORT REGISTER
// </div>

//         <table>
//             <thead>
//                 <tr>
//                 <th style="font-size: 12px;">
//                 Sr.No
//               </th>
//               <th style="font-size: 12px;">
//                 SER No
//               </th>
//               <th style="font-size: 12px;">
//               Airway Bill No
//             </th>
//             <th style="font-size: 12px;">
//             Destination
//           </th>
//           <th style="font-size: 12px;">
//           SB No
//         </th>
//         <th style="font-size: 12px;">
//         Weight
//        </th>
//        <th style="font-size: 12px;">
//        No Of PKG
//      </th>
      
//               <th style="font-size: 12px;">
//               Shpmnt Removal
//               </th>
//               <th style="width:6%;font-size: 12px; ">
//               <strong>  Shipped on Flight No</strong>
//               </th>
            
//               <th style="width:14%;font-size: 12px;" colspan="2">
//              <strong> Removing the PKG for shipmnt Airline Staff</strong>
//                </th>
//                <th style="width:14%;font-size: 12px;" colspan="2">
//                <strong> Custom-Escort</strong>
//                 </th>
                
//                 <th style="width:14%;font-size: 12px;" colspan="2">
//                 <strong> DGDC Sign Name official Delivering the pkg</strong>
//                  </th>
//                 </tr>
//                 <thead>
//                 <tr>
//                   <th></th>
//                   <th></th>
//                   <th></th>
//                   <th></th>
//                   <th></th>
//                   <th></th>
//                   <th></th>
//                   <th style="font-size: 12px;">Dt Of</th>
//                   <th style="font-size: 12px;">& Dt</th>
//                   <th style="font-size: 12px;">Name</th>
//                   <th style="font-size: 12px;">Sign</th>
//                   <th style="font-size: 12px;">Name</th>
//                   <th style="font-size: 12px;">Sign</th>
//                   <th style="font-size: 12px;">Name</th>
//                   <th style="font-size: 12px;">Sign</th>
//                 </tr>
//               </thead>
//             </thead>
//             <tbody>
//               ${currentPageRecords
//                 .map(
//                   (item, index) => `
//                       <td  class="custom-td">${index + 1}</td>
//                       <td class="custom-td">${item.serNo}</td>
//                       <td class="custom-td">${item.airwayBillNo}</td>
//                       <td class="custom-td">${item.countryOfDestination} | ${item.portOfDestination}</td>
//                       <td class="custom-td">${item.sbNo}</td>
//                       <td class="custom-td">${item.grossWeight}</td>
//                       <td class="custom-td">${item.noOfPackages}</td>
//                       <td class="custom-td"></td>
//                       <td class="custom-td"></td>
//                       <td class="custom-td"></td>
//                       <td class="custom-td"></td>
//                       <td class="custom-td"></td>
//                       <td class="custom-td"></td>
//                       <td class="custom-td"></td>
//                       <td class="custom-td"></td>
//                       </tr>
//                       <tr>
//                       <td colspan="15" style="font-weight: bold; font-size: 12px; text-align: left;">Remarks:</td>

//                     </tr>
//                   `
//                 )
//                 .join("")}
//                 </tbody>
//                 </table>
     
//             </div>  
//         </div>
//               </body>
//         </html>
//         <div style="display: flex; flex-wrap: wrap;padding-top: 9px;padding-bottom: 9px;">
      
//     `);

//         if (pageIndex < Math.ceil(totalRecords / recordsPerPage) - 1) {
//           printWindow.document.write('<div class="page-break"></div>'); // Add a page break
//         }

//         currentPageNumber++;
//       }

//       // Display "Total AWB" and "TOTAL PKGS" at the end of each pctmNo data section
//       printWindow.document.write(`
//   <div style=" width: 25%;font-size: 12px;">
//   Total AWB :&nbsp;&nbsp;${airwayBillCountForPctmNo} &nbsp;&nbsp;(${numberToWords(
//         airwayBillCountForPctmNo
//       )}) 
//   </div>
//   <div style=" width: 25%;font-size: 12px;">
//   TOTAL PKGS :  &nbsp;&nbsp; ${totalNoOfPackages} &nbsp;&nbsp;(${numberToWords(totalNoOfPackages)})
//   </div>
// </div>
//   `);
//       printWindow.document.write('<div class="page-break"></div>'); // Add a page break
//     }

//     printWindow.document.close();
//     printWindow.print();
//     printWindow.onafterprint = () => printWindow.close();
//   };
//   const handlePDFAcknoledgeDownload = async () => {
//     const pdfBlob = await pdf(
//       <MyPDFAcknoledgeDocument {...{ ReordList }} />
//     ).toBlob();
//     saveAs(pdfBlob, "Export_Register.pdf");
//   };
//   const MyPDFAcknoledgeDocument = ({ ReordList }) => {
//     const recordsPerPage = 7;
//     let currentPageNumber = 0;
//     const uniquePctmNos = [...new Set(ReordList.map((item) => item.pctmNo))];
//     return (
//       <Document>
//         {uniquePctmNos.map((pctmNo, index) => {
//           const currentPageRecords = ReordList.filter(
//             (item) => item.pctmNo === pctmNo
//           );

//           const totalRecords = currentPageRecords.length;

//           const totalNoOfPackages = currentPageRecords.reduce(
//             (total, item) => total + item.noOfPackages,
//             0
//           );
//           const distinctAirwayBillCounts = {}; // Object to store distinct airwayBillNo counts for each pctmNo

//           // Iterate through ReordList to count distinct airwayBillNo values for each pctmNo
//           ReordList.forEach((item) => {
//             if (!distinctAirwayBillCounts[item.pctmNo]) {
//               distinctAirwayBillCounts[item.pctmNo] = new Set(); // Use Set to store distinct airwayBillNo values
//             }
//             distinctAirwayBillCounts[item.pctmNo].add(item.airwayBillNo);
//           });
//           // Count of distinct airwayBillNo values for the current pctmNo
//           const airwayBillCountForPctmNo = distinctAirwayBillCounts[pctmNo]
//             ? distinctAirwayBillCounts[pctmNo].size
//             : 0;

//           const pageCount = Math.ceil(
//             currentPageRecords.length / recordsPerPage
//           );
//           let serialNumber = 0;

//           return (
//             <React.Fragment key={`pctmNo_${pctmNo}`}>
//               {Array.from({ length: pageCount }, (_, pageIdx) => {
//                 const startIdx = pageIdx * recordsPerPage;
//                 const endIdx = startIdx + recordsPerPage;
//                 const pageRecords = currentPageRecords.slice(startIdx, endIdx);

//                 currentPageNumber++;

//                 return (
//                   <Page
//                     size="A4"
//                     orientation="landscape"
//                     style={{ ...styles.page }}
//                   >
//                     {/* Your page content */}

//                     <View style={styles.addressContainer}>
//                       <View style={styles.addressColumn}>
//                         <Text style={{ fontSize: 9 }}>Airport MUMBAI</Text>
//                         <Text style={{ fontSize: 9 }}>CUSTODIAN'S OFFICE</Text>
//                       </View>

//                       <View style={styles.addressColumn}>
//                         <Text style={{ fontSize: 9 }}>D.G.D.C. LIMITED</Text>
//                         <Text style={{ fontSize: 9 }}>
//                           DGDC SEEPZ SEZ STRONG ROOM
//                         </Text>
//                         <Text style={{ fontSize: 9 }}>
//                           MIAL - CSI AIRPORT, AIR CARGO, SAHAR MUMBAI - 99
//                         </Text>
//                       </View>

//                       <View style={styles.addressColumn}>
//                         <Text style={{ fontSize: 9 }}>
//                           TRIP NO. {formatTpNo(currentPageRecords[0].tpNo)}
//                         </Text>
//                         <Text style={{ fontSize: 9 }}>
//                           TRIP DATE:{" "}
//                           {formatedDate(currentPageRecords[0].tpDate)}
//                         </Text>
//                         <Text style={{ fontSize: 9 }}>
//                           FLIGHT: {currentPageRecords[0].airlineName}
//                         </Text>
//                       </View>

//                       <View style={styles.addressColumn}>
//                         <Text style={{ fontSize: 9 }}>
//                           PCTM No: {formatPctmNo(currentPageRecords[0].pctmNo)}
//                         </Text>
//                         <Text style={{ fontSize: 9 }}>
//                           Page No: {currentPageNumber}
//                         </Text>
//                       </View>
//                     </View>
//                     <View
//                       style={{
//                         ...styles.centeredText,
//                         fontWeight: "bold",
//                         paddingBottom: 5,
//                       }}
//                     >
//                       <Text>EXPORT REGISTER</Text>
//                     </View>

//                     <View style={styles.table}>
//                       <View style={styles.tableRow}>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { fontSize: 9, width: 36 },
//                           ]}
//                         >
//                           Sl.No.
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { fontSize: 9, width: 50 },
//                           ]}
//                         >
//                           SER No
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { fontSize: 9, width: 83 },
//                           ]}
//                         >
//                           Airway Bill No
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { fontSize: 9, width: 67 },
//                           ]}
//                         >
//                           Destination
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { fontSize: 9 },
//                           ]}
//                         >
//                           SB No
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { fontSize: 9, width: 67 },
//                           ]}
//                         >
//                           Weight
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { fontSize: 9, width: 36 },
//                           ]}
//                         >
//                           No Of PKG
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { fontSize: 9, width: 36 },
//                           ]}
//                         >
//                           Shpmnt Removal
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeader,
//                             { fontSize: 9 },
//                           ]}
//                         >
//                           Shipped on Flight No
//                         </Text>

//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { fontSize: 9, width: 96 },
//                           ]}
//                           colSpan={2}
//                         >
//                           Removing the PKG for shipmnt Airline Staff
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { fontSize: 9, width: 96 },
//                           ]}
//                           colSpan={2}
//                         >
//                           Custom-Escort
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { fontSize: 9, width: 96 },
//                           ]}
//                           colSpan={2}
//                         >
//                           DGDC Sign Name official Delivering the pkg
//                         </Text>
//                       </View>

//                       <View style={styles.tableRow}>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             {  width: 36 },
//                           ]}
//                         ></Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             {  width: 50 },
//                           ]}
//                         ></Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { fontSize: 9, width: 83 },
//                           ]}
//                         ></Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             {  width: 67 },
//                           ]}
//                         ></Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { },
//                           ]}
//                         ></Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { width: 67 },
//                           ]}
//                         ></Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             {  width: 36 },
//                           ]}
//                         ></Text>

//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             {  width: 36 },
//                           ]}
//                         >
//                         DT OF
//                         </Text>

//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             { width: 52 },
//                           ]}
//                         >
//                          &DT
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             {  width: 48 },
//                           ]}
//                         >
//                          NAME
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             {  width: 48 },
//                           ]}
//                         >
//                           SIGN
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             {  width: 48 },
//                           ]}
//                         >
//                          NAME
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             {  width: 48 },
//                           ]}
//                         >
//                           SIGN
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             {  width: 48 },
//                           ]}
//                         >
//                           NAME
//                         </Text>
//                         <Text
//                           style={[
//                             styles.tableCel,
//                             styles.tableCellHeade,
//                             {  width: 48 },
//                           ]}
//                         >
//                           SIGN
//                         </Text>
//                       </View>

//                       {pageRecords.map((item, index) => {
//                         // Increment the serialNumber for each record
//                         serialNumber++;

//                         return (
//                           <React.Fragment key={index}>
//                             <View style={styles.tableRow}>
//                               <Text style={[styles.tableCel, { width: 36 }]}>
//                                 {serialNumber || ""}
//                               </Text>
//                               <Text style={[styles.tableCel, { width: 50 }]}>
//                                 {item.serNo || ""}
//                               </Text>
//                               <Text style={[styles.tableCel, { width: 83 }]}>
//                                 {item.airwayBillNo || ""}
//                               </Text>
//                               <Text style={[styles.tableCel, { width: 67 }]}>
//                               {item.countryOfDestination || ""} | {item.portOfDestination  || ""}
//                               </Text>
//                               <Text style={styles.tableCel}>
//                                 {item.sbNo || ""}
//                               </Text>
//                               <Text style={[styles.tableCel, { width: 67 }]}>
//                                 {item.grossWeight || ""}
//                               </Text>
//                               <Text style={[styles.tableCel, { width: 36 }]}>
//                                 {item.noOfPackages || ""}
//                               </Text>
//                               <Text
//                                 style={[styles.tableCel, { width: 36 }]}
//                               ></Text>
//                               <Text
//                                 style={[
//                                   styles.tableCel,

//                                   {  width: 52 },
//                                 ]}
//                               ></Text>
//                               <Text
//                                 style={[
//                                   styles.tableCel,
//                                   styles.tableCellHeade,
//                                   {  width: 48 },
//                                 ]}
//                               ></Text>
//                               <Text
//                                 style={[
//                                   styles.tableCel,

//                                   {  width: 48 },
//                                 ]}
//                               ></Text>
//                               <Text
//                                 style={[
//                                   styles.tableCel,

//                                   {  width: 48 },
//                                 ]}
//                               ></Text>
//                               <Text
//                                 style={[
//                                   styles.tableCel,

//                                   {  width: 48 },
//                                 ]}
//                               ></Text>
//                               <Text
//                                 style={[
//                                   styles.tableCel,

//                                   {  width: 48 },
//                                 ]}
//                               ></Text>
//                               <Text
//                                 style={[
//                                   styles.tableCel,

//                                   {  width: 48 },
//                                 ]}
//                               ></Text>
//                             </View>

//                             <View style={styles.tableRow}>
//                               <Text
//                                 style={[
//                                   styles.tableCellRemarks,
//                                   {
//                                     height: 18,
//                                     justifyContent: "center",
//                                     paddingTop: 7,
//                                   },
//                                 ]}
//                                 colSpan={15}
//                               >
//                                REMARKS
//                               </Text>
//                             </View>
//                           </React.Fragment>
//                         );
//                       })}
//                     </View>

//                     {pageIdx === pageCount - 1 && (
//                       <View style={{ flexDirection: "row" }}>
//                         <View style={{ width: "25%" }}>
//                           <Text style={{ fontSize: 9 }}>
//                             Total AWB {airwayBillCountForPctmNo} &nbsp;(
//                             {numberToWords(airwayBillCountForPctmNo)})
//                           </Text>
//                         </View>
//                         <View style={{ width: "45%" }}>
//                           <Text style={{ fontSize: 9 }}>
//                             TOTAL PKGS {totalNoOfPackages} &nbsp;(
//                             {numberToWords(totalNoOfPackages)})
//                           </Text>
//                         </View>
//                       </View>
//                     )}
//                   </Page>
//                 );
//               })}
//             </React.Fragment>
//           );
//         })}
//       </Document>
//     );
//   };

//   const handlePDFDownload = async () => {
//     const pdfBlob = await pdf(<MyPDFDocument {...{ ReordList }} />).toBlob();
//     saveAs(pdfBlob, "Export_PCTM.pdf");
//   };

//   const MyPDFDocument = ({ ReordList }) => {
//     const recordsPerPage = 18;
//     let currentPageNumber = 0;
//     const uniquePctmNos = [...new Set(ReordList.map((item) => item.pctmNo))];
//     return (
//       <Document>
//         {uniquePctmNos.map((pctmNo, index) => {
//           const currentPageRecords = ReordList.filter(
//             (item) => item.pctmNo === pctmNo
//           );
//           currentPageNumber++;
//           const totalRecords = currentPageRecords.length;

//           const totalNoOfPackages = currentPageRecords.reduce(
//             (total, item) => total + item.noOfPackages,
//             0
//           );
//           const distinctAirwayBillCounts = {}; // Object to store distinct airwayBillNo counts for each pctmNo
//           ReordList.forEach((item) => {
//             if (!distinctAirwayBillCounts[item.pctmNo]) {
//               distinctAirwayBillCounts[item.pctmNo] = new Set(); // Use Set to store distinct airwayBillNo values
//             }
//             distinctAirwayBillCounts[item.pctmNo].add(item.airwayBillNo);
//           });
//           // Count of distinct airwayBillNo values for the current pctmNo
//           const airwayBillCountForPctmNo = distinctAirwayBillCounts[pctmNo]
//             ? distinctAirwayBillCounts[pctmNo].size
//             : 0;
//           return (
//             <>
//               <Page size="A4" style={styles.page}>
//                 <CustomHeader />
//                 <View>
//                   <Text
//                     style={[
//                       styles.centeredText,
//                       { fontSize: 12, fontWeight: "bold" },
//                     ]}
//                   >
//                     EXPORT
//                   </Text>
//                   <Text style={[styles.centeredText, { fontWeight: "bold" }]}>
//                     PRECIOUS CARGO TRANSFER MANIFEST
//                     {"\n\n"}
//                   </Text>
//                 </View>

//                 <View
//                   style={{
//                     flexDirection: "row",
//                     paddingTop: 4,
//                     paddingBottom: 5,
//                   }}
//                 >
//                   <View style={{ width: "75%" }}>
//                     <Text style={{ fontSize: 9 }}>
//                       PCTM NO : {formatPctmNo(currentPageRecords[0].pctmNo)}
//                       {"\n"}
//                       {"\n"}
//                     </Text>
//                     <Text style={{ fontSize: 9, maxWidth: 180 }}>
//                       AIRPORT NAME
//                       {"\n"}
//                     </Text>
//                   </View>
//                   <View style={{ width: "25%" }}>
//                     <Text style={{ fontSize: 9 }}>
//                       Trip No : {formatTpNo(currentPageRecords[0].tpNo)}
//                       {"\n"}
//                     </Text>
//                     <Text style={{ fontSize: 9 }}>
//                       Date : {formatedDate(currentPageRecords[0].tpDate)}
//                       {"\n"}
//                       {"\n"}
//                     </Text>
//                     <Text style={{ fontSize: 9 }}>
//                       Transferred To {currentPageRecords[0].airlineName}
//                       {"\n"}
//                     </Text>
//                     <Text style={{ fontSize: 9 }}>
//                       Page No : {currentPageNumber}
//                       {"\n"}
//                     </Text>
//                   </View>
//                 </View>
//                 <View style={styles.table}>
//                   <View style={styles.tableRow}>
//                     <Text
//                       style={[
//                         styles.tableCell,
//                         styles.tableCellHeader,
//                         { width: 40 },
//                       ]}
//                     >
//                       Sl.No.
//                     </Text>
//                     <Text
//                       style={[
//                         styles.tableCell,
//                         styles.tableCellHeader,
//                         { width: 50 },
//                       ]}
//                     >
//                       SER No
//                     </Text>
//                     <Text
//                       style={[
//                         styles.tableCell,
//                         styles.tableCellHeader,
//                         { fontSize: 9, width: 63 },
//                       ]}
//                     >
//                       SER Date
//                     </Text>
//                     <Text
//                       style={[
//                         styles.tableCell,
//                         styles.tableCellHeader,
//                         { width: 70 },
//                       ]}
//                     >
//                       Destination
//                     </Text>
//                     <Text
//                       style={[
//                         styles.tableCell,
//                         styles.tableCellHeader,
//                         { fontSize: 9 },
//                       ]}
//                     >
//                      NOP
//                     </Text>
//                     <Text
//                       style={[
//                         styles.tableCell,
//                         styles.tableCellHeader,
//                         { width: 63 },
//                       ]}
//                     >
//                       SB NO
//                     </Text>
//                     <Text
//                       style={[
//                         styles.tableCell,
//                         styles.tableCellHeader,
//                         { width: 63 },
//                       ]}
//                     >
//                       SB DATE
//                     </Text>
//                     <Text
//                       style={[
//                         styles.tableCell,
//                         styles.tableCellHeader,
//                         { width: 68 },
//                       ]}
//                     >
//                       WEIGHT(GRAMS)
//                     </Text>
//                     <Text
//                       style={[
//                         styles.tableCell,
//                         styles.tableCellHeader,
//                         { width: 65 },
//                       ]}
//                     >
//                       AIRWAY BILL NO
//                     </Text>
//                   </View>
//                   {currentPageRecords.map((item, index) => (
//                     <View style={styles.tableRow} key={index}>
//                       <Text
//                         style={{
//                           ...styles.tableCell,

//                           width: 40,
//                         }}
//                       >
//                         {index + 1}
//                       </Text>
//                       <Text
//                         style={{
//                           ...styles.tableCell,

//                           width: 50,
//                         }}
//                       >
//                         {item.serNo || ""}
//                       </Text>
//                       <Text
//                         style={{
//                           ...styles.tableCell,

//                           width: 63,
//                         }}
//                       >
//                         {formatedDate(item.serDate) || ""}
//                       </Text>
//                       <Text
//                         style={{
//                           ...styles.tableCell,

//                           width: 70,
//                         }}
//                       >
//                         {item.countryOfDestination || ""}
//                       </Text>
//                       <Text style={{ ...styles.tableCell }}>
//                         {item.noOfPackages || ""}
//                       </Text>
//                       <Text
//                         style={{
//                           ...styles.tableCell,

//                           width: 63,
//                         }}
//                       >
//                         {item.sbNo || ""}
//                       </Text>
//                       <Text
//                         style={{
//                           ...styles.tableCell,

//                           width: 63,
//                         }}
//                       >
//                         {formatedDate(item.sbDate) || ""}
//                       </Text>
//                       <Text
//                         style={{
//                           ...styles.tableCell,

//                           width: 68,
//                         }}
//                       >
//                         {item.grossWeight || ""}
//                       </Text>
//                       <Text
//                         style={{
//                           ...styles.tableCell,

//                           width: 65,
//                         }}
//                       >
//                         {item.airwayBillNo || ""}
//                       </Text>
//                     </View>
//                   ))}
//                 </View>
//                 <View style={styles.tableRow}>
//                   <Text style={{ ...styles.tableCell, width: 40 }}>Total</Text>
//                   <Text style={{ ...styles.tableCell, width: 50 }}></Text>
//                   <Text
//                     style={[
//                       styles.tableCell,
//                       styles.tableCellHeader,
//                       { width: 63 },
//                     ]}
//                   ></Text>
//                   <Text style={{ ...styles.tableCell, width: 70 }}></Text>
//                   <Text style={{ ...styles.tableCell }}>
//                     {totalNoOfPackages}
//                   </Text>
//                   <Text style={{ ...styles.tableCell, width: 63 }}>
//                     {totalRecords}
//                   </Text>
//                   <Text style={{ ...styles.tableCell, width: 63 }}></Text>
//                   <Text style={{ ...styles.tableCell, width: 68 }}></Text>
//                   <Text style={{ ...styles.tableCell, width: 65 }}></Text>
//                 </View>
//                 <Line style={styles.line} />
//                 <Text style={{ fontSize: 9, paddingTop: 9 }}>
//                   {" "}
//                   Copy for: A.C. SEEPZ/DGDC Sahar/Copy for Airline/Transport
//                   Agent/Custodian
//                 </Text>
//                 <View style={{ flexDirection: "row", paddingTop: 9 }}>
//                   {/* Left side */}
//                   <View style={{ width: "70%" }}>
//                     <Text style={{ fontSize: 9 }}>Transferred by</Text>
//                     <Text style={{ fontSize: 9 }}>__________________</Text>
//                     <Text style={{ fontSize: 9 }}>
//                       (Name of Transferring Carrier)
//                     </Text>
//                   </View>

//                   {/* Right side */}
//                   <View style={{ width: "30%" }}>
//                     <Text style={{ fontSize: 9, float: "right" }}>
//                       Above consignment(s) received in full and
//                     </Text>
//                     <Text style={{ fontSize: 9 }}>
//                       apparent good condition except as noted in the
//                     </Text>
//                     <Text style={{ fontSize: 9 }}>"remarks" column.</Text>
//                     <Text style={{ fontSize: 9 }}>Receivers Signature _________</Text>
//                     <Text style={{ fontSize: 9 }}>Name _________</Text>
//                   </View>
//                 </View>
//               </Page>

//               {index < uniquePctmNos.length && (
//                 <Page size="A4" style={styles.page}>
//                   <CustomHeader />
//                   <View>
//                     <View>
//                       <Text
//                         style={[
//                           styles.centeredText,
//                           { fontSize: 13, fontWeight: "bold" },
//                         ]}
//                       >
//                         DGDC LIMITED {"\n\n"}
//                       </Text>
//                     </View>

//                     <View style={{ flexDirection: "row", paddingTop: 9 }}>
//                       <View style={{ width: "80%" }}>
//                         <Text style={{ fontSize: 9 }}>
//                           {currentPageRecords[0].airlineName} CARGO SECTION
//                           {"\n"}
//                           {"\n"}
//                         </Text>
//                         <Text style={{ fontSize: 9, maxWidth: 180 }}>
//                           SAHAR MUMBAI - 400 099
//                           {"\n"}
//                         </Text>
//                       </View>

//                       <View style={{ width: "20%", paddingBottom: 9 }}>
//                         <Text style={{ fontSize: 9 }}>
//                           Date :{formatedDate(currentPageRecords[0].tpDate)}
//                           {"\n"}
//                           {"\n"}
//                         </Text>
//                         <Text style={{ fontSize: 9 }}>
//                           Page No :{currentPageNumber}
//                           {"\n"}
//                         </Text>
//                       </View>
//                     </View>

//                     <View>
//                       <Text style={styles.centeredText}>
//                         SUB : PCTM NO{" "}
//                         {formatPctmNo(currentPageRecords[0].pctmNo)}
//                         {"\n"}
//                         {"\n"}
//                         {"\n"}
//                       </Text>
//                     </View>
//                     <Text style={{ fontSize: 9 }}>
//                       Sir, {"\n"}
//                       {"\n"}
//                     </Text>
//                     <Text style={{ fontSize: 9 }}>
//                       PLEASE ACKNOWLEDGE RECEIPT OF THE FOLLOWING DOCUMENTS BY
//                       SIGNING AND RETURNED THE DUPLICATE OF THE LETTER {"\n"}{" "}
//                       {"\n"}
//                       {"\n"}
//                     </Text>
//                     <Text style={{ fontSize: 9 }}>
//                       1. A.W. BILL : {airwayBillCountForPctmNo} {"\n"}{" "}
//                     </Text>
//                     <Text style={{ fontSize: 9 }}>
//                       2. SHIPPING BILL BOTH{"\n"}
//                       DUPLICATE & EP COPY : {totalRecords} {"\n"}{" "}
//                     </Text>
//                     <Text style={{ fontSize: 9 }}>
//                       3. TOTAL NUMBER OF PACKAGES: {totalNoOfPackages}
//                       {"\n"} {"\n"}{" "}
//                     </Text>
//                   </View>
//                   <View>
//                     <Text style={{ fontSize: 9 }}>
//                       THE PARCEL PERTAINING TO THE ABOVE DOCUMENTS HAS BEEN
//                       DEPOSITED AT OUR SAHAR STRONG ROOM KINDLY ARRANGE TO
//                       COLLECT THE SAME {"\n"} {"\n"}
//                       {"\n"}
//                     </Text>
//                   </View>
//                   <View
//                     style={{ flexDirection: "row", justifyContent: "flex-end" }}
//                   >
//                     <View>
//                       <Text style={{ fontSize: 9 }}>
//                         YOURS FAITHFULLY {"\n"} {"\n"}
//                         {"\n"}
//                       </Text>
//                       <Text style={{ fontSize: 9 }}>
//                         FOR CUSTODIAN {"\n"} {"\n"}
//                         {"\n"}
//                         {"\n"}
//                         {"\n"}
//                       </Text>
//                     </View>
//                   </View>
//                   <View
//                     style={{
//                       flexDirection: "row",
//                       justifyContent: "flex-start",
//                     }}
//                   >
//                     <View>
//                       <Text style={{ fontSize: 9 }}>FOR AIRLINE USE</Text>
//                       <Text style={{ fontSize: 9, paddingTop: 9 }}>
//                         RECEIVED THE ABOVE MENTIONED AS DETAILED IN THE PCTM NO
//                         {"\n"}
//                       </Text>
//                       <Text style={{ fontSize: 9, paddingTop: 9 }}>
//                         DATE :{formatedDate(currentPageRecords[0].tpDate)}
//                         {"\n"}
//                       </Text>
//                       <Text style={{ fontSize: 9, paddingTop: 9 }}>
//                         SIGNATURE :{"\n"}
//                       </Text>
//                       <Text style={{ fontSize: 9, paddingTop: 9 }}>
//                         NAME :{"\n"}
//                       </Text>
//                     </View>
//                   </View>
//                 </Page>
//               )}
//             </>
//           );
//         })}
//       </Document>
//     );
//   };


//   //Export PCTM

// const formatDateTime2 = (value) => {
//   if (!value) {
//     return "";
//   }
//   const date = new Date(value);
//   const day = String(date.getDate()).padStart(2, "0");
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const year = date.getFullYear();
//   const hours = String(date.getHours()).padStart(2, "0");
//   const minutes = String(date.getMinutes()).padStart(2, "0");
//   const seconds = String(date.getSeconds()).padStart(2, "0");
//   return `${day}/${month}/${year} `;
// };
// const getExcel = (imp) => {
//   const filename = `Export_PCTM_${formatDateTime2(new Date())}.xlsx`; // Note: Changed file extension to xlsx
//   axios.post(`https://${ipaddress}export/pctmexcel`, imp, { responseType: 'blob' }) // Added responseType: 'blob'
//     .then(async (response) => {
//       const blob = new Blob([response.data], { type: response.headers['content-type'] });

//       // Create a temporary URL for the blob
//       const url = window.URL.createObjectURL(blob);

//       // Create a link element to trigger the download
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = filename;
//       document.body.appendChild(a);
//       a.click();

//       // Clean up
//       window.URL.revokeObjectURL(url);
//       document.body.removeChild(a);
//     })
//     .catch((error) => {
//       toast.error("Something went wrong", {
//         autoClose: 700
//       });
//     });
//   }



//   const pctmPrint = async(data,data1) =>{
//     // console.log("pctmdata ",data)
//     const combineData = {
//       tpData: data,
//       tpSummery: data1
//     }

//     const params = {
//       companyId: companyid,
//       branchId: branchId,
//       serDate: formattedSerDate,
//       tpNo: selectedCartingAgentTpNo,
//       custodian: Custodian,
//       cartingAgent:selectedCartingAgent   
//     };



//     await axios.post(`https://${ipaddress}export/exportPctmReport`,combineData,{params})
//       .then((response)=>{
//         const base64PDF = response.data;

//         // Create a new window for displaying the PDF
//         const newWindow = window.open("", "_blank");

//         // Write the HTML content to the new window
//         newWindow.document.write(`
//         <!DOCTYPE html>
//         <html>
//         <head>
//           <title>Export PCTM</title>
//           <style>
//             body {
//               margin: 0;
//               padding: 0;
//               overflow: hidden;
//             }
//             embed {
//               width: 100vw;
//               height: 100vh;
//             }
//           </style>
//         </head>
//         <body>
//           <embed src="data:application/pdf;base64,${base64PDF}" type="application/pdf" width="100%" height="100%">
//         </body>
//         </html>
//       `);
//       })
//       .catch((error)=>{
//         if(error){
//           toast.error("Something went wrong",{
//             autoClose:1000
//           })
//         }
//       })      
//   }


//   const pctmPdf = async(data,data1) =>{
//     console.log("pctmdata ",data)
//     const combineData = {
//       tpData: data,
//       tpSummery: data1
//     }
//     const params = {
//       companyId: companyid,
//       branchId: branchId,
//       serDate: formattedSerDate,
//       tpNo: selectedCartingAgentTpNo,
//       custodian: Custodian,
//       cartingAgent:selectedCartingAgent   
//     };



//     await axios.post(`https://${ipaddress}export/exportPctmReport`,combineData,{params})
//           .then((response)=>{
//         const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF
//         // Create a Blob from the Base64 data
//         const pdfBlob = new Blob([Uint8Array.from(atob(pdfBase64), c => c.charCodeAt(0))], { type: 'application/pdf' });
//         // Create a URL for the Blob
//         const blobUrl = URL.createObjectURL(pdfBlob);
//         // Create an anchor element for downloading
//         const downloadLink = document.createElement('a');
//         downloadLink.href = blobUrl;
//         downloadLink.download = 'ExportPctm.pdf'; // Set the filename for the downloaded PDF
//         downloadLink.style.display = 'none';
//         document.body.appendChild(downloadLink);
//         // Trigger the download
//         downloadLink.click();
//         // Clean up
//         document.body.removeChild(downloadLink);
//         window.URL.revokeObjectURL(blobUrl);
//         toast.success("Downloading Pdf!", {
//             position: toast.POSITION.TOP_CENTER,
//             autoClose: 800,
//         });
//       })
//       .catch((error)=>{
//         if(error){
//           toast.error("Something went wrong",{
//             autoClose:1000
//           })
//         }
//       })      
//   }
  


//   const ackPrint = async(data,data1) =>{
//     console.log("pctmdata ",data)
//     const combineData = {
//       tpData: data,
//       tpSummery: data1
//     }
//     await axios.post(`https://${ipaddress}export/exportAckReport?companyId=${companyid}&branchId=${branchId}&tpNo=${selectedCartingAgentTpNo}&serDate=${formattedSerDate}`,combineData)
//       .then((response)=>{
//         const base64PDF = response.data;

//         // Create a new window for displaying the PDF
//         const newWindow = window.open("", "_blank");

//         // Write the HTML content to the new window
//         newWindow.document.write(`
//         <!DOCTYPE html>
//         <html>
//         <head>
//           <title>Export Acknowlegement</title>
//           <style>
//             body {
//               margin: 0;
//               padding: 0;
//               overflow: hidden;
//             }
//             embed {
//               width: 100vw;
//               height: 100vh;
//             }
//           </style>
//         </head>
//         <body>
//           <embed src="data:application/pdf;base64,${base64PDF}" type="application/pdf" width="100%" height="100%">
//         </body>
//         </html>
//       `);
//       })
//       .catch((error)=>{
//         if(error){
//           toast.error("Something went wrong",{
//             autoClose:1000
//           })
//         }
//       })      
//   }

//   const ackPdf = async(data,data1) =>{
//     console.log("pctmdata ",data)
//     const combineData = {
//       tpData: data,
//       tpSummery: data1
//     }
//     await axios.post(`https://${ipaddress}export/exportAckReport?companyId=${companyid}&branchId=${branchId}&tpNo=${selectedCartingAgentTpNo}&serDate=${formattedSerDate}`,combineData)
//       .then((response)=>{
//         const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF
//         // Create a Blob from the Base64 data
//         const pdfBlob = new Blob([Uint8Array.from(atob(pdfBase64), c => c.charCodeAt(0))], { type: 'application/pdf' });
//         // Create a URL for the Blob
//         const blobUrl = URL.createObjectURL(pdfBlob);
//         // Create an anchor element for downloading
//         const downloadLink = document.createElement('a');
//         downloadLink.href = blobUrl;
//         downloadLink.download = 'ExportAcknowledgement.pdf'; // Set the filename for the downloaded PDF
//         downloadLink.style.display = 'none';
//         document.body.appendChild(downloadLink);
//         // Trigger the download
//         downloadLink.click();
//         // Clean up
//         document.body.removeChild(downloadLink);
//         window.URL.revokeObjectURL(blobUrl);
//         toast.success("Downloading Pdf!", {
//             position: toast.POSITION.TOP_CENTER,
//             autoClose: 800,
//         });
//       })
//       .catch((error)=>{
//         if(error){
//           toast.error("Something went wrong",{
//             autoClose:1000
//           })
//         }
//       })      
//   }


//   return (

//     <div className="Container" >
//       <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }} > <FontAwesomeIcon
//         icon={faFile}
//         style={{
//           marginRight: '8px',
//           color: 'black', // Set the color to golden
//         }}
//       />Export PCTM</h5>
//       <Card>
//         <Container>
//           <div className="Container" style={{ marginTop: 20 }}>
//             <ul className="nav nav-tabs" id="myTab" role="tablist">
//               <li className="nav-item tabspace" role="presentation">
//                 <button
//                   style={{ color: "gray" }}
//                   className="nav-link active"
//                   id="home-tab"
//                   data-bs-toggle="tab"
//                   data-bs-target="#home"
//                   type="button"
//                   role="tab"
//                   aria-controls="home"
//                   aria-selected="true"
//                   onClick={(e)=>makeFieldEmpty()}
//                 >
//                   <h6>Generate</h6>
//                 </button>
//               </li>

//               <li className="nav-item tabspace" role="presentation">
//                 <button
//                   style={{ color: "gray" }}
//                   className="nav-link"
//                   id="contact-tab"
//                   data-bs-toggle="tab"
//                   data-bs-target="#contact"
//                   type="button"
//                   role="tab"
//                   aria-controls="contact"
//                   aria-selected="false"
//                   onClick={(e)=>makeFieldEmpty()}
//                 >
//                   <h6>Print</h6>
//                 </button>
//               </li>
//             </ul>
//             <div className="tab-content" id="myTabContent">
//               <div
//                 className="tab-pane fade show active"
//                 id="home"
//                 role="tabpanel"
//                 aria-labelledby="home-tab"
//               >
//                 <Card
//                   style={{
//                     marginTop: 25,
//                     marginBottom: 25,
//                     padding: 8,
//                   }}
//                 ><h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '3%', marginBottom: '10px' }} > <FontAwesomeIcon
//                   icon={faBolt}
//                   style={{
//                     marginRight: '8px',
//                     color: 'black', // Set the color to golden
//                   }}
//                 /> Generate</h5>
//                   <Container>
//                     <Form>
//                       <Row>
//                         <Col md={3}>
//                           <Form.Group>
//                             <Form.Label className="forlabel">
//                               SER Date From
//                             </Form.Label>
//                             <DatePicker
//                               selected={startDate}
//                               wrapperClassName="custom-react-datepicker-wrapper"
//                               onChange={(date) => {
//                                 if (date) {
//                                   setStartDate(date);
//                                 }
//                               }}
//                               value={startDate}
//                               dateFormat="dd/MM/yyyy"
//                               className="form-control"
//                               customInput={<input style={{ width: '100%' }} />}
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col md={3}>
//                           <Form.Group>
//                             <Form.Label className="forlabel">
//                               SER Date To
//                             </Form.Label>
//                             <DatePicker
//                               selected={endDate}
//                               wrapperClassName="custom-react-datepicker-wrapper"
//                               onChange={(date) => {
//                                 if (date) {
//                                   setEndDate(date);
//                                 }
//                               }}
//                               value={endDate}
//                               dateFormat="dd/MM/yyyy"
//                               className="form-control"
//                               customInput={<input style={{ width: '100%' }} />}
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col md={2}>
//                           <Button
//                             color="primary"
//                             outline
//                             style={{ marginTop: 32 }}
//                             onClick={handleShow}
//                           >
//                             <FontAwesomeIcon
//                               icon={faArrowsToEye}
//                               style={{ marginRight: "5px" }}
//                             />
//                             Show
//                           </Button>
//                         </Col>
//                       </Row>
//                     </Form>
//                   </Container>
//                 </Card>
//               </div>
//               {exportData.length > 0 && (
//                 <div style={{ paddingTop: 27 }}>
//                   <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '3%', paddingRight: '-50px' }} > <FontAwesomeIcon
//                     icon={faFile}
//                     style={{
//                       marginRight: '8px',
//                       color: 'black', // Set the color to golden
//                     }}
//                   /> Export Data</h5>

//                   <div className="table-responsive">
//                     <Table className="table table-striped table-hover">
//                       <thead>
//                         <tr>
//                           <th style={{ backgroundColor: '#BADDDA' }}>Sr.No</th>
//                           <th style={{ backgroundColor: '#BADDDA' }}>SER No</th>
//                           <th style={{ backgroundColor: '#BADDDA' }}>
//                             Parcel Type
//                           </th>
//                           <th style={{ backgroundColor: '#BADDDA' }}>
//                             Destination
//                           </th>
//                           <th style={{ backgroundColor: '#BADDDA' }}>
//                             No Of Packages
//                           </th>
//                           <th style={{ backgroundColor: '#BADDDA' }}>
//                             Shipping Bill No
//                           </th>
//                           <th style={{ backgroundColor: '#BADDDA' }}>
//                             Neight Weight
//                           </th>
//                           <th style={{ backgroundColor: '#BADDDA' }}>
//                             Airway Bill No
//                           </th>
//                           {/* Add more column headers as needed */}
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {exportData.map((item, index) => (
//                           <tr key={index}>
//                             <td>{index + 1}</td>
//                             <td>{item.serNo}</td>
//                             <td>{item.serNo}</td>
//                             <td>{item.countryOfDestination}</td>
//                             <td>{item.noOfPackages}</td>
//                             <td>{item.sbNo}</td>
//                             <td>{item.grossWeight}</td>
//                             <td>{item.airwayBillNo}</td>
//                             {/* Add more table cells as needed */}
//                           </tr>
//                         ))}
//                       </tbody>
//                     </Table>
//                   </div>
//                   <Link
//                     to={{
//                       path: "/pdfReport",
//                       state: {
//                         exportData: pdfReportData, // Pass the imported data to the PDFReport component
//                         startDate: formattedStartDate,
//                         endDate: formattedEndDate,
//                         //  selectedAirlineFlightdate:selectedAirlineFlightdate,
//                       },
//                     }}
//                     style={{ float: "right", textDecorationColor: "red" }}
//                     onClick={generateExportPCTM}
//                   >
//                     Generate PCTM
//                   </Link>
//                 </div>
//               )}

//               <div
//                 className="tab-pane fade"
//                 id="contact"
//                 role="tabpanel"
//                 aria-labelledby="contact-tab"
//               >
//                 <Card
//                   style={{
//                     marginTop: 25,

//                     padding: 8,
//                   }}
//                 ><h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '3%', marginBottom: '10px' }} > <FontAwesomeIcon
//                   icon={faPrint}
//                   style={{
//                     marginRight: '8px',
//                     color: 'black', // Set the color to golden
//                   }}
//                 /> Print</h5>

//                   <Container>
//                     <Form>
//                       <Row>
//                         <Col md={3}>
//                           <Form.Group>
//                             <Form.Label className="inputhead">Date</Form.Label>
//                             <div>
//                               <DatePicker
//                                 selected={serDate}
//                                 wrapperClassName="custom-react-datepicker-wrapper"
//                                 onChange={(date) => {
//                                   if (date) {
//                                     setSerDate(date);
//                                   }
//                                 }}
//                                 value={serDate}
//                                 dateFormat="dd/MM/yyyy"
//                                 className="form-control"
//                                 customInput={<input style={{ width: '100%' }} />}
//                               />

//                             </div>
//                           </Form.Group>
//                         </Col>
//                         <Col md={3}>
//                           <Form.Group>
//                             <Form.Label className="inputhead">
//                               Carting Agent
//                             </Form.Label>
//                             {/* Use the Select component for the dropdown */}



//                             <Select
//                               name="cartingAgent"
//                               options={cartingAgents.map((cartingAgent) => ({
//                                 label: namesMap[cartingAgent], // Map the value to the userName
//                                 value: cartingAgent,
//                               }))}
//                               value={{
//                                 label: namesMap[selectedCartingAgent], // Map the selected value to the userName
//                                 value: selectedCartingAgent,
//                               }}
//                               onChange={(option) => setSelectedCartingAgent(option.value)}
//                             />


//                           </Form.Group>
//                         </Col>
//                         <Col md={3}>
//                           <Form.Label className="inputhead">
//                             Transhipment Permit No
//                           </Form.Label>
//                           <Select
//                           name="tpNumbers"
//                           options={tpNumbers.map((tpNumber) => ({
//                             label: formatTpNo(tpNumber),
//                             value: tpNumber,
//                           }))}
//                           value={{
//                             label: formatTpNo(selectedCartingAgentTpNo),
//                             value: selectedCartingAgentTpNo,
//                           }}
//                           onChange={(option) =>
//                             setSelectedCartingAgentTpNo(option.value)
//                           }
//                         />
//                         </Col>

//                         <Col md={3} >
//                 <Form.Group>
//                   <Form.Label className="forlabel">
//                     Custodian
//                   </Form.Label>

//                   {/* <label className="forlabel">Vehicle No</label> */}
//                   <Input
//                     type="text"
//                     name="Custodian"
//                     placeholder="Enter Custodian Name"
//                     className=""
//                     value={Custodian}
//                     onChange={(e) => setCustodian(e.target.value)}
//                   />
//                 </Form.Group>
//               </Col>



                       
//                       </Row>



//                       <div className="d-flex justify-content-center align-items-center">
//                 <div style={{ marginTop: 18 }}>
//                           <Button
//                             color="success"
//                             outline
//                             style={{ marginTop: 32, marginRight: 9 }}
//                             onClick={handleShowTPData}
//                           >
//                             <FontAwesomeIcon
//                               icon={faSearch}
//                               style={{ marginRight: "5px" }}
//                             />
//                             Show
//                           </Button>

//                           <Button
//                             outline
//                             color="danger"
//                             style={{ marginTop: 32 }}
//                             onClick={(e) => makeFieldEmpty()}
//                           >
//                             <FontAwesomeIcon
//                               icon={faUndo}
//                               style={{ marginRight: "5px" }}
//                             // onClick={handleResetForm}
//                             />
//                             Reset
//                           </Button>
//                           </div>
//               </div>


//                     </Form>
//                   </Container>
//                 </Card>

//                 <Card style={{ marginTop: 18, marginBottom: 18 }}>
//                   <Container>
//                     {allTpData.length > 0 && (
//                       <div>
//                         {allTpData.length > 0 && (
//                           <div style={{ marginTop: 23 }}>
//                             <Row>
//                               <Col md={2} > <Label className="forlabel" for="search">Download PCTM </Label></Col>
//                               <Col md={4} >


                             


//                                 <button
//                                   style={{ marginLeft: 9, marginTop: 5 }}
//                                   className="btn btn-outline-primary btn-margin"
//                                   type="button"
//                                   onClick={()=>pctmPrint(allTpData,allTpDataSummery)}
//                                 > <FontAwesomeIcon icon={faPrint} style={{ marginRight: '5px' }} />
//                                   Print
//                                 </button>
//                                 <button
//                                   className="btn btn-outline-success btn-margin"
//                                   type="button"
//                                   onClick={()=>pctmPdf(allTpData,allTpDataSummery)}
//                                   style={{ marginLeft: "10px", marginTop: 5 }}
//                                 ><FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
//                                   PDF
//                                 </button>
//                                 <button
//                                   className="btn btn-outline-primary btn-margin"
//                                   type="button"
//                                   style={{ marginLeft: "10px", marginRight: 9, marginTop: 5 }}
//                                   onClick={()=>getExcel(allTpData)}
//                                 ><FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '5px' }} />
//                                   XLS
//                                 </button>
//                               </Col>

//                               <Col md={6}>

//                                 <Row>

//                                   <Col md={6}>

//                                     <Label className="forlabel" for="user_Type">

//                                       Download Acknowledgement
//                                     </Label>

//                                   </Col>
//                                   <Col md={6}>

//                                     <button
                                     
//                                       className="btn btn-outline-primary btn-margin"
//                                       type="button"
//                                       onClick={()=>ackPrint(allTpData,allTpDataSummery)}
//                                     ><FontAwesomeIcon icon={faPrint} style={{ marginRight: '5px' }} />
//                                       Print
//                                     </button>
                                 

//                                     <button
//                                       className="btn btn-outline-success btn-margin"
//                                       type="button"
//                                       onClick={()=>ackPdf(allTpData,allTpDataSummery)}
//                                       style={{ marginLeft: '9px' }}
//                                     ><FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
//                                       PDF
//                                     </button>


//                                   </Col>


//                                 </Row>




//                               </Col>

//                             </Row>
//                           </div>
//                         )}

//                         <Table
//                           striped
//                           responsive
//                           bordered
//                           style={{ marginTop: 9 }}
//                         >
//                           <thead>
//                             <tr>
//                               <th style={{ backgroundColor: '#BADDDA' }}>
//                                 Sr.No
//                               </th>
//                               <th style={{ backgroundColor: '#BADDDA' }}>
//                                 SER No
//                               </th>
//                               <th style={{ backgroundColor: '#BADDDA' }}>
//                                 Parcel Type
//                               </th>
//                               <th style={{ backgroundColor: '#BADDDA' }}>
//                                 Destination
//                               </th>
//                               <th style={{ backgroundColor: '#BADDDA' }}>
//                                 No Of Packages
//                               </th>
//                               <th style={{ backgroundColor: '#BADDDA' }}>
//                                 Shipping Bill No
//                               </th>
//                               <th style={{ backgroundColor: '#BADDDA' }}>
//                                 NET Weight(Grams)
//                               </th>
//                               <th style={{ backgroundColor: '#BADDDA' }}>
//                                 Airway Bill No
//                               </th>
//                             </tr>
//                           </thead>
//                           <tbody>
//                             {allTpData.map((item, index) => (
//                               <tr key={index}>
//                                 <td>{index + 1}</td>
//                                 <td>{item[0]}</td>
//                                 <td>{item[7] === 'Y'?'SC':item[8] === 'Y' ? 'P':item[9]==='Y'?'HW':''}</td>
//                                 <td>
//                                   {item[1] &&
//                                   item[2]
//                                     ? `${item[1]} | ${item[2]}`
//                                     : ""}
//                                 </td>
//                                 <td>{item[3]}</td>
//                                 <td>{item[4]}</td>
//                                 <td style={{textAlign:'right'}}>{item[5]}</td>
//                                 <td>{item[6]}</td>
//                                 {/* Add more table cells as needed */}
//                               </tr>
//                             ))}
//                           </tbody>
//                         </Table>
//                       </div>
//                     )}
//                     {allTpDataSummery.length > 0 && (
//                       <div>
//                         <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '4%', paddingRight: '-50px' }} > <FontAwesomeIcon
//                           icon={faDatabase}
//                           style={{
//                             marginRight: '8px',
//                             color: 'black', // Set the color to golden
//                           }}
//                         /> Summary Data</h5>

//                         <div className="table-responsive">
//                           <Table className="table table-striped table-hover">


//                             <thead>
//                               <tr>
//                                 <th
//                                   style={{ backgroundColor: '#BADDDA' }}
//                                 >
//                                   Airline Name
//                                 </th>
//                                 <th
//                                   style={{ backgroundColor: '#BADDDA' }}
//                                 >
//                                   No Of Packages
//                                 </th>
//                                 <th
//                                   style={{ backgroundColor: '#BADDDA' }}
//                                 >
//                                   SB Count
//                                 </th>
//                                 <th
//                                   style={{ backgroundColor: '#BADDDA' }}
//                                 >
//                                   MAWB Count
//                                 </th>
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {allTpDataSummery.map((item, index) => (
//                                 <tr key={index}>
//                                   <td style={{ width: "20%" }}>
//                                     {item[1]}
//                                   </td>
//                                   <td style={{ width: "20%" }}>
//                                     {item[2]}
//                                   </td>
//                                   <td style={{ width: "20%" }}>{item[3]}</td>
//                                   <td style={{ width: "20%" }}>
//                                     {item[4]}
//                                   </td>{" "}
//                                   {/* Display distinct MAWB count */}
//                                 </tr>
//                               ))}
//                               <tr >
//                               <th
//                                   style={{ backgroundColor: '#BADDDA' }}
//                                 >
//                                  Total {allTpDataSummery.length} Airlines
//                                 </th>
//                                 <th
//                                   style={{ backgroundColor: '#BADDDA' }}
//                                 >
//                                   {sumOfNop}
//                                 </th>
//                                 <th
//                                   style={{ backgroundColor: '#BADDDA' }}
//                                 >
//                                   {sumOfSB}
//                                 </th>
//                                 <th
//                                   style={{ backgroundColor: '#BADDDA' }}
//                                 >
//                                   {sumOfMAWB}
//                                 </th>
//                                 </tr>

//                             </tbody>
//                           </Table>
//                         </div>
//                       </div>
//                     )}
//                   </Container>
//                 </Card>
//               </div>
//             </div>
//           </div>
//         </Container>
//       </Card>
//     </div>
//   );
// }





import { Link, redirect } from "react-router-dom";
import AuthContext from "../Components/AuthProvider";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import "../Components/Style.css";
import dgdcImage from "../Images/report.jpeg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Select from "react-select";
import {
  Button, Card, CardBody, Label,
  Table, Input, FormGroup
} from "reactstrap";
import PDFReport from "./PDFReport";
import { saveAs } from "file-saver"; // Import file-saver for triggering the download
import * as XLSX from "xlsx"; // Import XLSX library for creating XLS files
import "@react-pdf-viewer/core/lib/styles/index.css";
import ipaddress from "../Components/IpAddress";
import { toast } from "react-toastify";
import PartyListTable from "../Parent_Pages/PartyListTable";
import {
  faDatabase,
  faFile,
  faFileExcel,
  faFilePdf,
  faPrint,
  faSave,
  faSearch,
  faSyncAlt,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  pdf,
  Line,
  BlobProvider,
} from "@react-pdf/renderer";
import ReactLoading from 'react-loading';
import moment from 'moment';
import Rate_Chart_Service from "../services/Rate_Chart_Service";


export default function ExportPCTM() {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const currentDate = new Date();
  const currentDateString = currentDate.toISOString().split("T")[0];
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const reactPageName = "Export PCTM Data Party";
  const [cartingAgents, setCartingAgents] = useState([]);
  const [tpNumbers, setTpNumbers] = useState([]);
  const [selectedCartingAgent, setSelectedCartingAgent] = useState("");
  const [serDate, setSerDate] = useState(new Date());
  const [selectedCartingAgentTpNo, setSelectedCartingAgentTpNo] = useState("");
  const [exportTpData, setExportTpData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [importAllData, setImportAllData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showImportDataTable, setShowImportDataTable] = useState(false);
  const [pdfReportData, setPDFReportData] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [summaryDataForTPData, setSummaryDataForTPData] = useState([]); // Define the state variable
  const [ReordList, setReordList] = useState([]);
  const [filteredExportData, setfilteredExportData] = useState([]);
  const [distinctMawbCount, setDistinctMawbCount] = useState(0); // Define the state variable
  const formattedStartDate = startDate.toISOString().split('T')[0]; // Extract yyyy-MM-dd
  const formattedEndDate = endDate.toISOString().split('T')[0];
  const formattedSerDate = serDate.toISOString().split('T')[0];
  const [Custodian, setCustodian] = useState("Sunil Patil");




  const {
    jwtToken,
    user_Id,
    username,
    branchId,
    companyid,
    role,
    companyname,
    branchname,
    login,
    logout,
  } = useContext(AuthContext);


  const [loading, setLoading] = useState(false);
  const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    },
  };

  const [errors, setErrors] = useState({});

  const handleConsoleChange = selectedOption => {
    setSelectedConsole(selectedOption);
    setSearchCriteria({
      ...searchCriteria, console: selectedOption ? selectedOption.value : '',
      consoleName: selectedOption ? selectedOption.label : ''
    });

    setExportTpData([]);
    setExportData([]);
    setSummaryDataForTPData([]);
    setAllTpDataSummery([]);
    setAllTpData([]);
  };

  const handleCartingChange = selectedOption => {
    setSelectedCarting(selectedOption);
    setSearchCriteria({
      ...searchCriteria, carting: selectedOption ? selectedOption.value : '',
      cartingName: selectedOption ? selectedOption.label : ''
    });
    if (errors.carting) {
      const updatedErrors = { ...errors };
      delete updatedErrors.carting;
      setErrors(updatedErrors);
    }

  };




  const handleTpNoChange = async selectedOption => {
    setSearchCriteria({ ...searchCriteria, console: '' });
    setConsoleArray([]);
    setSelectedConsole(null)

    setSelectedTpNo(selectedOption);
    setSearchCriteria({ ...searchCriteria, tpNo: selectedOption ? selectedOption.value : '' });
    if (errors.tpNo) {
      const updatedErrors = { ...errors };
      delete updatedErrors.tpNo;
      setErrors(updatedErrors);
    }
  };



  const CustomHeader = () => {
    return (
      <View style={styles.header}>
        <Image src={dgdcImage} style={styles.image} />
      </View>
    );
  };

  const makeFieldEmpty = () => {
    setSelectedCarting(null);
    setSelectedTpNo(null);
    setSelectedConsole(null);
    setSerDate(new Date());
    setExportTpData([]);
    setExportData([]);
    setSummaryDataForTPData([]);
    setAllTpDataSummery([]);
    setAllTpData([]);
    setSearchCriteria(initialSearchCriteria);
    setErrors([]);
  };


  const formatPctmNo = (pctmNo) => {
    // Remove leading zeros using a regular expression
    return pctmNo.replace(/^0+/, "");
  };

  const formatTpNo = (tpNo) => {
    // Remove leading zeros using a regular expression
    return tpNo.replace(/^0+/, "");
  };

  const [consoleArray, setConsoleArray] = useState([])
  const [selectedConsole, setSelectedConsole] = useState(null);
  const [cartingArray, setCartingArray] = useState([])
  const [selectedCarting, setSelectedCarting] = useState(null);

  const [tpNoArray, setTpNoArray] = useState([]);
  const [selectedTpNo, setSelectedTpNo] = useState(null);


  const formatDateNew = (date) => {
    if (!date) {
      date = new Date();
    }
    return moment(date).format('YYYY-MM-DD');
  };


  const initialSearchCriteria = {
    companyId: companyid,
    branchId: branchId,
    tpNo: '',
    startDate: formatDateNew(new Date()),
    endDate: formatDateNew(new Date()),
    Custodian: 'Sunil Patil',
    console: '',
    consoleName: '',
    carting: '',
    cartingName: '',
    userType: 'console'
  };
  const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);

  // const handleSearch = async (searchCriteria) => {
  //   setLoading(true);
  //   try {
  //     const response = await Rate_Chart_Service.searchExportTpData({ params: searchCriteria });
  //     const data = response.data;
  //     if (data && data.length > 0) {
  //       settpall(data);
  //       console.log("tp dataaa \n", data);
  //     } else {
  //       setTpdata([]);
  //     }
  //     setError([]);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setError(error); // Set the error state if there's an error
  //   }finally
  //   {
  //     setLoading(false);
  //   }
  // };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };


  const findTpNumbers = async () => {
    setLoading(true);
    setTpNoArray([]);
    setSelectedTpNo(null);
    setAllTpData([]);
    try {
      const resultSet = await Rate_Chart_Service.searchTp({ params: searchCriteria });
      setTpNoArray(resultSet.data);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
    finally {
      setLoading(false);
    }
  }



  const findConsoles = async () => {
    setLoading(true);
    setConsoleArray([]);
    setSelectedConsole(null);
    try {
      const partyOptions = await Rate_Chart_Service.searchConsoleForExportTp({ params: searchCriteria })
      setConsoleArray(partyOptions.data);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
    finally {
      setLoading(false);
    }
  }


  const findCartingAgent = async () => {
    setLoading(true);
    setCartingArray([]);
    setSelectedCarting(null);
    try {
      const partyOptions = await Rate_Chart_Service.searchCartingForExportPctm({ params: searchCriteria })
      setCartingArray(partyOptions.data);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
    finally {
      setLoading(false);
    }
  }



  // Effect to call findTpNumbers when tpDate changes
  useEffect(() => {
    findCartingAgent();
    findTpNumbers();
  }, [searchCriteria.startDate]); // Dependency array

  useEffect(() => {
    findConsoles();
  }, [searchCriteria.tpNo, searchCriteria.startDate]); // Dependency array



  // Handle change for date input
  const handleDateChange = (date) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      startDate: formatDateNew(date),
      endDate: formatDateNew(date)
    }));

    if (errors.startDate) {
      const updatedErrors = { ...errors };
      delete updatedErrors.startDate;
      setErrors(updatedErrors);
    }
  };

  

  const handleShow = async () => {
    // You can use this function to display the fetched data in a table or perform other actions


    // console.log("dd", formattedStartDate);
    // console.log("dd", formattedEndDate);

    await axios
      .get(
        `https://${ipaddress}export/exportData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
      )
      .then((response) => {
        const exportData = response.data;

        setExportData(exportData);
        console.log("EXport data ", exportData);
        setShowTable(true);
        setTotalEntries(exportData.length);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  
  useEffect(() => {
    if (!isAuthenticated) {
      navigate(
        "/login?message=You need to be authenticated to access this page."
      );
    }
  }, [isAuthenticated, navigate]);

  // useEffect(() => {
  //   // Fetch data from your backend API
  //   axios
  //     .get(`https://${ipaddress}export/all/${companyid}/${branchId}`)
  //     .then((response) => {
  //       // Assuming your API response is an array of Import objects
  //       setImportAllDatalist(importAllDatalist);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };


  const [allTpData, setAllTpData] = useState([]);
  const [sumOfNop, setSumOfNop] = useState(0);
  const [sumOfSB, setSumOfSB] = useState(0);
  const [sumOfMAWB, setSumOfMAWB] = useState(0);

  const [allTpDataSummery, setAllTpDataSummery] = useState([]);

  const handleShowTPData = async (searchCriteria) => {
    const error = {};
    // Validate startDate
    if (!searchCriteria.startDate) {
      error.startDate = "Start Date is required";
    }
    // Validate carting
    if (!searchCriteria.carting) {
      error.carting = "Carting is required";
    }
    // Validate tpNo
    if (!searchCriteria.tpNo) {
      error.tpNo = "TP No is required";
    }
    // Check if any errors exist
    if (Object.keys(error).length > 0) {

      toast.error("Select required fields", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1200,
      });
      setErrors(error);
      // Return early if there are errors
      return;
    }
    setLoading(true);
    try {
      // const response = await axios.get(
      //   `https://${ipaddress}export/getExportTpData?companyId=${companyid}&branchId=${branchId}&serDate=${formattedSerDate}&cartingAgent=${selectedCartingAgent}&tpNo=${selectedCartingAgentTpNo}`
      // );


      const response = await Rate_Chart_Service.searchExportPctmData({ params: searchCriteria });

      console.log(' response handleShowTPData \n', response.data);
      setAllTpData(response.data.tpData);
      setAllTpDataSummery(response.data.tpSummary);

      const calculatedSumOfNop = response.data.tpSummary.reduce((acc, curr) => acc + curr[2], 0);
      const calculatedSumOfSB = response.data.tpSummary.reduce((acc, curr) => acc + curr[3], 0);
      const calculatedSumOfMAWB = response.data.tpSummary.reduce((acc, curr) => acc + curr[4], 0);

      setSumOfNop(calculatedSumOfNop);
      setSumOfSB(calculatedSumOfSB);
      setSumOfMAWB(calculatedSumOfMAWB);
    } catch (error) {
      console.error("Error fetching PCTM data:", error);
      toast.error("Error fetching PCTM data", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
      console.log('Finished handling TP Data request.');
    }
  };






  

  const [pctmRecords, setPctmRecords] = useState({});
  const [uniquePCTMs, setUniquePCTMs] = useState(new Set());

  useEffect(() => {
    const uniquePCTMSet = new Set(); // Create a new Set
    const pctmGroupedRecords = {};

    ReordList.forEach((item) => {
      const { pctmNo } = item;
      uniquePCTMSet.add(pctmNo);

      // Add the record to the corresponding MAWB group in the object
      if (!pctmGroupedRecords[pctmNo]) {
        pctmGroupedRecords[pctmNo] = [];
      }
      pctmGroupedRecords[pctmNo].push(item);
    });
    setUniquePCTMs(uniquePCTMs); // Update the state with unique MAWB values
    setPctmRecords(pctmRecords); // Update the state with grouped records
  }, [ReordList]);

 
  
  const formatDateTime2 = (value) => {
    if (!value) {
      return "";
    }
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}/${month}/${year} `;
  };
  const getExcel = async (imp) => {
    const filename = `Export_PCTM_${formatDateTime2(new Date())}.xlsx`; // Note: Changed file extension to xlsx
   setLoading(true);
    try {
      const response = await axios.post(`https://${ipaddress}export/pctmexcel`, imp, { responseType: 'blob' });
  
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
  
      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);
  
      // Create a link element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
  
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
  
      toast.success("File downloaded successfully!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 800,
      });
    } catch (error) {
      console.error("Error in getExcel:", error);
      toast.error("Something went wrong", {
        autoClose: 700,
      });
    } finally {
      setLoading(false);
      console.log("getExcel execution finished.");
    }
  };
  



  const pctmPrint = async (data, data1) => {
    const combineData = {
      tpData: data,
      tpSummery: data1,
    };

    const params = {
      companyId: companyid,
      branchId: branchId,
      serDate: searchCriteria.startDate,
      tpNo: searchCriteria.tpNo,
      custodian: searchCriteria.Custodian,
      cartingAgent: searchCriteria.cartingName,
      consoleName: searchCriteria.consoleName
    };
    setLoading(true);
    try {
      const response = await axios.post(`https://${ipaddress}export/exportPctmReport`, combineData, { params });
      const base64PDF = response.data;

      // Create a new window for displaying the PDF
      const newWindow = window.open("", "_blank");

      // Write the HTML content to the new window
      newWindow.document.write(`
        <!DOCTYPE html>
        <html>
        <head>
          <title>Export PCTM</title>
          <style>
            body {
              margin: 0;
              padding: 0;
              overflow: hidden;
            }
            embed {
              width: 100vw;
              height: 100vh;
            }
          </style>
        </head>
        <body>
          <embed src="data:application/pdf;base64,${base64PDF}" type="application/pdf" width="100%" height="100%">
        </body>
        </html>
      `);
    } catch (error) {
      console.error("Error in pctmPrint:", error);
      toast.error("Something went wrong", {
        autoClose: 1000,
      });
    } finally {
      // Any cleanup actions, if needed
      setLoading(false);
      console.log("pctmPrint execution finished.");
    }
  };


  const pctmPdf = async (data, data1) => {
    console.log("pctmdata ", data);
    const combineData = {
      tpData: data,
      tpSummery: data1,
    };

    const params = {
      companyId: companyid,
      branchId: branchId,
      serDate: searchCriteria.startDate,
      tpNo: searchCriteria.tpNo,
      custodian: searchCriteria.Custodian,
      cartingAgent: searchCriteria.cartingName,
      consoleName: searchCriteria.consoleName
    };

    try {
      const response = await axios.post(`https://${ipaddress}export/exportPctmReport`, combineData, { params });
      const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF

      // Create a Blob from the Base64 data
      const pdfBlob = new Blob([Uint8Array.from(atob(pdfBase64), c => c.charCodeAt(0))], { type: 'application/pdf' });

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(pdfBlob);

      // Create an anchor element for downloading
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = 'ExportPctm.pdf'; // Set the filename for the downloaded PDF
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);

      // Trigger the download
      downloadLink.click();

      // Clean up
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(blobUrl);

      toast.success("Downloading PDF!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 800,
      });
    } catch (error) {
      console.error("Error in pctmPdf:", error);
      toast.error("Something went wrong", {
        autoClose: 1000,
      });
    } finally {
      console.log("pctmPdf execution finished.");
    }
  };



  const ackPrint = async (data, data1) => {
    console.log("pctmdata ", data)
    const combineData = {
      tpData: data,
      tpSummery: data1
    }
    await axios.post(`https://${ipaddress}export/exportAckReport?companyId=${companyid}&branchId=${branchId}&tpNo=${selectedCartingAgentTpNo}&serDate=${formattedSerDate}`, combineData)
      .then((response) => {
        const base64PDF = response.data;

        // Create a new window for displaying the PDF
        const newWindow = window.open("", "_blank");

        // Write the HTML content to the new window
        newWindow.document.write(`
        <!DOCTYPE html>
        <html>
        <head>
          <title>Export Acknowlegement</title>
          <style>
            body {
              margin: 0;
              padding: 0;
              overflow: hidden;
            }
            embed {
              width: 100vw;
              height: 100vh;
            }
          </style>
        </head>
        <body>
          <embed src="data:application/pdf;base64,${base64PDF}" type="application/pdf" width="100%" height="100%">
        </body>
        </html>
      `);
      })
      .catch((error) => {
        if (error) {
          toast.error("Something went wrong", {
            autoClose: 1000
          })
        }
      })
  }

  const ackPdf = async (data, data1) => {
    console.log("pctmdata ", data)
    const combineData = {
      tpData: data,
      tpSummery: data1
    }
    await axios.post(`https://${ipaddress}export/exportAckReport?companyId=${companyid}&branchId=${branchId}&tpNo=${selectedCartingAgentTpNo}&serDate=${formattedSerDate}`, combineData)
      .then((response) => {
        const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF
        // Create a Blob from the Base64 data
        const pdfBlob = new Blob([Uint8Array.from(atob(pdfBase64), c => c.charCodeAt(0))], { type: 'application/pdf' });
        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(pdfBlob);
        // Create an anchor element for downloading
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = 'ExportAcknowledgement.pdf'; // Set the filename for the downloaded PDF
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        // Trigger the download
        downloadLink.click();
        // Clean up
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(blobUrl);
        toast.success("Downloading Pdf!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 800,
        });
      })
      .catch((error) => {
        if (error) {
          toast.error("Something went wrong", {
            autoClose: 1000
          })
        }
      })
  }
  // Function to calculate the total for a specific column
  // const calculateTotal = (data, columnIndex,field) => {
  //   const total = data.reduce((sum, row) => sum + (parseFloat(row[columnIndex]) || 0), 0);
  //   // Check if the total has a fractional part
  //   const roundedTotal = total % 1 !== 0 ? parseFloat(total.toFixed(2)) : total;

  //   return roundedTotal !== 0 ? roundedTotal : ''; // Return the rounded total or an empty string if zero
  // };

  const calculateTotal = (data, columnIndex, field) => {
    const total = data.reduce((sum, row) => {
      let value = parseFloat(row[columnIndex]) || 0;

      // Check the value at the 14th position (index 13)
      const unit = row[14];

      if (field === 'GW' && unit === 'KILO GRAMS') {
        // Convert kilograms to grams
        value = value * 1000;
      }

      // Add the value to the sum
      return sum + value;
    }, 0);

    // Check if the total has a fractional part
    const roundedTotal = total % 1 !== 0 ? parseFloat(total.toFixed(2)) : total;

    return roundedTotal !== 0 ? roundedTotal : ''; // Return the rounded total or an empty string if zero
  };


  return (

    <>
      {loading && (
        <div style={styles.overlay}>
          <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
        </div>
      )}
      <div className="Container" >
        <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }} > <FontAwesomeIcon
          icon={faFile}
          style={{
            marginRight: '8px',
            color: 'black', // Set the color to golden
          }}
        />Export PCTM</h5>
        <Card>
          <CardBody>
            <Form>
              <Row>
                <Col md={2}>
                  <FormGroup>
                    <Label className="forlabel" for="branchId">Tp Date</Label><span className='error'>*</span>
                    <DatePicker
                      selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
                      wrapperClassName="custom-react-datepicker-wrapper"
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy" // Specify the combined format
                      className={`form-control border-right-0 inputField ${errors.startDate ? 'error-border' : ''}`}
                      customInput={<input style={{ width: '100%' }} />}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label className="forlabel" for="branchId">Select Carting Agent</Label><span className='error'>*</span>
                    <Select
                      options={cartingArray}
                      value={selectedCarting}
                      onChange={handleCartingChange}
                      className={errors.carting ? 'error-border' : ''}
                      isClearable
                      placeholder="Select Carting Agent"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                          boxShadow: 'none',
                          '&:hover': {
                            border: '1px solid #ccc'
                          }
                        }),
                        indicatorSeparator: () => ({
                          display: 'none'
                        }),
                        dropdownIndicator: () => ({
                          display: 'none'
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label className="forlabel" for="typeOfProduct">Select Tp No</Label><span className='error'>*</span>
                    <Select
                      options={tpNoArray}
                      value={selectedTpNo}
                      onChange={handleTpNoChange}
                      className={errors.tpNo ? 'error-border' : ''}
                      isClearable
                      placeholder="Select Tp No"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                          // borderColor: errors.consoleName ? '#f52b2b' : '',
                          boxShadow: 'none',
                          '&:hover': {
                            border: '1px solid #ccc'
                          }
                        }),
                        indicatorSeparator: () => ({
                          display: 'none'
                        }),
                        dropdownIndicator: () => ({
                          display: 'none'
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label className="forlabel" for="branchId">Select Console</Label>
                    <Select
                      options={consoleArray}
                      value={selectedConsole}
                      onChange={handleConsoleChange}
                      isClearable
                      placeholder="Select Console"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                          boxShadow: 'none',
                          '&:hover': {
                            border: '1px solid #ccc'
                          }
                        }),
                        indicatorSeparator: () => ({
                          display: 'none'
                        }),
                        dropdownIndicator: () => ({
                          display: 'none'
                        })
                      }}
                    />
                  </FormGroup>
                </Col>



                <Col md={2} >
                  <FormGroup>
                    <Label className="forlabel">
                      Custodian
                    </Label>
                    <Input
                      type="text"
                      name="Custodian"
                      placeholder="Enter Custodian Name"
                      className=""
                      value={searchCriteria.Custodian}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>




              </Row>

              <div className="d-flex justify-content-center align-items-center">
                <div style={{ marginTop: 12 }}>
                  <Button
                    color="primary"
                    outline
                    style={{ marginRight: 9, fontWeight: 'bold' }}
                    onClick={() => handleShowTPData(searchCriteria)}
                  >
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ marginRight: "5px" }}
                    />
                    Search
                  </Button>

                  <Button
                    outline
                    color="danger"
                    style={{ fontWeight: 'bold' }}
                    onClick={(e) => makeFieldEmpty()}
                  >
                    <FontAwesomeIcon
                      icon={faSyncAlt}
                      style={{ marginRight: "5px" }}
                    // onClick={handleResetForm}
                    />
                    Reset
                  </Button>
                </div>
              </div>
            </Form>
            {allTpData.length > 0 && (
              <div>
                <hr />
                {allTpData.length > 0 && (
                  <div style={{ marginTop: 23 }}>
                    <Row>
                      <Col md={2} > <Label className="forlabel" for="search">Download PCTM </Label></Col>
                      <Col md={4} >

                        <button
                          style={{ marginLeft: 9, marginTop: 5, fontWeight: 'bold' }}
                          className="btn btn-outline-primary btn-margin"
                          type="button"
                          onClick={() => pctmPrint(allTpData, allTpDataSummery)}
                        > <FontAwesomeIcon icon={faPrint} style={{ marginRight: '5px' }} />
                          Print
                        </button>
                        <button
                          className="btn btn-outline-success btn-margin"
                          type="button"
                          onClick={() => pctmPdf(allTpData, allTpDataSummery)}
                          style={{ marginLeft: "10px", marginTop: 5, fontWeight: 'bold' }}
                        ><FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
                          PDF
                        </button>
                        <button
                          className="btn btn-outline-primary btn-margin"
                          type="button"
                          style={{ marginLeft: "10px", marginRight: 9, marginTop: 5, fontWeight: 'bold' }}
                          onClick={() => getExcel(allTpData)}
                        ><FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '5px' }} />
                          XLS
                        </button>
                      </Col>

                      <Col md={6}>

                        <Row>

                          <Col md={6}>

                            <Label className="forlabel" for="user_Type">

                              Download Acknowledgement
                            </Label>

                          </Col>
                          <Col md={6}>

                            <button
                              style={{ fontWeight: 'bold' }}
                              className="btn btn-outline-primary btn-margin"
                              type="button"
                              onClick={() => ackPrint(allTpData, allTpDataSummery)}
                            ><FontAwesomeIcon icon={faPrint} style={{ marginRight: '5px' }} />
                              Print
                            </button>


                            <button
                              style={{ marginLeft: '9px', fontWeight: 'bold' }}
                              className="btn btn-outline-success btn-margin"
                              type="button"
                              onClick={() => ackPdf(allTpData, allTpDataSummery)}
                            ><FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
                              PDF
                            </button>


                          </Col>


                        </Row>




                      </Col>

                    </Row>
                  </div>
                )}

                <div className="table-responsive mt-2" >
                  <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                    <thead className="thead-dark bg-dark">
                      <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
                        <th style={{ backgroundColor: '#BADDDA' }}>
                          Sr.No
                        </th>
                        <th style={{ backgroundColor: '#BADDDA' }}>
                          SER No
                        </th>
                        <th style={{ backgroundColor: '#BADDDA' }}>
                          Parcel Type
                        </th>
                        <th style={{ backgroundColor: '#BADDDA' }}>
                          Destination
                        </th>
                        <th style={{ backgroundColor: '#BADDDA' }}>
                          No Of Packages
                        </th>
                        <th style={{ backgroundColor: '#BADDDA' }}>
                          Shipping Bill No
                        </th>
                        <th style={{ backgroundColor: '#BADDDA' }}>
                          NET Weight
                        </th>
                        <th style={{ backgroundColor: '#BADDDA' }}>
                          Airway Bill No
                        </th>
                      </tr>
                    </thead>

                    <thead>
                      <tr className="text-center">
                        <th style={{ backgroundColor: "#BADDDA" }}>Total</th>
                        <th style={{ backgroundColor: "#BADDDA" }}> </th>
                        <th style={{ backgroundColor: "#BADDDA" }}> </th>
                        <th style={{ backgroundColor: "#BADDDA" }}> </th>
                        <th style={{ backgroundColor: "#BADDDA" }}>{calculateTotal(allTpData, 3)}</th>
                        <th style={{ backgroundColor: "#BADDDA" }}>{sumOfSB}</th>
                        <th style={{ backgroundColor: "#BADDDA" }}>{calculateTotal(allTpData, 5, 'GW')} GMS</th>
                        <th style={{ backgroundColor: "#BADDDA" }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {allTpData.map((item, index) => (
                        <tr key={index} className='text-center'>
                          <td>{index + 1}</td>
                          <td>{item[0]}</td>
                          <td>{item[7] === 'Y' ? 'SC' : item[8] === 'Y' ? 'P' : item[9] === 'Y' ? 'HW' : ''}</td>
                          <td>
                            {item[1] &&
                              item[2]
                              ? `${item[1]} | ${item[2]}`
                              : ""}
                          </td>
                          <td>{item[3]}</td>
                          <td>{item[4]}</td>
                          <td>{item[5] + ' (' + (item[14] === 'KILO GRAMS' ? 'KGS' : 'GMS') + ')'}</td>
                          <td>{item[6]}</td>
                          {/* Add more table cells as needed */}
                        </tr>
                      ))}



                    </tbody>
                  </Table>

                </div>

              </div>
            )}
            {allTpDataSummery && allTpDataSummery.length > 0 && (
              <div>
                <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '4%', paddingRight: '-50px' }} > <FontAwesomeIcon
                  icon={faDatabase}
                  style={{
                    marginRight: '8px',
                    color: 'black', // Set the color to golden
                  }}
                /> Summary Data</h5>

                <div className="table-responsive">
                  <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                    <thead className="thead-dark bg-dark">
                      <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
                        <th
                          style={{ backgroundColor: '#BADDDA' }}
                        >
                          Airline Name
                        </th>
                        <th
                          style={{ backgroundColor: '#BADDDA' }}
                        >
                          No Of Packages
                        </th>
                        <th
                          style={{ backgroundColor: '#BADDDA' }}
                        >
                          SB Count
                        </th>
                        <th
                          style={{ backgroundColor: '#BADDDA' }}
                        >
                          MAWB Count
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allTpDataSummery.map((item, index) => (
                        <tr key={index} className='text-center'>
                          <td style={{ width: "20%" }}>
                            {item[1]}
                          </td>
                          <td style={{ width: "20%" }}>
                            {item[2]}
                          </td>
                          <td style={{ width: "20%" }}>{item[3]}</td>
                          <td style={{ width: "20%" }}>
                            {item[4]}
                          </td>{" "}
                          {/* Display distinct MAWB count */}
                        </tr>
                      ))}
                      <tr className='text-center'>
                        <th
                          style={{ backgroundColor: '#BADDDA' }}
                        >
                          Total {allTpDataSummery.length} Airlines
                        </th>
                        <th
                          style={{ backgroundColor: '#BADDDA' }}
                        >
                          {sumOfNop}
                        </th>
                        <th
                          style={{ backgroundColor: '#BADDDA' }}
                        >
                          {sumOfSB}
                        </th>
                        <th
                          style={{ backgroundColor: '#BADDDA' }}
                        >
                          {sumOfMAWB}
                        </th>
                      </tr>

                    </tbody>
                  </Table>
                </div>
              </div>
            )}


          </CardBody>
        </Card>
      </div>
    </>
  );
}







