// // // import { redirect } from "react-router-dom";
// // // import AuthContext from "../Components/AuthProvider";
// // // import { useNavigate } from "react-router-dom";
// // // import React, { useEffect, useState, useContext } from "react";
// // // import "../Components/Style.css";
// // // import Row from "react-bootstrap/Row";
// // // import * as XLSX from "xlsx"; // Import XLSX library for creating XLS files
// // // import Col from "react-bootstrap/Col";
// // // import { format } from "date-fns";
// // // import {
// // //   Button,
// // //   ButtonToggle,
// // //   CardBody,
// // //   Container,
// // //   Table,
// // //   ToastHeader,
// // // } from "reactstrap";
// // // import Form from "react-bootstrap/Form";
// // // import Card from "react-bootstrap/Card";
// // // import DatePicker from "react-datepicker";
// // // import ipaddress from "../Components/IpAddress";
// // // import { saveAs } from "file-saver";
// // // import axios from "axios";
// // // import dgdcImage from "../Images/report.jpeg";
// // // import {
// // //   faFileExcel,
// // //   faFilePdf,
// // //   faPrint,
// // // } from "@fortawesome/free-solid-svg-icons";
// // // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // // import {
// // //   Document,
// // //   Page,
// // //   Text,
// // //   View,
// // //   pdf,
// // //   StyleSheet,
// // //   PDFViewer,
// // //   Image,
// // // } from "@react-pdf/renderer";
// // // import { toast } from "react-toastify";

// // // export default function Export_Register() {
// // //   const navigate = useNavigate();
// // //   const [startDate, setStartDate] = useState(new Date());
// // //   const [endDate, setEndDate] = useState(new Date());
// // //   const [selectedParty, setSelectedParty] = useState("");
// // //   const [showSubContractDataTable, setSubContractDataTable] = useState(false);
// // //   const [showExportDataTable, setShowExportDataTable] = useState(false);
// // //   const [SubImportContractData, setSubImportContractData] = useState([]);
// // //   const [subContractData, setSubContractData] = useState([]);
// // //   const [exportData, setExportData] = useState([]);
// // //   const [exportSubData, setExportSubData] = useState([]);
// // //   const [partyNameToIdMap, setPartyNameToIdMap] = useState({});
// // //   const [requestId, setRequestId] = useState("");
// // //   const [subRequestIdData, setsubRequestIdData] = useState([]);
// // //   const [showAlert, setShowAlert] = useState(false);
// // //   const {
// // //     jwtToken,
// // //     userId,
// // //     username,
// // //     branchId,
// // //     companyid,
// // //     role,
// // //     companyname,
// // //     branchname,
// // //     isAuthenticated,
// // //     login,
// // //     logout,
// // //   } = useContext(AuthContext);
// // //   const styles = StyleSheet.create({
// // //     // page: {
// // //     //   paddingTop: 30,
// // //     //   paddingBottom: 60,
// // //     //   paddingHorizontal: 30,
// // //     // },

// // //     page: {
// // //       flexDirection: "row", // Set landscape orientation
// // //       // paddingTop: 9,
// // //       paddingBottom: 9,
// // //       paddingHorizontal: 9,
// // //     },
// // //     header: {
// // //       flexDirection: "row",
// // //       alignItems: "center",
// // //       marginBottom: 4,
// // //     },
// // //     section: {
// // //       flexGrow: 1, // Take the available space
// // //       margin: 10, // Add some margin for spacing
// // //       display: "flex",
// // //       justifyContent: "center", // Center content horizontally
// // //       alignItems: "center", // Center content vertically
// // //     },
// // //     image: {
// // //       width: 306,
// // //       height: 100,
// // //     },
// // //     headerText: {
// // //       flex: 1,
// // //       textAlign: "center",
// // //       fontSize: 18,
// // //       fontWeight: "bold",
// // //     },
// // //     centeredText: {
// // //       fontSize: 14,
// // //       textAlign: "center", // Center text horizontally
// // //       paddingBottom: 2,
// // //       paddingTop: 0,
// // //     },
// // //     reportHeader: {
// // //       flexDirection: "row",
// // //       justifyContent: "space-between",
// // //       paddingBottom: 1,
// // //       fontSize: 10,
// // //     },
// // //     table: {
// // //       width: "100%",
// // //       borderCollapse: "collapse",
// // //       marginBottom: 10,
// // //     },
// // //     tableRow: {
// // //       flexDirection: "row",
// // //       borderBottomWidth: 0.4,
// // //       borderBottomColor: "black",
// // //       fontSize: 10,
// // //       borderRightWidth: 0.4,
// // //       borderLeftWidth: 0.4,
// // //       borderLeftColor: "black",
// // //       borderRightColor: "black",
// // //       flexWrap: "wrap",
// // //     },

// // //     tableCell: {
// // //       flex: 1,
// // //       borderWidth: 0.4,
// // //       borderStyle: "solid",
// // //       borderColor: "#000",
// // //       padding:1,
// // //       fontWeight: "bold",
// // //       textAlign: "center",
// // //       fontSize: 7,
// // //     },
// // //     tableCellHeader: {
// // //       fontWeight: "bold",
// // //       textAlign: "center",
// // //       fontSize: 9,
// // //     },
// // //   });
// // //   const CustomHeader = () => {
// // //     return (
// // //       <View style={styles.header}>
// // //         <Image src={dgdcImage} style={styles.image} />
// // //       </View>
// // //     );
// // //   };

// // //   const totalSbNoCount = subContractData.length;
// // //   // Calculate the sum of noOfPackages and fobValueInINR
// // //   const totalNoOfPackages = subContractData.reduce(
// // //     (total, item) => total + item.noOfPackages,
// // //     0
// // //   );

// // //   const totalFobValueInINR = subContractData.reduce(
// // //     (total, exportItem) => total + exportItem.fobValueInINR,
// // //     0
// // //   );

// // //   //Export
// // //   const totalExSbNoCount = exportData.length;
// // //   const totalExNoOfPackages = exportData.reduce(
// // //     (total, exportItem) => total + exportItem.noOfPackages,
// // //     0
// // //   );

// // //   const totalExFobValueInINR = exportData.reduce(
// // //     (total, exportItem) => total + exportItem.fobValueInINR,
// // //     0
// // //   );
// // // //Sub NOP total 
// // // const totalEXSubNoOfPackages = exportSubData.reduce(
// // //   (total, exportSubItem) => total + exportSubItem.nop,
// // //   0
// // // );

// // // const mergedAllData = [...exportData, ...exportSubData];
// // //   const formatedDate = (inputDate) => {
// // //     const date = new Date(inputDate);
// // //     const year = date.getFullYear();
// // //     const month = String(date.getMonth() + 1).padStart(2, "0");
// // //     const day = String(date.getDate()).padStart(2, "0");
// // //     return `${day}/${month}/${year}`;
// // //   };
// // //   // const formatDate = (inputDate) => {
// // //   //   const date = new Date(inputDate);
// // //   //   const year = date.getFullYear();
// // //   //   const month = String(date.getMonth() + 1).padStart(2, "0");
// // //   //   const day = String(date.getDate()).padStart(2, "0");

// // //   //   return `${year}-${month}-${day}`;
// // //   // };

// // //   const formatDate = (inputDate, setTimeTo) => {
// // //     const date = new Date(inputDate);
// // //     const year = date.getFullYear();
// // //     const month = String(date.getMonth() + 1).padStart(2, "0");
// // //     const day = String(date.getDate()).padStart(2, "0");
// // //     const hours = setTimeTo === "start" ? "00" : "23";
// // //     const minutes = setTimeTo === "start" ? "00" : "59";
// // //     return `${year}-${month}-${day} ${hours}:${minutes}`;
// // //   };

// // //   const formattedStartDate = formatDate(startDate);
// // //   const formattedEndDate = formatDate(endDate);

// // //   const StarDate = formatedDate(formattedStartDate);
// // //   const EndDate = formatedDate(formattedEndDate);

// // //   console.log("stastdate ", startDate);

// // //   console.log("enddate ", endDate);
// // //   // If the user is not authenticated, redirect to the login page
// // //   useEffect(() => {
// // //     if (!isAuthenticated) {
// // //       navigate(
// // //         "/login?message=You need to be authenticated to access this page."
// // //       );
// // //     }
// // //   }, [isAuthenticated, navigate]);
// // //   console.log(startDate);

// // //   const [consoles, setConsoles] = useState([]);
// // //   const [getConsoleId, setGetConsoleId] = useState({});
// // //   const fetchConsoleNames = async () => {
// // //     try {
// // //       const response = await fetch(
// // //         `https://${ipaddress}externalParty/console/${companyid}/${branchId}`
// // //       );
// // //       const data = await response.json();
// // //       const consoleMap = {};
// // //       data.forEach((console) => {
// // //         consoleMap[console.externaluserId] = console.userName;
// // //       });
// // //       setGetConsoleId(consoleMap);
// // //       setConsoles(data);
// // //       setPartyNameToIdMap(consoleMap);
// // //     } catch (error) {
// // //       console.error("Error fetching party names:", error);
// // //     }
// // //   };

// // //   useEffect(() => {
// // //     fetchConsoleNames();
// // //   }, []);

// // //   const [partys, setPartys] = useState([]);
// // //   const [getpartyId, setGetpartyId] = useState({});
// // //   const fetchPartyNames = async () => {
// // //     try {
// // //       const response = await fetch(
// // //         `https://${ipaddress}parties/getAll/${companyid}/${branchId}`
// // //       );
// // //       const data = await response.json();
// // //       const namesMap = {};
// // //       data.forEach((party) => {
// // //         namesMap[party.partyId] = party.partyName;
// // //       });
// // //       setGetpartyId(namesMap);
// // //       setPartys(data);
// // //       setPartyNameToIdMap(namesMap);
// // //     } catch (error) {
// // //       console.error("Error fetching party names:", error);
// // //     }
// // //   };

// // //   useEffect(() => {
// // //     fetchPartyNames();
// // //   }, []);

// // //   const handlePartyChange = (event) => {
// // //     const selectedPartyName = event.target.value;
// // //     setSelectedParty(selectedPartyName);
// // //     setShowExportDataTable(false);
// // //   };

// // //   const fetchSubContractData = () => {
// // //     // Make an API request here to fetch the list of airline names based on the provided criteria
// // //     fetch(
// // //       `https://${ipaddress}export/findExportAllData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&cartingAgent=${selectedParty}`
// // //     )
// // //       .then((response) => response.json())
// // //       .then((data) => {
// // //         // Ensure that 'data' is an array before updating the 'airlines' state
// // //         if (Array.isArray(data) && data.length > 0) {
// // //           // Update the 'airlines' state with the fetched data
// // //           setSubContractData(data);
// // //           setSubContractDataTable("true");
// // //           setShowAlert(false);
// // //           setExportData([]);
// // //           toast.success("Data Found !", {
// // //             position: "top-center",
// // //             autoClose: 540, // Duration in milliseconds
// // //             hideProgressBar: false,
// // //             closeOnClick: true,
// // //             pauseOnHover: true,
// // //             draggable: true,
// // //             progress: undefined,
// // //           });
// // //           setExportSubData([]);
// // //         } else {
// // //           console.error("API response is not an array:", data);
       
// // //           toast.error("Data Not Found !", {
// // //             position: "top-center",
// // //             autoClose: 540, // Duration in milliseconds
// // //             hideProgressBar: false,
// // //             closeOnClick: true,
// // //             pauseOnHover: true,
// // //             draggable: true,
// // //             progress: undefined,
// // //           });
// // //       // setShowAlert(true);
        
// // //       setSubContractDataTable(false);
// // //       setShowExportDataTable(false);
// // //         }
// // //       })
// // //       .catch((error) => {
// // //         console.error("Error fetching airline names:", error);
// // //         setShowAlert(true);
// // //       });
// // //   };
// // // console.log('subContractData ',subContractData);
// // //   const fetchExportData = () => {
// // //     fetch(
// // //       `https://${ipaddress}export/findExportData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
// // //     )
// // //       .then((response) => response.json())
// // //       .then((data) => {
// // //         // Ensure that 'data' is an array before updating the 'airlines' state
// // //         if (Array.isArray(data) && data.length > 0) {
// // //           // Update the 'airlines' state with the fetched data
// // //           setExportData(data);
// // //           setShowExportDataTable(true);
// // //           setShowAlert(false);
// // //           setSubContractData([]);
// // //           setSubContractDataTable(false);
// // //           toast.success("Data Found !", {
// // //             position: "top-center",
// // //             autoClose: 540, // Duration in milliseconds
// // //             hideProgressBar: false,
// // //             closeOnClick: true,
// // //             pauseOnHover: true,
// // //             draggable: true,
// // //             progress: undefined,
// // //           });
// // //         } else {
// // //           console.error("API response is not an array:", data);
// // //           toast.error("Data Not Found !", {
// // //             position: "top-center",
// // //             autoClose: 540, // Duration in milliseconds
// // //             hideProgressBar: false,
// // //             closeOnClick: true,
// // //             pauseOnHover: true,
// // //             draggable: true,
// // //             progress: undefined,
// // //           });


// // //           // setShowExportDataTable(false);
// // //         }
// // //       })
// // //       .catch((error) => {
// // //         console.error("Error fetching airline names:", error);
       
// // //       });
// // //   };

// // //   const fetchExportSubData = () => {
// // //     fetch(
// // //       `https://${ipaddress}exportsub/findExportSubAllData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
// // //     )
// // //       .then((response) => response.json())
// // //       .then((data) => {
// // //         // Ensure that 'data' is an array before updating the 'airlines' state
// // //         if (Array.isArray(data) && data.length > 0) {
// // //           // Update the 'airlines' state with the fetched data
// // //           setExportSubData(data);
// // //           setShowExportDataTable(true);
// // //           setShowAlert(false);
// // //           setSubContractData([]);
          
// // //         } else {
// // //           console.error("API response is not an array:", data);
// // //           setShowAlert(true);
// // //         }
// // //       })
// // //       .catch((error) => {
// // //         console.error("Error fetching airline names:", error);
// // //         setShowAlert(true);
// // //       });
// // //   };

 
// // //   const handleSearch = () => {

// // //     if (startDate && endDate && !selectedParty) {
// // //       fetchExportData();
// // //       fetchExportSubData();
// // //     } else if (startDate && endDate && selectedParty) {
// // //       fetchSubContractData();
// // //     }
// // //   };

// // //   const handlePrint = () => {
// // //     const isoDate = new Date().toISOString();
// // //     const date = new Date(isoDate);
// // //     const day = date.getDate().toString().padStart(2, "0");
// // //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
// // //     const year = date.getFullYear().toString();

// // //     let printWindow = window.open("", "_blank");
// // //     printWindow.document.open();

// // //     const recordsPerPage = 25;
// // //     const totalRecords = subContractData.length;

// // //     let startIndex = 0; // Initialize the starting index for each page
// // //     let currentPageNumber = 1; // Initialize the current page number

// // //     for (
// // //       let pageIndex = 0;
// // //       pageIndex < Math.ceil(totalRecords / recordsPerPage);
// // //       pageIndex++
// // //     ) {
// // //       // Slice the subContractData array to get 10 records for the current page
// // //       const pageRecords = subContractData.slice(
// // //         startIndex,
// // //         startIndex + recordsPerPage
// // //       );

// // //       // Check if it's the last page
// // //       const isLastPage =
// // //         pageIndex === Math.ceil(totalRecords / recordsPerPage) - 1;

// // //       printWindow.document.write(`
// // //         <!DOCTYPE html>
// // //         <html>
// // //         <head>
// // //         <title> Export Report Register</title>
// // //         <style>
// // //           @page {
// // //             margin: 1cm;
// // //             /* Adjust this value to position the header correctly */
// // //           }
  
// // //           .printable-area {
// // //             font-family: Arial, sans-serif;
// // //             page-break-before: always;
// // //           }
  
// // //           table {
// // //             width: 100%;
// // //             border-collapse: collapse;
// // //           }
  
// // //           td {
// // //             border: 1px solid #dddddd;
// // //             text-align: center;
// // //             padding: 1px;
// // //             font-size: 10px;
// // //         }
    
// // //         th {
// // //           border: 1px solid #dddddd;
// // //             background-color: #f2f2f2;
// // //             text-align: center;
// // //             font-size: 12px;
// // //         }
// // //           .container {
// // //             display: flex;
// // //             justify-content: space-between;
// // //           }
  
// // //           .left-column {
// // //             width: 70%; /* Adjust the width as needed */
// // //           }
  
// // //           .page-break {
// // //             page-break-before: always; /* This will force a page break before the element */
// // //           }
  
// // //           .right-column {
// // //             width: 30%; /* Adjust the width as needed */
// // //             text-align: right; /* Align text to the right */
// // //           }
  
// // //           .header img {
// // //             max-width: auto; /* Ensure the image doesn't exceed the page width */
// // //             max-height: auto; /* Adjust the maximum image height as needed */
// // //           }
  
// // //           .report-header {
// // //             display: flex;
// // //             justify-content: space-between;
// // //             margin-bottom: 5px;
// // //           }
  
// // //           #page-header {
// // //             position: static;
// // //             top: 0;
// // //             left: 0;
// // //             right: 0;
// // //             text-align: center;
// // //           }
// // //         </style>
// // //         </head>
// // //         <body>
// // //         <div id="page-header">
// // //         <img src="${dgdcImage}" alt="Header Image" style="max-width: 306px; display: block; margin: 0 auto;">
// // //         <div style="text-align: center;">
// // //         <div style="font-size:14px;font-weight:bold;">Export Register Report - Page ${currentPageNumber}</div><br/>
// // //         </div>
// // //         </div>
// // //         <div class="report-header">
// // //         <div></div>
// // //         <div style="font-size:12px;">FOR THE PERIOD: ${StarDate} To ${EndDate}</div>
// // //         <div style="font-size:12px;"> REPORT DATE: ${format(new Date(), "dd/MM/yyyy HH:mm")} </div>
// // //         </div>
// // //         <table>
// // //         <thead>
// // //           <tr>
// // //           <th style="background-color: #BADDDA">Sr.No</th>
// // //           <th style="background-color: #BADDDA">SER No</th>
// // //           <th style="background-color: #BADDDA">SB No</th>
// // //           <th style="background-color: #BADDDA">SER Date</th>
      
// // //           <th style="background-color: #BADDDA">Exporter Names</th>
// // //           <th style="background-color: #BADDDA">CHA No</th>
// // //           <th style="background-color: #BADDDA">DESC</th>
// // //           <th style="background-color: #BADDDA">NOP</th>
// // //           <th style="background-color: #BADDDA">Net Weight</th>
// // //           <th style="background-color: #BADDDA">FOB value in INR</th>
// // //           <th style="background-color: #BADDDA">AIRWAY BILL NO</th>
// // //           <th style="background-color: #BADDDA">PORT OF DESTINATION</th>
// // //           <th style="background-color: #BADDDA">Console Is</th>
// // //           </tr>
// // //         </thead>
// // //         <tbody>
// // //         ${pageRecords
// // //           .map(
// // //             (item, index) => `
// // //               <tr>
// // //               <td>${startIndex + index + 1}</td>
// // //               <td>${item.serNo || ''}</td>
// // //               <td>${item.sbNo || ''}</td>
// // //               <td>${formatedDate(item.serDate) || ''}</td>
         
// // //               <td>${getpartyId[item.nameOfExporter] || ''}</td>
// // //               <td>${item.chaNo || ''}</td>
// // //               <td>${item.descriptionOfGoods || '' }</td>
// // //               <td>${item.noOfPackages || ''}</td>
// // //               <td>${item.grossWeight || ''}</td>
// // //               <td>${item.fobValueInINR || ''}</td>
// // //               <td>${item.airwayBillNo || ''}</td>
// // //               <td>${item.portOfDestination || ''}</td>
// // //               <td>${getConsoleId[item.cartingAgent] || ''}</td>
// // //               </tr>
// // //             `
// // //           )
// // //           .join("")}
// // //         </tbody>
// // //         </table>
// // //         </div>
// // //         </body>
// // //         </html>
// // //       `);

// // //       // Check if it's not the last page, then add a page break
// // //       if (!isLastPage) {
// // //         printWindow.document.write('<div class="page-break"></div>');
// // //       } else {
// // //         printWindow.document.write(`
// // //           <table>
// // //           <tbody>
// // //           <tr>
// // //           <td style="font-weight: bold;background-color: #BADDDA">Total</td>
// // //           <td style="background-color: #BADDDA"></td>
         
// // //           <td style="background-color: #BADDDA">${totalSbNoCount}</td>
// // //           <td style="background-color: #BADDDA"></td>
      
// // //           <td style="background-color: #BADDDA"></td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           <td style="background-color: #BADDDA">${totalNoOfPackages}</td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           <td style="background-color: #BADDDA">${totalFobValueInINR}</td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           </tr>
// // //           </tbody>
// // //           </table>
// // //         `);
// // //       }

// // //       // Update the starting index for the next page
// // //       startIndex += recordsPerPage;
// // //       currentPageNumber++;
// // //     }

// // //     printWindow.document.close();
// // //     printWindow.print();
// // //     printWindow.onafterprint = () => printWindow.close();
// // //   };

// // //   const handleExportPrint = () => {
// // //     const isoDate = new Date().toISOString();
// // //     const date = new Date(isoDate);
// // //     const day = date.getDate().toString().padStart(2, "0");
// // //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
// // //     const year = date.getFullYear().toString();
  
// // //     let printWindow = window.open("", "_blank");
// // //     printWindow.document.open();
  
// // //     const recordsPerPage = 25;
// // //     const totalRecords = exportData.length + exportSubData.length;
  
// // //     let startIndex = 0; // Initialize the starting index for each page
// // //     let currentPageNumber = 1; // Initialize the current page number
  
// // //     // Function to generate the table HTML for a set of records
// // //     const generateTableHTML = (records) => {
// // //       return `
// // //         <table>
// // //           <thead>
// // //             <tr>
// // //               <th style="background-color: #BADDDA">Sr.No</th>
// // //               <th style="background-color: #BADDDA">SER No</th>
// // //               <th style="background-color: #BADDDA">SB No</th>
// // //               <th style="background-color: #BADDDA">SER Date</th>
// // //               <th style="background-color: #BADDDA">Exporter Names</th>
// // //               <th style="background-color: #BADDDA">CHA No</th>
// // //               <th style="background-color: #BADDDA">DESC</th>
// // //               <th style="background-color: #BADDDA">NOP</th>
// // //               <th style="background-color: #BADDDA">Net Weight</th>
// // //               <th style="background-color: #BADDDA">FOB value in INR</th>
// // //               <th style="background-color: #BADDDA">AIRWAY BILL NO</th>
// // //               <th style="background-color: #BADDDA">PORT OF DESTINATION</th>
// // //               <th style="background-color: #BADDDA">Console Is</th>
// // //             </tr>
// // //           </thead>
// // //           <tbody>
// // //             ${records
// // //               .map(
// // //                 (item, index) => `
// // //                 <tr>
// // //                 <td>${startIndex + index + 1}</td>
// // //                 <td>${item.serNo || ''}</td>
// // //                 <td>${item.sbNo || ''}</td>
// // //                 <td>${formatedDate(item.serDate) || ''}</td>
// // //                 <td>${getpartyId[item.nameOfExporter || item.exporter] || ''}</td>
// // //                 <td>${item.chaNo || ''}</td>
// // //                 <td>${item.descriptionOfGoods || item.remarks || ''}</td>
// // //                 <td>${item.noOfPackages || item.nop || ''}</td>
// // //                 <td>${item.grossWeight || item.gwWeight || ''}</td>
// // //                 <td>${item.fobValueInINR || ''}</td>
// // //                 <td>${item.airwayBillNo || ''}</td>
// // //                 <td>${item.portOfDestination || ''}</td>
// // //                 <td>${getConsoleId[item.cartingAgent] || ''}</td>
// // //               </tr>
// // //                 `
// // //               )
// // //               .join("")}
// // //           </tbody>
// // //         </table>
// // //       `;
// // //     };
  
// // //     for (
// // //       let pageIndex = 0;
// // //       pageIndex < Math.ceil(totalRecords / recordsPerPage);
// // //       pageIndex++
// // //     ) {
// // //       // Merge exportData and exportSubData arrays for the current page
// // //       const pageRecords = [...exportData, ...exportSubData].slice(
// // //         startIndex,
// // //         startIndex + recordsPerPage
// // //       );
  
// // //       // Check if it's the last page
// // //       const isLastPage =
// // //         pageIndex === Math.ceil(totalRecords / recordsPerPage) - 1;
  
// // //       printWindow.document.write(`
// // //         <!DOCTYPE html>
// // //         <html>
// // //         <head>
// // //         <title> Export Report Register</title>
// // //         <style>
// // //           @page {
// // //             margin: 1cm;
// // //             /* Adjust this value to position the header correctly */
// // //           }
  
// // //           .printable-area {
// // //             font-family: Arial, sans-serif;
// // //             page-break-before: always;
// // //           }
  
// // //           table {
// // //             width: 100%;
// // //             border-collapse: collapse;
// // //           }
  
// // //           td {
// // //             border: 1px solid #dddddd;
// // //             text-align: center;
// // //             padding: 1px;
// // //             font-size: 10px;
// // //         }
    
// // //         th {
// // //           border: 1px solid #dddddd;
// // //             background-color: #f2f2f2;
// // //             text-align: center;
// // //             font-size: 12px;
// // //         }
  
// // //           .container {
// // //             display: flex;
// // //             justify-content: space-between;
// // //           }
  
// // //           .left-column {
// // //             width: 70%; /* Adjust the width as needed */
// // //           }
  
// // //           .page-break {
// // //             page-break-before: always; /* This will force a page break before the element */
// // //           }
  
// // //           .right-column {
// // //             width: 30%; /* Adjust the width as needed */
// // //             text-align: right; /* Align text to the right */
// // //           }
  
// // //           .header img {
// // //             max-width: auto; /* Ensure the image doesn't exceed the page width */
// // //             max-height: auto; /* Adjust the maximum image height as needed */
// // //           }
  
// // //           .report-header {
// // //             display: flex;
// // //             justify-content: space-between;
// // //             margin-bottom: 5px;
// // //           }
  
// // //           #page-header {
// // //             position: static;
// // //             top: 0;
// // //             left: 0;
// // //             right: 0;
// // //             text-align: center;
// // //           }
// // //         </style>
// // //         </head>
// // //         <body>
// // //         <div id="page-header">
// // //           <img src="${dgdcImage}" alt="Header Image" style="max-width: 306px; display: block; margin: 0 auto;">
// // //           <div style="text-align: center;">
// // //             <div style="font-size:14px;font-weight:bold;">Export Register Report - Page ${currentPageNumber}</div><br/>
// // //           </div>
// // //         </div>
// // //         <div class="report-header">
// // //           <div></div>
// // //           <div style="font-size:12px;">FOR THE PERIOD: ${StarDate} To ${EndDate}</div>
// // //           <div style="font-size:12px;"> REPORT DATE: ${format(new Date(), "dd/MM/yyyy HH:mm")} </div>
// // //         </div>
// // //         ${generateTableHTML(pageRecords)}
// // //         </body>
// // //         </html>
// // //       `);
  
// // //       // Check if it's not the last page, then add a page break
// // //       if (!isLastPage) {
// // //         printWindow.document.write('<div class="page-break"></div>');
// // //       } else {
// // //         printWindow.document.write(`
// // //           <table>
// // //           <tbody>
// // //           <tr>
// // //           <td style="font-weight: bold;background-color: #BADDDA">Total</td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           <td style="background-color: #BADDDA">${totalExSbNoCount}</td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           <td style="background-color: #BADDDA">${totalEXSubNoOfPackages + totalExNoOfPackages}</td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           <td style="background-color: #BADDDA">${totalExFobValueInINR}</td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           <td style="background-color: #BADDDA"></td>
// // //           </tr>
// // //           </tbody>
// // //           </table>
// // //         `);
// // //       }
  
// // //       // Update the starting index for the next page
// // //       startIndex += recordsPerPage;
// // //       currentPageNumber++;
// // //     }
  
// // //     printWindow.document.close();
// // //     printWindow.print();
// // //     printWindow.onafterprint = () => printWindow.close();
// // //   };
// // //   const handleExportPDFDownload = async () => {
// // //     const pdfBlob = await pdf(
// // //       <MyExportPDFDocument
// // //         {...{
// // //           StarDate,
// // //           EndDate,
// // //           selectedParty,
// // //           exportSubData,
// // //           exportData,
// // //           totalEXSubNoOfPackages,
// // //           totalExFobValueInINR,
// // //           totalExSbNoCount,
// // //           totalExNoOfPackages
// // //         }}
// // //       />
// // //     ).toBlob();
// // //     saveAs(pdfBlob, "Export_Register.pdf");
// // //   };

// // //   const PAGE_BREAK_ROWS_PDF = 15;

// // //   const MyExportPDFDocument = ({
// // //     StarDate,
// // //     EndDate,
// // //     exportData,
// // //     exportSubData, // Include exportSubData as a prop
// // //     totalExSbNoCount,
// // //     totalExNoOfPackages,
// // //     totalExFobValueInINR,
// // //   }) =>
// // //   {
// // //     if (!exportData || !Array.isArray(exportData) || !exportSubData || !Array.isArray(exportSubData)) {
// // //       // Handle the case when data is not available
// // //       return null;
// // //     }
  
// // //   const mergedData = [...exportData, ...exportSubData];
// // //   return(
// // //     <Document>
// // //       {Array.from({
// // //         length: Math.ceil(mergedData.length / PAGE_BREAK_ROWS_PDF),
// // //       }).map((_, pageIndex) => (
// // //         <Page
// // //           key={pageIndex}
// // //           size="A4"
// // //           orientation="landscape"
// // //           style={styles.page}
// // //         >
// // //           <View style={styles.section}>
// // //             {/* Your header content */}
// // //             <View style={styles.header}>
// // //               <CustomHeader />
// // //             </View>
// // //             <View style={[styles.centeredText, { fontWeight: "bold" }]}>
// // //               <Text style={[styles.centeredText, { fontWeight: "bold" }]}>
// // //                 EXPORT REGISTER
// // //               </Text>
// // //             </View>
// // //             <View style={styles.reportHeader}>
// // //               <Text></Text>
// // //               <Text>
// // //                 FOR THE PERIOD :{StarDate} TO {EndDate}{" "}
// // //               </Text>
// // //               <Text style={{ marginLeft: 4 }}>
// // //                 REPORT DATE: {format(new Date(), "dd/MM/yyyy HH:mm")}
// // //               </Text>
// // //             </View>
  
// // //             <View style={styles.table}>
// // //               <View style={styles.tableRow}>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   Sr.No
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   SER No
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   SB No
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   SER Date
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   Exporter Names
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   CHA No
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>DESC</Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader ,{width:45}]}>
// // //                 NOP
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   Net Weight
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   FOB value in INR
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   AIRWAY BILL NO
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   PORT OF DESTINATION
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   Console Is
// // //                 </Text>
// // //               </View>
// // //               {[...exportData, ...exportSubData] // Merge exportData and exportSubData
// // //                 .slice(
// // //                   pageIndex * PAGE_BREAK_ROWS_PDF,
// // //                   (pageIndex + 1) * PAGE_BREAK_ROWS_PDF
// // //                 )
// // //                 .map((item, index) => (
// // //                   <View style={styles.tableRow} key={index}>
// // //                     <Text style={styles.tableCell}>
// // //                       {index + 1 + pageIndex * PAGE_BREAK_ROWS_PDF}
// // //                     </Text>
// // //                     <Text style={styles.tableCell}>{item.serNo || ""}</Text>
// // //                     <Text style={styles.tableCell}>{item.sbNo}</Text>
// // //                     <Text style={styles.tableCell}>
// // //                       {formatedDate(item.serDate) || ""}
// // //                     </Text>
// // //                     <Text style={styles.tableCell}>
// // //                       {getpartyId[item.nameOfExporter || item.exporter] || ""}
// // //                     </Text>
// // //                     <Text style={styles.tableCell}>{item.chaNo}</Text>
// // //                     <Text style={styles.tableCell}>
// // //                       {item.descriptionOfGoods || item.remarks || ""}
// // //                     </Text>
// // //                     <Text style={[styles.tableCell  ,{width:45}]}>
// // //                       {item.noOfPackages || item.nop || ""}
// // //                     </Text>
// // //                     <Text style={styles.tableCell}>
// // //                       {item.grossWeight || item.gwWeight || ""}
// // //                     </Text>
// // //                     <Text style={styles.tableCell}>{item.fobValueInINR}</Text>
// // //                     <Text style={styles.tableCell}>{item.airwayBillNo}</Text>
// // //                     <Text style={styles.tableCell}>
// // //                       {item.portOfDestination || ""}
// // //                     </Text>
// // //                     <Text style={styles.tableCell}>
// // //                       {getConsoleId[item.cartingAgent] || ""}
// // //                     </Text>
// // //                   </View>
// // //                 ))}
// // //               {pageIndex ===
// // //                 Math.ceil(exportData.length / PAGE_BREAK_ROWS_PDF) - 1 && (
// // //                 <View style={styles.tableRow}>
// // //                   <Text style={styles.tableCell}>Total</Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}>{totalExSbNoCount}</Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={[styles.tableCell,{width:45}]}>
// // //                     {totalExNoOfPackages}
// // //                   </Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}>{totalExFobValueInINR}</Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                 </View>
// // //               )}
// // //             </View>
// // //           </View>
// // //         </Page>
// // //       ))}

// // //     </Document>
// // //   );
// // // };
  
// // //   const handlePDFDownload = async () => {
// // //     const pdfBlob = await pdf(
// // //       <MyPDFDocument
// // //         {...{
// // //           StarDate,
// // //           EndDate,
// // //           selectedParty,
// // //           subContractData,
// // //           totalFobValueInINR,
// // //           totalNoOfPackages,
// // //           totalSbNoCount,
// // //         }}
// // //       />
// // //     ).toBlob();
// // //     saveAs(pdfBlob, "Export_Register.pdf");
// // //   };
// // //   const handleReset =()=>{
// // //     setStartDate(new Date());
// // //     setEndDate(new Date());
// // //     setSelectedParty("");
// // //     setExportSubData([]);
// // //     setExportData([]);
// // //     setShowExportDataTable(false);
// // //       }

// // //   function fetchCompanyName(companyId) {
// // //     // Make an Axios GET request to retrieve the company name based on companyId
// // //     return axios
// // //       .get(`https://${ipaddress}export/findCompanyname/${companyId}`)
// // //       .then(function (response) {
// // //         return response.data; // Return the retrieved company name
// // //       })
// // //       .catch(function (error) {
// // //         console.error("Error fetching company name:", error);
// // //         return ""; // Return an empty string or handle the error as needed
// // //       });
// // //   }
// // //   function fetchPartyName(companyId, branchId, partyId) {
// // //     // Make an Axios GET request to retrieve the company name based on companyId
// // //     return axios
// // //       .get(
// // //         `https://${ipaddress}export/findPartyName/${companyId}/${branchId}/${partyId}`
// // //       )
// // //       .then(function (response) {
// // //         return response.data; // Return the retrieved company name
// // //       })
// // //       .catch(function (error) {
// // //         console.error("Error fetching company name:", error);
// // //         return ""; // Return an empty string or handle the error as needed
// // //       });
// // //   }

// // //   function fetchExporterName(companyId, branchId, partyId) {
// // //     // Make an Axios GET request to retrieve the company name based on companyId
// // //     return axios
// // //       .get(
// // //         `https://${ipaddress}export/findPartyName/${companyId}/${branchId}/${partyId}`
// // //       )
// // //       .then(function (response) {
// // //         return response.data; // Return the retrieved company name
// // //       })
// // //       .catch(function (error) {
// // //         console.error("Error fetching company name:", error);
// // //         return ""; // Return an empty string or handle the error as needed
// // //       });
// // //   }

// // //   function fetchConsoleName(companyId, branchId, externaluserId) {
// // //     // Make an Axios GET request to retrieve the company name based on companyId
// // //     return axios
// // //       .get(
// // //         `https://${ipaddress}export/findConsoleName/${companyId}/${branchId}/${externaluserId}`
// // //       )
// // //       .then(function (response) {
// // //         return response.data; // Return the retrieved company name
// // //       })
// // //       .catch(function (error) {
// // //         console.error("Error fetching company name:", error);
// // //         return ""; // Return an empty string or handle the error as needed
// // //       });
// // //   }
// // //   function fetchBranchName(companyId, branchId) {
// // //     // Make an Axios GET request to retrieve the branch name based on branchId
// // //     return axios
// // //       .get(
// // //         `https://${ipaddress}export/findBranchName/${companyId}/${branchId}`
// // //       )
// // //       .then(function (response) {
// // //         return response.data; // Return the retrieved branch name
// // //       })
// // //       .catch(function (error) {
// // //         console.error("Error fetching branch name:", error);
// // //         return ""; // Return an empty string or handle the error as needed
// // //       });
// // //   }

// // //   const handleXLSDownload = async () => {
// // //     const modifiedReordList = await Promise.all(
// // //       subContractData.map(async (item, index) => {
// // //         // const companyname = await fetchCompanyName(item.companyId);
// // //         // const branchname = await fetchBranchName(item.companyId, item.branchId);
// // //         const partyName = await fetchPartyName(
// // //           item.companyId,
// // //           item.branchId,
// // //           item.nameOfExporter
// // //         );
// // //         const userName = await fetchConsoleName(
// // //           item.companyId,
// // //           item.branchId,
// // //           item.consoleAgent
// // //         );

// // //         return {
// // //           "Sr.No": index + 1,
// // //           // "Company Name": companyname,
// // //           // "Branch Name": branchname,
// // //           "SER No": item.serNo,
// // //           "SB No": item.sbNo,
// // //           "SER Date": formatedDate(item.serDate),
// // //           "Parcle Type": " ", // Modify this to the actual field name
// // //           "Exporter Names": getpartyId[item.nameOfExporter],
// // //           "CHA No": item.chaNo,
// // //           DESC: item.descriptionOfGoods,
// // //           "No of Pkgs": item.noOfPackages,
// // //           "Net Weight": item.grossWeight,
// // //           "FOB value in INR": item.fobValueInINR,
// // //           "AIRWAY BILL NO": item.airwayBillNo,
// // //           "PORT OF DESTINATION": item.portOfDestination,
// // //           "Console Is": getConsoleId[item.consoleAgent],
// // //           // Add more fields as needed
// // //         };
// // //       })
// // //     );

// // //     const workbook = XLSX.utils.book_new();
// // //     const worksheet = XLSX.utils.json_to_sheet(modifiedReordList);

// // //     // Add headers for all fields
// // //     const headers = Object.keys(modifiedReordList[0]);
// // //     headers.forEach((header, index) => {
// // //       worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
// // //         t: "s",
// // //         v: header,
// // //         s: { font: { bold: true } }, //
// // //       };
// // //     });

// // //     // Set column widths based on data
// // //     const colWidths = headers.map((header) => ({
// // //       wch: header.length + 2, // You can adjust the width as needed
// // //     }));

// // //     worksheet["!cols"] = colWidths;

// // //     XLSX.utils.book_append_sheet(workbook, worksheet, "export_transaction");
// // //     const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
// // //     const blob = new Blob([s2ab(xlsFile)], {
// // //       type: "application/vnd.ms-excel",
// // //     });
// // //     saveAs(blob, "Export_Register.xls");
// // //   };

// // //   const s2ab = (s) => {
// // //     const buf = new ArrayBuffer(s.length);
// // //     const view = new Uint8Array(buf);
// // //     for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
// // //     return buf;
// // //   };


// // //   const handleExportXLSDownload = async () => {
// // //     const modifiedReordList = await Promise.all(
// // //       mergedAllData.map(async (item, index) => {
// // //         // const companyname = await fetchCompanyName(item.companyId);
// // //         // const branchname = await fetchBranchName(item.companyId, item.branchId);
// // //         const partyName = await fetchPartyName(
// // //           item.companyId,
// // //           item.branchId,
// // //           item.nameOfExporter
// // //         );

// // //         const exporterName = await fetchExporterName(
// // //           item.companyId,
// // //           item.branchId,
// // //           item.exporter
// // //         );
// // //         const userName = await fetchConsoleName(
// // //           item.companyId,
// // //           item.branchId,
// // //           item.consoleAgent
// // //         );

// // //         return {
// // //           "Sr.No": index + 1,
// // //           // "Company Name": companyname,
// // //           // "Branch Name": branchname,
// // //           "SER No": item.serNo ||  item.serNo,
// // //           "SB No": item.sbNo,
// // //           "SER Date": formatedDate(item.serDate) ||formatedDate(item.serDate),
// // //           "Parcle Type": " ", // Modify this to the actual field name
// // //           "Exporter Names": getpartyId[item.nameOfExporter]  || getpartyId[item.exporter],
// // //           "CHA No": item.chaNo,
// // //           "DESC": item.descriptionOfGoods || item.remarks,
// // //           "No of Pkgs": item.noOfPackages || item.nop,
// // //           "Net Weight": item.grossWeight || item.gwWeight,
// // //           "FOB value in INR": item.fobValueInINR,
// // //           "AIRWAY BILL NO": item.airwayBillNo,
// // //           "PORT OF DESTINATION": item.portOfDestination,
// // //           "Console Is": getConsoleId[item.consoleAgent],
// // //         };
// // //       })
// // //     );

// // //     const workbook = XLSX.utils.book_new();
// // //     const worksheet = XLSX.utils.json_to_sheet(modifiedReordList);

// // //     // Add headers for all fields
// // //     const headers = Object.keys(modifiedReordList[0]);
// // //     headers.forEach((header, index) => {
// // //       worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
// // //         t: "s",
// // //         v: header,
// // //         s: { font: { bold: true } }, //
// // //       };
// // //     });

// // //     // Set column widths based on data
// // //     const colWidths = headers.map((header) => ({
// // //       wch: header.length + 2, // You can adjust the width as needed
// // //     }));

// // //     worksheet["!cols"] = colWidths;

// // //     XLSX.utils.book_append_sheet(workbook, worksheet, "export_report");
// // //     const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
// // //     const blob = new Blob([s2ab(xlsFile)], {
// // //       type: "application/vnd.ms-excel",
// // //     });
// // //     saveAs(blob, "Export_Register.xls");
// // //   };

  
// // //   const MyPDFDocument = ({
// // //     totalNoOfPackages,
// // //     subContractData,
// // //     totalSbNoCount,
// // //     totalFobValueInINR,
// // //   }) => (
// // //     <Document>
// // //       {Array.from({
// // //         length: Math.ceil(subContractData.length / PAGE_BREAK_ROWS_PDF),
// // //       }).map((_, pageIndex) => (
// // //         <Page
// // //           key={pageIndex}
// // //           size="A4"
// // //           orientation="landscape"
// // //           style={{ ...styles.page }}
// // //         >
// // //           <View style={styles.section}>
// // //             {/* Your header content */}
// // //             <View style={styles.header}>
// // //               <Image src={dgdcImage} style={styles.image} />
// // //             </View>
// // //             <View style={[styles.centeredText, { fontWeight: "bold" }]}>
// // //               <Text style={[styles.centeredText, { fontWeight: "bold" }]}>
// // //                 EXPORT REGISTER
// // //               </Text>
// // //             </View>
// // //             <View style={styles.reportHeader}>
// // //               <Text></Text>
// // //               <Text>
// // //                 FOR THE PERIOD :{StarDate} TO {EndDate}{" "}
// // //               </Text>
// // //               <Text style={{ marginLeft: 4 }}>
// // //                 REPORT DATE: {format(new Date(), "dd/MM/yyyy HH:mm")}
// // //               </Text>
// // //             </View>

// // //             {/* Your table */}
// // //             <View style={styles.table}>
// // //               <View style={styles.tableRow}>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   Sr.No
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   SER No
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   SB No
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   SER Date
// // //                 </Text>
// // //                 {/* <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   Parcel Type
// // //                 </Text> */}
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   Exporter Names
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   CHA No
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   DESC
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                  NOP
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   Net Weight
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   FOB value in INR
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   AIRWAY BILL NO
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   PORT OF DESTINATION
// // //                 </Text>
// // //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// // //                   Console Is
// // //                 </Text>
// // //               </View>
// // //               {subContractData
// // //                 .slice(
// // //                   pageIndex * PAGE_BREAK_ROWS_PDF,
// // //                   (pageIndex + 1) * PAGE_BREAK_ROWS_PDF
// // //                 )
// // //                 .map((item, index) => (
// // //                   <View style={styles.tableRow} key={index}>
// // //                     <Text style={styles.tableCell}>
// // //                       {index + 1 + pageIndex * PAGE_BREAK_ROWS_PDF}
// // //                     </Text>
// // //                     <Text style={styles.tableCell}>{item.serNo}</Text>
// // //                     <Text style={styles.tableCell}>{item.sbNo}</Text>
// // //                     <Text style={styles.tableCell}>
// // //                       {formatedDate(item.serDate)}
// // //                     </Text>
// // //                     {/* <Text style={styles.tableCell}>Parcel Type Value</Text> */}
// // //                     <Text style={styles.tableCell}>
// // //                       {getpartyId[item.nameOfExporter]}
// // //                     </Text>
// // //                     <Text style={styles.tableCell}>{item.chaNo}</Text>
// // //                     <Text style={styles.tableCell}>
// // //                       {item.descriptionOfGoods}
// // //                     </Text>
// // //                     <Text style={styles.tableCell}>{item.noOfPackages}</Text>
// // //                     <Text style={styles.tableCell}>{item.grossWeight}</Text>
// // //                     <Text style={styles.tableCell}>{item.fobValueInINR}</Text>
// // //                     <Text style={styles.tableCell}>{item.airwayBillNo}</Text>
// // //                     <Text style={styles.tableCell}>
// // //                       {item.portOfDestination}
// // //                     </Text>
// // //                     <Text style={styles.tableCell}>
// // //                       {getConsoleId[item.cartingAgent]}
// // //                     </Text>
// // //                   </View>
// // //                 ))}
// // //               {/* Display the "Total" row only on the last page */}
// // //               {pageIndex ===
// // //                 Math.ceil(subContractData.length / PAGE_BREAK_ROWS_PDF) - 1 && (
// // //                 <View style={styles.tableRow}>
// // //                   <Text style={styles.tableCell}>Total</Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}>{totalSbNoCount}</Text>
// // //                   {/* <Text style={styles.tableCell}></Text> */}
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}>{totalNoOfPackages}</Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}>{totalFobValueInINR}</Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                   <Text style={styles.tableCell}></Text>
// // //                 </View>
// // //               )}
// // //             </View>
// // //           </View>
// // //         </Page>
// // //       ))}
// // //     </Document>
// // //   );


// // //   let indexCounter = 1;
// // //   return (
// // //     <div>
// // //       <h4 style={{ marginLeft: 18, marginTop: 25 }}>Export Register Report</h4>
// // //       {/* style={{marginTop:25,marginRight:1,marginLeft:18,padding: 8}} */}
// // //       <Card
// // //         style={{ marginTop: 25, marginRight: 18, marginLeft: 18, padding: 8 }}
// // //       >
// // //         <Container>
// // //           <Form>
// // //             <Row>
// // //               <Col md={3}>
// // //                 <Form.Group>
// // //                   <Form.Label className="inputhead">From Date</Form.Label>
// // //                   <div className="input-group">
// // //                     <DatePicker
// // //                       selected={startDate}
// // //                       onChange={(date) => setStartDate(date)}
// // //                       dateFormat="dd/MM/yyyy" // You can customize the date format
// // //                       name="startDate"
// // //                       required
// // //                       className="form-control border-right-0 inputField"
// // //                       customInput={<input style={{ width: "18vw" }} />}
// // //                     />
// // //                   </div>
// // //                 </Form.Group>
// // //               </Col>
// // //               <Col md={3}>
// // //                 <Form.Group>
// // //                   <Form.Label className="inputhead">To Date</Form.Label>
// // //                   <div className="input-group">
// // //                     <DatePicker
// // //                       selected={endDate}
// // //                       onChange={(date) => setEndDate(date)}
// // //                       dateFormat="dd/MM/yyyy" // You can customize the date format
// // //                       name="endDate"
// // //                       required
// // //                       className="form-control border-right-0 inputField"
// // //                       customInput={<input style={{ width: "18vw" }} />}
// // //                     />
// // //                   </div>{" "}
// // //                 </Form.Group>
// // //               </Col>
// // //               <Col md={3}>
// // //                 <label htmlFor="company" className="inputhead">
// // //                   Console
// // //                 </label>
// // //                 <select
// // //                   name="company"
// // //                   id="dw1"
// // //                   className=""
// // //                   onChange={handlePartyChange}
// // //                   value={selectedParty}
// // //                 >
// // //                   <option value="">Select</option>
// // //                   {consoles.map((party) => (
// // //                     <option
// // //                       key={party.externaluserId}
// // //                       value={party.externaluserId}
// // //                     >
// // //                       {party.userName}
// // //                     </option>
// // //                   ))}
// // //                 </select>
// // //               </Col>
// // //               <Col md={3}>
// // //                 <div style={{ marginTop: 30 }}>
// // //                   <Button
// // //                     color="success"
// // //                     outline
// // //                     style={{ marginRight: 18 }}
// // //                     onClick={handleSearch}
// // //                   >
// // //                     SEARCH
// // //                   </Button>

// // //                   <Button onClick={handleReset} color="warning" outline>
// // //                     RESET
// // //                   </Button>
// // //                 </div>
// // //               </Col>
// // //             </Row>
// // //           </Form>
// // //         </Container>
// // //       </Card>
// // //       {showSubContractDataTable ? (
// // //         <div>
// // //           <Card
// // //             style={{
// // //               marginTop: 25,
// // //               marginRight: 18,
// // //               marginLeft: 18,
// // //               padding: 8,
// // //             }}
// // //           >
// // //             <Row>
// // //               <Col md={8}></Col>
// // //               <Col md={4}>
// // //                 <button
// // //                   style={{ marginLeft: 9 }}
// // //                   className="btn btn-outline-danger btn-margin"
// // //                   type="button"
// // //                   onClick={handlePrint}
// // //                 >
// // //                   <FontAwesomeIcon
// // //                     icon={faPrint}
// // //                     style={{ marginRight: "5px" }}
// // //                   />
// // //                   Print
// // //                 </button>
// // //                 <button
// // //                   className="btn btn-outline-danger btn-margin"
// // //                   type="button"
// // //                   style={{ marginLeft: "10px", marginRight: 9 }}
// // //                   onClick={handleXLSDownload}
// // //                 >
// // //                   <FontAwesomeIcon
// // //                     icon={faFileExcel}
// // //                     style={{ marginRight: "5px" }}
// // //                   />
// // //                   XLS
// // //                 </button>
// // //                 <button
// // //                   className="btn btn-outline-danger btn-margin"
// // //                   type="button"
// // //                   onClick={handlePDFDownload}
// // //                   style={{ marginLeft: "10px" }}
// // //                 >
// // //                   <FontAwesomeIcon
// // //                     icon={faFilePdf}
// // //                     style={{ marginRight: "5px" }}
// // //                   />
// // //                   PDF
// // //                 </button>
// // //               </Col>
// // //             </Row>
// // //             <div className="table-responsive">
// // //               <Table
// // //                 className="table table-striped table-hover"
// // //                 style={{ marginTop: 9 }}
// // //               >
// // //                 {/* Render subContractData table here */}
// // //                 <thead>
// // //                   <tr>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>Sr.No</th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>SER No</th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>SB No</th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>SER Date</th>

// // //                     <th style={{ backgroundColor: "#BADDDA" }}>
// // //                       Exporter Names
// // //                     </th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>CHA No</th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>DESC</th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>No of Pkgs</th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>Net Weight</th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>
// // //                       FOB value in INR
// // //                     </th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>
// // //                       AIRWAY BILL NO
// // //                     </th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>
// // //                       PORT OF DESTINATION
// // //                     </th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>Console Is</th>
// // //                   </tr>
// // //                 </thead>
// // //                 <thead>
// // //                   <tr>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>Total</th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>
// // //                       {totalSbNoCount}
// // //                     </th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}></th>

// // //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>
// // //                       {totalNoOfPackages}
// // //                     </th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}>
// // //                       {totalFobValueInINR}
// // //                     </th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                   </tr>
// // //                 </thead>
// // //                 <tbody>
// // //                   {subContractData.map((item, index) => (
// // //                     <tr key={index}>
// // //                       <td>{index + 1}</td>
// // //                       <td>{item.serNo}</td>
// // //                       <td>{item.sbNo}</td>
// // //                       <td>{formatedDate(item.serDate)}</td>

// // //                       <td>{getpartyId[item.nameOfExporter]}</td>
// // //                       <td>{item.chaNo}</td>
// // //                       <td>{item.descriptionOfGoods}</td>
// // //                       <td>{item.noOfPackages}</td>
// // //                       <td>{item.grossWeight}</td>
// // //                       <td>{item.fobValueInINR}</td>
// // //                       <td>{item.airwayBillNo}</td>
// // //                       <td>{item.portOfDestination}</td>
// // //                       <td>{getConsoleId[item.consoleAgent]}</td>
// // //                     </tr>
// // //                   ))}
// // //                 </tbody>
// // //               </Table>
// // //             </div>
// // //             <div
// // //               style={{
// // //                 marginTop: 4,
// // //                 display: "flex",
// // //                 flexDirection: "row",
// // //                 justifyContent: "space-between",
// // //               }}
// // //             >
// // //               <p style={{ fontWeight: "bold" }}>
// // //                 Total No. SB No: {totalSbNoCount}
// // //               </p>
// // //               <p style={{ fontWeight: "bold" }}>
// // //                 Total No. of Packages: {totalNoOfPackages}
// // //               </p>
// // //               <p style={{ fontWeight: "bold" }}>
// // //                 Total FOB Value in INR: {totalFobValueInINR}
// // //               </p>
// // //             </div>
// // //           </Card>
// // //         </div>
// // //       ) : (
// // //         <div>
// // //           {showExportDataTable ? (
// // //             <Card
// // //               style={{
// // //                 marginTop: 25,
// // //                 marginRight: 18,
// // //                 marginLeft: 18,
// // //                 padding: 8,
// // //               }}
// // //             >
// // //               <Row>
// // //                 <Col md={8}></Col>
// // //                 <Col md={4}>
// // //                   <button
// // //                     style={{ marginLeft: 9 }}
// // //                     className="btn btn-outline-danger btn-margin"
// // //                     type="button"
// // //                     onClick={handleExportPrint}
// // //                   >
// // //                     <FontAwesomeIcon
// // //                       icon={faPrint}
// // //                       style={{ marginRight: "5px" }}
// // //                     />
// // //                     Print
// // //                   </button>
// // //                   <button
// // //                     className="btn btn-outline-danger btn-margin"
// // //                     type="button"
// // //                     style={{ marginLeft: "10px", marginRight: 9 }}
// // //                     onClick={handleExportXLSDownload}
// // //                   >
// // //                     <FontAwesomeIcon
// // //                       icon={faFileExcel}
// // //                       style={{ marginRight: "5px" }}
// // //                     />
// // //                     XLS
// // //                   </button>
// // //                   <button
// // //                     className="btn btn-outline-danger btn-margin"
// // //                     type="button"
// // //                     onClick={handleExportPDFDownload}
// // //                     style={{ marginLeft: "10px" }}
// // //                   >
// // //                     <FontAwesomeIcon
// // //                       icon={faFilePdf}
// // //                       style={{ marginRight: "5px" }}
// // //                     />
// // //                     PDF
// // //                   </button>
// // //                 </Col>
// // //               </Row>
// // //               <div className="table-responsive">
// // //                 <Table
// // //                   className="table table-striped table-hover"
// // //                   style={{ marginTop: 9 }}
// // //                 >
// // //                   <thead>
// // //                     <tr>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>Sr.No</th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>SER No</th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>SB No</th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>SER Date</th>

// // //                       <th style={{ backgroundColor: "#BADDDA" }}>
// // //                         Exporter Names
// // //                       </th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>CHA No</th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>DESC</th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>No of Pkgs</th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>Net Weight</th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>
// // //                         FOB value in INR
// // //                       </th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>
// // //                         AIRWAY BILL NO
// // //                       </th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>
// // //                         PORT OF DESTINATION
// // //                       </th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>Console Is</th>
// // //                     </tr>
// // //                   </thead>
// // //                   <thead>
// // //                     <tr>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>Total</th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>
// // //                         {totalExSbNoCount}
// // //                       </th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}></th>

// // //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>
// // //                         {totalExNoOfPackages + totalEXSubNoOfPackages}
// // //                       </th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}>
// // //                         {totalExFobValueInINR}
// // //                       </th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// // //                     </tr>
               
// // //                   </thead>
// // //                   <tbody>
                
// // //                     {exportData.map((exportItem, index) => (
// // //                       <tr key={index}>
// // //                         <td>{indexCounter++}</td>
// // //                         <td>{exportItem.serNo}</td>
// // //                         <td>{exportItem.sbNo}</td>
// // //                         <td>{formatedDate(exportItem.serDate)}</td>

// // //                         <td>{getpartyId[exportItem.nameOfExporter]}</td>
// // //                         <td>{exportItem.chaNo}</td>
// // //                         <td>{exportItem.descriptionOfGoods}</td>
// // //                         <td>{exportItem.noOfPackages}</td>
// // //                         <td>{exportItem.grossWeight}</td>
// // //                         <td>{exportItem.fobValueInINR}</td>
// // //                         <td>{exportItem.airwayBillNo}</td>
// // //                         <td>{exportItem.portOfDestination}</td>
// // //                         <td>{getConsoleId[exportItem.consoleAgent]}</td>
// // //                       </tr>
// // //                     ))}

// // //                     {exportSubData.map((exportSubItem, index) => (
// // //                       <tr key={index}>
// // //                         <td>{indexCounter++}</td>
// // //                         <td>{exportSubItem.serNo}</td>
// // //                         <td>{exportSubItem.sbNo}</td>
// // //                         <td>{formatedDate(exportSubItem.serDate)}</td>

// // //                         <td>{getpartyId[exportSubItem.exporter]}</td>
// // //                         <td>{exportSubItem.chaNo}</td>
// // //                         <td>{exportSubItem.remarks}</td>
// // //                         <td>{exportSubItem.nop}</td>
// // //                         <td>{exportSubItem.gwWeight}</td>
// // //                         <td>{exportSubItem.fobValueInINR}</td>
// // //                         <td>{exportSubItem.airwayBillNo}</td>
// // //                         <td>{exportSubItem.portOfDestination}</td>
// // //                         <td>{getConsoleId[exportSubItem.consoleAgent]}</td>
// // //                       </tr>
// // //                     ))}
// // //                   </tbody>
// // //                 </Table>

// // //                 <div
// // //               style={{
// // //                 marginTop: 4,
// // //                 display: "flex",
// // //                 flexDirection: "row",
// // //                 justifyContent: "space-between",
// // //               }}
// // //             >
// // //               <p style={{ fontWeight: "bold" }}>
// // //                 Total No. SB No: {totalExSbNoCount}
// // //               </p>
// // //               <p style={{ fontWeight: "bold" }}>
// // //                 Total No. of Packages: {totalExNoOfPackages + totalEXSubNoOfPackages}
// // //               </p>
// // //               <p style={{ fontWeight: "bold" }}>
// // //                 Total FOB Value in INR: {totalExFobValueInINR}
// // //               </p>
// // //             </div>
// // //               </div>
// // //             </Card>
// // //           ) : null}
// // //         </div>
// // //       )}
      
// // //     </div>
// // //   );
// // // }


// // import { redirect } from "react-router-dom";
// // import AuthContext from "../Components/AuthProvider";
// // import { useNavigate } from "react-router-dom";
// // import React, { useEffect, useState, useContext } from "react";
// // import "../Components/Style.css";
// // import Row from "react-bootstrap/Row";
// // import * as XLSX from "xlsx"; // Import XLSX library for creating XLS files
// // import Col from "react-bootstrap/Col";
// // import { format } from "date-fns";
// // import {
// //   Button,
// //   ButtonToggle,
// //   CardBody,
// //   Container,
// //   Table,
// //   ToastHeader,
// // } from "reactstrap";
// // import Form from "react-bootstrap/Form";
// // import Card from "react-bootstrap/Card";
// // import DatePicker from "react-datepicker";
// // import ipaddress from "../Components/IpAddress";
// // import { saveAs } from "file-saver";
// // import axios from "axios";
// // import dgdcImage from "../Images/report.jpeg";
// // import {
// //   faFileExcel,
// //   faFilePdf,
// //   faPrint,
// // } from "@fortawesome/free-solid-svg-icons";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import {
// //   Document,
// //   Page,
// //   Text,
// //   View,
// //   pdf,
// //   StyleSheet,
// //   PDFViewer,
// //   Image,
// // } from "@react-pdf/renderer";
// // import { toast } from "react-toastify";

// // export default function Export_Register() {
// //   const navigate = useNavigate();
// //   const [startDate, setStartDate] = useState(new Date());
// //   const [endDate, setEndDate] = useState(new Date());
// //   const [selectedParty, setSelectedParty] = useState("");
// //   const [showSubContractDataTable, setSubContractDataTable] = useState(false);
// //   const [showExportDataTable, setShowExportDataTable] = useState(false);
// //   const [SubImportContractData, setSubImportContractData] = useState([]);
// //   const [subContractData, setSubContractData] = useState([]);
// //   const [exportData, setExportData] = useState([]);
// //   const [exportSubData, setExportSubData] = useState([]);
// //   const [partyNameToIdMap, setPartyNameToIdMap] = useState({});
// //   const [requestId, setRequestId] = useState("");
// //   const [subRequestIdData, setsubRequestIdData] = useState([]);
// //   const [showAlert, setShowAlert] = useState(false);
// //   const {
// //     jwtToken,
// //     userId,
// //     username,
// //     branchId,
// //     companyid,
// //     role,
// //     companyname,
// //     branchname,
// //     isAuthenticated,
// //     login,
// //     logout,
// //   } = useContext(AuthContext);
// //   const styles = StyleSheet.create({
// //     // page: {
// //     //   paddingTop: 30,
// //     //   paddingBottom: 60,
// //     //   paddingHorizontal: 30,
// //     // },

// //     page: {
// //       flexDirection: "row", // Set landscape orientation
// //       // paddingTop: 9,
// //       paddingBottom: 9,
// //       paddingHorizontal: 9,
// //     },
// //     header: {
// //       flexDirection: "row",
// //       alignItems: "center",
// //       marginBottom: 4,
// //     },
// //     section: {
// //       flexGrow: 1, // Take the available space
// //       margin: 10, // Add some margin for spacing
// //       display: "flex",
// //       justifyContent: "center", // Center content horizontally
// //       alignItems: "center", // Center content vertically
// //     },
// //     image: {
// //       width: 306,
// //       height: 100,
// //     },
// //     headerText: {
// //       flex: 1,
// //       textAlign: "center",
// //       fontSize: 18,
// //       fontWeight: "bold",
// //     },
// //     centeredText: {
// //       fontSize: 14,
// //       textAlign: "center", // Center text horizontally
// //       paddingBottom: 2,
// //       paddingTop: 0,
// //     },
// //     reportHeader: {
// //       flexDirection: "row",
// //       justifyContent: "space-between",
// //       paddingBottom: 1,
// //       fontSize: 10,
// //     },
// //     table: {
// //       width: "100%",
// //       borderCollapse: "collapse",
// //       marginBottom: 10,
// //     },
// //     tableRow: {
// //       flexDirection: "row",
// //       borderBottomWidth: 0.4,
// //       borderBottomColor: "black",
// //       fontSize: 10,
// //       borderRightWidth: 0.4,
// //       borderLeftWidth: 0.4,
// //       borderLeftColor: "black",
// //       borderRightColor: "black",
// //       flexWrap: "wrap",
// //     },

// //     tableCell: {
// //       flex: 1,
// //       borderWidth: 0.4,
// //       borderStyle: "solid",
// //       borderColor: "#000",
// //       padding:1,
// //       fontWeight: "bold",
// //       textAlign: "center",
// //       fontSize: 7,
// //     },
// //     tableCellHeader: {
// //       fontWeight: "bold",
// //       textAlign: "center",
// //       fontSize: 9,
// //     },
// //   });
// //   const CustomHeader = () => {
// //     return (
// //       <View style={styles.header}>
// //         <Image src={dgdcImage} style={styles.image} />
// //       </View>
// //     );
// //   };

// //   const totalSbNoCount = subContractData.length;
// //   // Calculate the sum of noOfPackages and fobValueInINR
// //   const totalNoOfPackages = subContractData.reduce(
// //     (total, item) => total + item.noOfPackages,
// //     0
// //   );

// //   const totalFobValueInINR = subContractData.reduce(
// //     (total, exportItem) => total + exportItem.fobValueInINR,
// //     0
// //   );

// //   //Export
// //   const totalExSbNoCount = exportData.length;
// //   const totalExNoOfPackages = exportData.reduce(
// //     (total, exportItem) => total + exportItem.noOfPackages,
// //     0
// //   );

// //   const totalExFobValueInINR = exportData.reduce(
// //     (total, exportItem) => total + exportItem.fobValueInINR,
// //     0
// //   );
// // //Sub NOP total 
// // const totalEXSubNoOfPackages = exportSubData.reduce(
// //   (total, exportSubItem) => total + exportSubItem.nop,
// //   0
// // );

// // const mergedAllData = [...exportData, ...exportSubData];
// //   const formatedDate = (inputDate) => {
// //     const date = new Date(inputDate);
// //     const year = date.getFullYear();
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const day = String(date.getDate()).padStart(2, "0");
// //     return `${day}/${month}/${year}`;
// //   };
// //   // const formatDate = (inputDate) => {
// //   //   const date = new Date(inputDate);
// //   //   const year = date.getFullYear();
// //   //   const month = String(date.getMonth() + 1).padStart(2, "0");
// //   //   const day = String(date.getDate()).padStart(2, "0");

// //   //   return `${year}-${month}-${day}`;
// //   // };

// //   const formatDate = (inputDate, setTimeTo) => {
// //     const date = new Date(inputDate);
// //     const year = date.getFullYear();
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const day = String(date.getDate()).padStart(2, "0");
// //     const hours = setTimeTo === "start" ? "00" : "23";
// //     const minutes = setTimeTo === "start" ? "00" : "59";
// //     return `${year}-${month}-${day}`;
// //   };

// //   const formattedStartDate = formatDate(startDate);
// //   const formattedEndDate = formatDate(endDate);

// //   const StarDate = formatedDate(formattedStartDate);
// //   const EndDate = formatedDate(formattedEndDate);

// //   console.log("stastdate ", startDate);

// //   console.log("enddate ", endDate);
// //   // If the user is not authenticated, redirect to the login page
// //   useEffect(() => {
// //     if (!isAuthenticated) {
// //       navigate(
// //         "/login?message=You need to be authenticated to access this page."
// //       );
// //     }
// //   }, [isAuthenticated, navigate]);
// //   console.log(startDate);

// //   const [consoles, setConsoles] = useState([]);
// //   const [getConsoleId, setGetConsoleId] = useState({});
// //   const fetchConsoleNames = async () => {
// //     try {
// //       const response = await fetch(
// //         `https://${ipaddress}externalParty/console/${companyid}/${branchId}`
// //       );
// //       const data = await response.json();
// //       const consoleMap = {};
// //       data.forEach((console) => {
// //         consoleMap[console.externaluserId] = console.userName;
// //       });
// //       setGetConsoleId(consoleMap);
// //       setConsoles(data);
// //       setPartyNameToIdMap(consoleMap);
// //     } catch (error) {
// //       console.error("Error fetching party names:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     fetchConsoleNames();
// //   }, []);

// //   const [partys, setPartys] = useState([]);
// //   const [getpartyId, setGetpartyId] = useState({});
// //   const fetchPartyNames = async () => {
// //     try {
// //       const response = await fetch(
// //         `https://${ipaddress}parties/getAll/${companyid}/${branchId}`
// //       );
// //       const data = await response.json();
// //       const namesMap = {};
// //       data.forEach((party) => {
// //         namesMap[party.partyId] = party.partyName;
// //       });
// //       setGetpartyId(namesMap);
// //       setPartys(data);
// //       setPartyNameToIdMap(namesMap);
// //     } catch (error) {
// //       console.error("Error fetching party names:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     fetchPartyNames();
// //   }, []);

// //   const handlePartyChange = (event) => {
// //     const selectedPartyName = event.target.value;
// //     setSelectedParty(selectedPartyName);
// //     setShowExportDataTable(false);
// //   };

// //   const fetchSubContractData = () => {
// //     // Make an API request here to fetch the list of airline names based on the provided criteria
// //     fetch(
// //       `https://${ipaddress}export/findExportAllData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&cartingAgent=${selectedParty}`
// //     )
// //       .then((response) => response.json())
// //       .then((data) => {
// //         // Ensure that 'data' is an array before updating the 'airlines' state
// //         if (Array.isArray(data) && data.length > 0) {
// //           // Update the 'airlines' state with the fetched data
// //           setSubContractData(data);
// //           setSubContractDataTable("true");
// //           setShowAlert(false);
// //           setExportData([]);
// //           toast.success("Data Found !", {
// //             position: "top-center",
// //             autoClose: 540, // Duration in milliseconds
// //             hideProgressBar: false,
// //             closeOnClick: true,
// //             pauseOnHover: true,
// //             draggable: true,
// //             progress: undefined,
// //           });
// //           setExportSubData([]);
// //         } else {
// //           console.error("API response is not an array:", data);
       
// //           toast.error("Data Not Found !", {
// //             position: "top-center",
// //             autoClose: 540, // Duration in milliseconds
// //             hideProgressBar: false,
// //             closeOnClick: true,
// //             pauseOnHover: true,
// //             draggable: true,
// //             progress: undefined,
// //           });
// //       // setShowAlert(true);
        
// //       setSubContractDataTable(false);
// //       setShowExportDataTable(false);
// //         }
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching airline names:", error);
// //         setShowAlert(true);
// //       });
// //   };
// // console.log('subContractData ',subContractData);
// //   const fetchExportData = () => {
// //     fetch(
// //       `https://${ipaddress}export/findExportData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
// //     )
// //       .then((response) => response.json())
// //       .then((data) => {
// //         // Ensure that 'data' is an array before updating the 'airlines' state
// //         if (Array.isArray(data) && data.length > 0) {
// //           // Update the 'airlines' state with the fetched data
// //           setExportData(data);
// //           setShowExportDataTable(true);
// //           setShowAlert(false);
// //           setSubContractData([]);
// //           setSubContractDataTable(false);
// //           toast.success("Data Found !", {
// //             position: "top-center",
// //             autoClose: 540, // Duration in milliseconds
// //             hideProgressBar: false,
// //             closeOnClick: true,
// //             pauseOnHover: true,
// //             draggable: true,
// //             progress: undefined,
// //           });
// //         } else {
// //           console.error("API response is not an array:", data);
// //           toast.error("Data Not Found !", {
// //             position: "top-center",
// //             autoClose: 540, // Duration in milliseconds
// //             hideProgressBar: false,
// //             closeOnClick: true,
// //             pauseOnHover: true,
// //             draggable: true,
// //             progress: undefined,
// //           });


// //           // setShowExportDataTable(false);
// //         }
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching airline names:", error);
       
// //       });
// //   };

// //   const fetchExportSubData = () => {
// //     fetch(
// //       `https://${ipaddress}exportsub/findExportSubAllData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
// //     )
// //       .then((response) => response.json())
// //       .then((data) => {
// //         // Ensure that 'data' is an array before updating the 'airlines' state
// //         if (Array.isArray(data) && data.length > 0) {
// //           // Update the 'airlines' state with the fetched data
// //           setExportSubData(data);
// //           setShowExportDataTable(true);
// //           setShowAlert(false);
// //           setSubContractData([]);
          
// //         } else {
// //           console.error("API response is not an array:", data);
// //           setShowAlert(true);
// //         }
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching airline names:", error);
// //         setShowAlert(true);
// //       });
// //   };

 
// //   const handleSearch = () => {

// //     if (startDate && endDate && !selectedParty) {
// //       fetchExportData();
// //       fetchExportSubData();
// //     } else if (startDate && endDate && selectedParty) {
// //       fetchSubContractData();
// //     }
// //   };

// //   const handlePrint = () => {
// //     const isoDate = new Date().toISOString();
// //     const date = new Date(isoDate);
// //     const day = date.getDate().toString().padStart(2, "0");
// //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
// //     const year = date.getFullYear().toString();

// //     let printWindow = window.open("", "_blank");
// //     printWindow.document.open();

// //     const recordsPerPage = 25;
// //     const totalRecords = subContractData.length;

// //     let startIndex = 0; // Initialize the starting index for each page
// //     let currentPageNumber = 1; // Initialize the current page number

// //     for (
// //       let pageIndex = 0;
// //       pageIndex < Math.ceil(totalRecords / recordsPerPage);
// //       pageIndex++
// //     ) {
// //       // Slice the subContractData array to get 10 records for the current page
// //       const pageRecords = subContractData.slice(
// //         startIndex,
// //         startIndex + recordsPerPage
// //       );

// //       // Check if it's the last page
// //       const isLastPage =
// //         pageIndex === Math.ceil(totalRecords / recordsPerPage) - 1;

// //       printWindow.document.write(`
// //         <!DOCTYPE html>
// //         <html>
// //         <head>
// //         <title> Export Report Register</title>
// //         <style>
// //           @page {
// //             margin: 1cm;
// //             /* Adjust this value to position the header correctly */
// //           }
  
// //           .printable-area {
// //             font-family: Arial, sans-serif;
// //             page-break-before: always;
// //           }
  
// //           table {
// //             width: 100%;
// //             border-collapse: collapse;
// //           }
  
// //           td {
// //             border: 1px solid #dddddd;
// //             text-align: center;
// //             padding: 1px;
// //             font-size: 10px;
           
// //         }
    
// //         th {
// //           border: 1px solid #dddddd;
// //             background-color: #f2f2f2;
// //             text-align: center;
// //             font-size: 12px;
// //         }
// //           .container {
// //             display: flex;
// //             justify-content: space-between;
// //           }
  
// //           .left-column {
// //             width: 70%; /* Adjust the width as needed */
// //           }
  
// //           .page-break {
// //             page-break-before: always; /* This will force a page break before the element */
// //           }
  
// //           .right-column {
// //             width: 30%; /* Adjust the width as needed */
// //             text-align: right; /* Align text to the right */
// //           }
  
// //           .header img {
// //             max-width: auto; /* Ensure the image doesn't exceed the page width */
// //             max-height: auto; /* Adjust the maximum image height as needed */
// //           }
  
// //           .report-header {
// //             display: flex;
// //             justify-content: space-between;
// //             margin-bottom: 5px;
// //           }
  
// //           #page-header {
// //             position: static;
// //             top: 0;
// //             left: 0;
// //             right: 0;
// //             text-align: center;
// //           }
// //         </style>
// //         </head>
// //         <body>
// //         <div id="page-header">
// //         <img src="${dgdcImage}" alt="Header Image" style="max-width: 306px; display: block; margin: 0 auto;">
// //         <div style="text-align: center;">
// //         <div style="font-size:14px;font-weight:bold;">Export Register Report - Page ${currentPageNumber}</div><br/>
// //         </div>
// //         </div>
// //         <div class="report-header">
// //         <div></div>
// //         <div style="font-size:12px;">FOR THE PERIOD: ${StarDate} To ${EndDate}</div>
// //         <div style="font-size:12px;"> REPORT DATE: ${format(new Date(), "dd/MM/yyyy HH:mm")} </div>
// //         </div>
// //         <table>
// //         <thead>
// //           <tr>
// //           <th style="background-color: #BADDDA">Sr.No</th>
// //           <th style="background-color: #BADDDA">SER No</th>
// //           <th style="background-color: #BADDDA">SB No</th>
// //           <th style="background-color: #BADDDA">SER Date</th>
      
// //           <th style="background-color: #BADDDA">Exporter Names</th>
// //           <th style="background-color: #BADDDA">CHA No</th>
// //           <th style="background-color: #BADDDA">DESC</th>
// //           <th style="background-color: #BADDDA">NOP</th>
// //           <th style="background-color: #BADDDA">Net Weight</th>
// //           <th style="background-color: #BADDDA">FOB value in INR</th>
// //           <th style="background-color: #BADDDA">AIRWAY BILL NO</th>
// //           <th style="background-color: #BADDDA">PORT OF DESTINATION</th>
// //           <th style="background-color: #BADDDA">Console Is</th>
// //           </tr>
// //         </thead>
// //         <tbody>
// //         ${pageRecords
// //           .map(
// //             (item, index) => `
// //               <tr>
// //               <td>${startIndex + index + 1}</td>
// //               <td>${item.serNo || ''}</td>
// //               <td>${item.sbNo || ''}</td>
// //               <td>${formatedDate(item.serDate) || ''}</td>
         
// //               <td>${getpartyId[item.nameOfExporter] || ''}</td>
// //               <td>${item.chaNo || ''}</td>
// //               <td>${item.descriptionOfGoods || '' }</td>
// //               <td>${item.noOfPackages || ''}</td>
// //               <td>${item.grossWeight || ''}</td>
// //               <td>${item.fobValueInINR || ''}</td>
// //               <td>${item.airwayBillNo || ''}</td>
// //               <td>${item.portOfDestination || ''}</td>
// //               <td>${getConsoleId[item.cartingAgent] || ''}</td>
// //               </tr>
// //             `
// //           )
// //           .join("")}
// //         </tbody>
// //         </table>
// //         </div>
// //         </body>
// //         </html>
// //       `);

// //       // Check if it's not the last page, then add a page break
// //       if (!isLastPage) {
// //         printWindow.document.write('<div class="page-break"></div>');
// //       } else {
// //         printWindow.document.write(`
// //           <table>
// //           <tbody>
// //           <tr>
// //           <td style="font-weight: bold; background-color: #BADDDA; width: 8px;">Total</td>
// //           <td style="background-color: #BADDDA; width: 5px;"></td>
         
// //           <td style="background-color: #BADDDA; width: 4px;">${totalSbNoCount}</td>
// //           <td style="background-color: #BADDDA; width: 5px;"></td>
      
// //           <td style="background-color: #BADDDA; width: 15px;"></td>
// //           <td style="background-color: #BADDDA; width: 3px;"></td>
// //           <td style="background-color: #BADDDA; width: 15px;"></td>
// //           <td style="background-color: #BADDDA; width: 2px;">${totalNoOfPackages}</td>
// //           <td style="background-color: #BADDDA; width: 3px;"></td>
// //           <td style="background-color: #BADDDA; width: 5px;">${totalFobValueInINR}</td>
// //           <td style="background-color: #BADDDA; width: 20px;"></td>
// //           <td style="background-color: #BADDDA; width: 10px;"></td>
// //           <td style="background-color: #BADDDA; width: 10px;"></td>
// //           </tr>
// //           </tbody>
// //           </table>
// //         `);
// //       }

// //       // Update the starting index for the next page
// //       startIndex += recordsPerPage;
// //       currentPageNumber++;
// //     }

// //     printWindow.document.close();
// //     printWindow.print();
// //     printWindow.onafterprint = () => printWindow.close();
// //   };

// //   const handleExportPrint = () => {
// //     const isoDate = new Date().toISOString();
// //     const date = new Date(isoDate);
// //     const day = date.getDate().toString().padStart(2, "0");
// //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
// //     const year = date.getFullYear().toString();
  
// //     let printWindow = window.open("", "_blank");
// //     printWindow.document.open();
  
// //     const recordsPerPage = 25;
// //     const totalRecords = exportData.length + exportSubData.length;
  
// //     let startIndex = 0; // Initialize the starting index for each page
// //     let currentPageNumber = 1; // Initialize the current page number
  
// //     // Function to generate the table HTML for a set of records
// //     const generateTableHTML = (records) => {
// //       return `
// //         <table>
// //           <thead>
// //             <tr>
// //               <th style="background-color: #BADDDA">Sr.No</th>
// //               <th style="background-color: #BADDDA">SER No</th>
// //               <th style="background-color: #BADDDA">SB No</th>
// //               <th style="background-color: #BADDDA">SER Date</th>
// //               <th style="background-color: #BADDDA">Exporter Names</th>
// //               <th style="background-color: #BADDDA">CHA No</th>
// //               <th style="background-color: #BADDDA">DESC</th>
// //               <th style="background-color: #BADDDA">NOP</th>
// //               <th style="background-color: #BADDDA">Net Weight</th>
// //               <th style="background-color: #BADDDA">FOB value in INR</th>
// //               <th style="background-color: #BADDDA">AIRWAY BILL NO</th>
// //               <th style="background-color: #BADDDA">PORT OF DESTINATION</th>
// //               <th style="background-color: #BADDDA">Console Is</th>
// //             </tr>
// //           </thead>
// //           <tbody>
// //             ${records
// //               .map(
// //                 (item, index) => `
// //                 <tr>
// //                 <td>${startIndex + index + 1}</td>
// //                 <td>${item.serNo || ''}</td>
// //                 <td>${item.sbNo || ''}</td>
// //                 <td>${formatedDate(item.serDate) || ''}</td>
// //                 <td>${getpartyId[item.nameOfExporter || item.exporter] || ''}</td>
// //                 <td>${item.chaNo || ''}</td>
// //                 <td>${item.descriptionOfGoods || item.remarks || ''}</td>
// //                 <td>${item.noOfPackages || item.nop || ''}</td>
// //                 <td>${item.grossWeight || item.gwWeight || ''}</td>
// //                 <td>${item.fobValueInINR || ''}</td>
// //                 <td>${item.airwayBillNo || ''}</td>
// //                 <td>${item.portOfDestination || ''}</td>
// //                 <td>${getConsoleId[item.cartingAgent] || ''}</td>
// //               </tr>
// //                 `
// //               )
// //               .join("")}
// //           </tbody>
// //         </table>
// //       `;
// //     };
  
// //     for (
// //       let pageIndex = 0;
// //       pageIndex < Math.ceil(totalRecords / recordsPerPage);
// //       pageIndex++
// //     ) {
// //       // Merge exportData and exportSubData arrays for the current page
// //       const pageRecords = [...exportData, ...exportSubData].slice(
// //         startIndex,
// //         startIndex + recordsPerPage
// //       );
  
// //       // Check if it's the last page
// //       const isLastPage =
// //         pageIndex === Math.ceil(totalRecords / recordsPerPage) - 1;
  
// //       printWindow.document.write(`
// //         <!DOCTYPE html>
// //         <html>
// //         <head>
// //         <title> Export Report Register</title>
// //         <style>
// //           @page {
// //             margin: 1cm;
// //             /* Adjust this value to position the header correctly */
// //           }
  
// //           .printable-area {
// //             font-family: Arial, sans-serif;
// //             page-break-before: always;
// //           }
  
// //           table {
// //             width: 100%;
// //             border-collapse: collapse;
// //           }
  
// //           td {
// //             border: 1px solid #dddddd;
// //             text-align: center;
// //             padding: 1px;
// //             font-size: 10px;
// //         }
    
// //         th {
// //           border: 1px solid #dddddd;
// //             background-color: #f2f2f2;
// //             text-align: center;
// //             font-size: 12px;
// //         }
  
// //           .container {
// //             display: flex;
// //             justify-content: space-between;
// //           }
  
// //           .left-column {
// //             width: 70%; /* Adjust the width as needed */
// //           }
  
// //           .page-break {
// //             page-break-before: always; /* This will force a page break before the element */
// //           }
  
// //           .right-column {
// //             width: 30%; /* Adjust the width as needed */
// //             text-align: right; /* Align text to the right */
// //           }
  
// //           .header img {
// //             max-width: auto; /* Ensure the image doesn't exceed the page width */
// //             max-height: auto; /* Adjust the maximum image height as needed */
// //           }
  
// //           .report-header {
// //             display: flex;
// //             justify-content: space-between;
// //             margin-bottom: 5px;
// //           }
  
// //           #page-header {
// //             position: static;
// //             top: 0;
// //             left: 0;
// //             right: 0;
// //             text-align: center;
// //           }
// //         </style>
// //         </head>
// //         <body>
// //         <div id="page-header">
// //           <img src="${dgdcImage}" alt="Header Image" style="max-width: 306px; display: block; margin: 0 auto;">
// //           <div style="text-align: center;">
// //             <div style="font-size:14px;font-weight:bold;">Export Register Report - Page ${currentPageNumber}</div><br/>
// //           </div>
// //         </div>
// //         <div class="report-header">
// //           <div></div>
// //           <div style="font-size:12px;">FOR THE PERIOD: ${StarDate} To ${EndDate}</div>
// //           <div style="font-size:12px;"> REPORT DATE: ${format(new Date(), "dd/MM/yyyy HH:mm")} </div>
// //         </div>
// //         ${generateTableHTML(pageRecords)}
// //         </body>
// //         </html>
// //       `);
  
// //       // Check if it's not the last page, then add a page break
// //       if (!isLastPage) {
// //         printWindow.document.write('<div class="page-break"></div>');
// //       } else {
// //         printWindow.document.write(`
// //           <table>
// //           <tbody>
// //           <tr>
// //           <td style="font-weight: bold;background-color: #BADDDA; width: 8px;">Total</td>
// //           <td style="background-color: #BADDDA; width: 8px;"></td>
// //           <td style="background-color: #BADDDA; width: 8px;">${totalExSbNoCount}</td>
// //           <td style="background-color: #BADDDA; width: 8px;"></td>
// //           <td style="background-color: #BADDDA; width: 8px;"></td>
// //           <td style="background-color: #BADDDA; width: 8px;"></td>
// //           <td style="background-color: #BADDDA; width: 8px;"></td>
// //           <td style="background-color: #BADDDA; width: 8px;">${totalEXSubNoOfPackages + totalExNoOfPackages}</td>
// //           <td style="background-color: #BADDDA; width: 8px;"></td>
// //           <td style="background-color: #BADDDA; width: 8px;">${totalExFobValueInINR}</td>
// //           <td style="background-color: #BADDDA; width: 8px;"></td>
// //           <td style="background-color: #BADDDA; width: 8px;"></td>
// //           <td style="background-color: #BADDDA; width: 8px;"></td>
// //           </tr>
// //           </tbody>
// //           </table>
// //         `);
// //       }
  
// //       // Update the starting index for the next page
// //       startIndex += recordsPerPage;
// //       currentPageNumber++;
// //     }
  
// //     printWindow.document.close();
// //     printWindow.print();
// //     printWindow.onafterprint = () => printWindow.close();
// //   };
// //   const handleExportPDFDownload = async () => {
// //     const pdfBlob = await pdf(
// //       <MyExportPDFDocument
// //         {...{
// //           StarDate,
// //           EndDate,
// //           selectedParty,
// //           exportSubData,
// //           exportData,
// //           totalEXSubNoOfPackages,
// //           totalExFobValueInINR,
// //           totalExSbNoCount,
// //           totalExNoOfPackages
// //         }}        
// //       />
// //     ).toBlob();
// //     saveAs(pdfBlob, "Export_Register.pdf");
// //   };

// //   const handleExportPDFSHOW = async () => {
// //     const pdfBlob = await pdf(
// //       <MyExportPDFDocument
// //         {...{
// //           StarDate,
// //           EndDate,
// //           selectedParty,
// //           exportSubData,
// //           exportData,
// //           totalEXSubNoOfPackages,
// //           totalExFobValueInINR,
// //           totalExSbNoCount,
// //           totalExNoOfPackages
// //         }}
// //       />
// //     ).toBlob();
// //     const fileURL = URL.createObjectURL(pdfBlob);
// //     const newTab = window.open();
// //     newTab.document.write(
// //         `<iframe width='100%' height='100%' src='${fileURL}'></iframe>`
// //     );
// //   };





// //   const PAGE_BREAK_ROWS_PDF = 12;

// //   const MyExportPDFDocument = ({
// //     StarDate,
// //     EndDate,
// //     exportData,
// //     exportSubData, // Include exportSubData as a prop
// //     totalExSbNoCount,
// //     totalExNoOfPackages,
// //     totalExFobValueInINR,
// //   }) =>
// //   {
// //     if (!exportData || !Array.isArray(exportData) || !exportSubData || !Array.isArray(exportSubData)) {
// //       // Handle the case when data is not available
// //       return null;
// //     }
  
// //   const mergedData = [...exportData, ...exportSubData];
// //   return(
// //     <Document>
// //       {Array.from({
// //         length: Math.ceil(mergedData.length / PAGE_BREAK_ROWS_PDF),
// //       }).map((_, pageIndex) => (
// //         <Page
// //           key={pageIndex}
// //           size="A4"
// //           orientation="landscape"
// //           style={styles.page}
// //         >
// //           <View style={styles.section}>
// //             {/* Your header content */}
// //             <View style={styles.header}>
// //               <CustomHeader />
// //             </View>
// //             <View style={[styles.centeredText, { fontWeight: "bold" }]}>
// //               <Text style={[styles.centeredText, { fontWeight: "bold" }]}>
// //                 EXPORT REGISTER
// //               </Text>
// //             </View>
// //             <View style={styles.reportHeader}>
// //               <Text></Text>
// //               <Text>
// //                 FOR THE PERIOD :{StarDate} TO {EndDate}{" "}
// //               </Text>
// //               <Text style={{ marginLeft: 4 }}>
// //                 REPORT DATE: {format(new Date(), "dd/MM/yyyy HH:mm")}
// //               </Text>
// //             </View>
  
// //             <View style={styles.table}>
// //               <View style={styles.tableRow}>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Sr.No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   SER No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   SB No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   SER Date
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Exporter Names
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   CHA No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>DESC</Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader ,{width:45}]}>
// //                 NOP
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Net Weight
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   FOB value in INR
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   AIRWAY BILL NO
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   PORT OF DESTINATION
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Console Is
// //                 </Text>
// //               </View>
// //               {[...exportData, ...exportSubData] // Merge exportData and exportSubData
// //                 .slice(
// //                   pageIndex * PAGE_BREAK_ROWS_PDF,
// //                   (pageIndex + 1) * PAGE_BREAK_ROWS_PDF
// //                 )
// //                 .map((item, index) => (
// //                   <View style={styles.tableRow} key={index}>
// //                     <Text style={styles.tableCell}>
// //                       {index + 1 + pageIndex * PAGE_BREAK_ROWS_PDF}
// //                     </Text>
// //                     <Text style={styles.tableCell}>{item.serNo || ""}</Text>
// //                     <Text style={styles.tableCell}>{item.sbNo}</Text>
// //                     <Text style={styles.tableCell}>
// //                       {formatedDate(item.serDate) || ""}
// //                     </Text>
// //                     <Text style={styles.tableCell}>
// //                       {getpartyId[item.nameOfExporter || item.exporter] || ""}
// //                     </Text>
// //                     <Text style={styles.tableCell}>{item.chaNo}</Text>
// //                     <Text style={styles.tableCell}>
// //                       {item.descriptionOfGoods || item.remarks || ""}
// //                     </Text>
// //                     <Text style={[styles.tableCell  ,{width:45}]}>
// //                       {item.noOfPackages || item.nop || ""}
// //                     </Text>
// //                     <Text style={styles.tableCell}>
// //                       {item.grossWeight || item.gwWeight || ""}
// //                     </Text>
// //                     <Text style={styles.tableCell}>{item.fobValueInINR}</Text>
// //                     <Text style={styles.tableCell}>{item.airwayBillNo}</Text>
// //                     <Text style={styles.tableCell}>
// //                       {item.portOfDestination || ""}
// //                     </Text>
// //                     <Text style={styles.tableCell}>
// //                       {getConsoleId[item.cartingAgent] || ""}
// //                     </Text>
// //                   </View>
// //                 ))}
// //               {pageIndex ===
// //                 Math.ceil(mergedData.length / PAGE_BREAK_ROWS_PDF) - 1 && (
// //                 <View style={styles.tableRow}>
// //                   <Text style={styles.tableCell}>Total</Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}>{totalExSbNoCount}</Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={[styles.tableCell,{width:45}]}>
// //                     {totalExNoOfPackages}
// //                   </Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}>{totalExFobValueInINR}</Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                 </View>
// //               )}
// //             </View>
// //           </View>
// //         </Page>
// //       ))}

// //     </Document>
// //   );
// // };
  
// //   const handlePDFDownload = async () => {
// //     const pdfBlob = await pdf(
// //       <MyPDFDocument
// //         {...{
// //           StarDate,
// //           EndDate,
// //           selectedParty,
// //           subContractData,
// //           totalFobValueInINR,
// //           totalNoOfPackages,
// //           totalSbNoCount,
// //         }}
// //       />
// //     ).toBlob();
// //     saveAs(pdfBlob, "Export_Register.pdf");
// //   };

// //   const handlePDFSHOW = async () => {
// //     const pdfBlob = await pdf(
// //       <MyPDFDocument
// //         {...{
// //           StarDate,
// //           EndDate,
// //           selectedParty,
// //           subContractData,
// //           totalFobValueInINR,
// //           totalNoOfPackages,
// //           totalSbNoCount,
// //         }}
// //       />
// //     ).toBlob();
// //     const fileURL = URL.createObjectURL(pdfBlob);
// //     const newTab = window.open();
// //     newTab.document.write(
// //         `<iframe width='100%' height='100%' src='${fileURL}'></iframe>`
// //     );
// //   };







// //   const handleReset =()=>{
// //     setStartDate(new Date());
// //     setEndDate(new Date());
// //     setSelectedParty("");
// //     setExportSubData([]);
// //     setExportData([]);
// //     setShowExportDataTable(false);
// //       }

// //   function fetchCompanyName(companyId) {
// //     // Make an Axios GET request to retrieve the company name based on companyId
// //     return axios
// //       .get(`https://${ipaddress}export/findCompanyname/${companyId}`)
// //       .then(function (response) {
// //         return response.data; // Return the retrieved company name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching company name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }
// //   function fetchPartyName(companyId, branchId, partyId) {
// //     // Make an Axios GET request to retrieve the company name based on companyId
// //     return axios
// //       .get(
// //         `https://${ipaddress}export/findPartyName/${companyId}/${branchId}/${partyId}`
// //       )
// //       .then(function (response) {
// //         return response.data; // Return the retrieved company name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching company name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }

// //   function fetchExporterName(companyId, branchId, partyId) {
// //     // Make an Axios GET request to retrieve the company name based on companyId
// //     return axios
// //       .get(
// //         `https://${ipaddress}export/findPartyName/${companyId}/${branchId}/${partyId}`
// //       )
// //       .then(function (response) {
// //         return response.data; // Return the retrieved company name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching company name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }

// //   function fetchConsoleName(companyId, branchId, externaluserId) {
// //     // Make an Axios GET request to retrieve the company name based on companyId
// //     return axios
// //       .get(
// //         `https://${ipaddress}export/findConsoleName/${companyId}/${branchId}/${externaluserId}`
// //       )
// //       .then(function (response) {
// //         return response.data; // Return the retrieved company name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching company name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }
// //   function fetchBranchName(companyId, branchId) {
// //     // Make an Axios GET request to retrieve the branch name based on branchId
// //     return axios
// //       .get(
// //         `https://${ipaddress}export/findBranchName/${companyId}/${branchId}`
// //       )
// //       .then(function (response) {
// //         return response.data; // Return the retrieved branch name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching branch name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }

// //   const handleXLSDownload = async () => {
// //     const modifiedReordList = await Promise.all(
// //       subContractData.map(async (item, index) => {
// //         // const companyname = await fetchCompanyName(item.companyId);
// //         // const branchname = await fetchBranchName(item.companyId, item.branchId);
// //         const partyName = await fetchPartyName(
// //           item.companyId,
// //           item.branchId,
// //           item.nameOfExporter
// //         );
// //         const userName = await fetchConsoleName(
// //           item.companyId,
// //           item.branchId,
// //           item.consoleAgent
// //         );

// //         return {
// //           "Sr.No": index + 1,
// //           // "Company Name": companyname,
// //           // "Branch Name": branchname,
// //           "SER No": item.serNo,
// //           "SB No": item.sbNo,
// //           "SER Date": formatedDate(item.serDate),
// //           "Parcle Type": " ", // Modify this to the actual field name
// //           "Exporter Names": getpartyId[item.nameOfExporter],
// //           "CHA No": item.chaNo,
// //           DESC: item.descriptionOfGoods,
// //           "No of Pkgs": item.noOfPackages,
// //           "Net Weight": item.grossWeight,
// //           "FOB value in INR": item.fobValueInINR,
// //           "AIRWAY BILL NO": item.airwayBillNo,
// //           "PORT OF DESTINATION": item.portOfDestination,
// //           "Console Is": getConsoleId[item.consoleAgent],
// //           // Add more fields as needed
// //         };
// //       })
// //     );

// //     const workbook = XLSX.utils.book_new();
// //     const worksheet = XLSX.utils.json_to_sheet(modifiedReordList);

// //     // Add headers for all fields
// //     const headers = Object.keys(modifiedReordList[0]);
// //     headers.forEach((header, index) => {
// //       worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
// //         t: "s",
// //         v: header,
// //         s: { font: { bold: true } }, //
// //       };
// //     });

// //     // Set column widths based on data
// //     const colWidths = headers.map((header) => ({
// //       wch: header.length + 2, // You can adjust the width as needed
// //     }));

// //     worksheet["!cols"] = colWidths;

// //     XLSX.utils.book_append_sheet(workbook, worksheet, "export_transaction");
// //     const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
// //     const blob = new Blob([s2ab(xlsFile)], {
// //       type: "application/vnd.ms-excel",
// //     });
// //     saveAs(blob, "Export_Register.xls");
// //   };

// //   const s2ab = (s) => {
// //     const buf = new ArrayBuffer(s.length);
// //     const view = new Uint8Array(buf);
// //     for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
// //     return buf;
// //   };


// //   const handleExportXLSDownload = async () => {
// //     const modifiedReordList = await Promise.all(
// //       mergedAllData.map(async (item, index) => {
// //         // const companyname = await fetchCompanyName(item.companyId);
// //         // const branchname = await fetchBranchName(item.companyId, item.branchId);
// //         const partyName = await fetchPartyName(
// //           item.companyId,
// //           item.branchId,
// //           item.nameOfExporter
// //         );

// //         const exporterName = await fetchExporterName(
// //           item.companyId,
// //           item.branchId,
// //           item.exporter
// //         );
// //         const userName = await fetchConsoleName(
// //           item.companyId,
// //           item.branchId,
// //           item.consoleAgent
// //         );

// //         return {
// //           "Sr.No": index + 1,
// //           // "Company Name": companyname,
// //           // "Branch Name": branchname,
// //           "SER No": item.serNo ||  item.serNo,
// //           "SB No": item.sbNo,
// //           "SER Date": formatedDate(item.serDate) ||formatedDate(item.serDate),
// //           "Parcle Type": " ", // Modify this to the actual field name
// //           "Exporter Names": getpartyId[item.nameOfExporter]  || getpartyId[item.exporter],
// //           "CHA No": item.chaNo,
// //           "DESC": item.descriptionOfGoods || item.remarks,
// //           "No of Pkgs": item.noOfPackages || item.nop,
// //           "Net Weight": item.grossWeight || item.gwWeight,
// //           "FOB value in INR": item.fobValueInINR,
// //           "AIRWAY BILL NO": item.airwayBillNo,
// //           "PORT OF DESTINATION": item.portOfDestination,
// //           "Console Is": getConsoleId[item.consoleAgent],
// //         };
// //       })
// //     );

// //     const workbook = XLSX.utils.book_new();
// //     const worksheet = XLSX.utils.json_to_sheet(modifiedReordList);

// //     // Add headers for all fields
// //     const headers = Object.keys(modifiedReordList[0]);
// //     headers.forEach((header, index) => {
// //       worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
// //         t: "s",
// //         v: header,
// //         s: { font: { bold: true } }, //
// //       };
// //     });

// //     // Set column widths based on data
// //     const colWidths = headers.map((header) => ({
// //       wch: header.length + 2, // You can adjust the width as needed
// //     }));

// //     worksheet["!cols"] = colWidths;

// //     XLSX.utils.book_append_sheet(workbook, worksheet, "export_report");
// //     const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
// //     const blob = new Blob([s2ab(xlsFile)], {
// //       type: "application/vnd.ms-excel",
// //     });
// //     saveAs(blob, "Export_Register.xls");
// //   };

  
// //   const MyPDFDocument = ({
// //     totalNoOfPackages,
// //     subContractData,
// //     totalSbNoCount,
// //     totalFobValueInINR,
// //   }) => (
// //     <Document>
// //       {Array.from({
// //         length: Math.ceil(subContractData.length / PAGE_BREAK_ROWS_PDF),
// //       }).map((_, pageIndex) => (
// //         <Page
// //           key={pageIndex}
// //           size="A4"
// //           orientation="landscape"
// //           style={{ ...styles.page }}
// //         >
// //           <View style={styles.section}>
// //             {/* Your header content */}
// //             <View style={styles.header}>
// //               <Image src={dgdcImage} style={styles.image} />
// //             </View>
// //             <View style={[styles.centeredText, { fontWeight: "bold" }]}>
// //               <Text style={[styles.centeredText, { fontWeight: "bold" }]}>
// //                 EXPORT REGISTER
// //               </Text>
// //             </View>
// //             <View style={styles.reportHeader}>
// //               <Text></Text>
// //               <Text>
// //                 FOR THE PERIOD :{StarDate} TO {EndDate}{" "}
// //               </Text>
// //               <Text style={{ marginLeft: 4 }}>
// //                 REPORT DATE: {format(new Date(), "dd/MM/yyyy HH:mm")}
// //               </Text>
// //             </View>

// //             {/* Your table */}
// //             <View style={styles.table}>
// //               <View style={styles.tableRow}>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Sr.No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   SER No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   SB No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   SER Date
// //                 </Text>
// //                 {/* <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Parcel Type
// //                 </Text> */}
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Exporter Names
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   CHA No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   DESC
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                  NOP
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Net Weight
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   FOB value in INR
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   AIRWAY BILL NO
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   PORT OF DESTINATION
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Console Is
// //                 </Text>
// //               </View>
// //               {subContractData
// //                 .slice(
// //                   pageIndex * PAGE_BREAK_ROWS_PDF,
// //                   (pageIndex + 1) * PAGE_BREAK_ROWS_PDF
// //                 )
// //                 .map((item, index) => (
// //                   <View style={styles.tableRow} key={index}>
// //                     <Text style={styles.tableCell}>
// //                       {index + 1 + pageIndex * PAGE_BREAK_ROWS_PDF}
// //                     </Text>
// //                     <Text style={styles.tableCell}>{item.serNo}</Text>
// //                     <Text style={styles.tableCell}>{item.sbNo}</Text>
// //                     <Text style={styles.tableCell}>
// //                       {formatedDate(item.serDate)}
// //                     </Text>
// //                     {/* <Text style={styles.tableCell}>Parcel Type Value</Text> */}
// //                     <Text style={styles.tableCell}>
// //                       {getpartyId[item.nameOfExporter]}
// //                     </Text>
// //                     <Text style={styles.tableCell}>{item.chaNo}</Text>
// //                     <Text style={styles.tableCell}>
// //                       {item.descriptionOfGoods}
// //                     </Text>
// //                     <Text style={styles.tableCell}>{item.noOfPackages}</Text>
// //                     <Text style={styles.tableCell}>{item.grossWeight}</Text>
// //                     <Text style={styles.tableCell}>{item.fobValueInINR}</Text>
// //                     <Text style={styles.tableCell}>{item.airwayBillNo}</Text>
// //                     <Text style={styles.tableCell}>
// //                       {item.portOfDestination}
// //                     </Text>
// //                     <Text style={styles.tableCell}>
// //                       {getConsoleId[item.cartingAgent]}
// //                     </Text>
// //                   </View>
// //                 ))}
// //               {/* Display the "Total" row only on the last page */}
// //               {pageIndex ===
// //                 Math.ceil(subContractData.length / PAGE_BREAK_ROWS_PDF) - 1 && (
// //                 <View style={styles.tableRow}>
// //                   <Text style={styles.tableCell}>Total</Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}>{totalSbNoCount}</Text>
// //                   {/* <Text style={styles.tableCell}></Text> */}
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}>{totalNoOfPackages}</Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}>{totalFobValueInINR}</Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                 </View>
// //               )}
// //             </View>
// //           </View>
// //         </Page>
// //       ))}
// //     </Document>
// //   );

// //   let indexCounter = 1;


// //   //Export Register

// //  const formatDateTime2 = (value) => {
// //   if (!value) {
// //     return "";
// //   }
// //   const date = new Date(value);
// //   const day = String(date.getDate()).padStart(2, "0");
// //   const month = String(date.getMonth() + 1).padStart(2, "0");
// //   const year = date.getFullYear();
// //   const hours = String(date.getHours()).padStart(2, "0");
// //   const minutes = String(date.getMinutes()).padStart(2, "0");
// //   const seconds = String(date.getSeconds()).padStart(2, "0");
// //   return `${day}/${month}/${year} `;
// // };
// // const getExcel = (expdata,expsubdata) => {
// //   const filename = `Export_Register.xlsx`;
// //   const dataToSend = {
// //     expdata,
// //     expsubdata
// // };
 
// //   axios.post(`https://${ipaddress}export/registerexcel`,dataToSend,{ responseType: 'blob' })
// //     .then(async (response) => {
// //       const blob = new Blob([response.data], { type: response.headers['content-type'] });

// //       // Create a temporary URL for the blob
// //       const url = window.URL.createObjectURL(blob);

// //       // Create a link element to trigger the download
// //       const a = document.createElement("a");
// //       a.href = url;
// //       a.download = filename;
// //       document.body.appendChild(a);
// //       a.click();

// //       // Clean up
// //       window.URL.revokeObjectURL(url);
// //       document.body.removeChild(a);
// //     })
// //     .catch((error) => {
// //       toast.error("Something went wrong", {
// //         autoClose: 700
// //       });
// //     });
// // }


// // const getExcel1 = (expdata) => {
// //   const filename = `Export_Register.xlsx`;


 
// //   axios.post(`https://${ipaddress}export/registerexcel1`,expdata,{ responseType: 'blob' })
// //     .then(async (response) => {
// //       const blob = new Blob([response.data], { type: response.headers['content-type'] });

// //       // Create a temporary URL for the blob
// //       const url = window.URL.createObjectURL(blob);

// //       // Create a link element to trigger the download
// //       const a = document.createElement("a");
// //       a.href = url;
// //       a.download = filename;
// //       document.body.appendChild(a);
// //       a.click();

// //       // Clean up
// //       window.URL.revokeObjectURL(url);
// //       document.body.removeChild(a);
// //     })
// //     .catch((error) => {
// //       toast.error("Something went wrong", {
// //         autoClose: 700
// //       });
// //     });
// // }
// //   return (
// //     <div>
// //       <h4 style={{ marginLeft: 18, marginTop: 25 }}>Export Register Report</h4>
// //       {/* style={{marginTop:25,marginRight:1,marginLeft:18,padding: 8}} */}
// //       <Card
// //         style={{ marginTop: 25, marginRight: 18, marginLeft: 18, padding: 8 }}
// //       >
// //         <Container>
// //           <Form>
// //             <Row>
// //               <Col md={3}>
// //                 <Form.Group>
// //                   <Form.Label className="inputhead">From Date</Form.Label>
// //                   <div className="input-group">
// //                     <DatePicker
// //                       selected={startDate}
// //                       onChange={(date) => setStartDate(date)}
// //                       dateFormat="dd/MM/yyyy" // You can customize the date format
// //                       name="startDate"
// //                       required
// //                       className="form-control border-right-0 inputField"
// //                       customInput={<input style={{ width: "18vw" }} />}
// //                     />
// //                   </div>
// //                 </Form.Group>
// //               </Col>
// //               <Col md={3}>
// //                 <Form.Group>
// //                   <Form.Label className="inputhead">To Date</Form.Label>
// //                   <div className="input-group">
// //                     <DatePicker
// //                       selected={endDate}
// //                       onChange={(date) => setEndDate(date)}
// //                       dateFormat="dd/MM/yyyy" // You can customize the date format
// //                       name="endDate"
// //                       required
// //                       className="form-control border-right-0 inputField"
// //                       customInput={<input style={{ width: "18vw" }} />}
// //                     />
// //                   </div>{" "}
// //                 </Form.Group>
// //               </Col>
// //               <Col md={3}>
// //                 <label htmlFor="company" className="inputhead">
// //                   Console
// //                 </label>
// //                 <select
// //                   name="company"
// //                   id="dw1"
// //                   className=""
// //                   onChange={handlePartyChange}
// //                   value={selectedParty}
// //                 >
// //                   <option value="">Select</option>
// //                   {consoles.map((party) => (
// //                     <option
// //                       key={party.externaluserId}
// //                       value={party.externaluserId}
// //                     >
// //                       {party.userName}
// //                     </option>
// //                   ))}
// //                 </select>
// //               </Col>
// //               <Col md={3}>
// //                 <div style={{ marginTop: 30 }}>
// //                   <Button
// //                     color="success"
// //                     outline
// //                     style={{ marginRight: 18 }}
// //                     onClick={handleSearch}
// //                   >
// //                     SEARCH
// //                   </Button>

// //                   <Button onClick={handleReset} color="warning" outline>
// //                     RESET
// //                   </Button>
// //                 </div>
// //               </Col>
// //             </Row>
// //           </Form>
// //         </Container>
// //       </Card>
// //       {showSubContractDataTable ? (
// //         <div>
// //           <Card
// //             style={{
// //               marginTop: 25,
// //               marginRight: 18,
// //               marginLeft: 18,
// //               padding: 8,
// //             }}
// //           >
// //             <Row>
// //               <Col md={8}></Col>
// //               <Col md={4}>
// //                 <button
// //                   style={{ marginLeft: 9 }}
// //                   className="btn btn-outline-danger btn-margin"
// //                   type="button"
// //                   onClick={handlePDFSHOW}
// //                 >
// //                   <FontAwesomeIcon
// //                     icon={faPrint}
// //                     style={{ marginRight: "5px" }}
// //                   />
// //                   Print
// //                 </button>
// //                 <button
// //                   className="btn btn-outline-danger btn-margin"
// //                   type="button"
// //                   style={{ marginLeft: "10px", marginRight: 9 }}
// //                   onClick={()=>getExcel1(subContractData)}
// //                 >
// //                   <FontAwesomeIcon
// //                     icon={faFileExcel}
// //                     style={{ marginRight: "5px" }}
// //                   />
// //                   XLS
// //                 </button>
// //                 <button
// //                   className="btn btn-outline-danger btn-margin"
// //                   type="button"
// //                   onClick={handlePDFDownload}
// //                   style={{ marginLeft: "10px" }}
// //                 >
// //                   <FontAwesomeIcon
// //                     icon={faFilePdf}
// //                     style={{ marginRight: "5px" }}
// //                   />
// //                   PDF
// //                 </button>
// //               </Col>
// //             </Row>
// //             <div className="table-responsive">
// //               <Table
// //                 className="table table-striped table-hover"
// //                 style={{ marginTop: 9 }}
// //               >
// //                 {/* Render subContractData table here */}
// //                 <thead>
// //                   <tr>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>Sr.No</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>SER No</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>SB No</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>SER Date</th>

// //                     <th style={{ backgroundColor: "#BADDDA" }}>
// //                       Exporter Names
// //                     </th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>CHA No</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>DESC</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>No of Pkgs</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>Net Weight</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>
// //                       FOB value in INR
// //                     </th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>
// //                       AIRWAY BILL NO
// //                     </th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>
// //                       PORT OF DESTINATION
// //                     </th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>Console Is</th>
// //                   </tr>
// //                 </thead>
// //                 <thead>
// //                   <tr>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>Total</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>
// //                       {totalSbNoCount}
// //                     </th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>

// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>
// //                       {totalNoOfPackages}
// //                     </th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>
// //                       {totalFobValueInINR}
// //                     </th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                   </tr>
// //                 </thead>
// //                 <tbody>
// //                   {subContractData.map((item, index) => (
// //                     <tr key={index}>
// //                       <td>{index + 1}</td>
// //                       <td>{item.serNo}</td>
// //                       <td>{item.sbNo}</td>
// //                       <td>{formatedDate(item.serDate)}</td>

// //                       <td>{getpartyId[item.nameOfExporter]}</td>
// //                       <td>{item.chaNo}</td>
// //                       <td>{item.descriptionOfGoods}</td>
// //                       <td>{item.noOfPackages}</td>
// //                       <td>{item.grossWeight}</td>
// //                       <td>{item.fobValueInINR}</td>
// //                       <td>{item.airwayBillNo}</td>
// //                       <td>{item.portOfDestination}</td>
// //                       <td>{getConsoleId[item.consoleAgent]}</td>
// //                     </tr>
// //                   ))}
// //                 </tbody>
// //               </Table>
// //             </div>
// //             <div
// //               style={{
// //                 marginTop: 4,
// //                 display: "flex",
// //                 flexDirection: "row",
// //                 justifyContent: "space-between",
// //               }}
// //             >
// //               <p style={{ fontWeight: "bold" }}>
// //                 Total No. SB No: {totalSbNoCount}
// //               </p>
// //               <p style={{ fontWeight: "bold" }}>
// //                 Total No. of Packages: {totalNoOfPackages}
// //               </p>
// //               <p style={{ fontWeight: "bold" }}>
// //                 Total FOB Value in INR: {totalFobValueInINR}
// //               </p>
// //             </div>
// //           </Card>
// //         </div>
// //       ) : (
// //         <div>
// //           {showExportDataTable ? (
// //             <Card
// //               style={{
// //                 marginTop: 25,
// //                 marginRight: 18,
// //                 marginLeft: 18,
// //                 padding: 8,
// //               }}
// //             >
// //               <Row>
// //                 <Col md={8}></Col>
// //                 <Col md={4}>
// //                   <button
// //                     style={{ marginLeft: 9 }}
// //                     className="btn btn-outline-danger btn-margin"
// //                     type="button"
// //                     onClick={handleExportPDFSHOW}
// //                   >
// //                     <FontAwesomeIcon
// //                       icon={faPrint}
// //                       style={{ marginRight: "5px" }}
// //                     />
// //                     Print
// //                   </button>
// //                   <button
// //                     className="btn btn-outline-danger btn-margin"
// //                     type="button"
// //                     style={{ marginLeft: "10px", marginRight: 9 }}
// //                     onClick={()=>getExcel(exportData,exportSubData)}
// //                   >
// //                     <FontAwesomeIcon
// //                       icon={faFileExcel}
// //                       style={{ marginRight: "5px" }}
// //                     />
// //                     XLS
// //                   </button>
// //                   <button
// //                     className="btn btn-outline-danger btn-margin"
// //                     type="button"
// //                     onClick={handleExportPDFDownload}
// //                     style={{ marginLeft: "10px" }}
// //                   >
// //                     <FontAwesomeIcon
// //                       icon={faFilePdf}
// //                       style={{ marginRight: "5px" }}
// //                     />
// //                     PDF
// //                   </button>
// //                 </Col>
// //               </Row>
// //               <div className="table-responsive">
// //                 <Table
// //                   className="table table-striped table-hover"
// //                   style={{ marginTop: 9 }}
// //                 >
// //                   <thead>
// //                     <tr>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>Sr.No</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>SER No</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>SB No</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>SER Date</th>

// //                       <th style={{ backgroundColor: "#BADDDA" }}>
// //                         Exporter Names
// //                       </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>CHA No</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>DESC</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>No of Pkgs</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>Net Weight</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>
// //                         FOB value in INR
// //                       </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>
// //                         AIRWAY BILL NO
// //                       </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>
// //                         PORT OF DESTINATION
// //                       </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>Console Is</th>
// //                     </tr>
// //                   </thead>
// //                   <thead>
// //                     <tr>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>Total</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>
// //                         {totalExSbNoCount}
// //                       </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>

// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>
// //                         {totalExNoOfPackages + totalEXSubNoOfPackages}
// //                       </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>
// //                         {totalExFobValueInINR}
// //                       </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     </tr>
               
// //                   </thead>
// //                   <tbody>
                
// //                     {exportData.map((exportItem, index) => (
// //                       <tr key={index}>
// //                         <td>{indexCounter++}</td>
// //                         <td>{exportItem.serNo}</td>
// //                         <td>{exportItem.sbNo}</td>
// //                         <td>{formatedDate(exportItem.serDate)}</td>

// //                         <td>{getpartyId[exportItem.nameOfExporter]}</td>
// //                         <td>{exportItem.chaNo}</td>
// //                         <td>{exportItem.descriptionOfGoods}</td>
// //                         <td>{exportItem.noOfPackages}</td>
// //                         <td>{exportItem.grossWeight}</td>
// //                         <td>{exportItem.fobValueInINR}</td>
// //                         <td>{exportItem.airwayBillNo}</td>
// //                         <td>{exportItem.portOfDestination}</td>
// //                         <td>{getConsoleId[exportItem.consoleAgent]}</td>
// //                       </tr>
// //                     ))}

// //                     {exportSubData.map((exportSubItem, index) => (
// //                       <tr key={index}>
// //                         <td>{indexCounter++}</td>
// //                         <td>{exportSubItem.serNo}</td>
// //                         <td>{exportSubItem.sbNo}</td>
// //                         <td>{formatedDate(exportSubItem.serDate)}</td>

// //                         <td>{getpartyId[exportSubItem.exporter]}</td>
// //                         <td>{exportSubItem.chaNo}</td>
// //                         <td>{exportSubItem.remarks}</td>
// //                         <td>{exportSubItem.nop}</td>
// //                         <td>{exportSubItem.gwWeight}</td>
// //                         <td>{exportSubItem.fobValueInINR}</td>
// //                         <td>{exportSubItem.airwayBillNo}</td>
// //                         <td>{exportSubItem.portOfDestination}</td>
// //                         <td>{getConsoleId[exportSubItem.consoleAgent]}</td>
// //                       </tr>
// //                     ))}
// //                   </tbody>
// //                 </Table>

// //                 <div
// //               style={{
// //                 marginTop: 4,
// //                 display: "flex",
// //                 flexDirection: "row",
// //                 justifyContent: "space-between",
// //               }}
// //             >
// //               <p style={{ fontWeight: "bold" }}>
// //                 Total No. SB No: {totalExSbNoCount}
// //               </p>
// //               <p style={{ fontWeight: "bold" }}>
// //                 Total No. of Packages: {totalExNoOfPackages + totalEXSubNoOfPackages}
// //               </p>
// //               <p style={{ fontWeight: "bold" }}>
// //                 Total FOB Value in INR: {totalExFobValueInINR}
// //               </p>
// //             </div>
// //               </div>
// //             </Card>
// //           ) : null}
// //         </div>
// //       )}
      
// //     </div>
// //   );
// // }


// // import { redirect } from "react-router-dom";
// // import AuthContext from "../Components/AuthProvider";
// // import { useNavigate } from "react-router-dom";
// // import React, { useEffect, useState, useContext } from "react";
// // import "../Components/Style.css";
// // import Row from "react-bootstrap/Row";
// // import * as XLSX from "xlsx"; // Import XLSX library for creating XLS files
// // import Col from "react-bootstrap/Col";
// // import { format } from "date-fns";
// // import {
// //   Button,
// //   ButtonToggle,
// //   CardBody,
// //   Container,
// //   Table,
// //   ToastHeader,
// // } from "reactstrap";
// // import Form from "react-bootstrap/Form";
// // import Card from "react-bootstrap/Card";
// // import DatePicker from "react-datepicker";
// // import ipaddress from "../Components/IpAddress";
// // import { saveAs } from "file-saver";
// // import axios from "axios";
// // import dgdcImage from "../Images/report.jpeg";
// // import {
// //   faFileExcel,
// //   faFilePdf,
// //   faPrint,
// // } from "@fortawesome/free-solid-svg-icons";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import {
// //   Document,
// //   Page,
// //   Text,
// //   View,
// //   pdf,
// //   StyleSheet,
// //   PDFViewer,
// //   Image,
// // } from "@react-pdf/renderer";
// // import { toast } from "react-toastify";

// // export default function Export_Register() {
// //   const navigate = useNavigate();
// //   const [startDate, setStartDate] = useState(new Date());
// //   const [endDate, setEndDate] = useState(new Date());
// //   const [selectedParty, setSelectedParty] = useState("");
// //   const [showSubContractDataTable, setSubContractDataTable] = useState(false);
// //   const [showExportDataTable, setShowExportDataTable] = useState(false);
// //   const [SubImportContractData, setSubImportContractData] = useState([]);
// //   const [subContractData, setSubContractData] = useState([]);
// //   const [exportData, setExportData] = useState([]);
// //   const [exportSubData, setExportSubData] = useState([]);
// //   const [partyNameToIdMap, setPartyNameToIdMap] = useState({});
// //   const [requestId, setRequestId] = useState("");
// //   const [subRequestIdData, setsubRequestIdData] = useState([]);
// //   const [showAlert, setShowAlert] = useState(false);
// //   const {
// //     jwtToken,
// //     userId,
// //     username,
// //     branchId,
// //     companyid,
// //     role,
// //     companyname,
// //     branchname,
// //     isAuthenticated,
// //     login,
// //     logout,
// //   } = useContext(AuthContext);
// //   const styles = StyleSheet.create({
// //     // page: {
// //     //   paddingTop: 30,
// //     //   paddingBottom: 60,
// //     //   paddingHorizontal: 30,
// //     // },

// //     page: {
// //       flexDirection: "row", // Set landscape orientation
// //       // paddingTop: 9,
// //       paddingBottom: 9,
// //       paddingHorizontal: 9,
// //     },
// //     header: {
// //       flexDirection: "row",
// //       alignItems: "center",
// //       marginBottom: 4,
// //     },
// //     section: {
// //       flexGrow: 1, // Take the available space
// //       margin: 10, // Add some margin for spacing
// //       display: "flex",
// //       justifyContent: "center", // Center content horizontally
// //       alignItems: "center", // Center content vertically
// //     },
// //     image: {
// //       width: 306,
// //       height: 100,
// //     },
// //     headerText: {
// //       flex: 1,
// //       textAlign: "center",
// //       fontSize: 18,
// //       fontWeight: "bold",
// //     },
// //     centeredText: {
// //       fontSize: 14,
// //       textAlign: "center", // Center text horizontally
// //       paddingBottom: 2,
// //       paddingTop: 0,
// //     },
// //     reportHeader: {
// //       flexDirection: "row",
// //       justifyContent: "space-between",
// //       paddingBottom: 1,
// //       fontSize: 10,
// //     },
// //     table: {
// //       width: "100%",
// //       borderCollapse: "collapse",
// //       marginBottom: 10,
// //     },
// //     tableRow: {
// //       flexDirection: "row",
// //       borderBottomWidth: 0.4,
// //       borderBottomColor: "black",
// //       fontSize: 10,
// //       borderRightWidth: 0.4,
// //       borderLeftWidth: 0.4,
// //       borderLeftColor: "black",
// //       borderRightColor: "black",
// //       flexWrap: "wrap",
// //     },

// //     tableCell: {
// //       flex: 1,
// //       borderWidth: 0.4,
// //       borderStyle: "solid",
// //       borderColor: "#000",
// //       padding:1,
// //       fontWeight: "bold",
// //       textAlign: "center",
// //       fontSize: 7,
// //     },
// //     tableCellHeader: {
// //       fontWeight: "bold",
// //       textAlign: "center",
// //       fontSize: 9,
// //     },
// //   });
// //   const CustomHeader = () => {
// //     return (
// //       <View style={styles.header}>
// //         <Image src={dgdcImage} style={styles.image} />
// //       </View>
// //     );
// //   };

// //   const totalSbNoCount = subContractData.length;
// //   // Calculate the sum of noOfPackages and fobValueInINR
// //   const totalNoOfPackages = subContractData.reduce(
// //     (total, item) => total + item.noOfPackages,
// //     0
// //   );

// //   const totalFobValueInINR = subContractData.reduce(
// //     (total, exportItem) => total + exportItem.fobValueInINR,
// //     0
// //   );

// //   //Export
// //   const totalExSbNoCount = exportData.length;
// //   const totalExNoOfPackages = exportData.reduce(
// //     (total, exportItem) => total + exportItem.noOfPackages,
// //     0
// //   );

// //   const totalExFobValueInINR = exportData.reduce(
// //     (total, exportItem) => total + exportItem.fobValueInINR,
// //     0
// //   );
// // //Sub NOP total 
// // const totalEXSubNoOfPackages = exportSubData.reduce(
// //   (total, exportSubItem) => total + exportSubItem.nop,
// //   0
// // );

// // const mergedAllData = [...exportData, ...exportSubData];
// //   const formatedDate = (inputDate) => {
// //     const date = new Date(inputDate);
// //     const year = date.getFullYear();
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const day = String(date.getDate()).padStart(2, "0");
// //     return `${day}/${month}/${year}`;
// //   };
// //   // const formatDate = (inputDate) => {
// //   //   const date = new Date(inputDate);
// //   //   const year = date.getFullYear();
// //   //   const month = String(date.getMonth() + 1).padStart(2, "0");
// //   //   const day = String(date.getDate()).padStart(2, "0");

// //   //   return `${year}-${month}-${day}`;
// //   // };

// //   const formatDate = (inputDate, setTimeTo) => {
// //     const date = new Date(inputDate);
// //     const year = date.getFullYear();
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const day = String(date.getDate()).padStart(2, "0");
// //     const hours = setTimeTo === "start" ? "00" : "23";
// //     const minutes = setTimeTo === "start" ? "00" : "59";
// //     return `${year}-${month}-${day} ${hours}:${minutes}`;
// //   };

// //   const formattedStartDate = formatDate(startDate);
// //   const formattedEndDate = formatDate(endDate);

// //   const StarDate = formatedDate(formattedStartDate);
// //   const EndDate = formatedDate(formattedEndDate);

// //   console.log("stastdate ", startDate);

// //   console.log("enddate ", endDate);
// //   // If the user is not authenticated, redirect to the login page
// //   useEffect(() => {
// //     if (!isAuthenticated) {
// //       navigate(
// //         "/login?message=You need to be authenticated to access this page."
// //       );
// //     }
// //   }, [isAuthenticated, navigate]);
// //   console.log(startDate);

// //   const [consoles, setConsoles] = useState([]);
// //   const [getConsoleId, setGetConsoleId] = useState({});
// //   const fetchConsoleNames = async () => {
// //     try {
// //       const response = await fetch(
// //         `https://${ipaddress}externalParty/console/${companyid}/${branchId}`
// //       );
// //       const data = await response.json();
// //       const consoleMap = {};
// //       data.forEach((console) => {
// //         consoleMap[console.externaluserId] = console.userName;
// //       });
// //       setGetConsoleId(consoleMap);
// //       setConsoles(data);
// //       setPartyNameToIdMap(consoleMap);
// //     } catch (error) {
// //       console.error("Error fetching party names:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     fetchConsoleNames();
// //   }, []);

// //   const [partys, setPartys] = useState([]);
// //   const [getpartyId, setGetpartyId] = useState({});
// //   const fetchPartyNames = async () => {
// //     try {
// //       const response = await fetch(
// //         `https://${ipaddress}parties/getAll/${companyid}/${branchId}`
// //       );
// //       const data = await response.json();
// //       const namesMap = {};
// //       data.forEach((party) => {
// //         namesMap[party.partyId] = party.partyName;
// //       });
// //       setGetpartyId(namesMap);
// //       setPartys(data);
// //       setPartyNameToIdMap(namesMap);
// //     } catch (error) {
// //       console.error("Error fetching party names:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     fetchPartyNames();
// //   }, []);

// //   const handlePartyChange = (event) => {
// //     const selectedPartyName = event.target.value;
// //     setSelectedParty(selectedPartyName);
// //     setShowExportDataTable(false);
// //   };

// //   const fetchSubContractData = () => {
// //     // Make an API request here to fetch the list of airline names based on the provided criteria
// //     fetch(
// //       `https://${ipaddress}export/findExportAllData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&cartingAgent=${selectedParty}`
// //     )
// //       .then((response) => response.json())
// //       .then((data) => {
// //         // Ensure that 'data' is an array before updating the 'airlines' state
// //         if (Array.isArray(data) && data.length > 0) {
// //           // Update the 'airlines' state with the fetched data
// //           setSubContractData(data);
// //           setSubContractDataTable("true");
// //           setShowAlert(false);
// //           setExportData([]);
// //           toast.success("Data Found !", {
// //             position: "top-center",
// //             autoClose: 540, // Duration in milliseconds
// //             hideProgressBar: false,
// //             closeOnClick: true,
// //             pauseOnHover: true,
// //             draggable: true,
// //             progress: undefined,
// //           });
// //           setExportSubData([]);
// //         } else {
// //           console.error("API response is not an array:", data);
       
// //           toast.error("Data Not Found !", {
// //             position: "top-center",
// //             autoClose: 540, // Duration in milliseconds
// //             hideProgressBar: false,
// //             closeOnClick: true,
// //             pauseOnHover: true,
// //             draggable: true,
// //             progress: undefined,
// //           });
// //       // setShowAlert(true);
        
// //       setSubContractDataTable(false);
// //       setShowExportDataTable(false);
// //         }
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching airline names:", error);
// //         setShowAlert(true);
// //       });
// //   };
// // console.log('subContractData ',subContractData);
// //   const fetchExportData = () => {
// //     fetch(
// //       `https://${ipaddress}export/findExportData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
// //     )
// //       .then((response) => response.json())
// //       .then((data) => {
// //         // Ensure that 'data' is an array before updating the 'airlines' state
// //         if (Array.isArray(data) && data.length > 0) {
// //           // Update the 'airlines' state with the fetched data
// //           setExportData(data);
// //           setShowExportDataTable(true);
// //           setShowAlert(false);
// //           setSubContractData([]);
// //           setSubContractDataTable(false);
// //           toast.success("Data Found !", {
// //             position: "top-center",
// //             autoClose: 540, // Duration in milliseconds
// //             hideProgressBar: false,
// //             closeOnClick: true,
// //             pauseOnHover: true,
// //             draggable: true,
// //             progress: undefined,
// //           });
// //         } else {
// //           console.error("API response is not an array:", data);
// //           toast.error("Data Not Found !", {
// //             position: "top-center",
// //             autoClose: 540, // Duration in milliseconds
// //             hideProgressBar: false,
// //             closeOnClick: true,
// //             pauseOnHover: true,
// //             draggable: true,
// //             progress: undefined,
// //           });


// //           // setShowExportDataTable(false);
// //         }
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching airline names:", error);
       
// //       });
// //   };

// //   const fetchExportSubData = () => {
// //     fetch(
// //       `https://${ipaddress}exportsub/findExportSubAllData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
// //     )
// //       .then((response) => response.json())
// //       .then((data) => {
// //         // Ensure that 'data' is an array before updating the 'airlines' state
// //         if (Array.isArray(data) && data.length > 0) {
// //           // Update the 'airlines' state with the fetched data
// //           setExportSubData(data);
// //           setShowExportDataTable(true);
// //           setShowAlert(false);
// //           setSubContractData([]);
          
// //         } else {
// //           console.error("API response is not an array:", data);
// //           setShowAlert(true);
// //         }
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching airline names:", error);
// //         setShowAlert(true);
// //       });
// //   };

 
// //   const handleSearch = () => {

// //     if (startDate && endDate && !selectedParty) {
// //       fetchExportData();
// //       fetchExportSubData();
// //     } else if (startDate && endDate && selectedParty) {
// //       fetchSubContractData();
// //     }
// //   };

// //   const handlePrint = () => {
// //     const isoDate = new Date().toISOString();
// //     const date = new Date(isoDate);
// //     const day = date.getDate().toString().padStart(2, "0");
// //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
// //     const year = date.getFullYear().toString();

// //     let printWindow = window.open("", "_blank");
// //     printWindow.document.open();

// //     const recordsPerPage = 25;
// //     const totalRecords = subContractData.length;

// //     let startIndex = 0; // Initialize the starting index for each page
// //     let currentPageNumber = 1; // Initialize the current page number

// //     for (
// //       let pageIndex = 0;
// //       pageIndex < Math.ceil(totalRecords / recordsPerPage);
// //       pageIndex++
// //     ) {
// //       // Slice the subContractData array to get 10 records for the current page
// //       const pageRecords = subContractData.slice(
// //         startIndex,
// //         startIndex + recordsPerPage
// //       );

// //       // Check if it's the last page
// //       const isLastPage =
// //         pageIndex === Math.ceil(totalRecords / recordsPerPage) - 1;

// //       printWindow.document.write(`
// //         <!DOCTYPE html>
// //         <html>
// //         <head>
// //         <title> Export Report Register</title>
// //         <style>
// //           @page {
// //             margin: 1cm;
// //             /* Adjust this value to position the header correctly */
// //           }
  
// //           .printable-area {
// //             font-family: Arial, sans-serif;
// //             page-break-before: always;
// //           }
  
// //           table {
// //             width: 100%;
// //             border-collapse: collapse;
// //           }
  
// //           td {
// //             border: 1px solid #dddddd;
// //             text-align: center;
// //             padding: 1px;
// //             font-size: 10px;
// //         }
    
// //         th {
// //           border: 1px solid #dddddd;
// //             background-color: #f2f2f2;
// //             text-align: center;
// //             font-size: 12px;
// //         }
// //           .container {
// //             display: flex;
// //             justify-content: space-between;
// //           }
  
// //           .left-column {
// //             width: 70%; /* Adjust the width as needed */
// //           }
  
// //           .page-break {
// //             page-break-before: always; /* This will force a page break before the element */
// //           }
  
// //           .right-column {
// //             width: 30%; /* Adjust the width as needed */
// //             text-align: right; /* Align text to the right */
// //           }
  
// //           .header img {
// //             max-width: auto; /* Ensure the image doesn't exceed the page width */
// //             max-height: auto; /* Adjust the maximum image height as needed */
// //           }
  
// //           .report-header {
// //             display: flex;
// //             justify-content: space-between;
// //             margin-bottom: 5px;
// //           }
  
// //           #page-header {
// //             position: static;
// //             top: 0;
// //             left: 0;
// //             right: 0;
// //             text-align: center;
// //           }
// //         </style>
// //         </head>
// //         <body>
// //         <div id="page-header">
// //         <img src="${dgdcImage}" alt="Header Image" style="max-width: 306px; display: block; margin: 0 auto;">
// //         <div style="text-align: center;">
// //         <div style="font-size:14px;font-weight:bold;">Export Register Report - Page ${currentPageNumber}</div><br/>
// //         </div>
// //         </div>
// //         <div class="report-header">
// //         <div></div>
// //         <div style="font-size:12px;">FOR THE PERIOD: ${StarDate} To ${EndDate}</div>
// //         <div style="font-size:12px;"> REPORT DATE: ${format(new Date(), "dd/MM/yyyy HH:mm")} </div>
// //         </div>
// //         <table>
// //         <thead>
// //           <tr>
// //           <th style="background-color: #BADDDA">Sr.No</th>
// //           <th style="background-color: #BADDDA">SER No</th>
// //           <th style="background-color: #BADDDA">SB No</th>
// //           <th style="background-color: #BADDDA">SER Date</th>
      
// //           <th style="background-color: #BADDDA">Exporter Names</th>
// //           <th style="background-color: #BADDDA">CHA No</th>
// //           <th style="background-color: #BADDDA">DESC</th>
// //           <th style="background-color: #BADDDA">NOP</th>
// //           <th style="background-color: #BADDDA">Net Weight</th>
// //           <th style="background-color: #BADDDA">FOB value in INR</th>
// //           <th style="background-color: #BADDDA">AIRWAY BILL NO</th>
// //           <th style="background-color: #BADDDA">PORT OF DESTINATION</th>
// //           <th style="background-color: #BADDDA">Console Is</th>
// //           </tr>
// //         </thead>
// //         <tbody>
// //         ${pageRecords
// //           .map(
// //             (item, index) => `
// //               <tr>
// //               <td>${startIndex + index + 1}</td>
// //               <td>${item.serNo || ''}</td>
// //               <td>${item.sbNo || ''}</td>
// //               <td>${formatedDate(item.serDate) || ''}</td>
         
// //               <td>${getpartyId[item.nameOfExporter] || ''}</td>
// //               <td>${item.chaNo || ''}</td>
// //               <td>${item.descriptionOfGoods || '' }</td>
// //               <td>${item.noOfPackages || ''}</td>
// //               <td>${item.grossWeight || ''}</td>
// //               <td>${item.fobValueInINR || ''}</td>
// //               <td>${item.airwayBillNo || ''}</td>
// //               <td>${item.portOfDestination || ''}</td>
// //               <td>${getConsoleId[item.cartingAgent] || ''}</td>
// //               </tr>
// //             `
// //           )
// //           .join("")}
// //         </tbody>
// //         </table>
// //         </div>
// //         </body>
// //         </html>
// //       `);

// //       // Check if it's not the last page, then add a page break
// //       if (!isLastPage) {
// //         printWindow.document.write('<div class="page-break"></div>');
// //       } else {
// //         printWindow.document.write(`
// //           <table>
// //           <tbody>
// //           <tr>
// //           <td style="font-weight: bold;background-color: #BADDDA">Total</td>
// //           <td style="background-color: #BADDDA"></td>
         
// //           <td style="background-color: #BADDDA">${totalSbNoCount}</td>
// //           <td style="background-color: #BADDDA"></td>
      
// //           <td style="background-color: #BADDDA"></td>
// //           <td style="background-color: #BADDDA"></td>
// //           <td style="background-color: #BADDDA"></td>
// //           <td style="background-color: #BADDDA">${totalNoOfPackages}</td>
// //           <td style="background-color: #BADDDA"></td>
// //           <td style="background-color: #BADDDA">${totalFobValueInINR}</td>
// //           <td style="background-color: #BADDDA"></td>
// //           <td style="background-color: #BADDDA"></td>
// //           <td style="background-color: #BADDDA"></td>
// //           </tr>
// //           </tbody>
// //           </table>
// //         `);
// //       }

// //       // Update the starting index for the next page
// //       startIndex += recordsPerPage;
// //       currentPageNumber++;
// //     }

// //     printWindow.document.close();
// //     printWindow.print();
// //     printWindow.onafterprint = () => printWindow.close();
// //   };

// //   const handleExportPrint = () => {
// //     const isoDate = new Date().toISOString();
// //     const date = new Date(isoDate);
// //     const day = date.getDate().toString().padStart(2, "0");
// //     const month = (date.getMonth() + 1).toString().padStart(2, "0");
// //     const year = date.getFullYear().toString();
  
// //     let printWindow = window.open("", "_blank");
// //     printWindow.document.open();
  
// //     const recordsPerPage = 25;
// //     const totalRecords = exportData.length + exportSubData.length;
  
// //     let startIndex = 0; // Initialize the starting index for each page
// //     let currentPageNumber = 1; // Initialize the current page number
  
// //     // Function to generate the table HTML for a set of records
// //     const generateTableHTML = (records) => {
// //       return `
// //         <table>
// //           <thead>
// //             <tr>
// //               <th style="background-color: #BADDDA">Sr.No</th>
// //               <th style="background-color: #BADDDA">SER No</th>
// //               <th style="background-color: #BADDDA">SB No</th>
// //               <th style="background-color: #BADDDA">SER Date</th>
// //               <th style="background-color: #BADDDA">Exporter Names</th>
// //               <th style="background-color: #BADDDA">CHA No</th>
// //               <th style="background-color: #BADDDA">DESC</th>
// //               <th style="background-color: #BADDDA">NOP</th>
// //               <th style="background-color: #BADDDA">Net Weight</th>
// //               <th style="background-color: #BADDDA">FOB value in INR</th>
// //               <th style="background-color: #BADDDA">AIRWAY BILL NO</th>
// //               <th style="background-color: #BADDDA">PORT OF DESTINATION</th>
// //               <th style="background-color: #BADDDA">Console Is</th>
// //             </tr>
// //           </thead>
// //           <tbody>
// //             ${records
// //               .map(
// //                 (item, index) => `
// //                 <tr>
// //                 <td>${startIndex + index + 1}</td>
// //                 <td>${item.serNo || ''}</td>
// //                 <td>${item.sbNo || ''}</td>
// //                 <td>${formatedDate(item.serDate) || ''}</td>
// //                 <td>${getpartyId[item.nameOfExporter || item.exporter] || ''}</td>
// //                 <td>${item.chaNo || ''}</td>
// //                 <td>${item.descriptionOfGoods || item.remarks || ''}</td>
// //                 <td>${item.noOfPackages || item.nop || ''}</td>
// //                 <td>${item.grossWeight || item.gwWeight || ''}</td>
// //                 <td>${item.fobValueInINR || ''}</td>
// //                 <td>${item.airwayBillNo || ''}</td>
// //                 <td>${item.portOfDestination || ''}</td>
// //                 <td>${getConsoleId[item.cartingAgent] || ''}</td>
// //               </tr>
// //                 `
// //               )
// //               .join("")}
// //           </tbody>
// //         </table>
// //       `;
// //     };
  
// //     for (
// //       let pageIndex = 0;
// //       pageIndex < Math.ceil(totalRecords / recordsPerPage);
// //       pageIndex++
// //     ) {
// //       // Merge exportData and exportSubData arrays for the current page
// //       const pageRecords = [...exportData, ...exportSubData].slice(
// //         startIndex,
// //         startIndex + recordsPerPage
// //       );
  
// //       // Check if it's the last page
// //       const isLastPage =
// //         pageIndex === Math.ceil(totalRecords / recordsPerPage) - 1;
  
// //       printWindow.document.write(`
// //         <!DOCTYPE html>
// //         <html>
// //         <head>
// //         <title> Export Report Register</title>
// //         <style>
// //           @page {
// //             margin: 1cm;
// //             /* Adjust this value to position the header correctly */
// //           }
  
// //           .printable-area {
// //             font-family: Arial, sans-serif;
// //             page-break-before: always;
// //           }
  
// //           table {
// //             width: 100%;
// //             border-collapse: collapse;
// //           }
  
// //           td {
// //             border: 1px solid #dddddd;
// //             text-align: center;
// //             padding: 1px;
// //             font-size: 10px;
// //         }
    
// //         th {
// //           border: 1px solid #dddddd;
// //             background-color: #f2f2f2;
// //             text-align: center;
// //             font-size: 12px;
// //         }
  
// //           .container {
// //             display: flex;
// //             justify-content: space-between;
// //           }
  
// //           .left-column {
// //             width: 70%; /* Adjust the width as needed */
// //           }
  
// //           .page-break {
// //             page-break-before: always; /* This will force a page break before the element */
// //           }
  
// //           .right-column {
// //             width: 30%; /* Adjust the width as needed */
// //             text-align: right; /* Align text to the right */
// //           }
  
// //           .header img {
// //             max-width: auto; /* Ensure the image doesn't exceed the page width */
// //             max-height: auto; /* Adjust the maximum image height as needed */
// //           }
  
// //           .report-header {
// //             display: flex;
// //             justify-content: space-between;
// //             margin-bottom: 5px;
// //           }
  
// //           #page-header {
// //             position: static;
// //             top: 0;
// //             left: 0;
// //             right: 0;
// //             text-align: center;
// //           }
// //         </style>
// //         </head>
// //         <body>
// //         <div id="page-header">
// //           <img src="${dgdcImage}" alt="Header Image" style="max-width: 306px; display: block; margin: 0 auto;">
// //           <div style="text-align: center;">
// //             <div style="font-size:14px;font-weight:bold;">Export Register Report - Page ${currentPageNumber}</div><br/>
// //           </div>
// //         </div>
// //         <div class="report-header">
// //           <div></div>
// //           <div style="font-size:12px;">FOR THE PERIOD: ${StarDate} To ${EndDate}</div>
// //           <div style="font-size:12px;"> REPORT DATE: ${format(new Date(), "dd/MM/yyyy HH:mm")} </div>
// //         </div>
// //         ${generateTableHTML(pageRecords)}
// //         </body>
// //         </html>
// //       `);
  
// //       // Check if it's not the last page, then add a page break
// //       if (!isLastPage) {
// //         printWindow.document.write('<div class="page-break"></div>');
// //       } else {
// //         printWindow.document.write(`
// //           <table>
// //           <tbody>
// //           <tr>
// //           <td style="font-weight: bold;background-color: #BADDDA">Total</td>
// //           <td style="background-color: #BADDDA"></td>
// //           <td style="background-color: #BADDDA">${totalExSbNoCount}</td>
// //           <td style="background-color: #BADDDA"></td>
// //           <td style="background-color: #BADDDA"></td>
// //           <td style="background-color: #BADDDA"></td>
// //           <td style="background-color: #BADDDA"></td>
// //           <td style="background-color: #BADDDA">${totalEXSubNoOfPackages + totalExNoOfPackages}</td>
// //           <td style="background-color: #BADDDA"></td>
// //           <td style="background-color: #BADDDA">${totalExFobValueInINR}</td>
// //           <td style="background-color: #BADDDA"></td>
// //           <td style="background-color: #BADDDA"></td>
// //           <td style="background-color: #BADDDA"></td>
// //           </tr>
// //           </tbody>
// //           </table>
// //         `);
// //       }
  
// //       // Update the starting index for the next page
// //       startIndex += recordsPerPage;
// //       currentPageNumber++;
// //     }
  
// //     printWindow.document.close();
// //     printWindow.print();
// //     printWindow.onafterprint = () => printWindow.close();
// //   };
// //   const handleExportPDFDownload = async () => {
// //     const pdfBlob = await pdf(
// //       <MyExportPDFDocument
// //         {...{
// //           StarDate,
// //           EndDate,
// //           selectedParty,
// //           exportSubData,
// //           exportData,
// //           totalEXSubNoOfPackages,
// //           totalExFobValueInINR,
// //           totalExSbNoCount,
// //           totalExNoOfPackages
// //         }}
// //       />
// //     ).toBlob();
// //     saveAs(pdfBlob, "Export_Register.pdf");
// //   };

// //   const PAGE_BREAK_ROWS_PDF = 15;

// //   const MyExportPDFDocument = ({
// //     StarDate,
// //     EndDate,
// //     exportData,
// //     exportSubData, // Include exportSubData as a prop
// //     totalExSbNoCount,
// //     totalExNoOfPackages,
// //     totalExFobValueInINR,
// //   }) =>
// //   {
// //     if (!exportData || !Array.isArray(exportData) || !exportSubData || !Array.isArray(exportSubData)) {
// //       // Handle the case when data is not available
// //       return null;
// //     }
  
// //   const mergedData = [...exportData, ...exportSubData];
// //   return(
// //     <Document>
// //       {Array.from({
// //         length: Math.ceil(mergedData.length / PAGE_BREAK_ROWS_PDF),
// //       }).map((_, pageIndex) => (
// //         <Page
// //           key={pageIndex}
// //           size="A4"
// //           orientation="landscape"
// //           style={styles.page}
// //         >
// //           <View style={styles.section}>
// //             {/* Your header content */}
// //             <View style={styles.header}>
// //               <CustomHeader />
// //             </View>
// //             <View style={[styles.centeredText, { fontWeight: "bold" }]}>
// //               <Text style={[styles.centeredText, { fontWeight: "bold" }]}>
// //                 EXPORT REGISTER
// //               </Text>
// //             </View>
// //             <View style={styles.reportHeader}>
// //               <Text></Text>
// //               <Text>
// //                 FOR THE PERIOD :{StarDate} TO {EndDate}{" "}
// //               </Text>
// //               <Text style={{ marginLeft: 4 }}>
// //                 REPORT DATE: {format(new Date(), "dd/MM/yyyy HH:mm")}
// //               </Text>
// //             </View>
  
// //             <View style={styles.table}>
// //               <View style={styles.tableRow}>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Sr.No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   SER No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   SB No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   SER Date
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Exporter Names
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   CHA No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>DESC</Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader ,{width:45}]}>
// //                 NOP
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Net Weight
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   FOB value in INR
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   AIRWAY BILL NO
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   PORT OF DESTINATION
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Console Is
// //                 </Text>
// //               </View>
// //               {[...exportData, ...exportSubData] // Merge exportData and exportSubData
// //                 .slice(
// //                   pageIndex * PAGE_BREAK_ROWS_PDF,
// //                   (pageIndex + 1) * PAGE_BREAK_ROWS_PDF
// //                 )
// //                 .map((item, index) => (
// //                   <View style={styles.tableRow} key={index}>
// //                     <Text style={styles.tableCell}>
// //                       {index + 1 + pageIndex * PAGE_BREAK_ROWS_PDF}
// //                     </Text>
// //                     <Text style={styles.tableCell}>{item.serNo || ""}</Text>
// //                     <Text style={styles.tableCell}>{item.sbNo}</Text>
// //                     <Text style={styles.tableCell}>
// //                       {formatedDate(item.serDate) || ""}
// //                     </Text>
// //                     <Text style={styles.tableCell}>
// //                       {getpartyId[item.nameOfExporter || item.exporter] || ""}
// //                     </Text>
// //                     <Text style={styles.tableCell}>{item.chaNo}</Text>
// //                     <Text style={styles.tableCell}>
// //                       {item.descriptionOfGoods || item.remarks || ""}
// //                     </Text>
// //                     <Text style={[styles.tableCell  ,{width:45}]}>
// //                       {item.noOfPackages || item.nop || ""}
// //                     </Text>
// //                     <Text style={styles.tableCell}>
// //                       {item.grossWeight || item.gwWeight || ""}
// //                     </Text>
// //                     <Text style={styles.tableCell}>{item.fobValueInINR}</Text>
// //                     <Text style={styles.tableCell}>{item.airwayBillNo}</Text>
// //                     <Text style={styles.tableCell}>
// //                       {item.portOfDestination || ""}
// //                     </Text>
// //                     <Text style={styles.tableCell}>
// //                       {getConsoleId[item.cartingAgent] || ""}
// //                     </Text>
// //                   </View>
// //                 ))}
// //               {pageIndex ===
// //                 Math.ceil(exportData.length / PAGE_BREAK_ROWS_PDF) - 1 && (
// //                 <View style={styles.tableRow}>
// //                   <Text style={styles.tableCell}>Total</Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}>{totalExSbNoCount}</Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={[styles.tableCell,{width:45}]}>
// //                     {totalExNoOfPackages}
// //                   </Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}>{totalExFobValueInINR}</Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                 </View>
// //               )}
// //             </View>
// //           </View>
// //         </Page>
// //       ))}

// //     </Document>
// //   );
// // };
  
// //   const handlePDFDownload = async () => {
// //     const pdfBlob = await pdf(
// //       <MyPDFDocument
// //         {...{
// //           StarDate,
// //           EndDate,
// //           selectedParty,
// //           subContractData,
// //           totalFobValueInINR,
// //           totalNoOfPackages,
// //           totalSbNoCount,
// //         }}
// //       />
// //     ).toBlob();
// //     saveAs(pdfBlob, "Export_Register.pdf");
// //   };
// //   const handleReset =()=>{
// //     setStartDate(new Date());
// //     setEndDate(new Date());
// //     setSelectedParty("");
// //     setExportSubData([]);
// //     setExportData([]);
// //     setShowExportDataTable(false);
// //       }

// //   function fetchCompanyName(companyId) {
// //     // Make an Axios GET request to retrieve the company name based on companyId
// //     return axios
// //       .get(`https://${ipaddress}export/findCompanyname/${companyId}`)
// //       .then(function (response) {
// //         return response.data; // Return the retrieved company name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching company name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }
// //   function fetchPartyName(companyId, branchId, partyId) {
// //     // Make an Axios GET request to retrieve the company name based on companyId
// //     return axios
// //       .get(
// //         `https://${ipaddress}export/findPartyName/${companyId}/${branchId}/${partyId}`
// //       )
// //       .then(function (response) {
// //         return response.data; // Return the retrieved company name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching company name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }

// //   function fetchExporterName(companyId, branchId, partyId) {
// //     // Make an Axios GET request to retrieve the company name based on companyId
// //     return axios
// //       .get(
// //         `https://${ipaddress}export/findPartyName/${companyId}/${branchId}/${partyId}`
// //       )
// //       .then(function (response) {
// //         return response.data; // Return the retrieved company name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching company name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }

// //   function fetchConsoleName(companyId, branchId, externaluserId) {
// //     // Make an Axios GET request to retrieve the company name based on companyId
// //     return axios
// //       .get(
// //         `https://${ipaddress}export/findConsoleName/${companyId}/${branchId}/${externaluserId}`
// //       )
// //       .then(function (response) {
// //         return response.data; // Return the retrieved company name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching company name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }
// //   function fetchBranchName(companyId, branchId) {
// //     // Make an Axios GET request to retrieve the branch name based on branchId
// //     return axios
// //       .get(
// //         `https://${ipaddress}export/findBranchName/${companyId}/${branchId}`
// //       )
// //       .then(function (response) {
// //         return response.data; // Return the retrieved branch name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching branch name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }

// //   const handleXLSDownload = async () => {
// //     const modifiedReordList = await Promise.all(
// //       subContractData.map(async (item, index) => {
// //         // const companyname = await fetchCompanyName(item.companyId);
// //         // const branchname = await fetchBranchName(item.companyId, item.branchId);
// //         const partyName = await fetchPartyName(
// //           item.companyId,
// //           item.branchId,
// //           item.nameOfExporter
// //         );
// //         const userName = await fetchConsoleName(
// //           item.companyId,
// //           item.branchId,
// //           item.consoleAgent
// //         );

// //         return {
// //           "Sr.No": index + 1,
// //           // "Company Name": companyname,
// //           // "Branch Name": branchname,
// //           "SER No": item.serNo,
// //           "SB No": item.sbNo,
// //           "SER Date": formatedDate(item.serDate),
// //           "Parcle Type": " ", // Modify this to the actual field name
// //           "Exporter Names": getpartyId[item.nameOfExporter],
// //           "CHA No": item.chaNo,
// //           DESC: item.descriptionOfGoods,
// //           "No of Pkgs": item.noOfPackages,
// //           "Net Weight": item.grossWeight,
// //           "FOB value in INR": item.fobValueInINR,
// //           "AIRWAY BILL NO": item.airwayBillNo,
// //           "PORT OF DESTINATION": item.portOfDestination,
// //           "Console Is": getConsoleId[item.consoleAgent],
// //           // Add more fields as needed
// //         };
// //       })
// //     );

// //     const workbook = XLSX.utils.book_new();
// //     const worksheet = XLSX.utils.json_to_sheet(modifiedReordList);

// //     // Add headers for all fields
// //     const headers = Object.keys(modifiedReordList[0]);
// //     headers.forEach((header, index) => {
// //       worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
// //         t: "s",
// //         v: header,
// //         s: { font: { bold: true } }, //
// //       };
// //     });

// //     // Set column widths based on data
// //     const colWidths = headers.map((header) => ({
// //       wch: header.length + 2, // You can adjust the width as needed
// //     }));

// //     worksheet["!cols"] = colWidths;

// //     XLSX.utils.book_append_sheet(workbook, worksheet, "export_transaction");
// //     const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
// //     const blob = new Blob([s2ab(xlsFile)], {
// //       type: "application/vnd.ms-excel",
// //     });
// //     saveAs(blob, "Export_Register.xls");
// //   };

// //   const s2ab = (s) => {
// //     const buf = new ArrayBuffer(s.length);
// //     const view = new Uint8Array(buf);
// //     for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
// //     return buf;
// //   };


// //   const handleExportXLSDownload = async () => {
// //     const modifiedReordList = await Promise.all(
// //       mergedAllData.map(async (item, index) => {
// //         // const companyname = await fetchCompanyName(item.companyId);
// //         // const branchname = await fetchBranchName(item.companyId, item.branchId);
// //         const partyName = await fetchPartyName(
// //           item.companyId,
// //           item.branchId,
// //           item.nameOfExporter
// //         );

// //         const exporterName = await fetchExporterName(
// //           item.companyId,
// //           item.branchId,
// //           item.exporter
// //         );
// //         const userName = await fetchConsoleName(
// //           item.companyId,
// //           item.branchId,
// //           item.consoleAgent
// //         );

// //         return {
// //           "Sr.No": index + 1,
// //           // "Company Name": companyname,
// //           // "Branch Name": branchname,
// //           "SER No": item.serNo ||  item.serNo,
// //           "SB No": item.sbNo,
// //           "SER Date": formatedDate(item.serDate) ||formatedDate(item.serDate),
// //           "Parcle Type": " ", // Modify this to the actual field name
// //           "Exporter Names": getpartyId[item.nameOfExporter]  || getpartyId[item.exporter],
// //           "CHA No": item.chaNo,
// //           "DESC": item.descriptionOfGoods || item.remarks,
// //           "No of Pkgs": item.noOfPackages || item.nop,
// //           "Net Weight": item.grossWeight || item.gwWeight,
// //           "FOB value in INR": item.fobValueInINR,
// //           "AIRWAY BILL NO": item.airwayBillNo,
// //           "PORT OF DESTINATION": item.portOfDestination,
// //           "Console Is": getConsoleId[item.consoleAgent],
// //         };
// //       })
// //     );

// //     const workbook = XLSX.utils.book_new();
// //     const worksheet = XLSX.utils.json_to_sheet(modifiedReordList);

// //     // Add headers for all fields
// //     const headers = Object.keys(modifiedReordList[0]);
// //     headers.forEach((header, index) => {
// //       worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
// //         t: "s",
// //         v: header,
// //         s: { font: { bold: true } }, //
// //       };
// //     });

// //     // Set column widths based on data
// //     const colWidths = headers.map((header) => ({
// //       wch: header.length + 2, // You can adjust the width as needed
// //     }));

// //     worksheet["!cols"] = colWidths;

// //     XLSX.utils.book_append_sheet(workbook, worksheet, "export_report");
// //     const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
// //     const blob = new Blob([s2ab(xlsFile)], {
// //       type: "application/vnd.ms-excel",
// //     });
// //     saveAs(blob, "Export_Register.xls");
// //   };

  
// //   const MyPDFDocument = ({
// //     totalNoOfPackages,
// //     subContractData,
// //     totalSbNoCount,
// //     totalFobValueInINR,
// //   }) => (
// //     <Document>
// //       {Array.from({
// //         length: Math.ceil(subContractData.length / PAGE_BREAK_ROWS_PDF),
// //       }).map((_, pageIndex) => (
// //         <Page
// //           key={pageIndex}
// //           size="A4"
// //           orientation="landscape"
// //           style={{ ...styles.page }}
// //         >
// //           <View style={styles.section}>
// //             {/* Your header content */}
// //             <View style={styles.header}>
// //               <Image src={dgdcImage} style={styles.image} />
// //             </View>
// //             <View style={[styles.centeredText, { fontWeight: "bold" }]}>
// //               <Text style={[styles.centeredText, { fontWeight: "bold" }]}>
// //                 EXPORT REGISTER
// //               </Text>
// //             </View>
// //             <View style={styles.reportHeader}>
// //               <Text></Text>
// //               <Text>
// //                 FOR THE PERIOD :{StarDate} TO {EndDate}{" "}
// //               </Text>
// //               <Text style={{ marginLeft: 4 }}>
// //                 REPORT DATE: {format(new Date(), "dd/MM/yyyy HH:mm")}
// //               </Text>
// //             </View>

// //             {/* Your table */}
// //             <View style={styles.table}>
// //               <View style={styles.tableRow}>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Sr.No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   SER No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   SB No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   SER Date
// //                 </Text>
// //                 {/* <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Parcel Type
// //                 </Text> */}
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Exporter Names
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   CHA No
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   DESC
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                  NOP
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Net Weight
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   FOB value in INR
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   AIRWAY BILL NO
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   PORT OF DESTINATION
// //                 </Text>
// //                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
// //                   Console Is
// //                 </Text>
// //               </View>
// //               {subContractData
// //                 .slice(
// //                   pageIndex * PAGE_BREAK_ROWS_PDF,
// //                   (pageIndex + 1) * PAGE_BREAK_ROWS_PDF
// //                 )
// //                 .map((item, index) => (
// //                   <View style={styles.tableRow} key={index}>
// //                     <Text style={styles.tableCell}>
// //                       {index + 1 + pageIndex * PAGE_BREAK_ROWS_PDF}
// //                     </Text>
// //                     <Text style={styles.tableCell}>{item.serNo}</Text>
// //                     <Text style={styles.tableCell}>{item.sbNo}</Text>
// //                     <Text style={styles.tableCell}>
// //                       {formatedDate(item.serDate)}
// //                     </Text>
// //                     {/* <Text style={styles.tableCell}>Parcel Type Value</Text> */}
// //                     <Text style={styles.tableCell}>
// //                       {getpartyId[item.nameOfExporter]}
// //                     </Text>
// //                     <Text style={styles.tableCell}>{item.chaNo}</Text>
// //                     <Text style={styles.tableCell}>
// //                       {item.descriptionOfGoods}
// //                     </Text>
// //                     <Text style={styles.tableCell}>{item.noOfPackages}</Text>
// //                     <Text style={styles.tableCell}>{item.grossWeight}</Text>
// //                     <Text style={styles.tableCell}>{item.fobValueInINR}</Text>
// //                     <Text style={styles.tableCell}>{item.airwayBillNo}</Text>
// //                     <Text style={styles.tableCell}>
// //                       {item.portOfDestination}
// //                     </Text>
// //                     <Text style={styles.tableCell}>
// //                       {getConsoleId[item.cartingAgent]}
// //                     </Text>
// //                   </View>
// //                 ))}
// //               {/* Display the "Total" row only on the last page */}
// //               {pageIndex ===
// //                 Math.ceil(subContractData.length / PAGE_BREAK_ROWS_PDF) - 1 && (
// //                 <View style={styles.tableRow}>
// //                   <Text style={styles.tableCell}>Total</Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}>{totalSbNoCount}</Text>
// //                   {/* <Text style={styles.tableCell}></Text> */}
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}>{totalNoOfPackages}</Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}>{totalFobValueInINR}</Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                   <Text style={styles.tableCell}></Text>
// //                 </View>
// //               )}
// //             </View>
// //           </View>
// //         </Page>
// //       ))}
// //     </Document>
// //   );


// //   let indexCounter = 1;
// //   return (
// //     <div>
// //       <h4 style={{ marginLeft: 18, marginTop: 25 }}>Export Register Report</h4>
// //       {/* style={{marginTop:25,marginRight:1,marginLeft:18,padding: 8}} */}
// //       <Card
// //         style={{ marginTop: 25, marginRight: 18, marginLeft: 18, padding: 8 }}
// //       >
// //         <Container>
// //           <Form>
// //             <Row>
// //               <Col md={3}>
// //                 <Form.Group>
// //                   <Form.Label className="inputhead">From Date</Form.Label>
// //                   <div className="input-group">
// //                     <DatePicker
// //                       selected={startDate}
// //                       onChange={(date) => setStartDate(date)}
// //                       dateFormat="dd/MM/yyyy" // You can customize the date format
// //                       name="startDate"
// //                       required
// //                       className="form-control border-right-0 inputField"
// //                       customInput={<input style={{ width: "18vw" }} />}
// //                     />
// //                   </div>
// //                 </Form.Group>
// //               </Col>
// //               <Col md={3}>
// //                 <Form.Group>
// //                   <Form.Label className="inputhead">To Date</Form.Label>
// //                   <div className="input-group">
// //                     <DatePicker
// //                       selected={endDate}
// //                       onChange={(date) => setEndDate(date)}
// //                       dateFormat="dd/MM/yyyy" // You can customize the date format
// //                       name="endDate"
// //                       required
// //                       className="form-control border-right-0 inputField"
// //                       customInput={<input style={{ width: "18vw" }} />}
// //                     />
// //                   </div>{" "}
// //                 </Form.Group>
// //               </Col>
// //               <Col md={3}>
// //                 <label htmlFor="company" className="inputhead">
// //                   Console
// //                 </label>
// //                 <select
// //                   name="company"
// //                   id="dw1"
// //                   className=""
// //                   onChange={handlePartyChange}
// //                   value={selectedParty}
// //                 >
// //                   <option value="">Select</option>
// //                   {consoles.map((party) => (
// //                     <option
// //                       key={party.externaluserId}
// //                       value={party.externaluserId}
// //                     >
// //                       {party.userName}
// //                     </option>
// //                   ))}
// //                 </select>
// //               </Col>
// //               <Col md={3}>
// //                 <div style={{ marginTop: 30 }}>
// //                   <Button
// //                     color="success"
// //                     outline
// //                     style={{ marginRight: 18 }}
// //                     onClick={handleSearch}
// //                   >
// //                     SEARCH
// //                   </Button>

// //                   <Button onClick={handleReset} color="warning" outline>
// //                     RESET
// //                   </Button>
// //                 </div>
// //               </Col>
// //             </Row>
// //           </Form>
// //         </Container>
// //       </Card>
// //       {showSubContractDataTable ? (
// //         <div>
// //           <Card
// //             style={{
// //               marginTop: 25,
// //               marginRight: 18,
// //               marginLeft: 18,
// //               padding: 8,
// //             }}
// //           >
// //             <Row>
// //               <Col md={8}></Col>
// //               <Col md={4}>
// //                 <button
// //                   style={{ marginLeft: 9 }}
// //                   className="btn btn-outline-danger btn-margin"
// //                   type="button"
// //                   onClick={handlePrint}
// //                 >
// //                   <FontAwesomeIcon
// //                     icon={faPrint}
// //                     style={{ marginRight: "5px" }}
// //                   />
// //                   Print
// //                 </button>
// //                 <button
// //                   className="btn btn-outline-danger btn-margin"
// //                   type="button"
// //                   style={{ marginLeft: "10px", marginRight: 9 }}
// //                   onClick={handleXLSDownload}
// //                 >
// //                   <FontAwesomeIcon
// //                     icon={faFileExcel}
// //                     style={{ marginRight: "5px" }}
// //                   />
// //                   XLS
// //                 </button>
// //                 <button
// //                   className="btn btn-outline-danger btn-margin"
// //                   type="button"
// //                   onClick={handlePDFDownload}
// //                   style={{ marginLeft: "10px" }}
// //                 >
// //                   <FontAwesomeIcon
// //                     icon={faFilePdf}
// //                     style={{ marginRight: "5px" }}
// //                   />
// //                   PDF
// //                 </button>
// //               </Col>
// //             </Row>
// //             <div className="table-responsive">
// //               <Table
// //                 className="table table-striped table-hover"
// //                 style={{ marginTop: 9 }}
// //               >
// //                 {/* Render subContractData table here */}
// //                 <thead>
// //                   <tr>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>Sr.No</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>SER No</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>SB No</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>SER Date</th>

// //                     <th style={{ backgroundColor: "#BADDDA" }}>
// //                       Exporter Names
// //                     </th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>CHA No</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>DESC</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>No of Pkgs</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>Net Weight</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>
// //                       FOB value in INR
// //                     </th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>
// //                       AIRWAY BILL NO
// //                     </th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>
// //                       PORT OF DESTINATION
// //                     </th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>Console Is</th>
// //                   </tr>
// //                 </thead>
// //                 <thead>
// //                   <tr>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>Total</th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>
// //                       {totalSbNoCount}
// //                     </th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>

// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>
// //                       {totalNoOfPackages}
// //                     </th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}>
// //                       {totalFobValueInINR}
// //                     </th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                   </tr>
// //                 </thead>
// //                 <tbody>
// //                   {subContractData.map((item, index) => (
// //                     <tr key={index}>
// //                       <td>{index + 1}</td>
// //                       <td>{item.serNo}</td>
// //                       <td>{item.sbNo}</td>
// //                       <td>{formatedDate(item.serDate)}</td>

// //                       <td>{getpartyId[item.nameOfExporter]}</td>
// //                       <td>{item.chaNo}</td>
// //                       <td>{item.descriptionOfGoods}</td>
// //                       <td>{item.noOfPackages}</td>
// //                       <td>{item.grossWeight}</td>
// //                       <td>{item.fobValueInINR}</td>
// //                       <td>{item.airwayBillNo}</td>
// //                       <td>{item.portOfDestination}</td>
// //                       <td>{getConsoleId[item.consoleAgent]}</td>
// //                     </tr>
// //                   ))}
// //                 </tbody>
// //               </Table>
// //             </div>
// //             <div
// //               style={{
// //                 marginTop: 4,
// //                 display: "flex",
// //                 flexDirection: "row",
// //                 justifyContent: "space-between",
// //               }}
// //             >
// //               <p style={{ fontWeight: "bold" }}>
// //                 Total No. SB No: {totalSbNoCount}
// //               </p>
// //               <p style={{ fontWeight: "bold" }}>
// //                 Total No. of Packages: {totalNoOfPackages}
// //               </p>
// //               <p style={{ fontWeight: "bold" }}>
// //                 Total FOB Value in INR: {totalFobValueInINR}
// //               </p>
// //             </div>
// //           </Card>
// //         </div>
// //       ) : (
// //         <div>
// //           {showExportDataTable ? (
// //             <Card
// //               style={{
// //                 marginTop: 25,
// //                 marginRight: 18,
// //                 marginLeft: 18,
// //                 padding: 8,
// //               }}
// //             >
// //               <Row>
// //                 <Col md={8}></Col>
// //                 <Col md={4}>
// //                   <button
// //                     style={{ marginLeft: 9 }}
// //                     className="btn btn-outline-danger btn-margin"
// //                     type="button"
// //                     onClick={handleExportPrint}
// //                   >
// //                     <FontAwesomeIcon
// //                       icon={faPrint}
// //                       style={{ marginRight: "5px" }}
// //                     />
// //                     Print
// //                   </button>
// //                   <button
// //                     className="btn btn-outline-danger btn-margin"
// //                     type="button"
// //                     style={{ marginLeft: "10px", marginRight: 9 }}
// //                     onClick={handleExportXLSDownload}
// //                   >
// //                     <FontAwesomeIcon
// //                       icon={faFileExcel}
// //                       style={{ marginRight: "5px" }}
// //                     />
// //                     XLS
// //                   </button>
// //                   <button
// //                     className="btn btn-outline-danger btn-margin"
// //                     type="button"
// //                     onClick={handleExportPDFDownload}
// //                     style={{ marginLeft: "10px" }}
// //                   >
// //                     <FontAwesomeIcon
// //                       icon={faFilePdf}
// //                       style={{ marginRight: "5px" }}
// //                     />
// //                     PDF
// //                   </button>
// //                 </Col>
// //               </Row>
// //               <div className="table-responsive">
// //                 <Table
// //                   className="table table-striped table-hover"
// //                   style={{ marginTop: 9 }}
// //                 >
// //                   <thead>
// //                     <tr>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>Sr.No</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>SER No</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>SB No</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>SER Date</th>

// //                       <th style={{ backgroundColor: "#BADDDA" }}>
// //                         Exporter Names
// //                       </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>CHA No</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>DESC</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>No of Pkgs</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>Net Weight</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>
// //                         FOB value in INR
// //                       </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>
// //                         AIRWAY BILL NO
// //                       </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>
// //                         PORT OF DESTINATION
// //                       </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>Console Is</th>
// //                     </tr>
// //                   </thead>
// //                   <thead>
// //                     <tr>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>Total</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>
// //                         {totalExSbNoCount}
// //                       </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>

// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>
// //                         {totalExNoOfPackages + totalEXSubNoOfPackages}
// //                       </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>
// //                         {totalExFobValueInINR}
// //                       </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     </tr>
               
// //                   </thead>
// //                   <tbody>
                
// //                     {exportData.map((exportItem, index) => (
// //                       <tr key={index}>
// //                         <td>{indexCounter++}</td>
// //                         <td>{exportItem.serNo}</td>
// //                         <td>{exportItem.sbNo}</td>
// //                         <td>{formatedDate(exportItem.serDate)}</td>

// //                         <td>{getpartyId[exportItem.nameOfExporter]}</td>
// //                         <td>{exportItem.chaNo}</td>
// //                         <td>{exportItem.descriptionOfGoods}</td>
// //                         <td>{exportItem.noOfPackages}</td>
// //                         <td>{exportItem.grossWeight}</td>
// //                         <td>{exportItem.fobValueInINR}</td>
// //                         <td>{exportItem.airwayBillNo}</td>
// //                         <td>{exportItem.portOfDestination}</td>
// //                         <td>{getConsoleId[exportItem.consoleAgent]}</td>
// //                       </tr>
// //                     ))}

// //                     {exportSubData.map((exportSubItem, index) => (
// //                       <tr key={index}>
// //                         <td>{indexCounter++}</td>
// //                         <td>{exportSubItem.serNo}</td>
// //                         <td>{exportSubItem.sbNo}</td>
// //                         <td>{formatedDate(exportSubItem.serDate)}</td>

// //                         <td>{getpartyId[exportSubItem.exporter]}</td>
// //                         <td>{exportSubItem.chaNo}</td>
// //                         <td>{exportSubItem.remarks}</td>
// //                         <td>{exportSubItem.nop}</td>
// //                         <td>{exportSubItem.gwWeight}</td>
// //                         <td>{exportSubItem.fobValueInINR}</td>
// //                         <td>{exportSubItem.airwayBillNo}</td>
// //                         <td>{exportSubItem.portOfDestination}</td>
// //                         <td>{getConsoleId[exportSubItem.consoleAgent]}</td>
// //                       </tr>
// //                     ))}
// //                   </tbody>
// //                 </Table>

// //                 <div
// //               style={{
// //                 marginTop: 4,
// //                 display: "flex",
// //                 flexDirection: "row",
// //                 justifyContent: "space-between",
// //               }}
// //             >
// //               <p style={{ fontWeight: "bold" }}>
// //                 Total No. SB No: {totalExSbNoCount}
// //               </p>
// //               <p style={{ fontWeight: "bold" }}>
// //                 Total No. of Packages: {totalExNoOfPackages + totalEXSubNoOfPackages}
// //               </p>
// //               <p style={{ fontWeight: "bold" }}>
// //                 Total FOB Value in INR: {totalExFobValueInINR}
// //               </p>
// //             </div>
// //               </div>
// //             </Card>
// //           ) : null}
// //         </div>
// //       )}
      
// //     </div>
// //   );
// // }


// import { redirect } from "react-router-dom";
// import AuthContext from "../Components/AuthProvider";
// import { useNavigate } from "react-router-dom";
// import React, { useEffect, useState, useContext } from "react";
// import "../Components/Style.css";
// import Row from "react-bootstrap/Row";
// import * as XLSX from "xlsx"; // Import XLSX library for creating XLS files
// import Col from "react-bootstrap/Col";
// import { format } from "date-fns";
// import {
//   Button,
//   ButtonToggle,
//   CardBody,
//   Container,
//   Table,
//   ToastHeader,
// } from "reactstrap";
// import Form from "react-bootstrap/Form";
// import Card from "react-bootstrap/Card";
// import DatePicker from "react-datepicker";
// import ipaddress from "../Components/IpAddress";
// import { saveAs } from "file-saver";
// import axios from "axios";
// import dgdcImage from "../Images/report.jpeg";
// import {
//   faFileExcel,
//   faFilePdf,
//   faPrint,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   Document,
//   Page,
//   Text,
//   View,
//   pdf,
//   StyleSheet,
//   PDFViewer,
//   Image,
// } from "@react-pdf/renderer";
// import { toast } from "react-toastify";

// export default function Export_Register() {
//   const navigate = useNavigate();
//   const [startDate, setStartDate] = useState(new Date());
//   const [endDate, setEndDate] = useState(new Date());
//   const [selectedParty, setSelectedParty] = useState("");
//   const [showSubContractDataTable, setSubContractDataTable] = useState(false);
//   const [showExportDataTable, setShowExportDataTable] = useState(false);
//   const [SubImportContractData, setSubImportContractData] = useState([]);
//   const [subContractData, setSubContractData] = useState([]);
//   const [exportData, setExportData] = useState([]);
//   const [exportSubData, setExportSubData] = useState([]);
//   const [partyNameToIdMap, setPartyNameToIdMap] = useState({});
//   const [requestId, setRequestId] = useState("");
//   const [subRequestIdData, setsubRequestIdData] = useState([]);
//   const [showAlert, setShowAlert] = useState(false);
//   const {
//     jwtToken,
//     userId,
//     username,
//     branchId,
//     companyid,
//     role,
//     companyname,
//     branchname,
//     isAuthenticated,
//     login,
//     logout,
//   } = useContext(AuthContext);
//   const styles = StyleSheet.create({
//     // page: {
//     //   paddingTop: 30,
//     //   paddingBottom: 60,
//     //   paddingHorizontal: 30,
//     // },

//     page: {
//       flexDirection: "row", // Set landscape orientation
//       // paddingTop: 9,
//       paddingBottom: 9,
//       paddingHorizontal: 9,
//     },
//     header: {
//       flexDirection: "row",
//       alignItems: "center",
//       marginBottom: 4,
//     },
//     section: {
//       flexGrow: 1, // Take the available space
//       margin: 10, // Add some margin for spacing
//       display: "flex",
//       justifyContent: "center", // Center content horizontally
//       alignItems: "center", // Center content vertically
//     },
//     image: {
//       width: 306,
//       height: 100,
//     },
//     headerText: {
//       flex: 1,
//       textAlign: "center",
//       fontSize: 18,
//       fontWeight: "bold",
//     },
//     centeredText: {
//       fontSize: 14,
//       textAlign: "center", // Center text horizontally
//       paddingBottom: 2,
//       paddingTop: 0,
//     },
//     reportHeader: {
//       flexDirection: "row",
//       justifyContent: "space-between",
//       paddingBottom: 1,
//       fontSize: 10,
//     },
//     table: {
//       width: "100%",
//       borderCollapse: "collapse",
//       marginBottom: 10,
//     },
//     tableRow: {
//       flexDirection: "row",
//       borderBottomWidth: 0.4,
//       borderBottomColor: "black",
//       fontSize: 10,
//       borderRightWidth: 0.4,
//       borderLeftWidth: 0.4,
//       borderLeftColor: "black",
//       borderRightColor: "black",
//       flexWrap: "wrap",
//     },

//     tableCell: {
//       flex: 1,
//       borderWidth: 0.4,
//       borderStyle: "solid",
//       borderColor: "#000",
//       padding:1,
//       fontWeight: "bold",
//       textAlign: "center",
//       fontSize: 7,
//     },
//     tableCellHeader: {
//       fontWeight: "bold",
//       textAlign: "center",
//       fontSize: 9,
//     },
//   });
//   const CustomHeader = () => {
//     return (
//       <View style={styles.header}>
//         <Image src={dgdcImage} style={styles.image} />
//       </View>
//     );
//   };

//   const totalSbNoCount = subContractData.length;
//   // Calculate the sum of noOfPackages and fobValueInINR
//   const totalNoOfPackages = subContractData.reduce(
//     (total, item) => total + item.noOfPackages,
//     0
//   );

//   const totalFobValueInINR = subContractData.reduce(
//     (total, exportItem) => total + exportItem.fobValueInINR,
//     0
//   );

//   //Export
//   const totalExSbNoCount = exportData.length;
//   const totalExNoOfPackages = exportData.reduce(
//     (total, exportItem) => total + exportItem.noOfPackages,
//     0
//   );

//   const totalExFobValueInINR = exportData.reduce(
//     (total, exportItem) => total + exportItem.fobValueInINR,
//     0
//   );
// //Sub NOP total 
// const totalEXSubNoOfPackages = exportSubData.reduce(
//   (total, exportSubItem) => total + exportSubItem.nop,
//   0
// );

// const mergedAllData = [...exportData, ...exportSubData];
//   const formatedDate = (inputDate) => {
//     const date = new Date(inputDate);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${day}/${month}/${year}`;
//   };



//   const formattedDateTime = (inputDate) => {
//     if (!inputDate) {
//         return null; // Return null if inputDate is null or undefined
//     }

//     const date = new Date(inputDate);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     // const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

//     return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
// };
//   // const formatDate = (inputDate) => {
//   //   const date = new Date(inputDate);
//   //   const year = date.getFullYear();
//   //   const month = String(date.getMonth() + 1).padStart(2, "0");
//   //   const day = String(date.getDate()).padStart(2, "0");

//   //   return `${year}-${month}-${day}`;
//   // };

//   const formatDate = (inputDate, setTimeTo) => {
//     const date = new Date(inputDate);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     const hours = setTimeTo === "start" ? "00" : "23";
//     const minutes = setTimeTo === "start" ? "00" : "59";
//     return `${year}-${month}-${day}`;
//   };

//   const formattedStartDate = formatDate(startDate);
//   const formattedEndDate = formatDate(endDate);

//   const StarDate = formatedDate(formattedStartDate);
//   const EndDate = formatedDate(formattedEndDate);

//   console.log("stastdate ", startDate);

//   console.log("enddate ", endDate);
//   // If the user is not authenticated, redirect to the login page
//   useEffect(() => {
//     if (!isAuthenticated) {
//       navigate(
//         "/login?message=You need to be authenticated to access this page."
//       );
//     }
//   }, [isAuthenticated, navigate]);
//   console.log(startDate);

//   const [consoles, setConsoles] = useState([]);
//   const [getConsoleId, setGetConsoleId] = useState({});
//   const fetchConsoleNames = async () => {
//     try {
//       const response = await fetch(
//         `https://${ipaddress}externalParty/console/${companyid}/${branchId}`
//       );
//       const data = await response.json();
//       const consoleMap = {};
//       data.forEach((console) => {
//         consoleMap[console.externaluserId] = console.userName;
//       });
//       setGetConsoleId(consoleMap);
//       setConsoles(data);
//       setPartyNameToIdMap(consoleMap);
//     } catch (error) {
//       console.error("Error fetching party names:", error);
//     }
//   };

//   useEffect(() => {
//     fetchConsoleNames();
//   }, []);

//   const [partys, setPartys] = useState([]);
//   const [getpartyId, setGetpartyId] = useState({});
//   const fetchPartyNames = async () => {
//     try {
//       const response = await fetch(
//         `https://${ipaddress}parties/getAll/${companyid}/${branchId}`
//       );
//       const data = await response.json();
//       const namesMap = {};
//       data.forEach((party) => {
//         namesMap[party.partyId] = party.partyName;
//       });
//       setGetpartyId(namesMap);
//       setPartys(data);
//       setPartyNameToIdMap(namesMap);
//     } catch (error) {
//       console.error("Error fetching party names:", error);
//     }
//   };

//   useEffect(() => {
//     fetchPartyNames();
//   }, []);

//   const handlePartyChange = (event) => {
//     const selectedPartyName = event.target.value;
//     setSelectedParty(selectedPartyName);
//     setShowExportDataTable(false);
//   };

//   const fetchSubContractData = () => {
//     // Make an API request here to fetch the list of airline names based on the provided criteria
//     fetch(
//       `https://${ipaddress}export/findExportAllData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&cartingAgent=${selectedParty}`
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         // Ensure that 'data' is an array before updating the 'airlines' state
//         if (Array.isArray(data) && data.length > 0) {
//           // Update the 'airlines' state with the fetched data
//           setSubContractData(data);
//           setSubContractDataTable("true");
//           setShowAlert(false);
//           setExportData([]);
//           toast.success("Data Found !", {
//             position: "top-center",
//             autoClose: 540, // Duration in milliseconds
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//           });
//           setExportSubData([]);
//         } else {
//           console.error("API response is not an array:", data);
       
//           toast.error("Data Not Found !", {
//             position: "top-center",
//             autoClose: 540, // Duration in milliseconds
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//           });
//       // setShowAlert(true);
        
//       setSubContractDataTable(false);
//       setShowExportDataTable(false);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching airline names:", error);
//         setShowAlert(true);
//       });
//   };
// console.log('subContractData ',subContractData);
//   const fetchExportData = () => {
//     fetch(
//       `https://${ipaddress}export/findExportData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         // Ensure that 'data' is an array before updating the 'airlines' state
//         if (Array.isArray(data) && data.length > 0) {
//           // Update the 'airlines' state with the fetched data
//           setExportData(data);
//           setShowExportDataTable(true);
//           setShowAlert(false);
//           setSubContractData([]);
//           setSubContractDataTable(false);
//           toast.success("Data Found !", {
//             position: "top-center",
//             autoClose: 540, // Duration in milliseconds
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//           });
//         } else {
//           console.error("API response is not an array:", data);
//           toast.error("Data Not Found !", {
//             position: "top-center",
//             autoClose: 540, // Duration in milliseconds
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//           });


//           // setShowExportDataTable(false);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching airline names:", error);
       
//       });
//   };

//   const fetchExportSubData = () => {
//     fetch(
//       `https://${ipaddress}exportsub/findExportSubAllData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         // Ensure that 'data' is an array before updating the 'airlines' state
//         if (Array.isArray(data) && data.length > 0) {
//           // Update the 'airlines' state with the fetched data
//           setExportSubData(data);
//           setShowExportDataTable(true);
//           setShowAlert(false);
//           setSubContractData([]);
          
//         } else {
//           console.error("API response is not an array:", data);
//           setShowAlert(true);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching airline names:", error);
//         setShowAlert(true);
//       });
//   };

 
//   const handleSearch = () => {

//     if (startDate && endDate && !selectedParty) {
//       fetchExportData();
//       fetchExportSubData();
//     } else if (startDate && endDate && selectedParty) {
//       fetchSubContractData();
//     }
//   };

//   const handlePrint = () => {
//     const isoDate = new Date().toISOString();
//     const date = new Date(isoDate);
//     const day = date.getDate().toString().padStart(2, "0");
//     const month = (date.getMonth() + 1).toString().padStart(2, "0");
//     const year = date.getFullYear().toString();

//     let printWindow = window.open("", "_blank");
//     printWindow.document.open();

//     const recordsPerPage = 25;
//     const totalRecords = subContractData.length;

//     let startIndex = 0; // Initialize the starting index for each page
//     let currentPageNumber = 1; // Initialize the current page number

//     for (
//       let pageIndex = 0;
//       pageIndex < Math.ceil(totalRecords / recordsPerPage);
//       pageIndex++
//     ) {
//       // Slice the subContractData array to get 10 records for the current page
//       const pageRecords = subContractData.slice(
//         startIndex,
//         startIndex + recordsPerPage
//       );

//       // Check if it's the last page
//       const isLastPage =
//         pageIndex === Math.ceil(totalRecords / recordsPerPage) - 1;

//       printWindow.document.write(`
//         <!DOCTYPE html>
//         <html>
//         <head>
//         <title> Export Report Register</title>
//         <style>
//           @page {
//             margin: 1cm;
//             /* Adjust this value to position the header correctly */
//           }
  
//           .printable-area {
//             font-family: Arial, sans-serif;
//             page-break-before: always;
//           }
  
//           table {
//             width: 100%;
//             border-collapse: collapse;
//           }
  
//           td {
//             border: 1px solid #dddddd;
//             text-align: center;
//             padding: 1px;
//             font-size: 10px;
           
//         }
    
//         th {
//           border: 1px solid #dddddd;
//             background-color: #f2f2f2;
//             text-align: center;
//             font-size: 12px;
//         }
//           .container {
//             display: flex;
//             justify-content: space-between;
//           }
  
//           .left-column {
//             width: 70%; /* Adjust the width as needed */
//           }
  
//           .page-break {
//             page-break-before: always; /* This will force a page break before the element */
//           }
  
//           .right-column {
//             width: 30%; /* Adjust the width as needed */
//             text-align: right; /* Align text to the right */
//           }
  
//           .header img {
//             max-width: auto; /* Ensure the image doesn't exceed the page width */
//             max-height: auto; /* Adjust the maximum image height as needed */
//           }
  
//           .report-header {
//             display: flex;
//             justify-content: space-between;
//             margin-bottom: 5px;
//           }
  
//           #page-header {
//             position: static;
//             top: 0;
//             left: 0;
//             right: 0;
//             text-align: center;
//           }
//         </style>
//         </head>
//         <body>
//         <div id="page-header">
//         <img src="${dgdcImage}" alt="Header Image" style="max-width: 306px; display: block; margin: 0 auto;">
//         <div style="text-align: center;">
//         <div style="font-size:14px;font-weight:bold;">Export Register Report - Page ${currentPageNumber}</div><br/>
//         </div>
//         </div>
//         <div class="report-header">
//         <div></div>
//         <div style="font-size:12px;">FOR THE PERIOD: ${StarDate} To ${EndDate}</div>
//         <div style="font-size:12px;"> REPORT DATE: ${format(new Date(), "dd/MM/yyyy HH:mm")} </div>
//         </div>
//         <table>
//         <thead>
//           <tr>
//           <th style="background-color: #BADDDA">Sr.No</th>
//           <th style="background-color: #BADDDA">SER No</th>
//           <th style="background-color: #BADDDA">SB No</th>
//           <th style="background-color: #BADDDA">SER Date</th>
      
//           <th style="background-color: #BADDDA">Exporter Names</th>
//           <th style="background-color: #BADDDA">CHA No</th>
//           <th style="background-color: #BADDDA">DESC</th>
//           <th style="background-color: #BADDDA">NOP</th>
//           <th style="background-color: #BADDDA">Net Weight</th>
//           <th style="background-color: #BADDDA">FOB value in INR</th>
//           <th style="background-color: #BADDDA">AIRWAY BILL NO</th>
//           <th style="background-color: #BADDDA">PORT OF DESTINATION</th>
//           <th style="background-color: #BADDDA">Console Is</th>
//           </tr>
//         </thead>
//         <tbody>
//         ${pageRecords
//           .map(
//             (item, index) => `
//               <tr>
//               <td>${startIndex + index + 1}</td>
//               <td>${item.serNo || ''}</td>
//               <td>${item.sbNo || ''}</td>
//               <td>${formatedDate(item.serDate) || ''}</td>
         
//               <td>${getpartyId[item.nameOfExporter] || ''}</td>
//               <td>${item.chaNo || ''}</td>
//               <td>${item.descriptionOfGoods || '' }</td>
//               <td>${item.noOfPackages || ''}</td>
//               <td>${item.grossWeight || ''}</td>
//               <td>${item.fobValueInINR || ''}</td>
//               <td>${item.airwayBillNo || ''}</td>
//               <td>${item.portOfDestination || ''}</td>
//               <td>${getConsoleId[item.cartingAgent] || ''}</td>
//               </tr>
//             `
//           )
//           .join("")}
//         </tbody>
//         </table>
//         </div>
//         </body>
//         </html>
//       `);

//       // Check if it's not the last page, then add a page break
//       if (!isLastPage) {
//         printWindow.document.write('<div class="page-break"></div>');
//       } else {
//         printWindow.document.write(`
//           <table>
//           <tbody>
//           <tr>
//           <td style="font-weight: bold; background-color: #BADDDA; width: 8px;">Total</td>
//           <td style="background-color: #BADDDA; width: 5px;"></td>
         
//           <td style="background-color: #BADDDA; width: 4px;">${totalSbNoCount}</td>
//           <td style="background-color: #BADDDA; width: 5px;"></td>
      
//           <td style="background-color: #BADDDA; width: 15px;"></td>
//           <td style="background-color: #BADDDA; width: 3px;"></td>
//           <td style="background-color: #BADDDA; width: 15px;"></td>
//           <td style="background-color: #BADDDA; width: 2px;">${totalNoOfPackages}</td>
//           <td style="background-color: #BADDDA; width: 3px;"></td>
//           <td style="background-color: #BADDDA; width: 5px;">${totalFobValueInINR}</td>
//           <td style="background-color: #BADDDA; width: 20px;"></td>
//           <td style="background-color: #BADDDA; width: 10px;"></td>
//           <td style="background-color: #BADDDA; width: 10px;"></td>
//           </tr>
//           </tbody>
//           </table>
//         `);
//       }

//       // Update the starting index for the next page
//       startIndex += recordsPerPage;
//       currentPageNumber++;
//     }

//     printWindow.document.close();
//     printWindow.print();
//     printWindow.onafterprint = () => printWindow.close();
//   };

//   const handleExportPrint = () => {
//     const isoDate = new Date().toISOString();
//     const date = new Date(isoDate);
//     const day = date.getDate().toString().padStart(2, "0");
//     const month = (date.getMonth() + 1).toString().padStart(2, "0");
//     const year = date.getFullYear().toString();
  
//     let printWindow = window.open("", "_blank");
//     printWindow.document.open();
  
//     const recordsPerPage = 25;
//     const totalRecords = exportData.length + exportSubData.length;
  
//     let startIndex = 0; // Initialize the starting index for each page
//     let currentPageNumber = 1; // Initialize the current page number
  
//     // Function to generate the table HTML for a set of records
//     const generateTableHTML = (records) => {
//       return `
//         <table>
//           <thead>
//             <tr>
//               <th style="background-color: #BADDDA">Sr.No</th>
//               <th style="background-color: #BADDDA">SER No</th>
//               <th style="background-color: #BADDDA">SB No</th>
//               <th style="background-color: #BADDDA">SER Date</th>
//               <th style="background-color: #BADDDA">Exporter Names</th>
//               <th style="background-color: #BADDDA">CHA No</th>
//               <th style="background-color: #BADDDA">DESC</th>
//               <th style="background-color: #BADDDA">NOP</th>
//               <th style="background-color: #BADDDA">Net Weight</th>
//               <th style="background-color: #BADDDA">FOB value in INR</th>
//               <th style="background-color: #BADDDA">AIRWAY BILL NO</th>
//               <th style="background-color: #BADDDA">PORT OF DESTINATION</th>
//               <th style="background-color: #BADDDA">Console Is</th>
//             </tr>
//           </thead>
//           <tbody>
//             ${records
//               .map(
//                 (item, index) => `
//                 <tr>
//                 <td>${startIndex + index + 1}</td>
//                 <td>${item.serNo || ''}</td>
//                 <td>${item.sbNo || ''}</td>
//                 <td>${formatedDate(item.serDate) || ''}</td>
//                 <td>${getpartyId[item.nameOfExporter || item.exporter] || ''}</td>
//                 <td>${item.chaNo || ''}</td>
//                 <td>${item.descriptionOfGoods || item.remarks || ''}</td>
//                 <td>${item.noOfPackages || item.nop || ''}</td>
//                 <td>${item.grossWeight || item.gwWeight || ''}</td>
//                 <td>${item.fobValueInINR || ''}</td>
//                 <td>${item.airwayBillNo || ''}</td>
//                 <td>${item.portOfDestination || ''}</td>
//                 <td>${getConsoleId[item.cartingAgent] || ''}</td>
//               </tr>
//                 `
//               )
//               .join("")}
//           </tbody>
//         </table>
//       `;
//     };
  
//     for (
//       let pageIndex = 0;
//       pageIndex < Math.ceil(totalRecords / recordsPerPage);
//       pageIndex++
//     ) {
//       // Merge exportData and exportSubData arrays for the current page
//       const pageRecords = [...exportData, ...exportSubData].slice(
//         startIndex,
//         startIndex + recordsPerPage
//       );
  
//       // Check if it's the last page
//       const isLastPage =
//         pageIndex === Math.ceil(totalRecords / recordsPerPage) - 1;
  
//       printWindow.document.write(`
//         <!DOCTYPE html>
//         <html>
//         <head>
//         <title> Export Report Register</title>
//         <style>
//           @page {
//             margin: 1cm;
//             /* Adjust this value to position the header correctly */
//           }
  
//           .printable-area {
//             font-family: Arial, sans-serif;
//             page-break-before: always;
//           }
  
//           table {
//             width: 100%;
//             border-collapse: collapse;
//           }
  
//           td {
//             border: 1px solid #dddddd;
//             text-align: center;
//             padding: 1px;
//             font-size: 10px;
//         }
    
//         th {
//           border: 1px solid #dddddd;
//             background-color: #f2f2f2;
//             text-align: center;
//             font-size: 12px;
//         }
  
//           .container {
//             display: flex;
//             justify-content: space-between;
//           }
  
//           .left-column {
//             width: 70%; /* Adjust the width as needed */
//           }
  
//           .page-break {
//             page-break-before: always; /* This will force a page break before the element */
//           }
  
//           .right-column {
//             width: 30%; /* Adjust the width as needed */
//             text-align: right; /* Align text to the right */
//           }
  
//           .header img {
//             max-width: auto; /* Ensure the image doesn't exceed the page width */
//             max-height: auto; /* Adjust the maximum image height as needed */
//           }
  
//           .report-header {
//             display: flex;
//             justify-content: space-between;
//             margin-bottom: 5px;
//           }
  
//           #page-header {
//             position: static;
//             top: 0;
//             left: 0;
//             right: 0;
//             text-align: center;
//           }
//         </style>
//         </head>
//         <body>
//         <div id="page-header">
//           <img src="${dgdcImage}" alt="Header Image" style="max-width: 306px; display: block; margin: 0 auto;">
//           <div style="text-align: center;">
//             <div style="font-size:14px;font-weight:bold;">Export Register Report - Page ${currentPageNumber}</div><br/>
//           </div>
//         </div>
//         <div class="report-header">
//           <div></div>
//           <div style="font-size:12px;">FOR THE PERIOD: ${StarDate} To ${EndDate}</div>
//           <div style="font-size:12px;"> REPORT DATE: ${format(new Date(), "dd/MM/yyyy HH:mm")} </div>
//         </div>
//         ${generateTableHTML(pageRecords)}
//         </body>
//         </html>
//       `);
  
//       // Check if it's not the last page, then add a page break
//       if (!isLastPage) {
//         printWindow.document.write('<div class="page-break"></div>');
//       } else {
//         printWindow.document.write(`
//           <table>
//           <tbody>
//           <tr>
//           <td style="font-weight: bold;background-color: #BADDDA; width: 8px;">Total</td>
//           <td style="background-color: #BADDDA; width: 8px;"></td>
//           <td style="background-color: #BADDDA; width: 8px;">${totalExSbNoCount}</td>
//           <td style="background-color: #BADDDA; width: 8px;"></td>
//           <td style="background-color: #BADDDA; width: 8px;"></td>
//           <td style="background-color: #BADDDA; width: 8px;"></td>
//           <td style="background-color: #BADDDA; width: 8px;"></td>
//           <td style="background-color: #BADDDA; width: 8px;">${totalEXSubNoOfPackages + totalExNoOfPackages}</td>
//           <td style="background-color: #BADDDA; width: 8px;"></td>
//           <td style="background-color: #BADDDA; width: 8px;">${totalExFobValueInINR}</td>
//           <td style="background-color: #BADDDA; width: 8px;"></td>
//           <td style="background-color: #BADDDA; width: 8px;"></td>
//           <td style="background-color: #BADDDA; width: 8px;"></td>
//           </tr>
//           </tbody>
//           </table>
//         `);
//       }
  
//       // Update the starting index for the next page
//       startIndex += recordsPerPage;
//       currentPageNumber++;
//     }
  
//     printWindow.document.close();
//     printWindow.print();
//     printWindow.onafterprint = () => printWindow.close();
//   };
//   const handleExportPDFDownload = async () => {
//     const pdfBlob = await pdf(
//       <MyExportPDFDocument
//         {...{
//           StarDate,
//           EndDate,
//           selectedParty,
//           exportSubData,
//           exportData,
//           totalEXSubNoOfPackages,
//           totalExFobValueInINR,
//           totalExSbNoCount,
//           totalExNoOfPackages
//         }}        
//       />
//     ).toBlob();
//     saveAs(pdfBlob, "Export_Register.pdf");
//   };

//   const handleExportPDFSHOW = async () => {
//     const pdfBlob = await pdf(
//       <MyExportPDFDocument
//         {...{
//           StarDate,
//           EndDate,
//           selectedParty,
//           exportSubData,
//           exportData,
//           totalEXSubNoOfPackages,
//           totalExFobValueInINR,
//           totalExSbNoCount,
//           totalExNoOfPackages
//         }}
//       />
//     ).toBlob();
//     const fileURL = URL.createObjectURL(pdfBlob);
//     const newTab = window.open();
//     newTab.document.write(
//         `<iframe width='100%' height='100%' src='${fileURL}'></iframe>`
//     );
//   };





//   const PAGE_BREAK_ROWS_PDF = 12;

//   const MyExportPDFDocument = ({
//     StarDate,
//     EndDate,
//     exportData,
//     exportSubData, // Include exportSubData as a prop
//     totalExSbNoCount,
//     totalExNoOfPackages,
//     totalExFobValueInINR,
//   }) =>
//   {
//     if (!exportData || !Array.isArray(exportData) || !exportSubData || !Array.isArray(exportSubData)) {
//       // Handle the case when data is not available
//       return null;
//     }
  
//   const mergedData = [...exportData, ...exportSubData];
//   return(
//     <Document>
//       {Array.from({
//         length: Math.ceil(mergedData.length / PAGE_BREAK_ROWS_PDF),
//       }).map((_, pageIndex) => (
//         <Page
//           key={pageIndex}
//           size="A4"
//           orientation="landscape"
//           style={styles.page}
//         >
//           <View style={styles.section}>
//             {/* Your header content */}
//             <View style={styles.header}>
//               <CustomHeader />
//             </View>
//             <View style={[styles.centeredText, { fontWeight: "bold" }]}>
//               <Text style={[styles.centeredText, { fontWeight: "bold" }]}>
//                 EXPORT REGISTER
//               </Text>
//             </View>
//             <View style={styles.reportHeader}>
//               <Text></Text>
//               <Text>
//                 FOR THE PERIOD :{StarDate} TO {EndDate}{" "}
//               </Text>
//               <Text style={{ marginLeft: 4 }}>
//                 REPORT DATE: {format(new Date(), "dd/MM/yyyy HH:mm")}
//               </Text>
//             </View>
  
//             <View style={styles.table}>
//               <View style={styles.tableRow}>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   Sr.No
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   SER No
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   SB No
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   IN Date
//                 </Text>

//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   SER Date
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   Exporter Names
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   CHA No
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>DESC</Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader ,{width:45}]}>
//                 NOP
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   Net Weight
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   FOB value in INR
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   AIRWAY BILL NO
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   PORT OF DESTINATION
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   Console Is
//                 </Text>
//               </View>
//               {[...exportData, ...exportSubData] // Merge exportData and exportSubData
//                 .slice(
//                   pageIndex * PAGE_BREAK_ROWS_PDF,
//                   (pageIndex + 1) * PAGE_BREAK_ROWS_PDF
//                 )
//                 .map((item, index) => (
//                   <View style={styles.tableRow} key={index}>
//                     <Text style={styles.tableCell}>
//                       {index + 1 + pageIndex * PAGE_BREAK_ROWS_PDF}
//                     </Text>
//                     <Text style={styles.tableCell}>{item.serNo || ""}</Text>
//                     <Text style={styles.tableCell}>{item.sbNo}</Text>
//                     <Text style={styles.tableCell}>
//                       {formattedDateTime(item.approvedDate) || ""}
//                     </Text>

//                     <Text style={styles.tableCell}>
//                       {formatedDate(item.serDate) || ""}
//                     </Text>
//                     <Text style={styles.tableCell}>
//                       {getpartyId[item.nameOfExporter || item.exporter] || ""}
//                     </Text>
//                     <Text style={styles.tableCell}>{item.chaNo}</Text>
//                     <Text style={styles.tableCell}>
//                       {item.descriptionOfGoods || item.remarks || ""}
//                     </Text>
//                     <Text style={[styles.tableCell  ,{width:45}]}>
//                       {item.noOfPackages || item.nop || ""}
//                     </Text>
//                     <Text style={styles.tableCell}>
//                       {item.grossWeight || item.gwWeight || ""}
//                     </Text>
//                     <Text style={styles.tableCell}>{item.fobValueInINR}</Text>
//                     <Text style={styles.tableCell}>{item.airwayBillNo}</Text>
//                     <Text style={styles.tableCell}>
//                       {item.portOfDestination || ""}
//                     </Text>
//                     <Text style={styles.tableCell}>
//                       {getConsoleId[item.cartingAgent] || ""}
//                     </Text>
//                   </View>
//                 ))}
//               {pageIndex ===
//                 Math.ceil(mergedData.length / PAGE_BREAK_ROWS_PDF) - 1 && (
//                 <View style={styles.tableRow}>
//                   <Text style={styles.tableCell}>Total</Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}>{totalExSbNoCount}</Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={[styles.tableCell,{width:45}]}>
//                     {totalExNoOfPackages}
//                   </Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}>{totalExFobValueInINR}</Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}></Text>
//                 </View>
//               )}
//             </View>
//           </View>
//         </Page>
//       ))}

//     </Document>
//   );
// };
  
//   const handlePDFDownload = async () => {
//     const pdfBlob = await pdf(
//       <MyPDFDocument
//         {...{
//           StarDate,
//           EndDate,
//           selectedParty,
//           subContractData,
//           totalFobValueInINR,
//           totalNoOfPackages,
//           totalSbNoCount,
//         }}
//       />
//     ).toBlob();
//     saveAs(pdfBlob, "Export_Register.pdf");
//   };

//   const handlePDFSHOW = async () => {
//     const pdfBlob = await pdf(
//       <MyPDFDocument
//         {...{
//           StarDate,
//           EndDate,
//           selectedParty,
//           subContractData,
//           totalFobValueInINR,
//           totalNoOfPackages,
//           totalSbNoCount,
//         }}
//       />
//     ).toBlob();
//     const fileURL = URL.createObjectURL(pdfBlob);
//     const newTab = window.open();
//     newTab.document.write(
//         `<iframe width='100%' height='100%' src='${fileURL}'></iframe>`
//     );
//   };







//   const handleReset =()=>{
//     setStartDate(new Date());
//     setEndDate(new Date());
//     setSelectedParty("");
//     setExportSubData([]);
//     setExportData([]);
//     setShowExportDataTable(false);
//       }

//   function fetchCompanyName(companyId) {
//     // Make an Axios GET request to retrieve the company name based on companyId
//     return axios
//       .get(`https://${ipaddress}export/findCompanyname/${companyId}`)
//       .then(function (response) {
//         return response.data; // Return the retrieved company name
//       })
//       .catch(function (error) {
//         console.error("Error fetching company name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }
//   function fetchPartyName(companyId, branchId, partyId) {
//     // Make an Axios GET request to retrieve the company name based on companyId
//     return axios
//       .get(
//         `https://${ipaddress}export/findPartyName/${companyId}/${branchId}/${partyId}`
//       )
//       .then(function (response) {
//         return response.data; // Return the retrieved company name
//       })
//       .catch(function (error) {
//         console.error("Error fetching company name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }

//   function fetchExporterName(companyId, branchId, partyId) {
//     // Make an Axios GET request to retrieve the company name based on companyId
//     return axios
//       .get(
//         `https://${ipaddress}export/findPartyName/${companyId}/${branchId}/${partyId}`
//       )
//       .then(function (response) {
//         return response.data; // Return the retrieved company name
//       })
//       .catch(function (error) {
//         console.error("Error fetching company name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }

//   function fetchConsoleName(companyId, branchId, externaluserId) {
//     // Make an Axios GET request to retrieve the company name based on companyId
//     return axios
//       .get(
//         `https://${ipaddress}export/findConsoleName/${companyId}/${branchId}/${externaluserId}`
//       )
//       .then(function (response) {
//         return response.data; // Return the retrieved company name
//       })
//       .catch(function (error) {
//         console.error("Error fetching company name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }
//   function fetchBranchName(companyId, branchId) {
//     // Make an Axios GET request to retrieve the branch name based on branchId
//     return axios
//       .get(
//         `https://${ipaddress}export/findBranchName/${companyId}/${branchId}`
//       )
//       .then(function (response) {
//         return response.data; // Return the retrieved branch name
//       })
//       .catch(function (error) {
//         console.error("Error fetching branch name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }

//   const handleXLSDownload = async () => {
//     const modifiedReordList = await Promise.all(
//       subContractData.map(async (item, index) => {
//         // const companyname = await fetchCompanyName(item.companyId);
//         // const branchname = await fetchBranchName(item.companyId, item.branchId);
//         const partyName = await fetchPartyName(
//           item.companyId,
//           item.branchId,
//           item.nameOfExporter
//         );
//         const userName = await fetchConsoleName(
//           item.companyId,
//           item.branchId,
//           item.consoleAgent
//         );

//         return {
//           "Sr.No": index + 1,
//           // "Company Name": companyname,
//           // "Branch Name": branchname,
//           "SER No": item.serNo,
//           "SB No": item.sbNo,
//           "SER Date": formatedDate(item.serDate),
//           "Parcle Type": " ", // Modify this to the actual field name
//           "Exporter Names": getpartyId[item.nameOfExporter],
//           "CHA No": item.chaNo,
//           DESC: item.descriptionOfGoods,
//           "No of Pkgs": item.noOfPackages,
//           "Net Weight": item.grossWeight,
//           "FOB value in INR": item.fobValueInINR,
//           "AIRWAY BILL NO": item.airwayBillNo,
//           "PORT OF DESTINATION": item.portOfDestination,
//           "Console Is": getConsoleId[item.consoleAgent],
//           // Add more fields as needed
//         };
//       })
//     );

//     const workbook = XLSX.utils.book_new();
//     const worksheet = XLSX.utils.json_to_sheet(modifiedReordList);

//     // Add headers for all fields
//     const headers = Object.keys(modifiedReordList[0]);
//     headers.forEach((header, index) => {
//       worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
//         t: "s",
//         v: header,
//         s: { font: { bold: true } }, //
//       };
//     });

//     // Set column widths based on data
//     const colWidths = headers.map((header) => ({
//       wch: header.length + 2, // You can adjust the width as needed
//     }));

//     worksheet["!cols"] = colWidths;

//     XLSX.utils.book_append_sheet(workbook, worksheet, "export_transaction");
//     const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
//     const blob = new Blob([s2ab(xlsFile)], {
//       type: "application/vnd.ms-excel",
//     });
//     saveAs(blob, "Export_Register.xls");
//   };

//   const s2ab = (s) => {
//     const buf = new ArrayBuffer(s.length);
//     const view = new Uint8Array(buf);
//     for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
//     return buf;
//   };


//   const handleExportXLSDownload = async () => {
//     const modifiedReordList = await Promise.all(
//       mergedAllData.map(async (item, index) => {
//         // const companyname = await fetchCompanyName(item.companyId);
//         // const branchname = await fetchBranchName(item.companyId, item.branchId);
//         const partyName = await fetchPartyName(
//           item.companyId,
//           item.branchId,
//           item.nameOfExporter
//         );

//         const exporterName = await fetchExporterName(
//           item.companyId,
//           item.branchId,
//           item.exporter
//         );
//         const userName = await fetchConsoleName(
//           item.companyId,
//           item.branchId,
//           item.consoleAgent
//         );

//         return {
//           "Sr.No": index + 1,
//           // "Company Name": companyname,
//           // "Branch Name": branchname,
//           "SER No": item.serNo ||  item.serNo,
//           "SB No": item.sbNo,
//           "SER Date": formatedDate(item.serDate) ||formatedDate(item.serDate),
//           "Parcle Type": " ", // Modify this to the actual field name
//           "Exporter Names": getpartyId[item.nameOfExporter]  || getpartyId[item.exporter],
//           "CHA No": item.chaNo,
//           "DESC": item.descriptionOfGoods || item.remarks,
//           "No of Pkgs": item.noOfPackages || item.nop,
//           "Net Weight": item.grossWeight || item.gwWeight,
//           "FOB value in INR": item.fobValueInINR,
//           "AIRWAY BILL NO": item.airwayBillNo,
//           "PORT OF DESTINATION": item.portOfDestination,
//           "Console Is": getConsoleId[item.consoleAgent],
//         };
//       })
//     );

//     const workbook = XLSX.utils.book_new();
//     const worksheet = XLSX.utils.json_to_sheet(modifiedReordList);

//     // Add headers for all fields
//     const headers = Object.keys(modifiedReordList[0]);
//     headers.forEach((header, index) => {
//       worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
//         t: "s",
//         v: header,
//         s: { font: { bold: true } }, //
//       };
//     });

//     // Set column widths based on data
//     const colWidths = headers.map((header) => ({
//       wch: header.length + 2, // You can adjust the width as needed
//     }));

//     worksheet["!cols"] = colWidths;

//     XLSX.utils.book_append_sheet(workbook, worksheet, "export_report");
//     const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
//     const blob = new Blob([s2ab(xlsFile)], {
//       type: "application/vnd.ms-excel",
//     });
//     saveAs(blob, "Export_Register.xls");
//   };

  
//   const MyPDFDocument = ({
//     totalNoOfPackages,
//     subContractData,
//     totalSbNoCount,
//     totalFobValueInINR,
//   }) => (
//     <Document>
//       {Array.from({
//         length: Math.ceil(subContractData.length / PAGE_BREAK_ROWS_PDF),
//       }).map((_, pageIndex) => (
//         <Page
//           key={pageIndex}
//           size="A4"
//           orientation="landscape"
//           style={{ ...styles.page }}
//         >
//           <View style={styles.section}>
//             {/* Your header content */}
//             <View style={styles.header}>
//               <Image src={dgdcImage} style={styles.image} />
//             </View>
//             <View style={[styles.centeredText, { fontWeight: "bold" }]}>
//               <Text style={[styles.centeredText, { fontWeight: "bold" }]}>
//                 EXPORT REGISTER
//               </Text>
//             </View>
//             <View style={styles.reportHeader}>
//               <Text></Text>
//               <Text>
//                 FOR THE PERIOD :{StarDate} TO {EndDate}{" "}
//               </Text>
//               <Text style={{ marginLeft: 4 }}>
//                 REPORT DATE: {format(new Date(), "dd/MM/yyyy HH:mm")}
//               </Text>
//             </View>

//             {/* Your table */}
//             <View style={styles.table}>
//               <View style={styles.tableRow}>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   Sr.No
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   SER No
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   SB No
//                 </Text>

//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   IN Date
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   SER Date
//                 </Text>
//                 {/* <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   Parcel Type
//                 </Text> */}
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   Exporter Names
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   CHA No
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   DESC
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                  NOP
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   Net Weight
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   FOB value in INR
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   AIRWAY BILL NO
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   PORT OF DESTINATION
//                 </Text>
//                 <Text style={[styles.tableCell, styles.tableCellHeader]}>
//                   Console Is
//                 </Text>
//               </View>
//               {subContractData
//                 .slice(
//                   pageIndex * PAGE_BREAK_ROWS_PDF,
//                   (pageIndex + 1) * PAGE_BREAK_ROWS_PDF
//                 )
//                 .map((item, index) => (
//                   <View style={styles.tableRow} key={index}>
//                     <Text style={styles.tableCell}>
//                       {index + 1 + pageIndex * PAGE_BREAK_ROWS_PDF}
//                     </Text>
//                     <Text style={styles.tableCell}>{item.serNo}</Text>
//                     <Text style={styles.tableCell}>{item.sbNo}</Text>
//                     <Text style={styles.tableCell}>
//                       {formattedDateTime(item.approvedDate) || ""}
//                     </Text>
//                     <Text style={styles.tableCell}>
//                       {formatedDate(item.serDate)}
//                     </Text>
//                     {/* <Text style={styles.tableCell}>Parcel Type Value</Text> */}
//                     <Text style={styles.tableCell}>
//                       {getpartyId[item.nameOfExporter]}
//                     </Text>
//                     <Text style={styles.tableCell}>{item.chaNo}</Text>
//                     <Text style={styles.tableCell}>
//                       {item.descriptionOfGoods}
//                     </Text>
//                     <Text style={styles.tableCell}>{item.noOfPackages}</Text>
//                     <Text style={styles.tableCell}>{item.grossWeight}</Text>
//                     <Text style={styles.tableCell}>{item.fobValueInINR}</Text>
//                     <Text style={styles.tableCell}>{item.airwayBillNo}</Text>
//                     <Text style={styles.tableCell}>
//                       {item.portOfDestination}
//                     </Text>
//                     <Text style={styles.tableCell}>
//                       {getConsoleId[item.cartingAgent]}
//                     </Text>
//                   </View>
//                 ))}
//               {/* Display the "Total" row only on the last page */}
//               {pageIndex ===
//                 Math.ceil(subContractData.length / PAGE_BREAK_ROWS_PDF) - 1 && (
//                 <View style={styles.tableRow}>
//                   <Text style={styles.tableCell}>Total</Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}>{totalSbNoCount}</Text>
//                   {/* <Text style={styles.tableCell}></Text> */}
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}>{totalNoOfPackages}</Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}>{totalFobValueInINR}</Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}></Text>
//                   <Text style={styles.tableCell}></Text>
//                 </View>
//               )}
//             </View>
//           </View>
//         </Page>
//       ))}
//     </Document>
//   );

//   let indexCounter = 1;


//   //Export Register

//  const formatDateTime2 = (value) => {
//   if (!value) {
//     return "";
//   }
//   const date = new Date(value);
//   const day = String(date.getDate()).padStart(2, "0");
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const year = date.getFullYear();
//   const hours = String(date.getHours()).padStart(2, "0");
//   const minutes = String(date.getMinutes()).padStart(2, "0");
//   const seconds = String(date.getSeconds()).padStart(2, "0");
//   return `${day}/${month}/${year} `;
// };
// const getExcel = (expdata,expsubdata) => {
//   const filename = `Export_Register.xlsx`;
//   const dataToSend = {
//     expdata,
//     expsubdata
// };
 
//   axios.post(`https://${ipaddress}export/registerexcel`,dataToSend,{ responseType: 'blob' })
//     .then(async (response) => {
//       const blob = new Blob([response.data], { type: response.headers['content-type'] });

//       // Create a temporary URL for the blob
//       const url = window.URL.createObjectURL(blob);

//       // Create a link element to trigger the download
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = filename;
//       document.body.appendChild(a);
//       a.click();

//       // Clean up
//       window.URL.revokeObjectURL(url);
//       document.body.removeChild(a);
//     })
//     .catch((error) => {
//       toast.error("Something went wrong", {
//         autoClose: 700
//       });
//     });
// }


// const getExcel1 = (expdata) => {
//   const filename = `Export_Register.xlsx`;


 
//   axios.post(`https://${ipaddress}export/registerexcel1`,expdata,{ responseType: 'blob' })
//     .then(async (response) => {
//       const blob = new Blob([response.data], { type: response.headers['content-type'] });

//       // Create a temporary URL for the blob
//       const url = window.URL.createObjectURL(blob);

//       // Create a link element to trigger the download
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = filename;
//       document.body.appendChild(a);
//       a.click();

//       // Clean up
//       window.URL.revokeObjectURL(url);
//       document.body.removeChild(a);
//     })
//     .catch((error) => {
//       toast.error("Something went wrong", {
//         autoClose: 700
//       });
//     });
// }
//   return (
//     <div>
//       <h4 style={{ marginLeft: 18, marginTop: 25 }}>Export Register Report</h4>
//       {/* style={{marginTop:25,marginRight:1,marginLeft:18,padding: 8}} */}
//       <Card
//         style={{ marginTop: 25, marginRight: 18, marginLeft: 18, padding: 8 }}
//       >
//         <Container>
//           <Form>
//             <Row>
//               <Col md={3}>
//                 <Form.Group>
//                   <Form.Label className="inputhead">From Date</Form.Label>
//                   <div className="input-group">
//                     <DatePicker
//                       selected={startDate}
//                       onChange={(date) => setStartDate(date)}
//                       dateFormat="dd/MM/yyyy" // You can customize the date format
//                       name="startDate"
//                       required
//                       className="form-control border-right-0 inputField"
//                       customInput={<input style={{ width: "18vw" }} />}
//                     />
//                   </div>
//                 </Form.Group>
//               </Col>
//               <Col md={3}>
//                 <Form.Group>
//                   <Form.Label className="inputhead">To Date</Form.Label>
//                   <div className="input-group">
//                     <DatePicker
//                       selected={endDate}
//                       onChange={(date) => setEndDate(date)}
//                       dateFormat="dd/MM/yyyy" // You can customize the date format
//                       name="endDate"
//                       required
//                       className="form-control border-right-0 inputField"
//                       customInput={<input style={{ width: "18vw" }} />}
//                     />
//                   </div>{" "}
//                 </Form.Group>
//               </Col>
//               <Col md={3}>
//                 <label htmlFor="company" className="inputhead">
//                   Console
//                 </label>
//                 <select
//                   name="company"
//                   id="dw1"
//                   className=""
//                   onChange={handlePartyChange}
//                   value={selectedParty}
//                 >
//                   <option value="">Select</option>
//                   {consoles.map((party) => (
//                     <option
//                       key={party.externaluserId}
//                       value={party.externaluserId}
//                     >
//                       {party.userName}
//                     </option>
//                   ))}
//                 </select>
//               </Col>
//               <Col md={3}>
//                 <div style={{ marginTop: 30 }}>
//                   <Button
//                     color="success"
//                     outline
//                     style={{ marginRight: 18 }}
//                     onClick={handleSearch}
//                   >
//                     SEARCH
//                   </Button>

//                   <Button onClick={handleReset} color="warning" outline>
//                     RESET
//                   </Button>
//                 </div>
//               </Col>
//             </Row>
//           </Form>
//         </Container>
//       </Card>
//       {showSubContractDataTable ? (
//         <div>
//           <Card
//             style={{
//               marginTop: 25,
//               marginRight: 18,
//               marginLeft: 18,
//               padding: 8,
//             }}
//           >
//             <Row>
//               <Col md={8}></Col>
//               <Col md={4}>
//                 <button
//                   style={{ marginLeft: 9 }}
//                   className="btn btn-outline-danger btn-margin"
//                   type="button"
//                   onClick={handlePDFSHOW}
//                 >
//                   <FontAwesomeIcon
//                     icon={faPrint}
//                     style={{ marginRight: "5px" }}
//                   />
//                   Print
//                 </button>
//                 <button
//                   className="btn btn-outline-danger btn-margin"
//                   type="button"
//                   style={{ marginLeft: "10px", marginRight: 9 }}
//                   onClick={()=>getExcel1(subContractData)}
//                 >
//                   <FontAwesomeIcon
//                     icon={faFileExcel}
//                     style={{ marginRight: "5px" }}
//                   />
//                   XLS
//                 </button>
//                 <button
//                   className="btn btn-outline-danger btn-margin"
//                   type="button"
//                   onClick={handlePDFDownload}
//                   style={{ marginLeft: "10px" }}
//                 >
//                   <FontAwesomeIcon
//                     icon={faFilePdf}
//                     style={{ marginRight: "5px" }}
//                   />
//                   PDF
//                 </button>
//               </Col>
//             </Row>
//             <div className="table-responsive">
//               <Table
//                 className="table table-striped table-hover"
//                 style={{ marginTop: 9 }}
//               >
//                 {/* Render subContractData table here */}
//                 <thead>
//                   <tr>
//                     <th style={{ backgroundColor: "#BADDDA" }}>Sr.No</th>
//                     <th style={{ backgroundColor: "#BADDDA" }}>SER No</th>
//                     <th style={{ backgroundColor: "#BADDDA" }}>SB No</th>
//                     <th style={{ backgroundColor: "#BADDDA" }}>SER Date</th>

//                     <th style={{ backgroundColor: "#BADDDA" }}>
//                       Exporter Names
//                     </th>
//                     <th style={{ backgroundColor: "#BADDDA" }}>CHA No</th>
//                     <th style={{ backgroundColor: "#BADDDA" }}>DESC</th>
//                     <th style={{ backgroundColor: "#BADDDA" }}>No of Pkgs</th>
//                     <th style={{ backgroundColor: "#BADDDA" }}>Net Weight</th>
//                     <th style={{ backgroundColor: "#BADDDA" }}>
//                       FOB value in INR
//                     </th>
//                     <th style={{ backgroundColor: "#BADDDA" }}>
//                       AIRWAY BILL NO
//                     </th>
//                     <th style={{ backgroundColor: "#BADDDA" }}>
//                       PORT OF DESTINATION
//                     </th>
//                     <th style={{ backgroundColor: "#BADDDA" }}>Console Is</th>
//                   </tr>
//                 </thead>
//                 <thead>
//                   <tr>
//                     <th style={{ backgroundColor: "#BADDDA" }}>Total</th>
//                     <th style={{ backgroundColor: "#BADDDA" }}></th>
//                     <th style={{ backgroundColor: "#BADDDA" }}>
//                       {totalSbNoCount}
//                     </th>
//                     <th style={{ backgroundColor: "#BADDDA" }}></th>

//                     <th style={{ backgroundColor: "#BADDDA" }}></th>
//                     <th style={{ backgroundColor: "#BADDDA" }}></th>
//                     <th style={{ backgroundColor: "#BADDDA" }}></th>
//                     <th style={{ backgroundColor: "#BADDDA" }}>
//                       {totalNoOfPackages}
//                     </th>
//                     <th style={{ backgroundColor: "#BADDDA" }}></th>
//                     <th style={{ backgroundColor: "#BADDDA" }}>
//                       {totalFobValueInINR}
//                     </th>
//                     <th style={{ backgroundColor: "#BADDDA" }}></th>
//                     <th style={{ backgroundColor: "#BADDDA" }}></th>
//                     <th style={{ backgroundColor: "#BADDDA" }}></th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {subContractData.map((item, index) => (
//                     <tr key={index}>
//                       <td>{index + 1}</td>
//                       <td>{item.serNo}</td>
//                       <td>{item.sbNo}</td>
//                       <td>{formatedDate(item.serDate)}</td>

//                       <td>{getpartyId[item.nameOfExporter]}</td>
//                       <td>{item.chaNo}</td>
//                       <td>{item.descriptionOfGoods}</td>
//                       <td>{item.noOfPackages}</td>
//                       <td>{item.grossWeight}</td>
//                       <td>{item.fobValueInINR}</td>
//                       <td>{item.airwayBillNo}</td>
//                       <td>{item.portOfDestination}</td>
//                       <td>{getConsoleId[item.consoleAgent]}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>
//             </div>
//             <div
//               style={{
//                 marginTop: 4,
//                 display: "flex",
//                 flexDirection: "row",
//                 justifyContent: "space-between",
//               }}
//             >
//               <p style={{ fontWeight: "bold" }}>
//                 Total No. SB No: {totalSbNoCount}
//               </p>
//               <p style={{ fontWeight: "bold" }}>
//                 Total No. of Packages: {totalNoOfPackages}
//               </p>
//               <p style={{ fontWeight: "bold" }}>
//                 Total FOB Value in INR: {totalFobValueInINR}
//               </p>
//             </div>
//           </Card>
//         </div>
//       ) : (
//         <div>
//           {showExportDataTable ? (
//             <Card
//               style={{
//                 marginTop: 25,
//                 marginRight: 18,
//                 marginLeft: 18,
//                 padding: 8,
//               }}
//             >
//               <Row>
//                 <Col md={8}></Col>
//                 <Col md={4}>
//                   <button
//                     style={{ marginLeft: 9 }}
//                     className="btn btn-outline-danger btn-margin"
//                     type="button"
//                     onClick={handleExportPDFSHOW}
//                   >
//                     <FontAwesomeIcon
//                       icon={faPrint}
//                       style={{ marginRight: "5px" }}
//                     />
//                     Print
//                   </button>
//                   <button
//                     className="btn btn-outline-danger btn-margin"
//                     type="button"
//                     style={{ marginLeft: "10px", marginRight: 9 }}
//                     onClick={()=>getExcel(exportData,exportSubData)}
//                   >
//                     <FontAwesomeIcon
//                       icon={faFileExcel}
//                       style={{ marginRight: "5px" }}
//                     />
//                     XLS
//                   </button>
//                   <button
//                     className="btn btn-outline-danger btn-margin"
//                     type="button"
//                     onClick={handleExportPDFDownload}
//                     style={{ marginLeft: "10px" }}
//                   >
//                     <FontAwesomeIcon
//                       icon={faFilePdf}
//                       style={{ marginRight: "5px" }}
//                     />
//                     PDF
//                   </button>
//                 </Col>
//               </Row>
//               <div className="table-responsive">
//                 <Table
//                   className="table table-striped table-hover"
//                   style={{ marginTop: 9 }}
//                 >
//                   <thead>
//                     <tr>
//                       <th style={{ backgroundColor: "#BADDDA" }}>Sr.No</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>SER No</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>SB No</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>SER Date</th>

//                       <th style={{ backgroundColor: "#BADDDA" }}>
//                         Exporter Names
//                       </th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>CHA No</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>DESC</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>No of Pkgs</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>Net Weight</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>
//                         FOB value in INR
//                       </th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>
//                         AIRWAY BILL NO
//                       </th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>
//                         PORT OF DESTINATION
//                       </th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>Console Is</th>
//                     </tr>
//                   </thead>
//                   <thead>
//                     <tr>
//                       <th style={{ backgroundColor: "#BADDDA" }}>Total</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}></th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>
//                         {totalExSbNoCount}
//                       </th>
//                       <th style={{ backgroundColor: "#BADDDA" }}></th>

//                       <th style={{ backgroundColor: "#BADDDA" }}></th>
//                       <th style={{ backgroundColor: "#BADDDA" }}></th>
//                       <th style={{ backgroundColor: "#BADDDA" }}></th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>
//                         {totalExNoOfPackages + totalEXSubNoOfPackages}
//                       </th>
//                       <th style={{ backgroundColor: "#BADDDA" }}></th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>
//                         {totalExFobValueInINR}
//                       </th>
//                       <th style={{ backgroundColor: "#BADDDA" }}></th>
//                       <th style={{ backgroundColor: "#BADDDA" }}></th>
//                       <th style={{ backgroundColor: "#BADDDA" }}></th>
//                     </tr>
               
//                   </thead>
//                   <tbody>
                
//                     {exportData.map((exportItem, index) => (
//                       <tr key={index}>
//                         <td>{indexCounter++}</td>
//                         <td>{exportItem.serNo}</td>
//                         <td>{exportItem.sbNo}</td>
//                         <td>{formatedDate(exportItem.serDate)}</td>

//                         <td>{getpartyId[exportItem.nameOfExporter]}</td>
//                         <td>{exportItem.chaNo}</td>
//                         <td>{exportItem.descriptionOfGoods}</td>
//                         <td>{exportItem.noOfPackages}</td>
//                         <td>{exportItem.grossWeight}</td>
//                         <td>{exportItem.fobValueInINR}</td>
//                         <td>{exportItem.airwayBillNo}</td>
//                         <td>{exportItem.portOfDestination}</td>
//                         <td>{getConsoleId[exportItem.consoleAgent]}</td>
//                       </tr>
//                     ))}

//                     {exportSubData.map((exportSubItem, index) => (
//                       <tr key={index}>
//                         <td>{indexCounter++}</td>
//                         <td>{exportSubItem.serNo}</td>
//                         <td>{exportSubItem.sbNo}</td>
//                         <td>{formatedDate(exportSubItem.serDate)}</td>

//                         <td>{getpartyId[exportSubItem.exporter]}</td>
//                         <td>{exportSubItem.chaNo}</td>
//                         <td>{exportSubItem.remarks}</td>
//                         <td>{exportSubItem.nop}</td>
//                         <td>{exportSubItem.gwWeight}</td>
//                         <td>{exportSubItem.fobValueInINR}</td>
//                         <td>{exportSubItem.airwayBillNo}</td>
//                         <td>{exportSubItem.portOfDestination}</td>
//                         <td>{getConsoleId[exportSubItem.consoleAgent]}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </Table>

//                 <div
//               style={{
//                 marginTop: 4,
//                 display: "flex",
//                 flexDirection: "row",
//                 justifyContent: "space-between",
//               }}
//             >
//               <p style={{ fontWeight: "bold" }}>
//                 Total No. SB No: {totalExSbNoCount}
//               </p>
//               <p style={{ fontWeight: "bold" }}>
//                 Total No. of Packages: {totalExNoOfPackages + totalEXSubNoOfPackages}
//               </p>
//               <p style={{ fontWeight: "bold" }}>
//                 Total FOB Value in INR: {totalExFobValueInINR}
//               </p>
//             </div>
//               </div>
//             </Card>
//           ) : null}
//         </div>
//       )}
      
//     </div>
//   );
// }


import { redirect } from "react-router-dom";
import AuthContext from "../Components/AuthProvider";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import "../Components/Style.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Pagination } from 'react-bootstrap';
import {
  ButtonToggle,
  CardBody,
  Container,
  Table,
  ToastHeader,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import DatePicker from "react-datepicker";
import ipaddress from "../Components/IpAddress";
import axios from "axios";

import {
  faFileExcel,
  faFilePdf,
  faPrint,
  faSearch, faRefresh, faRegistered, faBook
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { FaFilePdf } from "react-icons/fa";
import ReactLoading from 'react-loading';


export default function Subcontract_report() {



  const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999, // Ensure the overlay is above other elements
    },
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedParty, setSelectedParty] = useState("");

  const [importData, setImportData] = useState([]);
  const [values, setValues] = useState([]);
  const [errors, setErrors] = useState({});

 
  const [loading, setLoading] = useState(false);

  const {
    branchId,
    companyid,

  } = useContext(AuthContext);


  const newFormatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const formatedDate = (inputDate) => {
    if (!inputDate) {
      return ""; // Return an empty string for undefined or null input
    }

    const date = new Date(inputDate);

    if (isNaN(date.getTime())) {
      return ""; // Return an empty string for invalid dates
    }
  }


  const [consoles, setConsoles] = useState([]);
  const [getConsoleId, setGetConsoleId] = useState({});
  const fetchConsoleNames = async () => {
    try {
      const response = await fetch(
        `https://${ipaddress}externalParty/console/${companyid}/${branchId}`
      );
      const data = await response.json();
      const consoleMap = {};
      data.forEach((console) => {
        consoleMap[console.externaluserId] = console.userName;
      });
      setGetConsoleId(consoleMap);
      setConsoles(data);

    } catch (error) {
      console.error("Error fetching party names:", error);
    }
  };

  useEffect(() => {
    fetchimportData(new Date(), new Date());
    fetchConsoleNames();
  }, []);


  const handlePartyChange = (event) => {
    const selectedPartyName = event.target.value;
    setSelectedParty(selectedPartyName);
  };



  const fetchimportData = async (startDate, endDate, selectedParty) => {

    const newErrors = {};

    if (!startDate) {
      newErrors['startDate'] = 'Please Select startDate';
  }
  
  if (!endDate) {
      newErrors['endDate'] = 'Please Select endDate';
  }
  
  setErrors(newErrors);  
  // Check if there are any errors
  if (Object.keys(newErrors).length > 0) {
      return;
  }

    setCurrentPage(1);
    setLoading(true);
    setImportData([]);
    setValues([]);
    try {
      const params = {
        companyId: companyid,
        branchId: branchId,
        startDate: newFormatDate(startDate),
        endDate: newFormatDate(endDate),
        console: selectedParty
      };

      const response = await axios.get(`https://${ipaddress}export/findExportRegister`, { params });
      const { combinedList, Values } = response.data;


      setImportData(combinedList);

      setValues(Values);

    } catch (error) {
      console.error("Error fetching import data:", error.message);
      // Handle the error or show an error message to the user
    }
    finally {
      setLoading(false);
    }

  };

  // const handleSearch = async (startDate, endDate, selectedParty) => {
  //   fetchimportData(startDate, endDate, selectedParty);
  // };


  const handleReset = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setSelectedParty('');
    fetchimportData(new Date(), new Date());

  };



  // Pagination 
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30; // Number of items to display per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = importData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(importData.length / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const displayPages = () => {
    const centerPageCount = 5;
    const middlePage = Math.floor(centerPageCount / 2);
    let startPage = currentPage - middlePage;
    let endPage = currentPage + middlePage;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, centerPageCount);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - centerPageCount + 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };


  const formatDate = (value) => {
    if (value === null || value === undefined || (typeof value !== 'string' && typeof value !== 'number') || value.toString().trim() === "") {
      return "";
    }
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handlePrint = async (type) => {
    setLoading(true);
    const params = {
      companyId: companyid,
      branchId: branchId,
      startDate: newFormatDate(startDate),
      endDate: newFormatDate(endDate),
      console: selectedParty,
      totalNoOfPackages: values[0][1],
      totalNoSIR: values[0][0],
      totalFOBValue: values[0][2]
    };


    try {
      const response = await axios.get(`https://${ipaddress}export/exportRegisterPrint`, { params });

      if (type === 'PDF') {
        if (response.data === 'not generated') {
          toast.error("Gate pass already generated", { position: "top-center", autoClose: 2000 });

        } else {
          const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF
          // Create a Blob from the Base64 data
          const pdfBlob = new Blob([Uint8Array.from(atob(pdfBase64), c => c.charCodeAt(0))], { type: 'application/pdf' });
          // Create a URL for the Blob
          const blobUrl = URL.createObjectURL(pdfBlob);
          // Create an anchor element for downloading
          const downloadLink = document.createElement('a');
          downloadLink.href = blobUrl;
          downloadLink.download = 'Import_Register.pdf'; // Set the filename for the downloaded PDF
          downloadLink.style.display = 'none';
          document.body.appendChild(downloadLink);
          // Trigger the download
          downloadLink.click();
          // Clean up
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(blobUrl);
          toast.success("Downloading Pdf!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 800,
          });

        }
      } else if (type === 'PRINT') {
        if (response.data === 'not generated') {
          toast.error("Gate pass already generated", { position: "top-center", autoClose: 2000 });

        } else {
          const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF
          // Create a Blob from the Base64 data
          const pdfBlob = new Blob([Uint8Array.from(atob(pdfBase64), c => c.charCodeAt(0))], { type: 'application/pdf' });
          // Create an object URL for the Blob
          const blobUrl = URL.createObjectURL(pdfBlob);
          // Open a new window and set the PDF content as the source
          window.open(blobUrl, '_blank');

        }
      } else {
        throw new Error('Invalid print type');
      }

      // handleSearch();
    } catch (error) {
      console.error(error);
      toast.error("Gate pass already generated", { position: "top-center", autoClose: 2000 });
    } finally {
      setLoading(false);
    }
  };



  const handleXLSDownload = async () => {

    setLoading(true);
    const params = {
      companyId: companyid,
      branchId: branchId,
      startDate: newFormatDate(startDate),
      endDate: newFormatDate(endDate),
      console: selectedParty,
      totalNoOfPackages: values[0][1],
      totalNoSIR: values[0][0],
      totalFOBValue: values[0][2]
    };

    try {
      const response = await axios.get(`https://${ipaddress}export/exportRegisterXLSDownload`, {
        params,
        responseType: 'arraybuffer', // Ensure response is treated as binary data
      });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = 'EXPORT REGISTER';
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error(error);
      toast.error('Something Went Wrong', { position: 'top-center', autoClose: 2000 });
    }finally {
      setLoading(false);
    }
  };



  return (
    <>

      {loading && (
        <div style={styles.overlay}>
          <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
        </div>
      )}
      <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }}>
        <FontAwesomeIcon icon={faBook} style={{ marginRight: '8px', color: 'black' }} />
        Export Register
      </h5>
      <Card>
        <CardBody>
          <Container>
            <Form>
              <Row>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label className="inputhead">From Date</Form.Label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        if (date) {
                          setStartDate(date);
                        } else {
                          setStartDate(null);
                        }
                      }}
                      dateFormat="dd/MM/yyyy"
                      name="startDate"
                      wrapperClassName="custom-react-datepicker-wrapper"
                      className="form-control border-right-0"
                      customInput={<input style={{ width: '100%', borderColor: errors.startDate ? '#f52b2b' : '' }} />}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label className="inputhead">To Date</Form.Label>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => {
                        if (date) {
                          setEndDate(date);
                        } else {
                          setEndDate(null);
                        }
                      }}
                      dateFormat="dd/MM/yyyy"
                      name="endDate"
                      wrapperClassName="custom-react-datepicker-wrapper"
                      className="form-control border-right-0"
                      customInput={<input style={{ width: '100%', borderColor: errors.endDate ? '#f52b2b' : '' }} />}                
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <label htmlFor="company" className="inputhead">Console</label>
                  <select
                    name="console"
                    className="form-select form-control"
                    onChange={handlePartyChange}
                    value={selectedParty}
                    style={{ marginTop: 9 }}
                  >
                    <option value="">Select</option>
                    {consoles.map((party) => (
                      <option key={party.externaluserId} value={party.externaluserId}>
                        {party.userName}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={3}>
                  <div style={{ marginTop: 30 }}>
                    <button
                      type="button"
                      className="btn me-md-2 btn-outline-primary"
                      onClick={() => fetchimportData(startDate, endDate, selectedParty)}
                      style={{ marginRight: '10px' }}
                    >
                      <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                      Search
                    </button>
                    <button
                      type="button"
                      className="btn gap-2 btn-outline-danger"
                      onClick={handleReset}
                    >
                      <FontAwesomeIcon icon={faRefresh} style={{ marginRight: '5px' }} />
                      Clear
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>


          {importData.length > 0 ? (
            <>
              <hr className="mt-3" />
              <Row >

                <Col md={8}>
                </Col>
                <Col md={4} className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => handlePrint("PRINT")}
                    style={{ marginRight: '10px' }}
                  >
                    <FontAwesomeIcon icon={faPrint} style={{ marginRight: '5px' }} />
                    PRINT
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => handlePrint("PDF")}
                    style={{ marginRight: '10px' }}
                  >
                    <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
                    PDF
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={handleXLSDownload}
                  >
                    <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '5px' }} />
                    XLS
                  </button>


                </Col>

              </Row>

              <div className="table-responsive">
                <Table className="table table-striped table-hover" style={{ marginTop: 9 }}>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#BADDDA", height: '30px' }}>Sr.No</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>SER No</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>SB No</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>SER Date</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>Exporter Names</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>CHA No</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>DESC</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>No of Pkgs</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>Net Weight</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>FOB value in INR</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>AIRWAY BILL NO</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>PORT OF DESTINATION</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>Console</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td style={{ backgroundColor: '#BADDDA' }}><b>Total</b> </td>
                      <td style={{ backgroundColor: '#BADDDA' }}></td>
                      <td style={{ backgroundColor: '#BADDDA' }}> <b>{values[0][0]}</b></td>
                      <td style={{ backgroundColor: '#BADDDA' }}></td>
                      <td style={{ backgroundColor: '#BADDDA' }}> </td>
                      <td style={{ backgroundColor: '#BADDDA' }}></td>
                      <td style={{ backgroundColor: '#BADDDA' }}></td>
                      <td style={{ backgroundColor: '#BADDDA' }}><b>{values[0][1]}</b></td>
                      <td style={{ backgroundColor: '#BADDDA' }}></td>
                      <td style={{ backgroundColor: '#BADDDA' }}> <b>{values[0][2]}</b></td>
                      <td style={{ backgroundColor: '#BADDDA' }}></td>
                      <td style={{ backgroundColor: '#BADDDA' }}></td>
                      <td style={{ backgroundColor: '#BADDDA' }}></td>

                    </tr>
                    {currentItems.map((currentItems, index) =>

                      <tr className="text-center dynamic-row-width">
                        <td>{((currentPage - 1) * itemsPerPage) + index + 1}</td>
                        <td>{currentItems[0]}</td>
                        <td>{currentItems[1]}</td>
                        <td>{formatDate(currentItems[2])}</td>
                        <td>{currentItems[3]}</td>
                        <td>{currentItems[4]}</td>
                        <td>{currentItems[5]}</td>
                        <td>{currentItems[6]}</td>
                        <td >{currentItems[7] + " "+currentItems[12]} </td>
                        <td >{currentItems[8]}</td>
                        <td >{currentItems[9]}</td>
                        <td >{currentItems[10]}</td>
                        <td >{currentItems[11]}</td>
                      </tr>
                    )
                    }


                  </tbody>
                </Table>

                <div className="text-center">

                  <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
                    <Pagination.First onClick={() => handlePageChange(1)} />
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    <Pagination.Ellipsis />

                    {displayPages().map((pageNumber) => (
                      <Pagination.Item
                        key={pageNumber}
                        active={pageNumber === currentPage}
                        onClick={() => handlePageChange(pageNumber)}
                      >
                        {pageNumber}
                      </Pagination.Item>
                    ))}

                    <Pagination.Ellipsis />
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                    <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                  </Pagination>

                </div>

              </div>
            </>
          ) : null}
        </CardBody>
      </Card>
    </>


  );
}
