// import ipaddress from "../Components/IpAddress";
// import { redirect } from "react-router-dom";
// import AuthContext from "../Components/AuthProvider";
// import { useNavigate } from "react-router-dom";
// import React, { useEffect, useState, useContext, useRef } from "react";
// import "../Components/Style.css";
// import html2canvas from "html2canvas";
// import { renderToStaticMarkup } from "react-dom/server";
// import Pagination from 'react-bootstrap/Pagination';
// import jsPDF from "jspdf";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import Card from "react-bootstrap/Card";
// import FileSaver from "file-saver"; // This library is used for saving the file
// import * as XLSX from "xlsx";
// import { saveAs } from "file-saver";
// import dgdcImage from "../Images/report.jpeg";

// import "../Parent_Pages/parent.css";
// import { CardBody, Label } from "reactstrap";
// import axios from "axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowsToEye, faBorderAll, faFileAlt, faRefresh, faSearch } from "@fortawesome/free-solid-svg-icons";
// import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";

// import {
//   faCheck,
//   faSave,
//   faTimes,
//   faSyncAlt,
//   faFileExcel,
//   faFilePdf,
//   faPrint,
// } from "@fortawesome/free-solid-svg-icons";
// import { Table } from "react-bootstrap";
// import { Line, PDFDownloadLink, pdf } from "@react-pdf/renderer";
// import {
//   Document,
//   Page,
//   Text,
//   View,
//   StyleSheet,
//   PDFViewer,
//   Image,
// } from "@react-pdf/renderer";
// import { BlobProvider } from "@react-pdf/renderer";
// import { setActiveLink } from "react-scroll/modules/mixins/scroller";
// import { toast } from "react-toastify";
// const CustomHeader = () => {
//   return (
//     <View style={styles.header}>
//       <Image src={dgdcImage} style={styles.headerImage} />
//     </View>
//   );
// };

// const styles = StyleSheet.create({
//   centeredTextContainer: {
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     marginBottom: 20,
//   },
//   image: {
//     width: 306,
//     height: 100,
//     marginBottom: 0,
//     marginLeft: 117,
//     justifyContent: "center",
//   },
//   rightText: {
//     fontSize: 10,
//     textAlign: "right", // Center text horizontally
//   },
//   centeredText: {
//     fontSize: 10,
//     textAlign: "center", // Center text horizontally
//   },
//   headerText: {
//     fontSize: 10,
//     fontWeight:"bold"
//   },
//   page: {
//     paddingTop: 18,
//     paddingBottom: 60,
//     paddingHorizontal: 18,
//   },
//   header: {
//     marginBottom:5,
//   },
//   heading: {
//     fontSize: 10,
//     marginBottom: 0,
//     fontWeight: "bold",
//     alignItems: "center",
//   },
//   table: {
//     width: "100%",
//     borderCollapse: "collapse",
//     marginBottom:5,
//   },

//   leftColumn: {
//     width: "100%",
//     paddingTop:5,
//   },
//   headingwithbox: {
//     fontSize: 10,
//     marginBottom: 0,
//     fontWeight: "bold",
//     alignItems: "center",

//     // Add padding for space between text and border
//   },
//   viewheadingwithbox: {
//     border: "1px solid black",
//     padding: 5,
//   },
//   paragraph: {
//     fontSize: 10,
//     marginBottom: 5,
//   },
//   headingwithborder: {
//     fontSize: 10,
//     marginBottom: 0,
//     fontWeight: "bold",
//     alignItems: "center",
//     borderBottom: "1px solid black",
//     // Add padding for space between text and border
//   },
//   // image: {
//   //   width: 400,
//   //   height: 80,
//   //   marginBottom: 0,
//   //   marginLeft: 55,
//   // },
//   dateSize: {
//     fontSize: 8,
//   },
//   normaltext: {
//     fontSize: 10,
//     marginTop: 25,
//     fontWeight: "bold",
//   },
//   line: {
//     width: "100%", // Adjust the width of the line
//     marginTop: 10, // Adjust the space above the line
//     marginBottom: 10, // Adjust the space below the line
//     borderTop: "1pt solid black", // Style the line
//   },

//   tableRow: {
//     flexDirection: "row",
//     borderBottomWidth: 0.3,
//     borderBottomColor: "black",
//     fontSize: 10,
//     borderRightWidth: 0.3,
//     borderLeftWidth: 0.3,
//     borderLeftColor: "black",
//     borderRightColor: "black",
//     flexWrap: "wrap",
//   },
//   tableCell: {
//     border: "0.3px solid black",
//     padding:1,
//     flexWrap: "wrap",
//     width:52,
//     textAlign:'center',
//     fontSize:7
//   },
//   tableCellHeader: {
//     fontWeight: "bold",
//     flexWrap: "wrap",
//     width:52,
//     fontWeight:"bold",
//     textAlign:'center',
//     fontSize:9
//   },
// });


// const PAGE_BREAK_ROWS = 14; // Adjust this based on how many rows fit on one page


// export default function Import_transaction() {
//   const {
//     jwtToken,
//     userId,
//     username,
//     branchId,
//     companyid,
//     role,
//     companyname,
//     branchname,
//     login,
//     logout,
//   } = useContext(AuthContext);

//   const navigate = useNavigate();
//   const { isAuthenticated } = useContext(AuthContext);

//   useEffect(() => {
//     if (!isAuthenticated) {
//       navigate(
//         "/login?message=You need to be authenticated to access this page."
//       );
//     }
//   }, [isAuthenticated, navigate]);

//   const today = new Date();
//   const [JarListDtlDGDC, setJarListDtlDGDC] = useState([]);

//   const formatedDate = (inputDate) => {
//     const date = new Date(inputDate);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${day}/${month}/${year}`;
//   };

//   const MyPDFDocument = ({ sbCount, noOfPackages, ReordList }) => (
//     <Document>
//       {Array.from({ length: Math.ceil(ReordList.length / PAGE_BREAK_ROWS) }).map(
//         (_, pageIndex) => (
//           <Page key={pageIndex} size="A4" style={styles.page}>
//             <CustomHeader />
//             <View>
//               <Text style={styles.centeredText}>
//                 Date : {formatedDate(searchCriteria.sirDate) }
//               </Text>
//               <Text style={styles.centeredText}>
//                 Status : {searchCriteria.dgdcStatus}
//                 {"\n\n"}
//               </Text>
  
//               <Text style={styles.headerText}>
//                 Import Transaction Report {"\n\n"}
//               </Text>
//             </View>
  
//             <View style={styles.table}>
//               {" "}
//               <View style={styles.tableRow}>
//                 <Text
//                   style={[
//                     styles.tableCell,
//                     styles.tableCellHeader,
//                     { width:31 },
//                   ]}
//                 >
//                   Sl.No.
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tableCell,
//                     styles.tableCellHeader,
//                     {  width:61},
//                   ]}
//                 >
//                   SIR Date
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tableCell,
//                     styles.tableCellHeader,
//                     {  },
//                   ]}
//                 >
//                   SIR No
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tableCell,
//                     styles.tableCellHeader,
//                     {  },
//                   ]}
//                 >
//                   Flight No
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tableCell,
//                     styles.tableCellHeader,
//                     {  },
//                   ]}
//                 >
//                   Importer Name
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tableCell,
//                     styles.tableCellHeader,
//                     { width:31},
//                   ]}
//                 >
//                   Packages
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tableCell,
//                     styles.tableCellHeader,
//                     {   },
//                   ]}
//                 >
//                   MAWB No
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tableCell,
//                     styles.tableCellHeader,
//                     {width:72},
//                   ]}
//                 >
//                   HAWB No
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tableCell,
//                     styles.tableCellHeader,
//                     { width:61 },
//                   ]}
//                 >
//                   BE REQUEST ID
//                 </Text>
//                 <Text
//                   style={[
//                     styles.tableCell,
//                     styles.tableCellHeader,
//                     {width:94},
//                   ]}
//                 >
//                   Current Status
//                 </Text>
//               </View>
//               {ReordList.slice(
//                 pageIndex * PAGE_BREAK_ROWS,
//                 (pageIndex + 1) * PAGE_BREAK_ROWS
//               ).map((item, index) => (
//                 <View style={styles.tableRow} key={index}>
//                   <Text style={{ ...styles.tableCell, width:31 }}>
//                     {index + 1 + pageIndex * PAGE_BREAK_ROWS}
//                   </Text>
//                   <Text style={{ ...styles.tableCell,width:61 }}>
//                   {formatedDate(item.sirDate)}
//                   </Text>
//                   <Text style={{ ...styles.tableCell}}>
//                     {item.sirNo}
//                   </Text>
//                   <Text style={{ ...styles.tableCell,  }}>
//                     {item.flightNo}
//                   </Text>
//                   <Text style={{ ...styles.tableCell,}}>
//                     {item.importernameOnParcel}
//                   </Text>
//                   <Text style={{ ...styles.tableCell,width:31 }}>
//                     {item.nop}
//                   </Text>
//                   <Text style={{ ...styles.tableCell,  }}>
//                     {item.mawb}
//                   </Text>
//                   <Text style={{ ...styles.tableCell,width:72}}>
//                     {item.hawb}
//                   </Text>
//                   <Text style={{ ...styles.tableCell, width:61}}>
//                     {item.beRequestId}
//                   </Text>
//                   <Text style={{ ...styles.tableCell,width:94 }}>
//                   {item.dgdc_Status}
//                   </Text>
//                 </View>
//               ))}
// </View>

//    {/* Display the "Total" row only on the last page */}
//    {pageIndex === Math.ceil(ReordList.length / PAGE_BREAK_ROWS) - 1 && (
//           <View style={styles.tableRow}>
//             <Text style={{ ...styles.tableCell, fontSize: 10 ,width:31 }}>
//               Total
//             </Text>
//             <Text style={{ ...styles.tableCell,width:61}}></Text>
//             <Text
//               style={[
//                 styles.tableCell,
//                 styles.tableCellHeader,
//                 { },
//               ]}
//             >
//               {sbCount}
//             </Text>
//             <Text style={{ ...styles.tableCell,  }}></Text>
//             <Text style={{ ...styles.tableCell }}></Text>
//             <Text style={{ ...styles.tableCell,  width: 31 }}>{noOfPackages}</Text>
//             <Text style={{ ...styles.tableCell }}></Text>
//             <Text style={{ ...styles.tableCell,  width: 72 }}></Text>
//             <Text style={{ ...styles.tableCell,width:61 }}></Text>
//             <Text style={{ ...styles.tableCell,  width: 94 }}></Text>
//           </View>
//         )}
//          <View>
//           <Text style={styles.rightText}>{"\n\n"}(For DGDC LIMITED)</Text>
//         </View>

//           </Page>
//         )
//       )}
//     </Document>
//   );

//   const initialSearchCriteria = {
//     companyId: companyid,
//     branchId: branchId,
//     dgdcStatus: "",
//     sirDate: new Date(),
//   };



//   const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);

//   const [ReordList, setReordList] = useState([]);

//   useEffect(() => {
//     getlist();
//   }, []);

//   const getlist = () => {
//     axios
//       .get(`https://${ipaddress}jardetail/dgdcstatus/${companyid}`)
//       .then((response) => {
//         setJarListDtlDGDC(response.data);
//       })
//       .catch((error) => {
//         console.error("GET list error:", error);
//       });
//   };

//   const handleShow = () => {
//     axios
//       .get(`https://${ipaddress}import/importTransaction`, {
//         params: searchCriteria,
//       })
//       .then((response) => {
//         setReordList(response.data);

//         toast.success("Data Found !", {
//           position: "top-center",
//           autoClose: 540, // Duration in milliseconds
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//         });

//         // console.log(
//         //   "---------------------------------------------------------------------------------"
//         // );
//         console.log(response.data);
//       })
//       .catch((error) => {

//         toast.error("Data Not Found !", {
//           position: "top-center",
//           autoClose: 540, // Duration in milliseconds
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//         });

//         setReordList([]);
//         console.error("GET list error:", error);
//       });
//   };

//   const handleRest = () => {
//     setSearchCriteria({
//       ...searchCriteria,
//       dgdcStatus: "", // Clear the dgdcStatus
//       sirDate: new Date(), // Set sbDate to today's date
//     });
//   };

//   function fetchCompanyName(companyId) {
//     // Make an Axios GET request to retrieve the company name based on companyId
//     return axios
//       .get(`https://${ipaddress}import/findCompanyname/${companyId}`)
//       .then(function (response) {
//         return response.data; // Return the retrieved company name
//       })
//       .catch(function (error) {
//         console.error("Error fetching company name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }

//   function fetchBranchName(companyId, branchId) {
//     // Make an Axios GET request to retrieve the branch name based on branchId
//     return axios
//       .get(
//         `https://${ipaddress}import/findBranchName/${companyId}/${branchId}`
//       )
//       .then(function (response) {
//         return response.data; // Return the retrieved branch name
//       })
//       .catch(function (error) {
//         console.error("Error fetching branch name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }

//   const generateXLS = async () => {
//     const modifiedRecordList = await Promise.all(
//       ReordList.map(async (item, index) => {
//         // const companyname = await fetchCompanyName(item.companyId);
//         // const branchname = await fetchBranchName(item.companyId, item.branchId);
  
//         return {
//           "Sr.No": index + 1,
//           // "Company Name": companyname,
//           // "Branch Name": branchname,
//           "SIR No": item.sirNo || '',
//           "SIR Date": formatedDate(item.sirDate) || '',
//           "Importer Names(As On Parcel)": item.importernameOnParcel || '',
//           "FLIGHT NO": item.flightNo || '', // Modify this to the actual field name
//           "No of Pkgs": item.nop || '',
//           "PARCEL TYPE": item.parcelType || '',
//           "MAWB NO": item.mawb || '',
//           "HAWB NO": item.hawb || '',
//           "BE REQUEST ID": item.beRequestId || '',
//           "CURRENT STATUS": item.dgdc_Status || '',
//         };
//       })
//     );
  
//     // Calculate the total "SIR No" and "No of Pkgs"
//     const totalSIRNo = modifiedRecordList.reduce(
//       (total, item) => total + (item["SIR No"] ? 1 : 0),
//       0
//     );
  
//     const totalNoOfPkgs = modifiedRecordList.reduce(
//       (total, item) => total + (item["No of Pkgs"] || 0),
//       0
//     );
//     const distanceRow = {
//       "Sr.No": '',
//       // "Company Name": '',
//       // "Branch Name": '',
//       "SIR No": '',
//       "SIR Date": '',
//       "Importer Names(As On Parcel)": '',
//       "FLIGHT NO": '',
//       "No of Pkgs": '',
//       "PARCEL TYPE": '',
//       "MAWB NO": '',
//       "HAWB NO": '',
//       "BE REQUEST ID": '',
//       "CURRENT STATUS": '',
//     };
//     // Add a total row
//     const totalRow = {
//       "Sr.No": 'Total ',
//       // "Company Name": '',
//       // "Branch Name": '',
//       "SIR No": totalSIRNo,
//       "SIR Date": '',
//       "Importer Names(As On Parcel)": '',
//       "FLIGHT NO": '',
//       "No of Pkgs": totalNoOfPkgs,
//       "PARCEL TYPE": '',
//       "MAWB NO": '',
//       "HAWB NO": '',
//       "BE REQUEST ID": '',
//       "CURRENT STATUS": '',
//     };
  
//     const workbook = XLSX.utils.book_new();
//     const worksheet = XLSX.utils.json_to_sheet([
//       distanceRow, 
//       ...modifiedRecordList,
//       distanceRow, // Insert the distance row
//       totalRow, // Insert the total row
//     ]);
  
//     // Add headers for all fields
//     const headers = Object.keys(modifiedRecordList[0]);
//     headers.forEach((header, index) => {
//       worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
//         t: "s",
//         v: header,
//         s: { font: { bold: true } },
//       };
//     });
  
//     // Set column widths based on data
//     const colWidths = headers.map((header) => ({
//       wch: header.length + 2, // You can adjust the width as needed
//     }));
  
//     worksheet["!cols"] = colWidths;
  
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Import_Register");
//     const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
//     const blob = new Blob([s2ab(xlsFile)], {
//       type: "application/vnd.ms-excel",
//     });
//     saveAs(blob, "import_transaction.xls");
//   };

//   const s2ab = (s) => {
//     const buf = new ArrayBuffer(s.length);
//     const view = new Uint8Array(buf);
//     for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
//     return buf;
//   };

//   const sbCount = ReordList.filter(
//     (item) => item.sbNo !== null && item.sbNo !== ""
//   ).length;

//   const noOfPackages = ReordList.reduce((total, item) => {
//     if (item.nop !== null && !isNaN(item.nop)) {
//       return total + parseInt(item.nop, 10);
//     }
//     return total;
//   }, 0);

//   const handlePrint = () => {
//     const isoDate = new Date().toISOString();
//     const date = new Date(isoDate);
//     const day = date.getDate().toString().padStart(2, "0");
//     const month = (date.getMonth() + 1).toString().padStart(2, "0");
//     const year = date.getFullYear().toString();
  
//     const printWindow = window.open("", "_blank");
//     printWindow.document.open();
  
//     const recordsPerPage = 16; // Number of records per page
//     const recordChunks = splitArrayIntoChunks(ReordList, recordsPerPage);
  
//     recordChunks.forEach((chunk, chunkIndex) => {
//       if (chunkIndex > 0) {
//         // Insert a page break before the new page
//         printWindow.document.write('<div style="page-break-before: always;"></div>');
//       }
  
//       // Create a new page
//       printWindow.document.write(`
//         <!DOCTYPE html>
//         <html>
//         <head>
//         <title>Import Transaction Report</title>
  
//         <style>
//           @page {
//             margin: 1cm;
//           }
  
//           .printable-area {
//             font-family: Arial, sans-serif;
//           }
  
//           table {
//             width: 100%;
//             border-collapse: collapse;
//           }
  
//           td {
//             border: 1px solid #dddddd;
//             text-align: center;
//             padding: 1px;
//             font-size: 10px;
//           }
  
//           th {
//             border: 1px solid #dddddd;
//             background-color: #f2f2f2;
//             text-align: center;
//             font-size: 12px;
//           }
  
//           .header img {
//             max-width: auto;
//             max-height: auto;
//           }
  
//           #page-header {
//             position: static;
//             top: 0;
//             left: 0;
//             right: 0;
//             text-align: center;
//           }
//         </style>
//         </head>
//         <body>
//         <div id="page-header">
//         <img src="${dgdcImage}" alt="Header Image" style="max-width: 306px; display: block; margin: 0 auto;">
//         <div style="text-align: center;">
//         <p style="font-size: 12px;">
//         <strong>Date : ${formatedDate(searchCriteria.sirDate)}</strong><br>
//         <strong>Status : ${searchCriteria.dgdcStatus}</strong>
//         </p>
//         </div>
//         </div>
//         <div class="content">
//         <div style="font-size: 12px;"><strong> Import Transaction Report</strong></div>
//         <table>
//           <thead>
//             <tr>
//               <th>Sr.No</th>
//               <th>Sir Date</th>
//               <th>Sir No</th>
//               <th>Parcel Type</th>
//               <th>Flight No</th>
//               <th>Importer Name</th>
//               <th>Packages</th>
//               <th>MAWB No.</th>
//               <th>HAWB No</th>
//               <th>BE REQUEST ID</th>
//               <th>Current Status</th>
//             </tr>
//           </thead>
//           <tbody>
//             ${chunk.map((item, index) => `
//               <tr>
//                 <td>${chunkIndex * recordsPerPage + index + 1}</td>
//                 <td>${formatedDate(item.sirDate) || ''}</td>
//                 <td>${item.sirNo || ''}</td>
//                 <td>${item.parcelType || ''}</td>
//                 <td>${item.flightNo || ''}</td>
//                 <td>${item.importernameOnParcel || ''}</td>
//                 <td>${item.nop || ''}</td>
//                 <td>${item.mawb || ''}</td>
//                 <td>${item.hawb || ''}</td>
//                 <td>${item.beRequestId || ''}</td>
//                 <td>${item.dgdc_Status || ''}</td>
//               </tr>
//             `).join("")}
//           </tbody>
//         </table>
//         </div>
//         <div>
//         <p style="float: right; margin-right: 18px; margin-top: 10px; font-size: 12px;">(For DGDC LIMITED)</p>
//         </div>
//         </body>
//         </html>
//       `);
//     });
  
//     printWindow.document.close();
//     printWindow.print();
//     printWindow.onafterprint = () => printWindow.close();
//   };
//   function splitArrayIntoChunks(array, chunkSize) {
//     const chunks = [];
//     for (let i = 0; i < array.length; i += chunkSize) {
//       chunks.push(array.slice(i, i + chunkSize));
//     }
//     return chunks;
//   }
//   const handlePDFDownload = async () => {
//     const pdfBlob = await pdf(
//       <MyPDFDocument {...{ sbCount, noOfPackages, ReordList }} />
//     ).toBlob();
//     saveAs(pdfBlob, "report.pdf");
//   };

  

//   const [partys, setPartys] = useState([]);

//   const [getpartyId, setGetpartyId] = useState({});

//   const fetchPartyNames = async () => {
//     try {
//       const response = await fetch(
//         `https://${ipaddress}parties/getAll/${companyid}/${branchId}`
//       );
//       const data = await response.json();
//       const namesMap = {};
//       data.forEach((party) => {
//         namesMap[party.partyId] = party.partyName;
//       });
//       setGetpartyId(namesMap);
//       setPartys(data);
//     } catch (error) {
//       console.error("Error fetching party names:", error);
//     }
//   };

//   useEffect(() => {
//     fetchPartyNames();
//   }, []);


//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 10; // Number of items to display per page

//   // Calculate the total number of pages based on the number of items and items per page
//   const totalPages = Math.ceil(ReordList.length / itemsPerPage);

//   // Calculate the index range for the current page
//   const startIndex = (currentPage - 1) * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;

//   // Slice the data for the current page
//   const currentItems = ReordList.slice(startIndex, endIndex);

//   // Handle page change
//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const toatlSirNoCount = currentItems.length;
//   const totalExNoOfPackages = currentItems.reduce(
//     (total, item) => total + item.nop,
//     0
//   );

// //Import Transaction

// const formatDateTime2 = (value) => {
//   if (!value) {
//     return "";
//   }
//   const date = new Date(value);
//   const day = String(date.getDate()).padStart(2, "0");
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const year = date.getFullYear();
//   const hours = String(date.getHours()).padStart(2, "0");
//   const minutes = String(date.getMinutes()).padStart(2, "0");
//   const seconds = String(date.getSeconds()).padStart(2, "0");
//   return `${day}/${month}/${year} `;
// };
// const getExcel = (imp) => {
//   const filename = `Import_Transaction_${formatDateTime2(new Date())}.xlsx`; // Note: Changed file extension to xlsx
//   axios.post(`https://${ipaddress}import/transactionexcel`, imp, { responseType: 'blob' }) // Added responseType: 'blob'
//     .then(async (response) => {
//       const blob = new Blob([response.data], { type: response.headers['content-type'] });

//       // Create a temporary URL for the blob
//       const url = window.URL.createObjectURL(blob);

//       // Create a link element to trigger the download
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = filename;
//       document.body.appendChild(a);
//       a.click();

//       // Clean up
//       window.URL.revokeObjectURL(url);
//       document.body.removeChild(a);
//     })
//     .catch((error) => {
//       toast.error("Something went wrong", {
//         autoClose: 700
//       });
//     });
//   }

//   return (
//     <div>
//      <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }} > <FontAwesomeIcon
//         icon={faFileAlt}
//         style={{
//           marginRight: '8px',
//           color: 'black', // Set the color to golden
//         }}
//       />Import Transaction</h5>
//       <Card
//         // style={{ marginTop: 25, marginRight: 18, marginLeft: 18, padding: 8 }}
//       >
//         <CardBody style={{ marginTop: "10" }}>
       

//               <Row>
//                 <Col sm={4}>
//                   <label className="forlabel">Select Date</label>
//                   <div>
//                     <DatePicker
//                       selected={searchCriteria.sirDate} // Set the selected date to BillGDate
//                       wrapperClassName="custom-react-datepicker-wrapper"
//                       onChange={(date) => {
//                         if (date) {
//                           setSearchCriteria({ ...searchCriteria, sirDate: date });
//                         } else {
//                           setSearchCriteria({ ...searchCriteria, sirDate: null });
//                         }
//                       }}
//                       dateFormat="dd/MM/yyyy"
//                       value={searchCriteria.sirDate}
//                       className="form-control border-right-0 inputField"
//                       customInput={<input style={{ width: '100%' }} />}
//                       maxDate={new Date()}

//                     />
//                   </div>
//                 </Col>
//                 <Col sm={4}>
//                   <div className="form-group">
//                     <label className="forlabel">DGDC Status</label>
//                     <select
//                       name="selectedDGDCStatus"
//                       className="form-control"
//                       value={searchCriteria.dgdcStatus}
//                       onChange={(e) =>
//                         setSearchCriteria({
//                           ...searchCriteria,
//                           dgdcStatus: e.target.value,
//                         })
//                       }
//                     >
//                       <option value="" disabled>
//                         Select DGDC Status
//                       </option>
//                       {JarListDtlDGDC.map((item) => (
//                         <option key={item.jarDtlDesc} value={item.jarDtlDesc}>
//                           {item.jarDtlDesc}
//                         </option>
//                       ))}
//                     </select>
//                   </div>
//                 </Col>
//                 <Col sm={4}>
//                   <div style={{ marginTop: 23 }}>
//                     <button
//                       className="btn btn-outline-primary btn-margin"
//                       onClick={handleShow}
//                       type="button"
//                     >
//                       <FontAwesomeIcon icon={faArrowsToEye} style={{ marginRight: '5px' }} />
//                       Show
//                     </button>
//                     <button
//                       className="btn btn-outline-danger btn-margin"
//                       type="button"
//                       onClick={handleRest}
//                       style={{ marginLeft: "5px" }}
//                     >
//                        <FontAwesomeIcon icon={faRefresh} style={{ marginRight: '5px' }} />
//                       Reset
//                     </button>
//                   </div>
//                 </Col>
//               </Row>
           

//           {ReordList.length !== 0 && (
//             <>            <div className="text-end" style={{ marginTop: 23 }}>
//               <button
//                 className="btn btn-outline-primary btn-margin"
//                 type="button"
//                 onClick={handlePrint}
//               >
//                  <FontAwesomeIcon icon={faPrint} style={{ marginRight: '5px' }} />
//                 Print
//               </button>
//               <button
//                 className="btn btn-outline-success btn-margin"
//                 type="button"
//                 style={{ marginLeft: "5px" }}
//                 onClick={()=>getExcel(ReordList)}
//               >
//                 <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '5px' }} />
//                 XLS
//               </button>
//               <button
//                 className="btn btn-outline-primary btn-margin"
//                 type="button"
//                 onClick={handlePDFDownload}
//                 style={{ marginLeft: "5px" }}
//               >
//                 <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
//                 Pdf
//               </button>
             
//             </div>

//               <hr />
//               <Table style={{ marginTop: 10 }} striped responsive bordered>
//                 <thead>
//                   <tr>
//                   <th style={{ background: '#BADDDA' }}>
//                       Sr.No
//                     </th>
//                     <th style={{ background: '#BADDDA' }}>
//                       Sir Date
//                     </th>
//                     <th style={{ background: '#BADDDA' }}>
//                       Sir No
//                     </th>
//                     <th style={{ background: '#BADDDA' }}>
//                       Parcel Type
//                     </th>
//                     <th style={{ background: '#BADDDA' }}>
//                       Flight No
//                     </th>
//                     <th style={{ background: '#BADDDA' }}>
//                       Importer Name
//                     </th>
//                     <th style={{ background: '#BADDDA' }}>
//                       Packages
//                     </th>
//                     <th style={{ background: '#BADDDA' }}>
//                       MAWB No.
//                     </th>
//                     <th style={{ background: '#BADDDA' }}>
//                       HAWB No
//                     </th>
//                     <th style={{ background: '#BADDDA' }}>
//                       BE REQUEST ID
//                     </th>
//                     <th style={{ background: '#BADDDA' }}>
//                       Current Status
//                     </th>
//                   </tr>
//                 </thead>
//                 <thead>
//                 <tr>
//                   <th style={{ background: "#BADDDA" }} scope="col">
//                     Total
//                   </th>
//                   <th style={{ background: "#BADDDA" }} scope="col">
                    
//                   </th>
//                   <th style={{ background: "#BADDDA" }} scope="col">
//                    {toatlSirNoCount}
//                   </th>
//                   <th style={{ background: "#BADDDA" }} scope="col">
                   
//                   </th>
//                   <th style={{ background: "#BADDDA" }} scope="col">
                  
//                   </th>
//                   <th style={{ background: "#BADDDA" }} scope="col">
                   
//                   </th>
//                   <th style={{ background: "#BADDDA" }} scope="col">
//                    {totalExNoOfPackages}
//                   </th>
//                   <th style={{ background: "#BADDDA" }} scope="col">
                    
//                   </th>
//                   <th style={{ background: "#BADDDA" }} scope="col">
                   
//                   </th>
//                   <th style={{ background: "#BADDDA" }} scope="col">
                   
//                   </th>
//                   <th style={{ background: "#BADDDA" }} scope="col">
                    
//                   </th>
//                 </tr>
//               </thead>
//                 <tbody>
//                   {currentItems.map((item, index) => (
//                     <tr key={index}>
//                       <td>{index + 1}</td>
//                       <td>{formatedDate(item.sirDate)}</td>
//                       <td>{item.sirNo}</td>
//                       <td>{item.parcelType}</td>
//                       <td>{item.flightNo}</td>
//                       <td>{getpartyId[item.importerId]}</td>
//                       <td>{item.nop}</td>
//                       <td>{item.mawb}</td>
//                       <td>{item.hawb}</td>
//                       <td>{item.beRequestId}</td>
//                       <td>{item.dgdc_Status}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>
//               <div
//                   style={{
//                     marginTop: 4,
//                     display: "flex",
//                     flexDirection: "row",
//                     justifyContent: "space-between",
//                   }}
//                 >
//                   <p style={{ fontWeight: "bold" }}>
//                     Total No. SIR No: {toatlSirNoCount}
//                   </p>
//                   <p style={{ fontWeight: "bold" }}>
//                     Total No. of Packages:{" "}
//                     {totalExNoOfPackages}
//                   </p>
//                   {/* <p style={{ fontWeight: "bold" }}>
//                     Total FOB Value in INR: {totalExFobValueInINR}
//                   </p> */}
//                 </div>


//               <Pagination>
//                 {Array.from({ length: totalPages }).map((_, index) => (
//                   <Pagination.Item
//                     key={index + 1}
//                     active={index + 1 === currentPage}
//                     onClick={() => handlePageChange(index + 1)}
//                   >
//                     {index + 1}
//                   </Pagination.Item>
//                 ))}

//               </Pagination>
//             </>
//           )}
//         </CardBody>
//       </Card>
//     </div>
//   );
// }



import { redirect } from "react-router-dom";
import AuthContext from "../Components/AuthProvider";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import "../Components/Style.css";
import { Button, Pagination } from 'react-bootstrap';
import { Card, CardBody, Row, Col, Form, Table, Container, FormGroup, Label, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import ipaddress from "../Components/IpAddress";
import axios from "axios";
import {
  faFileExcel,
  faFilePdf,
  faPrint,
  faSearch, faRefresh, faRegistered, faBook
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { FaFilePdf } from "react-icons/fa";
import ReactLoading from 'react-loading';
import Rate_Chart_Service from "../services/Rate_Chart_Service";
import Select from 'react-select';



function Import_transaction(props) {


  const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999, // Ensure the overlay is above other elements
    },
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedParty, setSelectedParty] = useState('');
  const [importData, setImportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalNop, setTotalNop] = useState(0);
  const [totalSirNo, setTotalSirNo] = useState(0);
  const [errors, setErrors] = useState({});
  const [DgdcStatusArray, setDgdcStatusArray] = useState([]);
  const [DGDC_Status, setDGDC_Status] = useState('');
  const [values, setValues] = useState([]);
  const {
    branchId,
    companyid,

  } = useContext(AuthContext);


  useEffect(() => {
    findDgdcStatus();
  }, []);

  const newFormatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const formatedDate = (inputDate) => {
    if (!inputDate) {
      return ""; // Return an empty string for undefined or null input
    }

    const date = new Date(inputDate);

    if (isNaN(date.getTime())) {
      return ""; // Return an empty string for invalid dates
    }
  }



  const fetchimportData = async (startDate, DGDC_Status) => {


    console.log("DGDC STAUS :" + DGDC_Status);
    const newErrors = {};

    if (!startDate) {
      newErrors['startDate'] = 'Please Select Date';
    }
    if (!DGDC_Status) {
      newErrors['DGDC_Status'] = 'Please Select DGDC_Status';
    }


    setErrors(newErrors);
    // Check if there are any errors
    if (Object.keys(newErrors).length > 0) {
      console.log("ERRORS");
      console.log(newErrors);
      return;
    }
    console.log("ERRORS");
    console.log(newErrors);
    setCurrentPage(1);
    setLoading(true);
    setImportData([]);
    try {
      const params = {
        companyId: companyid,
        branchId: branchId,
        sirDate: newFormatDate(startDate),
        status: DGDC_Status
      };

      const response = await axios.get(`https://${ipaddress}import/findimportTransactionData`, { params });
    
      let totalNop = 0;
          response.data.forEach(importItem => {
        totalNop += importItem[5];
      });

      setTotalNop(totalNop);
      setTotalSirNo(response.data.length);
      setImportData(response.data);
      

    } catch (error) {
      console.error("Error fetching import data:", error.message);
      // Handle the error or show an error message to the user
    } finally {
      setLoading(false);
    }
  };

  // const handleSearch = async (startDate, endDate, selectedParty) => {
  //   fetchimportData(startDate, endDate, selectedParty);
  // };


  const handleReset = () => {
    setImportData([]);
    setStartDate(new Date());
    setDGDC_Status('');
    setErrors({})
  };



  // Pagination 
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30; // Number of items to display per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = importData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(importData.length / itemsPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const displayPages = () => {
    const centerPageCount = 5;
    const middlePage = Math.floor(centerPageCount / 2);
    let startPage = currentPage - middlePage;
    let endPage = currentPage + middlePage;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, centerPageCount);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - centerPageCount + 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };






  const formatDate = (value) => {
    if (value === null || value === undefined || (typeof value !== 'string' && typeof value !== 'number') || value.toString().trim() === "") {
      return "";
    }
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handlePrint = async (type) => {
    setLoading(true);
    const params = {
      companyId: companyid,
      branchId: branchId,
      sirDate: newFormatDate(startDate),
      status: DGDC_Status,
      totalNoOfPackages: totalNop,
      totalNoSIR: totalSirNo
    };


    try {
      const response = await axios.get(`https://${ipaddress}import/importTransactionPrint`, { params });

      if (type === 'PDF') {
        if (response.data === 'not generated') {
          toast.error("Gate pass already generated", { position: "top-center", autoClose: 2000 });

        } else {
          const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF
          // Create a Blob from the Base64 data
          const pdfBlob = new Blob([Uint8Array.from(atob(pdfBase64), c => c.charCodeAt(0))], { type: 'application/pdf' });
          // Create a URL for the Blob
          const blobUrl = URL.createObjectURL(pdfBlob);
          // Create an anchor element for downloading
          const downloadLink = document.createElement('a');
          downloadLink.href = blobUrl;
          downloadLink.download = 'Import Transaction.pdf'; // Set the filename for the downloaded PDF
          downloadLink.style.display = 'none';
          document.body.appendChild(downloadLink);
          // Trigger the download
          downloadLink.click();
          // Clean up
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(blobUrl);
          toast.success("Downloading Pdf!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 800,
          });

        }
      } else if (type === 'PRINT') {
        if (response.data === 'not generated') {
          toast.error("Gate pass already generated", { position: "top-center", autoClose: 2000 });

        } else {
          const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF
          // Create a Blob from the Base64 data
          const pdfBlob = new Blob([Uint8Array.from(atob(pdfBase64), c => c.charCodeAt(0))], { type: 'application/pdf' });
          // Create an object URL for the Blob
          const blobUrl = URL.createObjectURL(pdfBlob);
          // Open a new window and set the PDF content as the source
          window.open(blobUrl, '_blank');

        }
      } else {
        throw new Error('Invalid print type');
      }

      // handleSearch();
    } catch (error) {
      console.error(error);
      toast.error("Gate pass already generated", { position: "top-center", autoClose: 2000 });
    } finally {
      setLoading(false);
    }
  };



  const handleXLSDownload = async () => {
    setLoading(true);
    const params = {
      companyId: companyid,
      branchId: branchId,
      sirDate: newFormatDate(startDate),
      status:  DGDC_Status,
      totalNoOfPackages: totalNop,
      totalNoSIR: totalSirNo
    };

    try {
      const response = await axios.get(`https://${ipaddress}import/importTransactionXLSDownload`, {
        params,
        responseType: 'arraybuffer', // Ensure response is treated as binary data
      });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = 'IMPORT Transaction';
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error(error);
      toast.error('Something Went Wrong', { position: 'top-center', autoClose: 2000 });
    } finally {
      setLoading(false);
    }
  };


  const findDgdcStatus = async () => {
    const PCKTYPEResponse = await Rate_Chart_Service.getjarsByJarId('J00009', companyid, branchId);
    const partyOptions = PCKTYPEResponse.data.map(jar => ({
      value: jar.jarId,
      label: jar.jarDtlDesc
    }));
    setDgdcStatusArray(partyOptions);
  };
  const handleStatusChange = (selectedOption, { action }) => {
    if (action === 'clear') {
      setDGDC_Status('')
    } else {
      setDGDC_Status(selectedOption ? selectedOption.label : '');
    }
  };





  return (
    <>
      {loading && (
        <div style={styles.overlay}>
          <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
        </div>
      )}

      <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }}>
        <FontAwesomeIcon icon={faBook} style={{ marginRight: '8px', color: 'black' }} />
        Import Transaction
      </h5>

      <Card>
        <CardBody>
          <Container>
            <Form>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label className="inputhead">From Date</Label>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        if (date) {
                          setStartDate(date);
                        } else {
                          setStartDate(null);
                        }
                      }}
                      dateFormat="dd/MM/yyyy"
                      name="startDate"
                      wrapperClassName="custom-react-datepicker-wrapper"
                      className="form-control border-right-0"
                      customInput={<input style={{ width: '100%', borderColor: errors.startDate ? '#f52b2b' : '' }} />}


                    />
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Label className="forlabel" for="branchId">DGDC Status</Label>

                    <Select
                      options={DgdcStatusArray}
                      value={{value:DGDC_Status , label:DGDC_Status}}
                      onChange={handleStatusChange}
                      isClearable
                      className={errors.DGDC_Status ? 'error-border' : ''}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                          // borderColor: errors.consoleName ? '#f52b2b' : '',
                          boxShadow: 'none',
                          '&:hover': {
                            border: '1px solid #ccc'
                          }
                        }),
                        indicatorSeparator: () => ({
                          display: 'none'
                        }),
                        dropdownIndicator: () => ({
                          display: 'none'
                        })
                      }}
                    />


                  </FormGroup>
                </Col>
                <Col md={3}>
                  <div style={{ marginTop: 30 }}>
                    <button
                      type="button"
                      className="btn me-md-2 btn-outline-primary"
                      onClick={() => fetchimportData(startDate, DGDC_Status)}
                      style={{ marginRight: '10px' }}
                    >
                      <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                      Search
                    </button>
                    <button
                      type="button"
                      className="btn gap-2 btn-outline-danger"
                      onClick={handleReset}
                    >
                      <FontAwesomeIcon icon={faRefresh} style={{ marginRight: '5px' }} />
                      Clear
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>




            {importData.length > 0 ? (
              <>
                <hr className="mt-3" />
                <Row >

                  <Col md={8}>
                  </Col>
                  <Col md={4} className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => handlePrint("PRINT")}
                      style={{ marginRight: '10px' }}
                    >
                      <FontAwesomeIcon icon={faPrint} style={{ marginRight: '5px' }} />
                      PRINT
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => handlePrint("PDF")}
                      style={{ marginRight: '10px' }}
                    >
                      <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '5px' }} />
                      PDF
                    </button>

                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={handleXLSDownload}
                    >
                      <FontAwesomeIcon icon={faFileExcel} style={{ marginRight: '5px' }} />
                      XLS
                    </button>


                  </Col>

                </Row>

                <div className="table-responsive">
                  <Table className="table table-striped table-hover" style={{ marginTop: 9 }}>
                    <thead>
                      <tr>
                        <th style={{ backgroundColor: "#BADDDA", height: '30px' }}>Sr.No</th>
                        <th style={{ backgroundColor: "#BADDDA" }}>SIR Date</th>
                        <th style={{ backgroundColor: "#BADDDA" }}>SIR No</th>
                        <th style={{ backgroundColor: "#BADDDA" }}>Parcel Type</th>
                        <th style={{ backgroundColor: "#BADDDA" }}>Flight No</th>
                        <th style={{ backgroundColor: "#BADDDA" }}>Importer Name</th>
                        <th style={{ backgroundColor: "#BADDDA" }}>No of Pkgs</th>
                        <th style={{ backgroundColor: "#BADDDA" }}>MAWB No.</th>
                        <th style={{ backgroundColor: "#BADDDA" }}>HAWB No.</th>
                        <th style={{ backgroundColor: "#BADDDA" }}>BE REQUEST ID</th>
                        <th style={{ backgroundColor: "#BADDDA" }}>Current Status</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr className="text-center">
                        <td style={{ backgroundColor: '#BADDDA' }}><b>Total</b> </td>
                        <td style={{ backgroundColor: '#BADDDA' }}></td>
                        <td style={{ backgroundColor: '#BADDDA' }}> <b>{totalSirNo}</b></td>
                        <td style={{ backgroundColor: '#BADDDA' }}></td>
                        <td style={{ backgroundColor: '#BADDDA' }}></td>
                        <td style={{ backgroundColor: '#BADDDA' }}></td>
                        <td style={{ backgroundColor: '#BADDDA' }}><b>{totalNop}</b></td>
                        <td style={{ backgroundColor: '#BADDDA' }}></td>
                        <td style={{ backgroundColor: '#BADDDA' }}></td>
                        <td style={{ backgroundColor: '#BADDDA' }}></td>
                        <td style={{ backgroundColor: '#BADDDA' }}></td>

                      </tr> 
                      {currentItems.map((currentItems, index) =>

                        <tr className="text-center dynamic-row-width">
                          <td>{((currentPage - 1) * itemsPerPage) + index + 1}</td>
                          <td>{formatDate(currentItems[0])}</td>
                          <td>{currentItems[1]}</td>
                          <td>{currentItems[2]}</td>
                          <td>{currentItems[3]}</td>
                          <td>{currentItems[4]}</td>
                          <td>{currentItems[5]}</td>
                          <td>{currentItems[6]}</td>
                          <td>{currentItems[7]}</td>
                          <td>{currentItems[8]}</td>
                          <td>{currentItems[9]}</td>
                          <td>{currentItems[10]}</td>
                        </tr>
                      )
                      }


                    </tbody>
                  </Table>

                  <div className="text-center">

                    <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
                      <Pagination.First onClick={() => handlePageChange(1)} />
                      <Pagination.Prev
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      />
                      <Pagination.Ellipsis />

                      {displayPages().map((pageNumber) => (
                        <Pagination.Item
                          key={pageNumber}
                          active={pageNumber === currentPage}
                          onClick={() => handlePageChange(pageNumber)}
                        >
                          {pageNumber}
                        </Pagination.Item>
                      ))}

                      <Pagination.Ellipsis />
                      <Pagination.Next
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      />
                      <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                    </Pagination>

                  </div>

                </div>
              </>
            ) : null}
















          </Container>
        </CardBody>
      </Card>

    </>
  );
}

export default Import_transaction;
