// // import { Link, redirect } from "react-router-dom";
// // import AuthContext from "../Components/AuthProvider";
// // import { useNavigate } from "react-router-dom";
// // import React, { useEffect, useState, useContext } from "react";
// // import "../Components/Style.css";
// // import DatePicker from "react-datepicker";
// // import Row from "react-bootstrap/Row";
// // import Col from "react-bootstrap/Col";
// // import Button from "react-bootstrap/Button";
// // import { format } from "date-fns";
// // import Form from "react-bootstrap/Form";
// // import Card from "react-bootstrap/Card";
// // import axios from "axios";
// // import Select from "react-select"; // Import Select component for dropdown
// // import { ButtonToggle, CardBody, Container, Table } from "reactstrap";
// // import PDFReport from "./PDFReport";
// // import { saveAs } from "file-saver"; // Import file-saver for triggering the download
// // import * as XLSX from "xlsx"; // Import XLSX library for creating XLS files
// // import "@react-pdf-viewer/core/lib/styles/index.css";
// // import ipaddress from "../Components/IpAddress";
// // import { toast } from "react-toastify";
// // import PartyListTable from "../Parent_Pages/PartyListTable";
// // import dgdcimage from "../Images/DGDC.png";
// // import moment from 'moment';

// // import {
// //   Page,
// //   Text,
// //   View,
// //   Document,
// //   StyleSheet,
// //   PDFViewer,
// //   Image,
// //   BlobProvider,
// //   pdf,
// // } from "@react-pdf/renderer";
// // import {
// //   faArrowsToEye,
// //   faBolt,
// //   faFileAlt,
// //   faFileExcel,
// //   faFilePdf,
// //   faPlane,
// //   faPrint,
// //   faSave,
// //   faSearch,
// // } from "@fortawesome/free-solid-svg-icons";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import Rate_Chart_Service from "../services/Rate_Chart_Service";

// // export default function Import_PCTM() {
// //   const navigate = useNavigate();
// //   const { isAuthenticated } = useContext(AuthContext);
// //   const [pctmNumbers, setPctmNumbers] = useState([]);

// //   const currentDate = new Date();
// //   const currentDateString = currentDate.toISOString().split("T")[0];

// //   const [startDate, setStartDate] = useState(new Date());
// //   const [endDate, setEndDate] = useState(new Date());
// //   const reactPageName = "Import PCTM Data Party";
// //   const [airlines, setAirlines] = useState([]);
// //   const [selectedAirline, setSelectedAirline] = useState("");
// //   const [airlineNames, setAirlineNames] = useState([]);
// //   const [selectedAllAirline, setSelectedAllAirline] = useState("");
// //   const [selectedAirlinePCTM, setSelectedAirlinePCTM] = useState("");
// //   const [importPCTMData, setImportedPCTMData] = useState([]);
// //   const [showPDFViewer, setShowPDFViewer] = useState(false);
// //   const [getOneParty, setOneParty] = useState([]);

// //   const [importData, setImportData] = useState([]);
// //   const [importAllData, setImportAllData] = useState([]);
// //   const [showTable, setShowTable] = useState(false);
// //   const [showImportDataTable, setShowImportDataTable] = useState(false);
// //   const [pdfReportData, setPDFReportData] = useState([]);
// //   const [totalEntries, setTotalEntries] = useState(0);

// //   const [showPDFReport, setShowPDFReport] = useState(false);

// //   const [showImportPDFReport, setShowImportPDFReport] = useState(false); // State for PDF report visibility

// //   const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
// //   const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
// //   // const formattedSerDate = sirDate.toISOString().split('T')[0];

// //   const makeFieldEmpty = () => {
// //     setStartDate(new Date());
// //     setEndDate(new Date());
// //     setAirlines([]);
// //     setAirlineNames([]);
// //     setImportedPCTMData([]);
// //     setImportData([]);
// //     setImportAllData([]);
// //     setPDFReportData([]);
// //     setSelectedAllAirline("");
// //     setSelectedAirlinePCTM("");
// //     setSelectedAirline("");
// //     // setExportTpData([]);
// //     // setExportData([]);
// //     // setSummaryDataForTPData([]);
// //   };

// //   const {
// //     jwtToken,
// //     user_Id,
// //     username,
// //     branchId,
// //     companyid,
// //     role,
// //     companyname,
// //     branchname,
// //     login,
// //     logout,
// //   } = useContext(AuthContext);

// //   const importFields = [
// //     "MAWB",
// //     "HAWB",
// //     "SIR_No",
// //     "Imp_Trans_Date",
// //     "IGM_No",
// //     "IGM_Date",
// //     "PCTM_No",
// //     "TP_No",
// //     "TP_Date",
// //     "Airline_Name",
// //     "Flight_No",
// //     "Flight_Date",
// //     "Country_Origin",
// //     "Port_Origin",
// //     "Importer_Id",
// //     "IEC",
// //     "SEZ_Entity_Id",
// //     "Console",
// //     "Package_Content_Type",
// //     "Parcel_Type",
// //     "UOM_Packages",
// //     "Nop",
// //     "Import_Remarks",
// //     "Description_Of_Goods",
// //     "CHA_CDE",
// //     "Assessable_Value",
// //     "Gross_Weight",
// //     "BE_Request_ID",
// //     "BE_No",
// //     "BE_Date",
// //     "Import_Address",
// //     "Status",
// //     "Sir_Date",
// //     "Created_By",
// //     "Created_Date",
// //     "Edited_By",
// //     "Edited_Date",
// //     "Approved_By",
// //     "Approved_Date",
// //   ];

// //   // const formatPctmNo = (pctmNumbers) => {
// //   //   // Remove leading zeros using a regular expression
// //   //   return pctmNumbers.replace(/^0+/, "");
// //   // };



// //   const formatPctmNo = (pctmNumbers) => {
// //     if (pctmNumbers === null || pctmNumbers === undefined) {
// //       return pctmNumbers; // Return null or undefined as it is
// //     }  
// //     // Remove leading zeros using a regular expression
// //     return pctmNumbers.replace(/^0+/, "");
// //   };

// //   const CustomHeader = () => {
// //     return (
// //       <View style={styles.header}>
// //         <Image src={dgdcimage} style={styles.image} />
// //       </View>
// //     );
// //   };

// //   const formatedDate = (inputDate) => {
// //     const date = new Date(inputDate);
// //     const year = date.getFullYear();
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const day = String(date.getDate()).padStart(2, "0");
// //     return `${day}/${month}/${year}`;
// //   };

// //   const commonTextStyle = {
// //     fontSize: 9,
// //   };

// //   const styles = StyleSheet.create({
// //     page: {
// //       flexDirection: "column",
// //       alignItems: "center",
// //       width: "100%",
// //       height: "100%",
// //       paddingTop: 9,
// //       paddingLeft: 27,
// //       paddingRight: 27,
// //       paddingTop: 27,
// //     },
// //     header: {
// //       marginBottom: 1,
// //     },
// //     table: {
// //       width: "100%",
// //       borderCollapse: "collapse",
// //       marginBottom: 5,
// //     },
// //     tableHeaderRow: {
// //       flexDirection: "row",
// //     },
// //     tableHeaderCell: {
// //       border: "0.5px solid #000",
// //       padding: 1,
// //       backgroundColor: "#f2f2f2",
// //       textAlign: "center",
// //       fontSize: 9,
// //     },
// //     tableRow: {
// //       flexDirection: "row",
// //       fontWeight: "bold",
// //     },
// //     tableCell: {
// //       border: "0.4px solid #000",
// //       padding: 1,
// //       textAlign: "center",
// //       fontSize: 7,
// //     },
// //     tableCellHeader: {
// //       fontWeight: "bold",
// //       textAlign: "center",
// //       fontSize: 9,
// //     },
// //     image: {
// //       width: 306,
// //       height: 72,
// //       // marginLeft: 18,
// //       justifyContent: "center",
// //     },

// //     text: {
// //       marginLeft: 18,
// //       ...commonTextStyle,
// //     },
// //     hr: {
// //       width: "100%",
// //       borderBottom: 1,
// //       marginTop: 5,
// //     },
// //     leftColumn: {
// //       width: "100%",
// //       paddingTop: 5,
// //     },
// //     underline: {
// //       textDecoration: "underline",
// //     },
// //   });

// //   const hrStyle = {
// //     borderTop: "5px solid black", // Adjust the thickness and color as needed
// //   };

// //   // Create a form state object with initial values for each field
// //   const initialFormState = {};
// //   importFields.forEach((field) => {
// //     initialFormState[field] = "";
// //   });

// //   const [formData, setFormData] = useState(initialFormState);
// //   // Function to handle the "SEARCH" button click

// //   const handleSearch = () => {
// //     // Make an API request here to fetch the list of airline names based on the provided criteria

// //     fetch(
// //       `https://${ipaddress}import/airline-names?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
// //     )
// //       .then((response) => response.json())
// //       .then((data) => {
// //         // Ensure that 'data' is an array before updating the 'airlines' state
// //         if (Array.isArray(data)) {
// //           // Update the 'airlines' state with the fetched data
// //           setSelectedAirline("Select ");
// //           console.log('airlines ', data);
// //           setAirlines(data);
// //         } else {
// //           console.error("API response is not an array:", data);
// //         }
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching airline names:", error);
// //       });
// //   };

// //   useEffect(() => {
// //     // Fetch airline names and preselect the first one when the component mounts
// //     handleSearch();
// //   }, []);

// //   const handleShow = () => {
// //     // You can use this function to display the fetched data in a table or perform other actions
// //     axios
// //       .get(
// //         `https://${ipaddress}import/allimportData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAirline}`
// //       )
// //       .then((response) => {
// //         const importData = response.data; // Data for the selected airline
// //         //  setSelectedAirlineFilghtDate(selectedAirlineFlightdate);
// //         setImportData(importData);
// //         console.log("Results");
// //         console.log(importData);
// //         // Update the state with the fetched data
// //         setShowTable(true);
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching data:", error);
// //       });
// //   };

// //   const [partys, setPartys] = useState([]);

// //   const [getpartyId, setGetpartyId] = useState({});
// //   const fetchPartyNames = async () => {
// //     try {
// //       const response = await fetch(
// //         `https://${ipaddress}parties/getAll/${companyid}/${branchId}`
// //       );
// //       const data = await response.json();
// //       const namesMap = {};
// //       data.forEach((party) => {
// //         namesMap[party.partyId] = party.partyName;
// //       });
// //       setGetpartyId(namesMap);
// //       setPartys(data);
// //     } catch (error) {
// //       console.error("Error fetching party names:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     fetchPartyNames();
// //   }, []);

// //   const [airlineNanmes, setAirlineNanmes] = useState([]);
// //   const [getAirlineNameByAirCode, setAirlineNameByAirCode] = useState({});
// //   const [namesMap, setNamesMap] = useState({});
// //   const fetchAirlinesNames = async () => {
// //     try {
// //       const response = await fetch(
// //         `https://${ipaddress}Airline/list/${companyid}/${branchId}`
// //       );
// //       const data = await response.json();
// //       const namesAirMap = {};
// //       data.forEach((air) => {
// //         namesAirMap[air.airlineCode] = air.airlineName;
// //       });
// //       setNamesMap(namesAirMap);
// //       setAirlineNameByAirCode(namesAirMap);
// //       console.log("Airlines  Print", namesAirMap);
// //       setAirlineNanmes(data);
// //     } catch (error) {
// //       console.error("Error fetching party names:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     fetchAirlinesNames();
// //   }, []);
// //   const getAIRNames = airlineNanmes.map((airline) => ({
// //     label: airline.airlineName, // Use the name from the namesMap
// //     value: airline.airlineCode,
// //   }));

// //   function fetchCompanyName(companyId) {
// //     // Make an Axios GET request to retrieve the company name based on companyId
// //     return axios
// //       .get(`https://${ipaddress}import/findCompanyname/${companyId}`)
// //       .then(function (response) {
// //         return response.data; // Return the retrieved company name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching company name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }
// //   function fetchExporterName(companyId, branchId, partyId) {
// //     // Make an Axios GET request to retrieve the company name based on companyId
// //     return axios
// //       .get(
// //         `https://${ipaddress}import/findPartyName/${companyId}/${branchId}/${partyId}`
// //       )
// //       .then(function (response) {
// //         return response.data; // Return the retrieved company name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching company name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }

// //   function fetchBranchName(companyId, branchId) {
// //     // Make an Axios GET request to retrieve the branch name based on branchId
// //     return axios
// //       .get(
// //         `https://${ipaddress}import/findBranchName/${companyId}/${branchId}`
// //       )
// //       .then(function (response) {
// //         return response.data; // Return the retrieved branch name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching branch name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }

// //   const calculateDistinctMAWB = (importPCTMData) => {
// //     const distinctMAWBCounts = {};

// //     importPCTMData.forEach((item) => {
// //       const mawb = item.mawb;
// //       if (mawb) {
// //         if (distinctMAWBCounts[mawb]) {
// //           distinctMAWBCounts[mawb] += 1;
// //         } else {
// //           distinctMAWBCounts[mawb] = 1;
// //         }
// //       }
// //     });

// //     return distinctMAWBCounts;
// //   };
// //   const distinctMAWBCounts = calculateDistinctMAWB(importPCTMData);
// //   const totalDistinctMAWBs = Object.keys(distinctMAWBCounts).length;

// //   const calculateDistinctIGM = (importPCTMData) => {
// //     const distinctIGMCounts = {};

// //     importPCTMData.forEach((item) => {
// //       const igmNo = item.igmNo;
// //       if (igmNo) {
// //         if (distinctIGMCounts[igmNo]) {
// //           distinctIGMCounts[igmNo] += 1;
// //         } else {
// //           distinctIGMCounts[igmNo] = 1;
// //         }
// //       }
// //     });

// //     return distinctIGMCounts;
// //   };
// //   const [consoles, setConsoles] = useState([]);
// //   const [getConsoleId, setGetConsoleId] = useState({});

// //   const fetchConsoleNames = async () => {
// //     try {
// //       const response = await fetch(
// //         `https://${ipaddress}externalParty/console/${companyid}/${branchId}`
// //       );
// //       const data = await response.json();
// //       const consoleMap = {};
// //       data.forEach((console) => {
// //         consoleMap[console.externaluserId] = console.userName;
// //       });
// //       setGetConsoleId(consoleMap);
// //       setConsoles(data);
// //     } catch (error) {
// //       console.error("Error fetching party names:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     fetchConsoleNames();
// //   }, []);
// //   const distinctIGMCounts = calculateDistinctIGM(importPCTMData);

// //   const totalDistinctIGM = Object.keys(distinctIGMCounts).length;

// //   const renderTable = () => (
// //     <View style={styles.table}>
// //       <View style={styles.tableRow}>
// //         <Text
// //           style={[
// //             styles.tableCell,
// //             styles.tableCellHeader,
// //             { width: 54, fontWeight: "bold" },
// //           ]}
// //         >
// //           Sr.No
// //         </Text>
// //         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 75 }]}>
// //           Sir No
// //         </Text>
// //         <Text
// //           style={[styles.tableCell, styles.tableCellHeader, { width: 227 }]}
// //         >
// //           Importer Name
// //         </Text>
// //         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 81 }]}>
// //           Origin Airport
// //         </Text>
// //         <Text
// //           style={[styles.tableCell, styles.tableCellHeader, { width: 120 }]}
// //         >
// //           MAWB
// //         </Text>
// //         <Text
// //           style={[styles.tableCell, styles.tableCellHeader, { width: 120 }]}
// //         >
// //           HAWB
// //         </Text>
// //         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 54 }]}>
// //           NOP
// //         </Text>
// //         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 72 }]}>
// //           Weight
// //         </Text>
// //         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 81 }]}>
// //           Desc
// //         </Text>
// //         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 72 }]}>
// //           Console
// //         </Text>
// //         <Text
// //           style={[styles.tableCell, styles.tableCellHeader, { width: 100 }]}
// //         >
// //           Remark Delivered
// //         </Text>
// //       </View>
// //       {importPCTMData.map((item, index) => (
// //         <View style={styles.tableRow} key={index}>
// //           <Text style={{ ...styles.tableCell, width: 54 }}>{index + 1}</Text>
// //           <Text style={{ ...styles.tableCell, width: 75 }}>
// //             {item.sirNo || ""}
// //           </Text>
// //           <Text style={{ ...styles.tableCell, width: 227 }}>
// //             {getpartyId[item.importerId] || ""}
// //           </Text>
// //           <Text style={{ ...styles.tableCell, width: 81 }}>
// //             {item.portOrigin || ""}
// //           </Text>
// //           <Text style={{ ...styles.tableCell, width: 120 }}>
// //             {item.mawb || ""}
// //           </Text>
// //           <Text style={{ ...styles.tableCell, width: 120 }}>
// //             {item.hawb || ""}
// //           </Text>
// //           <Text style={{ ...styles.tableCell, width: 54 }}>
// //             {item.nop || ""}
// //           </Text>
// //           <Text style={{ ...styles.tableCell, width: 72 }}>
// //             {item.grossWeight || ""}
// //           </Text>
// //           <Text style={{ ...styles.tableCell, width: 81 }}>
// //             {item.descriptionOfGoods || ""}
// //           </Text>
// //           <Text style={{ ...styles.tableCell, width: 72 }}>
// //             {getConsoleId[item.consoleName] || ""}
// //           </Text>
// //           <Text style={{ ...styles.tableCell, width: 100 }}>
// //             {item.importRemarks || ""}
// //           </Text>
// //         </View>
// //       ))}
// //     </View>
// //   );
// //   const handlePdfImportPCTMData = async () => {
// //     try {
// //       const response = await axios.post(
// //         `https://${ipaddress}import/printOfImportPctm?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}&pctmNo=${selectedAirlinePCTM}`
// //       );
// //       // toast.success("GST Summary PDF Created Successfully ", { position: "top-center" ,autoClose: 900});

// //       if (response.status === 200) {
// //         const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF

// //         // Create a Blob from the Base64 data
// //         const pdfBlob = new Blob(
// //           [Uint8Array.from(atob(pdfBase64), (c) => c.charCodeAt(0))],
// //           { type: "application/pdf" }
// //         );

// //         // Create a URL for the Blob
// //         const blobUrl = URL.createObjectURL(pdfBlob);

// //         // Create an anchor element for downloading
// //         const downloadLink = document.createElement("a");
// //         downloadLink.href = blobUrl;
// //         downloadLink.download = "import_pctm_report.pdf"; // Set the filename for the downloaded PDF
// //         downloadLink.style.display = "none";
// //         document.body.appendChild(downloadLink);

// //         // Trigger the download
// //         downloadLink.click();

// //         // Clean up
// //         document.body.removeChild(downloadLink);
// //         window.URL.revokeObjectURL(blobUrl);

// //         toast.success("Downloaded Import Pctm PDF Successfully !", {
// //           position: toast.POSITION.TOP_CENTER,
// //           autoClose: 900,
// //         });
// //       } else {
// //         throw new Error("Failed to generate PDF");
// //       }
// //     } catch (error) { }
// //   };
// //   const handlePrintAllImportPctmData = async () => {
// //     try {
// //       const response = await axios.post(
// //         `https://${ipaddress}import/printOfImportPctm?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}&pctmNo=${selectedAirlinePCTM}`
// //       );
// //       if (response.status === 200) {
// //         const base64PDF = response.data;

// //         // Create a new window for displaying the PDF
// //         const newWindow = window.open("", "_blank");

// //         // Write the HTML content to the new window
// //         newWindow.document.write(`
// //         <!DOCTYPE html>
// //         <html>
// //         <head>
// //           <title>import_pctm_report Viewer</title>
// //           <style>
// //             body {
// //               margin: 0;
// //               padding: 0;
// //               overflow: hidden;
// //             }
// //             embed {
// //               width: 100vw;
// //               height: 100vh;
// //             }
// //           </style>
// //         </head>
// //         <body>
// //           <embed src="data:application/pdf;base64,${base64PDF}" type="application/pdf" width="100%" height="100%">
// //         </body>
// //         </html>
// //       `);
// //       } else {
// //         throw new Error("Failed to generate PDF");
// //       }
// //     } catch (error) {
// //       // Handle errors if any
// //     }
// //   };
// //   const MyDocument = ({
// //     importPCTMData,
// //     selectedAllAirline,
// //     selectedAirlinePCTM,
// //     totalRows,
// //     totalDistinctMAWBs,
// //     totalDistinctIGM,
// //     totalPackages,
// //   }) => (
// //     <Document>
// //       <Page size="A4" style={styles.page}>
// //         <CustomHeader />
// //         <div
// //           style={{
// //             display: "flex",
// //             flexDirection: "column",
// //             alignItems: "center",
// //             justifyContent: "center",
// //           }}
// //         >
// //           <Text
// //             className="text-center"
// //             style={{ fontSize: 9, fontWeight: "bold" }}
// //           >
// //             IMPORT PCTM REPORT{" "}
// //           </Text>
// //           <Text className="text-center" style={{ fontSize: 9 }}>
// //             DGDC SEEPZ SEZ STRONG ROOM
// //           </Text>
// //           <Text className="text-center" style={{ fontSize: 9 }}>
// //             MAIL LTD-CSI AIRPORT ,AIR CARGO COMPLEX,SAHAR MUMBAI-400099
// //           </Text>
// //           <Text
// //             className="text-center"
// //             style={{ ...styles.underline, fontSize: 9 }}
// //           >
// //             IMPORT - PRECIOUS CARGO TRANSFER MANIFEST
// //           </Text>
// //           <Text className="text-center" style={{ fontSize: 9 }}>
// //             From Date : {formatedDate(startDate)} To Date :
// //             {formatedDate(endDate)}
// //           </Text>
// //           <br />
// //           <br />
// //         </div>

// //         <View style={styles.leftColumn}>
// //           <Text style={{ fontSize: 9, fontWeight: "bold" }}>
// //             REPORT DATE: {format(new Date(), "dd/MM/yyyy HH:mm")}
// //           </Text>
// //           <Text style={{ fontSize: 9, fontWeight: "bold" }}>
// //             PCTM NO: {formatPctmNo(importPCTMData[0].pctmNo)}
// //           </Text>
// //           <Text style={{ fontSize: 9, fontWeight: "bold" }}>
// //             IGM NO: {importPCTMData[0].igmNo}
// //           </Text>
// //         </View>
// //         <View style={styles.hr}></View>
// //         {/* <Text style={{ fontSize: 9, marginBottom: 4, marginTop: 5 }}>
// //           Received from <span style={{ fontWeight: "bold" }}>Flight -</span>{" "}
// //           {importPCTMData[0].airlineName} on{" "}
// //           <Text style={{ fontWeight: "bold" }}>Flight Date & Time -</Text>{" "}
// //           {importPCTMData[0].flightNo}{" "}
// //           {formatedDate(importPCTMData[0].flightDate)} at DGDC SEEPZ SEZ STRONG
// //           ROOM SAHAR in escort of
// //         </Text> */}
// //         <Text style={{ fontSize: 9, marginBottom: 4, marginTop: 5 }}>
// //           Received from
// //           <Text style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
// //             {" Flight - "}
// //           </Text>
// //           {importPCTMData[0].airlineName}
// //           {" on "}
// //           <Text style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
// //             {" Flight Date & Time - "}
// //           </Text>
// //           {importPCTMData[0].flightNo}{" "}
// //           {formatedDate(importPCTMData[0].flightDate)}
// //           {" at DGDC SEEPZ SEZ STRONG ROOM SAHAR in escort of"}
// //         </Text>

// //         {renderTable()}

// //         <View style={{ flexDirection: "row", paddingTop: 4 }}>
// //           <View style={{ marginRight: 27 }}>
// //             <Text style={{ fontWeight: "bold", fontSize: 9 }}>
// //               Total No. Of Packages Received:{" "}
// //               <Text style={{ fontWeight: "normal" }}>{totalPackages}</Text>
// //             </Text>
// //           </View>
// //           <View style={{ marginRight: 27 }}>
// //             <Text style={{ fontWeight: "bold", fontSize: 9 }}>
// //               By Flight No.: {importPCTMData[0].flightNo}
// //             </Text>
// //           </View>
// //           <View style={{ marginRight: 20, fontWeight: "bold" }}>
// //             <Text style={{ fontWeight: "bold", fontSize: 9 }}>
// //               Total Distinct MAWB No: {totalDistinctMAWBs}
// //             </Text>
// //           </View>
// //           <View style={{ marginRight: 20 }}>
// //             <Text style={{ fontWeight: "bold", fontSize: 9 }}>
// //               Total No. Of IGM No: {totalDistinctIGM}
// //             </Text>
// //           </View>
// //         </View>

// //         <View style={{ paddingTop: 9 }}>
// //           <View style={{ flexDirection: "row" }}>
// //             <View style={{ marginRight: 54 }}>
// //               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
// //                 ESCORT AND DEPOSITED :&nbsp;&nbsp;&nbsp; {totalPackages}
// //               </Text>
// //             </View>

// //             <View style={{ marginRight: 54 }}>
// //               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
// //                 PKGS BY FLIGHT :&nbsp;&nbsp;&nbsp;{" "}
// //                 {importPCTMData[0].airlineName}
// //               </Text>
// //             </View>
// //             <View style={{ marginLeft: 27 }}>
// //               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
// //                 ON.:&nbsp;&nbsp;&nbsp;{" "}
// //                 {formatedDate(importPCTMData[0].flightDate)}
// //               </Text>
// //             </View>
// //           </View>
// //         </View>

// //         <Text
// //           style={{
// //             fontWeight: "bold",
// //             fontSize: 9,
// //             marginTop: 18,
// //             paddingBottom: 9,
// //           }}
// //         >
// //           TO DGDC SEEPZ SEZ STRONG ROOM UNDER CUSTOMS PREVENTIVE SUPERVISION
// //         </Text>

// //         <View style={{ paddingLeft: 18 }}>
// //           <View style={{ flexDirection: "row" }}>
// //             <View style={{ width: "50%" }}>
// //               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
// //                 Airline Staff Signature:_______________{"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
// //                 Name :_______________{"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
// //                 Date & time:_______________{"\n"}
// //                 {"\n"}
// //               </Text>
// //             </View>

// //             <View style={{ width: "50%", paddingRight: 18 }}>
// //               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
// //                 I.F.O. Signature :_______________{"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
// //                 Name :_______________{"\n"}
// //                 {"\n"}
// //               </Text>

// //               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
// //                 Date & time :_______________{"\n"}
// //                 {"\n"}
// //               </Text>
// //             </View>
// //           </View>
// //         </View>

// //         <Text style={{ fontWeight: "bold", fontSize: 9, paddingTop: 9 }}>
// //           Received above consignment in full in apparent good order and
// //           condition except as noted in the Remarks Column
// //         </Text>
// //         <View style={{ paddingTop: 9 }}>
// //           <View style={{ flexDirection: "row" }}>
// //             <View
// //               style={{
// //                 width: "33%",
// //                 borderRight: "1px solid black",
// //                 paddingRight: 4,
// //                 paddingLeft: 5,
// //               }}
// //             >
// //               <Text style={{ fontSize: 9 }}>
// //                 Total No. Of Packages:&nbsp; {totalPackages}
// //                 <Text style={{ fontWeight: "bold" }}>{totalRows}</Text>
// //                 {"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontSize: 9 }}>
// //                 Receiver's Signature: {"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontSize: 9 }}>
// //                 Name: {"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontSize: 9 }}>
// //                 Asstt Custodian DGDC Seepz Sez, Strong room sahar Mumbai-99:{" "}
// //                 {"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontSize: 9 }}>
// //                 Date: {"\n"}
// //                 {"\n"}
// //               </Text>
// //             </View>

// //             <View
// //               style={{
// //                 width: "33%",
// //                 borderRight: "1px solid black",
// //                 paddingRight: 4,
// //                 paddingLeft: 5,
// //               }}
// //             >
// //               <Text style={{ fontSize: 9 }}>
// //                 Total No. Of Packages: &nbsp; {totalPackages}
// //                 <Text style={{ fontWeight: "bold" }}>{totalRows}</Text>
// //                 {"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontSize: 9 }}>
// //                 Receiver's Signature: {"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontSize: 9 }}>
// //                 Name: {"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontSize: 9 }}>
// //                 Asstt Custodian DGDC Seepz Sez, Strong room sahar Mumbai-99:{" "}
// //                 {"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontSize: 9 }}>
// //                 Date: {"\n"}
// //                 {"\n"}
// //               </Text>
// //             </View>

// //             <View style={{ width: "33%", paddingLeft: 5 }}>
// //               <Text style={{ fontSize: 9 }}>
// //                 Total No. Of Packages: &nbsp; {totalPackages}
// //                 <Text style={{ fontWeight: "bold" }}>{totalRows}</Text>
// //                 {"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontSize: 9 }}>
// //                 Receiver's Signature: {"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontSize: 9 }}>
// //                 Name: {"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontSize: 9 }}>
// //                 Asstt Custodian DGDC Seepz Sez, Strong room sahar Mumbai-99:{" "}
// //                 {"\n"}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontSize: 9 }}>
// //                 Date: {"\n"}
// //                 {"\n"}
// //               </Text>
// //             </View>
// //           </View>
// //         </View>
// //         <View style={styles.hr}></View>
// //       </Page>
// //     </Document>
// //   );

// //   const generatePDFReport = async () => {
// //     const pdfBlob = await pdf(
// //       <MyDocument
// //         {...{
// //           importPCTMData,
// //           totalDistinctMAWBs,
// //           totalDistinctIGM,
// //           totalPackages,
// //         }}
// //       />
// //     ).toBlob();
// //     saveAs(pdfBlob, "Import_PCTM.pdf");
// //   };

// //   const generateAllPDFReport = () => {
// //     if (selectedItems.length === 0) {
// //       // Show an error message if no rows are selected
// //       toast.error("No rows selected. Please select rows to generate PCTM.", {
// //         position: "top-center",
// //         autoClose: 3000,
// //         hideProgressBar: false,
// //         closeOnClick: true,
// //         pauseOnHover: true,
// //         draggable: true,
// //       });
// //       return; // Don't proceed with the API call
// //     }
// //     // console.log("SENDING.......");
// // // console.log(selectedItems);
// //   Rate_Chart_Service.updatePctmNumber(selectedItems)   
// //       .then((response) => {
// //         const reportData = response.data; // Data for the selected airline
// //         setShowPDFReport(true);
// //         console.log("RemovedRecordArray", RemovedRecordArray);
// //         // Pass the data to the PDFReport component
// //         setPDFReportData(pdfReportData);
// //         setImportAllData(importAllData);
// //         setTotalEntries(importData.length);
// //         handleShow();

// //         // Display a toast notification when pctmNo is updated successfully
// //         toast.success("pctmNo generated successfully", {
// //           position: "top-center",
// //           autoClose: 3000, // Close the notification after 3 seconds
// //           hideProgressBar: false,
// //           closeOnClick: true,
// //           pauseOnHover: true,
// //           draggable: true,
// //         });

// //         // Refresh the page
// //         // window.location.reload();
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching data:", error);

// //         // Display an error toast notification if there's an error
// //         toast.error("Error fetching data", {
// //           position: "bottom-right",
// //           autoClose: 5000, // Close the notification after 5 seconds
// //           hideProgressBar: false,
// //           closeOnClick: true,
// //           pauseOnHover: true,
// //           draggable: true,
// //         });
// //       });
// //   };

// //   useEffect(() => {
// //     if (!isAuthenticated) {
// //       navigate(
// //         "/login?message=You need to be authenticated to access this page."
// //       );
// //     }
// //   }, [isAuthenticated, navigate]);

// //   const [importAllDatalist, setImportAllDatalist] = useState([]);

// //   useEffect(() => {
// //     // Fetch data from your backend API
// //     axios
// //       .get(`https://${ipaddress}import/all/${companyid}/${branchId}`)
// //       .then((response) => {
// //         // Assuming your API response is an array of Import objects
// //         setImportAllDatalist(importAllDatalist);
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching data:", error);
// //       });
// //   }, []);
// //   const formatDate = (inputDate) => {
// //     const date = new Date(inputDate);
// //     const year = date.getFullYear();
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const day = String(date.getDate()).padStart(2, "0");
// //     return `${day}/${month}/${year}`;
// //   };

// //   const handleAllSearch = async () => {
// //     // Make an API request here to fetch the list of airline names based on the provided criteria
// //     console.log("///////////////////////////////////////////////////");
// //     console.log("StartDate " + formattedStartDate);
// //     console.log("EndDate  " + formattedEndDate);
// //     fetch(
// //       `https://${ipaddress}import/Allairline-names?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
// //     )
// //       .then((response) => response.json())
// //       .then((data) => {
// //         // console.warn(
// //         //   "******************************************************************************"
// //         // );
// //         console.log(data);
// //         // Ensure that 'data' is an array before updating the 'airlines' state
// //         if (Array.isArray(data)) {
// //           // Update the 'airlines' state with the fetched data
// //           setSelectedAllAirline("Select");
// //           setAirlineNames(data);
// //         } else {
// //           console.error("API response is not an array:", data);
// //         }
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching airline names:", error);
// //       });
// //   };

// //   const handleShowPctmData = () => {
// //     if (selectedAllAirline && selectedAirlinePCTM) {
// //       axios
// //         .get(
// //           `https://${ipaddress}import/allimportPCTMData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}&pctmNo=${selectedAirlinePCTM}`
// //         )
// //         .then((response) => {
// //           const importPCTMData = response.data; // Data for the selected PCTM number
// //           setImportedPCTMData(importPCTMData); // Update the state with the fetched data
// //           setShowImportDataTable(true);
// //           setTotalEntries(importPCTMData.length);
// //         })
// //         .catch((error) => {
// //           console.error("Error fetching PCTM data:", error);
// //         });
// //     }
// //   };

// //   useEffect(() => {
// //     // Fetch PCTM numbers based on the selected airline
// //     if (selectedAllAirline) {
// //       axios
// //         .get(
// //           `https://${ipaddress}import/getPctmNo?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}`
// //         )
// //         .then((response) => {
// //           const data = response.data;
// //           setPctmNumbers(data);
// //         })
// //         .catch((error) => {
// //           console.error("Error fetching PCTM numbers:", error);
// //         });
// //     }
// //   }, [selectedAllAirline]); // Run this effect whenever selectedAllAirline changes

// //   const totalPackages = importPCTMData.reduce(
// //     (total, item) => total + item.nop,
// //     0
// //   );

// //   const handleXLSDownload = async () => {
// //     const modifiedRecordList = await Promise.all(
// //       importPCTMData.map(async (item, index) => {
// //         // const companyname = await fetchCompanyName(item.companyId);
// //         // const branchname = await fetchBranchName(item.companyId, item.branchId);
// //         const importerId = await fetchExporterName(
// //           item.companyId,
// //           item.branchId,
// //           item.importerId
// //         );
// //         return {
// //           "Sr.No": index + 1,
// //           // "Company Name": companyname,
// //           // "Branch Name": branchname,
// //           "SIR Date": formatedDate(item.sirDate) || "",
// //           "SIR NO": item.sirNo || "",
// //           "M.A.W.B": item.mawb || "",
// //           "H.A.W.B": item.hawb.startsWith('000') ? '' : item.hawb || "", // Modify this to the actual field name
// //           "NO OF PKGS": item.nop || "",
// //           "Importer Names": getpartyId[item.importerId] || "",
// //         };
// //       })
// //     );

// //     // Calculate the total "SIR No" and "No of Pkgs"
// //     const totalSIRNo = modifiedRecordList.reduce(
// //       (total, item) => total + (item["SIR NO"] ? 1 : 0),
// //       0
// //     );

// //     const totalNoOfPkgs = modifiedRecordList.reduce(
// //       (total, item) => total + (item["NO OF PKGS"] || 0),
// //       0
// //     );
// //     const distanceRow = {
// //       "Sr.No": "",
// //       // "Company Name": "",
// //       // "Branch Name": "",
// //       "SIR Date": "",
// //       "SIR NO": "",
// //       "M.A.W.B": "",
// //       "H.A.W.B": "", // Modify this to the actual field name
// //       "NO OF PKGS": "",
// //       "Importer Names": "",
// //     };
// //     // Add a total row
// //     const totalRow = {
// //       "Sr.No": "Total ",
// //       // "Company Name": "",
// //       // "Branch Name": "",
// //       "SIR Date": "",
// //       "SIR No": totalSIRNo,
// //       "M.A.W.B": "",
// //       "H.A.W.B": "", //
// //       "NO OF PKGS": totalNoOfPkgs,
// //       "Importer Names": "",
// //     };

// //     const workbook = XLSX.utils.book_new();
// //     const worksheet = XLSX.utils.json_to_sheet([
// //       distanceRow,
// //       ...modifiedRecordList,
// //       distanceRow, // Insert the distance row
// //       totalRow, // Insert the total row
// //     ]);

// //     // Add headers for all fields
// //     const headers = Object.keys(modifiedRecordList[0]);
// //     headers.forEach((header, index) => {
// //       worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
// //         t: "s",
// //         v: header,
// //         s: { font: { bold: true } },
// //       };
// //     });

// //     // Set column widths based on data
// //     const colWidths = headers.map((header) => ({
// //       wch: header.length + 2, // You can adjust the width as needed
// //     }));

// //     worksheet["!cols"] = colWidths;

// //     XLSX.utils.book_append_sheet(workbook, worksheet, "Import_Register");
// //     const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
// //     const blob = new Blob([s2ab(xlsFile)], {
// //       type: "application/vnd.ms-excel",
// //     });
// //     saveAs(blob, "import_pctm.xls");
// //   };

// //   // Helper function to convert s2ab
// //   const s2ab = (s) => {
// //     const buf = new ArrayBuffer(s.length);
// //     const view = new Uint8Array(buf);
// //     for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
// //     return buf;
// //   };

// //   const [importerNames, setImporterNames] = useState({});

// //   const fetchImporterName = (importerId, partyId) => {
// //     // Ensure that partyId is equal to importerId
// //     if (partyId !== importerId) {
// //       console.error(
// //         `partyId (${partyId}) is not equal to importerId (${importerId}).`
// //       );
// //       return;
// //     }

// //     // Make an API request to fetch the importerName based on importerId
// //   };

// //   const totaleNoOfPackages = importData.reduce(
// //     (total, item) => total + item.nop,
// //     0
// //   );

// //   const [selectedRows, setSelectedRows] = useState([]);
// //   const [selectAllChecked, setSelectAllChecked] = useState(false);
// //   const [RemovedRecordArray, setRemovedRecordArray] = useState([]);

// //   const handleRowSelection = (item) => {

// //     const removedNumber = item.sirNo;
// //     setRemovedRecordArray(prevArray => {
// //       // Check if removedNumber is already in the array
// //       const index = prevArray.indexOf(removedNumber);
// //       console.log("gdgd", item);
// //       if (index !== -1) {
// //         // If it exists, remove it
// //         return prevArray.slice(0, index).concat(prevArray.slice(index + 1));
// //       } else {
// //         // If it doesn't exist, add it
// //         return [...prevArray, removedNumber];
// //       }
// //     });


// //     const updatedSelectedRows = selectedRows.includes(item)
// //       ? selectedRows.filter((row) => row !== item)
// //       : [...selectedRows, item];
// //     setSelectedRows(updatedSelectedRows);

// //     setSelectAllChecked(updatedSelectedRows.length === importData.length);
// //   };




// //   // const handleSelectAll = () => {
// //   //   const sirNoList = importData.map(item => item.sirNo);
// //   //   setRemovedRecordArray(sirNoList);
// //   //   // console.log(sirNoList);
// //   //   if (selectAllChecked) {
// //   //     setSelectedRows([]);
// //   //     setSelectAllChecked(false);
// //   //   } else {
// //   //     setSelectedRows(importData);
// //   //     setSelectAllChecked(true);
// //   //   }
// //   // };

// //   const [selectAll, setSelectAll] = useState(false);
// //   const [selectedItems, setSelectedItems] = useState([]);
// //   useEffect(() => {
// //     setSelectAll(selectedItems.length === importData.length);
// //     console.log("select");
// //     console.log(selectedItems);

// //   }, [selectedItems, importData]);

// //   const handleSelectAllToggle = () => {
// //     console.log("select all");
// //     console.log(selectedItems);
// //     if (selectAll) {
// //       setSelectedItems([]);
// //     } else {
// //       setSelectedItems(importData);
// //     }
// //     setSelectAll(!selectAll);
// //   };

// //   const handleRowCheckboxChange = (index) => {
// //     const selectedItem = importData[index];

// //     console.log("importData");
// //     console.log(importData);

// //     if (selectedItem) {
// //       const selectedIndex = selectedItems.findIndex((item) => item.sirNo === selectedItem.sirNo);

// //       if (selectedIndex !== -1) {
// //         // Remove the item from the selected items
// //         const updatedSelectedItems = [...selectedItems];
// //         updatedSelectedItems.splice(selectedIndex, 1);
// //         setSelectedItems(updatedSelectedItems);
// //       } else {
// //         // Add the item to the selected items
// //         setSelectedItems([...selectedItems, selectedItem]);
// //       }
// //     }

// //     console.log("Selected Items ");
// //     console.log(selectedItems);
// //   };



// //   const [pctmNoMap, setPctmNoMap] = useState({});

// //   // Function to generate pctmNo for selected rows
// //   const generatePctmNoForSelectedRows = () => {
// //     const pctmNoMapCopy = {};
// //     selectedRows.forEach((row) => {
// //       // Generate pctmNo for the selected row here
// //       // You can customize the logic to generate pctmNo as needed
// //       // For example, you can generate a unique pctmNo based on some data from the selected row.
// //       const pctmNo = generatePctmNoBasedOnRowData(row);
// //       pctmNoMapCopy[row.sirNo] = pctmNo;
// //     });
// //     setPctmNoMap(pctmNoMapCopy);
// //   };

// //   // Example function to generate a pctmNo based on row data (customize this according to your needs)
// //   const generatePctmNoBasedOnRowData = (row) => {
// //     // Replace this with your logic to generate a unique pctmNo for the row
// //     return `${row.sirNo}_PCTM`;
// //   };



// //   //Import PCTM

// // const formatDateTime2 = (value) => {
// //   if (!value) {
// //     return "";
// //   }
// //   const date = new Date(value);
// //   const day = String(date.getDate()).padStart(2, "0");
// //   const month = String(date.getMonth() + 1).padStart(2, "0");
// //   const year = date.getFullYear();
// //   const hours = String(date.getHours()).padStart(2, "0");
// //   const minutes = String(date.getMinutes()).padStart(2, "0");
// //   const seconds = String(date.getSeconds()).padStart(2, "0");
// //   return `${day}/${month}/${year} `;
// // };
// // const getExcel = (imp) => {
// //   const filename = `Import_PCTM_${formatDateTime2(new Date())}.xlsx`; // Note: Changed file extension to xlsx
// //   axios.post(`https://${ipaddress}import/pctmexcel`, imp, { responseType: 'blob' }) // Added responseType: 'blob'
// //     .then(async (response) => {
// //       const blob = new Blob([response.data], { type: response.headers['content-type'] });

// //       // Create a temporary URL for the blob
// //       const url = window.URL.createObjectURL(blob);

// //       // Create a link element to trigger the download
// //       const a = document.createElement("a");
// //       a.href = url;
// //       a.download = filename;
// //       document.body.appendChild(a);
// //       a.click();

// //       // Clean up
// //       window.URL.revokeObjectURL(url);
// //       document.body.removeChild(a);
// //     })
// //     .catch((error) => {
// //       toast.error("Something went wrong", {
// //         autoClose: 700
// //       });
// //     });
// // };

// //   return (
// //     <div className="Container">
// //       <h5
// //         className="pageHead"
// //         style={{
// //           fontFamily: "Your-Heading-Font",
// //           paddingLeft: "2%",
// //           paddingRight: "-0px",
// //         }}
// //       >
// //         {" "}
// //         <FontAwesomeIcon
// //           icon={faPlane}
// //           style={{
// //             marginRight: "8px",
// //             color: "black", // Set the color to golden
// //           }}
// //         />
// //         Import PCTM
// //       </h5>
// //       <Card>
// //         <Container>
// //           <div className="Container" style={{ marginTop: 20 }}>
// //             <ul className="nav nav-tabs" id="myTab" role="tablist">
// //               <li className="nav-item tabspace" role="presentation">
// //                 <button
// //                   style={{ color: "gray" }}
// //                   className="nav-link active"
// //                   id="home-tab"
// //                   data-bs-toggle="tab"
// //                   data-bs-target="#home"
// //                   type="button"
// //                   role="tab"
// //                   aria-controls="home"
// //                   aria-selected="true"
// //                   onClick={(e) => makeFieldEmpty()}
// //                 >
// //                   <h6>Generate</h6>
// //                 </button>
// //               </li>

// //               <li className="nav-item tabspace" role="presentation">
// //                 <button
// //                   style={{ color: "gray" }}
// //                   className="nav-link"
// //                   id="contact-tab"
// //                   data-bs-toggle="tab"
// //                   data-bs-target="#contact"
// //                   type="button"
// //                   role="tab"
// //                   aria-controls="contact"
// //                   aria-selected="false"
// //                   onClick={(e) => makeFieldEmpty()}
// //                 >
// //                   <h6>Print</h6>
// //                 </button>
// //               </li>
// //             </ul>
// //             <div className="tab-content" id="myTabContent">
// //               <div
// //                 className="tab-pane fade show active"
// //                 id="home"
// //                 role="tabpanel"
// //                 aria-labelledby="home-tab"
// //               >
// //                 <Card
// //                   style={{
// //                     marginTop: 25,
// //                     marginBottom: 25,

// //                     padding: 8,
// //                   }}
// //                 >
// //                   <h5
// //                     className="pageHead"
// //                     style={{
// //                       fontFamily: "Your-Heading-Font",
// //                       paddingLeft: "3%",
// //                     }}
// //                   >
// //                     {" "}
// //                     <FontAwesomeIcon
// //                       icon={faBolt}
// //                       style={{
// //                         marginRight: "8px",
// //                         color: "black", // Set the color to golden
// //                       }}
// //                     />{" "}
// //                     Generate{" "}
// //                   </h5>
// //                   <Container>
// //                     <Form>
// //                       <Row>
// //                         <Col md={3}>
// //                           <Form.Group>
// //                             <Form.Label className="forlabel">
// //                               SIR Date From
// //                             </Form.Label>
// //                             <DatePicker
// //                               selected={startDate}
// //                               wrapperClassName="custom-react-datepicker-wrapper"
// //                               onChange={(date) => {
// //                                 if (date) {
// //                                   setStartDate(date);
// //                                 }
// //                               }}
// //                               value={startDate}
// //                               dateFormat="dd/MM/yyyy"
// //                               className="form-control"
// //                               customInput={<input style={{ width: "100%" }} />}
// //                             />
// //                           </Form.Group>
// //                         </Col>
// //                         <Col md={3}>
// //                           <Form.Group>
// //                             <Form.Label className="forlabel">
// //                               SIR Date To
// //                             </Form.Label>
// //                             <DatePicker
// //                               selected={endDate}
// //                               wrapperClassName="custom-react-datepicker-wrapper"
// //                               onChange={(date) => {
// //                                 if (date) {
// //                                   setEndDate(date);
// //                                 }
// //                               }}
// //                               value={endDate}
// //                               dateFormat="dd/MM/yyyy"
// //                               className="form-control"
// //                               customInput={<input style={{ width: "100%" }} />}
// //                             />
// //                           </Form.Group>
// //                         </Col>
// //                         <Col md={2}>
// //                           <Button
// //                             type="button"
// //                             style={{
// //                               marginTop: 32,
// //                             }}
// //                             className="allbutton"
// //                             variant="outline-primary"
// //                             onClick={handleSearch}
// //                           >
// //                             <FontAwesomeIcon
// //                               icon={faSearch}
// //                               style={{ marginRight: "5px" }}
// //                             />
// //                             Search
// //                           </Button>
// //                         </Col>
// //                         <Col md={2}>
// //                           <Form.Group>
// //                             <Form.Label className="forlabel">
// //                               Select Air Lines
// //                             </Form.Label>
// //                             <Select
// //                               name="airlines"
// //                               options={[
// //                                 // Placeholder/default value
// //                                 ...airlines.map((airline) => ({
// //                                   label: getAirlineNameByAirCode[airline],
// //                                   value: airline,
// //                                 })),
// //                               ]}
// //                               value={{
// //                                 label: getAirlineNameByAirCode[selectedAirline],
// //                                 value: selectedAirline,
// //                               }}
// //                               onChange={(option) =>
// //                                 setSelectedAirline(option.value)
// //                               }
// //                             />
// //                           </Form.Group>
// //                         </Col>
// //                         <Col md={2}>
// //                           <Button
// //                             type="button"
// //                             style={{
// //                               marginTop: 32,
// //                               marginLeft: 5,
// //                             }}
// //                             className="allbutton"
// //                             variant="outline-primary"
// //                             onClick={handleShow}
// //                           >
// //                             <FontAwesomeIcon
// //                               icon={faArrowsToEye}
// //                               style={{ marginRight: "5px" }}
// //                             />
// //                             Show
// //                           </Button>
// //                         </Col>
// //                       </Row>
// //                     </Form>
// //                   </Container>
// //                 </Card>
// //               </div>

// //               {importData.length > 0 && (
// //                 <div>
// //                   <hr />
// //                   <h5
// //                     className="pageHead"
// //                     style={{
// //                       fontFamily: "Your-Heading-Font",
// //                       paddingLeft: "4%",
// //                       marginTop: "30px",
// //                     }}
// //                   >
// //                     {" "}
// //                     <FontAwesomeIcon
// //                       icon={faFileAlt}
// //                       style={{
// //                         marginRight: "8px",
// //                         color: "black", // Set the color to golden
// //                       }}
// //                     />{" "}
// //                     Imported Data
// //                   </h5>

// //                   <div className="table-responsive">
// //                     <Table className="table table-striped table-hover">
// //                       <thead>
// //                         <tr>
// //                           {" "}
// //                           <th
// //                             style={{ backgroundColor: "#BADDDA" }}
// //                             scope="col"
// //                           >
// //                             {/* <input type="checkbox" onChange={handleSelectAll} /> */}

// //                             <input
// //                               type="checkbox"
// //                               checked={selectAll}
// //                               onChange={() => handleSelectAllToggle()}
// //                             />
// //                           </th>
// //                           <th style={{ backgroundColor: "#BADDDA" }}>Sr.No</th>
// //                           <th style={{ backgroundColor: "#BADDDA" }}>
// //                             Airline Name
// //                           </th>
// //                           <th style={{ backgroundColor: "#BADDDA" }}>MAWB</th>
// //                           <th style={{ backgroundColor: "#BADDDA" }}>Sir No</th>
// //                           <th style={{ backgroundColor: "#BADDDA" }}>
// //                             Sir Date
// //                           </th>
// //                           <th style={{ backgroundColor: "#BADDDA" }}>
// //                             Parcle Type
// //                           </th>
// //                           <th style={{ backgroundColor: "#BADDDA" }}>HAWB</th>
// //                           <th style={{ backgroundColor: "#BADDDA" }}>NOP</th>
// //                           {/* Add more column headers as needed */}
// //                         </tr>
// //                       </thead>
// //                       <tbody>
// //                         {importData.map((item, index) => (
// //                           <tr key={index}>
// //                             <td>
// //                               {/* <input
// //                                 type="checkbox"
// //                                 checked={selectedRows.includes(item)}
// //                                 onChange={(e) => handleRowSelection(e, index)}
// //                               /> */}

// //                               <input
// //                                 type="checkbox"
// //                                 name="checkimport"
// //                                 checked={selectedItems.some((item) => item.sirNo === importData[index].sirNo)}
// //                                 onChange={() => handleRowCheckboxChange(index)}
// //                               />
// //                             </td>
// //                             <td>{index + 1}</td>
// //                             <td>{item.airlineName}</td>
// //                             <td>{item.mawb}</td>
// //                             <td>{item.sirNo}</td>
// //                             <td>{formatDate(item.sirDate)}</td>
// //                             <td>{item.parcelType}</td>
// //                             <td>{item.hawb.startsWith('000') ? '' : item.hawb}</td>
// //                             <td>{item.nop}</td>
// //                             {/* Add more table cells as needed */}
// //                           </tr>
// //                         ))}
// //                       </tbody>
// //                     </Table>
// //                   </div>

// //                   <CardBody>
// //                     <p>Total Packages Received - {totaleNoOfPackages}</p>
// //                     <Button
// //                       variant="outline-primary"
// //                       style={{ float: "right" }}
// //                       onClick={generateAllPDFReport}
// //                     >
// //                       {" "}
// //                       <FontAwesomeIcon
// //                         icon={faBolt}
// //                         style={{ marginRight: "5px" }}
// //                       />
// //                       Generate PCTM
// //                     </Button>
// //                   </CardBody>
// //                 </div>
// //               )}
// //               <hr className="mt-3" />
// //               <div
// //                 className="tab-pane fade"
// //                 id="contact"
// //                 role="tabpanel"
// //                 aria-labelledby="contact-tab"
// //               >
// //                 <h5
// //                   className="pageHead"
// //                   style={{
// //                     fontFamily: "Your-Heading-Font",
// //                     paddingLeft: "3%",
// //                     paddingRight: "-50px",
// //                   }}
// //                 >
// //                   {" "}
// //                   <FontAwesomeIcon
// //                     icon={faPrint}
// //                     style={{
// //                       marginRight: "8px",
// //                       color: "black", // Set the color to golden
// //                     }}
// //                   />{" "}
// //                   Print
// //                 </h5>

// //                 <Card
// //                   style={{
// //                     marginTop: 25,
// //                     marginRight: 18,
// //                     marginLeft: 18,
// //                     padding: 8,
// //                   }}
// //                 >
// //                   <Container></Container>
// //                   <Container>
// //                     <Form>
// //                       <Row>
// //                         <Col md={2}>
// //                           <Form.Group>
// //                             <Form.Label className="forlabel">
// //                               SIR Date From
// //                             </Form.Label>
// //                             <DatePicker
// //                               selected={startDate}
// //                               wrapperClassName="custom-react-datepicker-wrapper"
// //                               onChange={(date) => {
// //                                 if (date) {
// //                                   setStartDate(date);
// //                                 }
// //                               }}
// //                               value={startDate}
// //                               dateFormat="dd/MM/yyyy"
// //                               className="form-control"
// //                               customInput={<input style={{ width: "100%" }} />}
// //                             />
// //                           </Form.Group>
// //                         </Col>
// //                         <Col md={2}>
// //                           <Form.Group>
// //                             <Form.Label className="forlabel">
// //                               SIR Date To
// //                             </Form.Label>
// //                             <DatePicker
// //                               selected={endDate}
// //                               wrapperClassName="custom-react-datepicker-wrapper"
// //                               onChange={(date) => {
// //                                 if (date) {
// //                                   setEndDate(date);
// //                                 }
// //                               }}
// //                               value={endDate}
// //                               dateFormat="dd/MM/yyyy"
// //                               className="form-control"
// //                               customInput={<input style={{ width: "100%" }} />}
// //                             />
// //                           </Form.Group>
// //                         </Col>
// //                         <Col md={2}>
// //                           <Button
// //                             type="button"
// //                             style={{
// //                               marginTop: 32,
// //                             }}
// //                             className="allbutton"
// //                             variant="outline-primary"
// //                             onClick={handleAllSearch}
// //                           >
// //                             <FontAwesomeIcon
// //                               icon={faSearch}
// //                               style={{ marginRight: "5px" }}
// //                             />
// //                             Search
// //                           </Button>
// //                         </Col>

// //                         <Col sm={2}>
// //                           <Form.Group>
// //                             <Form.Label className="forlabel">
// //                               Select Air Lines
// //                             </Form.Label>
// //                             {/* Use the Select component for the dropdown */}

// //                             <Select
// //                               name="airlines"
// //                               options={[
// //                                 // Placeholder/default value
// //                                 ...airlineNames.map((airline) => ({
// //                                   label: getAirlineNameByAirCode[airline],
// //                                   value: airline,
// //                                 })),
// //                               ]}
// //                               value={{
// //                                 label:
// //                                   getAirlineNameByAirCode[selectedAllAirline],
// //                                 value: selectedAllAirline,
// //                               }}
// //                               onChange={(option) =>
// //                                 setSelectedAllAirline(option.value)
// //                               }
// //                             />
// //                           </Form.Group>
// //                         </Col>
// //                         <Col sm={2}>
// //                           <Form.Group>
// //                             <Form.Label className="forlabel">
// //                               Select PCTM No
// //                             </Form.Label>
// //                             <Select
// //                               name="pctmNumbers"
// //                               options={pctmNumbers.map((pctm) => ({
// //                                 label: formatPctmNo(pctm),
// //                                 value: pctm,
// //                               }))}
// //                               value={{
// //                                 label: formatPctmNo(selectedAirlinePCTM),
// //                                 value: selectedAirlinePCTM,
// //                               }}
// //                               onChange={(option) =>
// //                                 setSelectedAirlinePCTM(option.value)
// //                               }
// //                             />
// //                           </Form.Group>
// //                         </Col>
// //                         <Col sm={2}>
// //                           <Form.Label className="forlabel">
// //                             Search PCTM
// //                           </Form.Label>
// //                           <Button
// //                             type="button"
// //                             className="allbutton"
// //                             variant="outline-primary"
// //                             onClick={handleShowPctmData}
// //                           >
// //                             <FontAwesomeIcon
// //                               icon={faSearch}
// //                               style={{ marginRight: "5px" }}
// //                             />
// //                             PCTM
// //                           </Button>
// //                         </Col>
// //                       </Row>
// //                     </Form>
// //                   </Container>
// //                 </Card>

// //                 <Card style={{ marginTop: 18, marginBottom: 18 }}>
// //                   <Container>
// //                     {importPCTMData.length > 0 && (
// //                       <div>
// //                         {importPCTMData.length > 0 && (
// //                           <div>
// //                             {/* ... Existing JSX ... */}
// //                             <div>
// //                               <Button
// //                                 type="button"
// //                                 color="danger"
// //                                 outline
// //                                 style={{
// //                                   float: "right",
// //                                   marginLeft: '5x',
// //                                   textDecoration: "none",
// //                                 }}
// //                                 onClick={handlePrintAllImportPctmData}
// //                               >
// //                                 <FontAwesomeIcon
// //                                   icon={faPrint}
// //                                   style={{ marginRight: "5px" }}
// //                                 />
// //                                 Print
// //                               </Button>
// //                               <Button
// //                                 type="button"
// //                                 className="allbutton"
// //                                 variant="outline-success"
// //                                 onClick={()=>getExcel(importPCTMData)}
// //                                 // style={{ float: "right" }}
// //                                 style={{ marginRight: "5px" , float: "right"}}
// //                               >
// //                                 <FontAwesomeIcon
// //                                   icon={faFileExcel}
// //                                   style={{ marginRight: "5px" }}
// //                                 />
// //                                 XLS
// //                               </Button>
// //                               {/* <BlobProvider
// //                                 document={
// //                                   <MyDocument
// //                                     importPCTMData={importPCTMData}
// //                                     totalDistinctIGM={totalDistinctIGM}
// //                                     totalDistinctMAWBs={totalDistinctMAWBs}
// //                                     totalPackages={totalPackages}
// //                                   />
// //                                 }
// //                               >
// //                                 {({ blob, url, loading, error }) => (
// //                                   <a
// //                                     href={url}
// //                                     style={{ textDecoration: "none" }}
// //                                     target="_blank"
// //                                     rel="noopener noreferrer"
// //                                   >
// //                                     <Button
// //                                       type="button"
// //                                       color="danger"
// //                                       outline
// //                                       style={{
// //                                         float: "right",
// //                                         marginLeft: 4,
// //                                         textDecoration: "none",
// //                                       }}
// //                                     >
// //                                       <FontAwesomeIcon
// //                                         icon={faPrint}
// //                                         style={{ marginRight: "5px" }}
// //                                       />
// //                                       PRINT
// //                                     </Button>
// //                                   </a>
// //                                 )}
// //                               </BlobProvider> */}
// //                               <Button
// //                                 outline
// //                                 id="pdfButton"
// //                                 color="danger"
// //                                 style={{  marginRight: "5px", float: "right" }}
// //                                 onClick={handlePdfImportPCTMData}
// //                               >
// //                                 <FontAwesomeIcon
// //                                   icon={faFilePdf}
// //                                   style={{ marginLeft: "5px" }}
// //                                 />
// //                                 PDF
// //                               </Button>
// //                             </div>
// //                           </div>
// //                         )}

// //                         <h5
// //                           style={{
// //                             marginTop: 9,
// //                             marginRight: 9,
// //                             marginBottom: 27,
// //                           }}
// //                         >
// //                           Imported PCTM Data
// //                         </h5>

// //                         <div className="table-responsive">
// //                           <Table className="table table-striped table-hover">
// //                             <thead>
// //                               <tr>
// //                                 <th style={{ backgroundColor: "#BADDDA" }}>
// //                                   Sr.No
// //                                 </th>
// //                                 <th style={{ backgroundColor: "#BADDDA" }}>
// //                                   M.A.W.B. No
// //                                 </th>
// //                                 <th style={{ backgroundColor: "#BADDDA" }}>
// //                                   Sir Date
// //                                 </th>
// //                                 <th style={{ backgroundColor: "#BADDDA" }}>
// //                                   Sir No
// //                                 </th>
// //                                 <th style={{ backgroundColor: "#BADDDA" }}>
// //                                   Parcle Type
// //                                 </th>

// //                                 <th style={{ backgroundColor: "#BADDDA" }}>
// //                                   H.A.W.B. No
// //                                 </th>

// //                                 <th style={{ backgroundColor: "#BADDDA" }}>
// //                                   No Of Pkgs
// //                                 </th>
// //                                 <th style={{ backgroundColor: "#BADDDA" }}>
// //                                   Importer Name
// //                                 </th>
// //                               </tr>
// //                             </thead>
// //                             <tbody>
// //                               {importPCTMData.map((item, index) => (
// //                                 <tr key={index}>
// //                                   <td>{index + 1}</td>
// //                                   <td>{item.mawb}</td>
// //                                   <td>{formatDate(item.sirDate)}</td>
// //                                   <td>{item.sirNo}</td>
// //                                   <td>{item.parcelType}</td>
// //                                   <td>{item.hawb.startsWith('000') ? '' : item.hawb}</td>
// //                                   <td>{item.nop}</td>
// //                                   <td>{getpartyId[item.importerId]}</td>
// //                                   {/* Add more table cells as needed */}
// //                                 </tr>
// //                               ))}
// //                             </tbody>
// //                           </Table>
// //                         </div>
// //                         <p>Total Packages: {totalPackages}</p>

// //                         {showImportPDFReport && (
// //                           <PDFReport
// //                             data={importPCTMData}
// //                             startDate={startDate}
// //                             endDate={endDate}
// //                             selectedAirline={selectedAllAirline}
// //                           //selectedAirlineFlightdate={selectedAirlineFlightdate}
// //                           />
// //                         )}
// //                       </div>
// //                     )}
// //                   </Container>
// //                 </Card>
// //               </div>
// //             </div>
// //           </div>
// //         </Container>
// //       </Card>
// //     </div>
// //   );
// // }



// import { Link, redirect } from "react-router-dom";
// import AuthContext from "../Components/AuthProvider";
// import { useNavigate } from "react-router-dom";
// import React, { useEffect, useState, useContext } from "react";
// import "../Components/Style.css";
// import DatePicker from "react-datepicker";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
// import { format } from "date-fns";
// import Form from "react-bootstrap/Form";
// import Card from "react-bootstrap/Card";
// import axios from "axios";
// import Select from "react-select"; // Import Select component for dropdown
// import { ButtonToggle, CardBody, Container, Table } from "reactstrap";
// import PDFReport from "./PDFReport";
// import { saveAs } from "file-saver"; // Import file-saver for triggering the download
// import * as XLSX from "xlsx"; // Import XLSX library for creating XLS files
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import ipaddress from "../Components/IpAddress";
// import { toast } from "react-toastify";
// import PartyListTable from "../Parent_Pages/PartyListTable";
// import dgdcimage from "../Images/DGDC.png";
// import moment from 'moment';

// import {
//   Page,
//   Text,
//   View,
//   Document,
//   StyleSheet,
//   PDFViewer,
//   Image,
//   BlobProvider,
//   pdf,
// } from "@react-pdf/renderer";
// import {
//   faArrowsToEye,
//   faBolt,
//   faFileAlt,
//   faFileExcel,
//   faFilePdf,
//   faPlane,
//   faPrint,
//   faSave,
//   faSearch,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Rate_Chart_Service from "../services/Rate_Chart_Service";
// import ReactLoading from 'react-loading';


// export default function Import_PCTM() {


//   const styles2 = {
//     overlay: {
//       position: 'fixed',
//       top: 0,
//       left: 0,
//       width: '100%',
//       height: '100%',
//       backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       zIndex: 9999, // Ensure the overlay is above other elements
//     },
//   };


//   const navigate = useNavigate();
//   const { isAuthenticated } = useContext(AuthContext);
//   const [pctmNumbers, setPctmNumbers] = useState([]);

//   const currentDate = new Date();
//   const currentDateString = currentDate.toISOString().split("T")[0];

//   const [startDate, setStartDate] = useState(new Date());
//   const [endDate, setEndDate] = useState(new Date());
//   const reactPageName = "Import PCTM Data Party";
//   const [airlines, setAirlines] = useState([]);
//   const [selectedAirline, setSelectedAirline] = useState("");
//   const [airlineNames, setAirlineNames] = useState([]);
//   const [selectedAllAirline, setSelectedAllAirline] = useState("");
//   const [selectedAirlinePCTM, setSelectedAirlinePCTM] = useState("");
//   const [importPCTMData, setImportedPCTMData] = useState([]);
//   const [showPDFViewer, setShowPDFViewer] = useState(false);
//   const [getOneParty, setOneParty] = useState([]);
//   const [loading, setLoading] = useState(false);




//   const [importData, setImportData] = useState([]);
//   const [importAllData, setImportAllData] = useState([]);
//   const [showTable, setShowTable] = useState(false);
//   const [showImportDataTable, setShowImportDataTable] = useState(false);
//   const [pdfReportData, setPDFReportData] = useState([]);
//   const [totalEntries, setTotalEntries] = useState(0);

//   const [showPDFReport, setShowPDFReport] = useState(false);

//   const [showImportPDFReport, setShowImportPDFReport] = useState(false); // State for PDF report visibility

//   const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
//   const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
//   // const formattedSerDate = sirDate.toISOString().split('T')[0];

//   const makeFieldEmpty = () => {
//     setStartDate(new Date());
//     setEndDate(new Date());
//     setAirlines([]);
//     setAirlineNames([]);
//     setImportedPCTMData([]);
//     setImportData([]);
//     setImportAllData([]);
//     setPDFReportData([]);
//     setSelectedAllAirline("");
//     setSelectedAirlinePCTM("");
//     setSelectedAirline("");
//     // setExportTpData([]);
//     // setExportData([]);
//     // setSummaryDataForTPData([]);
//   };

//   const {
//     jwtToken,
//     user_Id,
//     username,
//     branchId,
//     companyid,
//     role,
//     companyname,
//     branchname,
//     login,
//     logout,
//   } = useContext(AuthContext);

//   const importFields = [
//     "MAWB",
//     "HAWB",
//     "SIR_No",
//     "Imp_Trans_Date",
//     "IGM_No",
//     "IGM_Date",
//     "PCTM_No",
//     "TP_No",
//     "TP_Date",
//     "Airline_Name",
//     "Flight_No",
//     "Flight_Date",
//     "Country_Origin",
//     "Port_Origin",
//     "Importer_Id",
//     "IEC",
//     "SEZ_Entity_Id",
//     "Console",
//     "Package_Content_Type",
//     "Parcel_Type",
//     "UOM_Packages",
//     "Nop",
//     "Import_Remarks",
//     "Description_Of_Goods",
//     "CHA_CDE",
//     "Assessable_Value",
//     "Gross_Weight",
//     "BE_Request_ID",
//     "BE_No",
//     "BE_Date",
//     "Import_Address",
//     "Status",
//     "Sir_Date",
//     "Created_By",
//     "Created_Date",
//     "Edited_By",
//     "Edited_Date",
//     "Approved_By",
//     "Approved_Date",
//   ];

//   // const formatPctmNo = (pctmNumbers) => {
//   //   // Remove leading zeros using a regular expression
//   //   return pctmNumbers.replace(/^0+/, "");
//   // };



//   const formatPctmNo = (pctmNumbers) => {
//     if (pctmNumbers === null || pctmNumbers === undefined) {
//       return pctmNumbers; // Return null or undefined as it is
//     }
//     // Remove leading zeros using a regular expression
//     return pctmNumbers.replace(/^0+/, "");
//   };

//   const CustomHeader = () => {
//     return (
//       <View style={styles.header}>
//         <Image src={dgdcimage} style={styles.image} />
//       </View>
//     );
//   };

//   const formatedDate = (inputDate) => {
//     const date = new Date(inputDate);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${day}/${month}/${year}`;
//   };

//   const commonTextStyle = {
//     fontSize: 9,
//   };

//   const styles = StyleSheet.create({
//     page: {
//       flexDirection: "column",
//       alignItems: "center",
//       width: "100%",
//       height: "100%",
//       paddingTop: 9,
//       paddingLeft: 27,
//       paddingRight: 27,
//       paddingTop: 27,
//     },
//     header: {
//       marginBottom: 1,
//     },
//     table: {
//       width: "100%",
//       borderCollapse: "collapse",
//       marginBottom: 5,
//     },
//     tableHeaderRow: {
//       flexDirection: "row",
//     },
//     tableHeaderCell: {
//       border: "0.5px solid #000",
//       padding: 1,
//       backgroundColor: "#f2f2f2",
//       textAlign: "center",
//       fontSize: 9,
//     },
//     tableRow: {
//       flexDirection: "row",
//       fontWeight: "bold",
//     },
//     tableCell: {
//       border: "0.4px solid #000",
//       padding: 1,
//       textAlign: "center",
//       fontSize: 7,
//     },
//     tableCellHeader: {
//       fontWeight: "bold",
//       textAlign: "center",
//       fontSize: 9,
//     },
//     image: {
//       width: 306,
//       height: 72,
//       // marginLeft: 18,
//       justifyContent: "center",
//     },

//     text: {
//       marginLeft: 18,
//       ...commonTextStyle,
//     },
//     hr: {
//       width: "100%",
//       borderBottom: 1,
//       marginTop: 5,
//     },
//     leftColumn: {
//       width: "100%",
//       paddingTop: 5,
//     },
//     underline: {
//       textDecoration: "underline",
//     },
//   });

//   const hrStyle = {
//     borderTop: "5px solid black", // Adjust the thickness and color as needed
//   };

//   // Create a form state object with initial values for each field
//   const initialFormState = {};
//   importFields.forEach((field) => {
//     initialFormState[field] = "";
//   });

//   const [formData, setFormData] = useState(initialFormState);
//   // Function to handle the "SEARCH" button click

//   const handleSearch = () => {
//     // Make an API request here to fetch the list of airline names based on the provided criteria
//     setLoading(true);
  
//     try {

//       fetch(
//         `https://${ipaddress}import/airline-names?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
//       )
//         .then((response) => response.json())
//         .then((data) => {
//           // Ensure that 'data' is an array before updating the 'airlines' state

//           console.log("Loading  In Try *** : "+loading);
//           if (Array.isArray(data)) {
//             // Update the 'airlines' state with the fetched data
//             setSelectedAirline("Select ");
           
//             setAirlines(data);
//           } else {
//             console.error("API response is not an array:", data);
//           }
//         })
//     }
//     catch (error) {
//       toast.error('Something went wrong', {
//         position: toast.POSITION.TOP_CENTER,
//         autoClose: 600,
//       });
//     }
//     finally {
//     setLoading(false);
//     } 
//   };

//   useEffect(() => {
//     // Fetch airline names and preselect the first one when the component mounts
//     handleSearch();
//   }, []);

//   const handleShow = () => {
//     // You can use this function to display the fetched data in a table or perform other actions
//     axios
//       .get(
//         `https://${ipaddress}import/allimportData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAirline}`
//       )
//       .then((response) => {
//         const importData = response.data; // Data for the selected airline
//         //  setSelectedAirlineFilghtDate(selectedAirlineFlightdate);
//         setImportData(importData);
//         console.log("Results");
//         console.log(importData);
//         // Update the state with the fetched data
//         setShowTable(true);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   };

//   const [partys, setPartys] = useState([]);

//   const [getpartyId, setGetpartyId] = useState({});
//   const fetchPartyNames = async () => {
//     try {
//       const response = await fetch(
//         `https://${ipaddress}parties/getAll/${companyid}/${branchId}`
//       );
//       const data = await response.json();
//       const namesMap = {};
//       data.forEach((party) => {
//         namesMap[party.partyId] = party.partyName;
//       });
//       setGetpartyId(namesMap);
//       setPartys(data);
//     } catch (error) {
//       console.error("Error fetching party names:", error);
//     }
//   };

//   useEffect(() => {
//     fetchPartyNames();
//   }, []);

//   const [airlineNanmes, setAirlineNanmes] = useState([]);
//   const [getAirlineNameByAirCode, setAirlineNameByAirCode] = useState({});
//   const [namesMap, setNamesMap] = useState({});
//   const fetchAirlinesNames = async () => {
//     try {
//       const response = await fetch(
//         `https://${ipaddress}Airline/list/${companyid}/${branchId}`
//       );
//       const data = await response.json();
//       const namesAirMap = {};
//       data.forEach((air) => {
//         namesAirMap[air.airlineCode] = air.airlineName;
//       });
//       setNamesMap(namesAirMap);
//       setAirlineNameByAirCode(namesAirMap);
//       console.log("Airlines  Print", namesAirMap);
//       setAirlineNanmes(data);
//     } catch (error) {
//       console.error("Error fetching party names:", error);
//     }
//   };

//   useEffect(() => {
//     fetchAirlinesNames();
//   }, []);
//   const getAIRNames = airlineNanmes.map((airline) => ({
//     label: airline.airlineName, // Use the name from the namesMap
//     value: airline.airlineCode,
//   }));

//   function fetchCompanyName(companyId) {
//     // Make an Axios GET request to retrieve the company name based on companyId
//     return axios
//       .get(`https://${ipaddress}import/findCompanyname/${companyId}`)
//       .then(function (response) {
//         return response.data; // Return the retrieved company name
//       })
//       .catch(function (error) {
//         console.error("Error fetching company name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }
//   function fetchExporterName(companyId, branchId, partyId) {
//     // Make an Axios GET request to retrieve the company name based on companyId
//     return axios
//       .get(
//         `https://${ipaddress}import/findPartyName/${companyId}/${branchId}/${partyId}`
//       )
//       .then(function (response) {
//         return response.data; // Return the retrieved company name
//       })
//       .catch(function (error) {
//         console.error("Error fetching company name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }

//   function fetchBranchName(companyId, branchId) {
//     // Make an Axios GET request to retrieve the branch name based on branchId
//     return axios
//       .get(
//         `https://${ipaddress}import/findBranchName/${companyId}/${branchId}`
//       )
//       .then(function (response) {
//         return response.data; // Return the retrieved branch name
//       })
//       .catch(function (error) {
//         console.error("Error fetching branch name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }

//   const calculateDistinctMAWB = (importPCTMData) => {
//     const distinctMAWBCounts = {};

//     importPCTMData.forEach((item) => {
//       const mawb = item.mawb;
//       if (mawb) {
//         if (distinctMAWBCounts[mawb]) {
//           distinctMAWBCounts[mawb] += 1;
//         } else {
//           distinctMAWBCounts[mawb] = 1;
//         }
//       }
//     });

//     return distinctMAWBCounts;
//   };
//   const distinctMAWBCounts = calculateDistinctMAWB(importPCTMData);
//   const totalDistinctMAWBs = Object.keys(distinctMAWBCounts).length;

//   const calculateDistinctIGM = (importPCTMData) => {
//     const distinctIGMCounts = {};

//     importPCTMData.forEach((item) => {
//       const igmNo = item.igmNo;
//       if (igmNo) {
//         if (distinctIGMCounts[igmNo]) {
//           distinctIGMCounts[igmNo] += 1;
//         } else {
//           distinctIGMCounts[igmNo] = 1;
//         }
//       }
//     });

//     return distinctIGMCounts;
//   };
//   const [consoles, setConsoles] = useState([]);
//   const [getConsoleId, setGetConsoleId] = useState({});

//   const fetchConsoleNames = async () => {
//     try {
//       const response = await fetch(
//         `https://${ipaddress}externalParty/console/${companyid}/${branchId}`
//       );
//       const data = await response.json();
//       const consoleMap = {};
//       data.forEach((console) => {
//         consoleMap[console.externaluserId] = console.userName;
//       });
//       setGetConsoleId(consoleMap);
//       setConsoles(data);
//     } catch (error) {
//       console.error("Error fetching party names:", error);
//     }
//   };

//   useEffect(() => {
//     fetchConsoleNames();
//   }, []);
//   const distinctIGMCounts = calculateDistinctIGM(importPCTMData);

//   const totalDistinctIGM = Object.keys(distinctIGMCounts).length;

//   const renderTable = () => (
//     <View style={styles.table}>
//       <View style={styles.tableRow}>
//         <Text
//           style={[
//             styles.tableCell,
//             styles.tableCellHeader,
//             { width: 54, fontWeight: "bold" },
//           ]}
//         >
//           Sr.No
//         </Text>
//         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 75 }]}>
//           Sir No
//         </Text>
//         <Text
//           style={[styles.tableCell, styles.tableCellHeader, { width: 227 }]}
//         >
//           Importer Name
//         </Text>
//         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 81 }]}>
//           Origin Airport
//         </Text>
//         <Text
//           style={[styles.tableCell, styles.tableCellHeader, { width: 120 }]}
//         >
//           MAWB
//         </Text>
//         <Text
//           style={[styles.tableCell, styles.tableCellHeader, { width: 120 }]}
//         >
//           HAWB
//         </Text>
//         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 54 }]}>
//           NOP
//         </Text>
//         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 72 }]}>
//           Weight
//         </Text>
//         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 81 }]}>
//           Desc
//         </Text>
//         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 72 }]}>
//           Console
//         </Text>
//         <Text
//           style={[styles.tableCell, styles.tableCellHeader, { width: 100 }]}
//         >
//           Remark Delivered
//         </Text>
//       </View>
//       {importPCTMData.map((item, index) => (
//         <View style={styles.tableRow} key={index}>
//           <Text style={{ ...styles.tableCell, width: 54 }}>{index + 1}</Text>
//           <Text style={{ ...styles.tableCell, width: 75 }}>
//             {item.sirNo || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 227 }}>
//             {getpartyId[item.importerId] || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 81 }}>
//             {item.portOrigin || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 120 }}>
//             {item.mawb || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 120 }}>
//             {item.hawb || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 54 }}>
//             {item.nop || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 72 }}>
//             {item.grossWeight || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 81 }}>
//             {item.descriptionOfGoods || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 72 }}>
//             {getConsoleId[item.consoleName] || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 100 }}>
//             {item.importRemarks || ""}
//           </Text>
//         </View>
//       ))}
//     </View>
//   );
//   const handlePdfImportPCTMData = async () => {
//     try {
//       const response = await axios.post(
//         `https://${ipaddress}import/printOfImportPctm?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}&pctmNo=${selectedAirlinePCTM}`
//       );
//       // toast.success("GST Summary PDF Created Successfully ", { position: "top-center" ,autoClose: 900});

//       if (response.status === 200) {
//         const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF

//         // Create a Blob from the Base64 data
//         const pdfBlob = new Blob(
//           [Uint8Array.from(atob(pdfBase64), (c) => c.charCodeAt(0))],
//           { type: "application/pdf" }
//         );

//         // Create a URL for the Blob
//         const blobUrl = URL.createObjectURL(pdfBlob);

//         // Create an anchor element for downloading
//         const downloadLink = document.createElement("a");
//         downloadLink.href = blobUrl;
//         downloadLink.download = "import_pctm_report.pdf"; // Set the filename for the downloaded PDF
//         downloadLink.style.display = "none";
//         document.body.appendChild(downloadLink);

//         // Trigger the download
//         downloadLink.click();

//         // Clean up
//         document.body.removeChild(downloadLink);
//         window.URL.revokeObjectURL(blobUrl);

//         toast.success("Downloaded Import Pctm PDF Successfully !", {
//           position: toast.POSITION.TOP_CENTER,
//           autoClose: 900,
//         });
//       } else {
//         throw new Error("Failed to generate PDF");
//       }
//     } catch (error) { }
//   };
//   const handlePrintAllImportPctmData = async () => {
//     try {
//       const response = await axios.post(
//         `https://${ipaddress}import/printOfImportPctm?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}&pctmNo=${selectedAirlinePCTM}`
//       );
//       if (response.status === 200) {
//         const base64PDF = response.data;

//         // Create a new window for displaying the PDF
//         const newWindow = window.open("", "_blank");

//         // Write the HTML content to the new window
//         newWindow.document.write(`
//         <!DOCTYPE html>
//         <html>
//         <head>
//           <title>import_pctm_report Viewer</title>
//           <style>
//             body {
//               margin: 0;
//               padding: 0;
//               overflow: hidden;
//             }
//             embed {
//               width: 100vw;
//               height: 100vh;
//             }
//           </style>
//         </head>
//         <body>
//           <embed src="data:application/pdf;base64,${base64PDF}" type="application/pdf" width="100%" height="100%">
//         </body>
//         </html>
//       `);
//       } else {
//         throw new Error("Failed to generate PDF");
//       }
//     } catch (error) {
//       // Handle errors if any
//     }
//   };
//   const MyDocument = ({
//     importPCTMData,
//     selectedAllAirline,
//     selectedAirlinePCTM,
//     totalRows,
//     totalDistinctMAWBs,
//     totalDistinctIGM,
//     totalPackages,
//   }) => (
//     <Document>
//       <Page size="A4" style={styles.page}>
//         <CustomHeader />
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Text
//             className="text-center"
//             style={{ fontSize: 9, fontWeight: "bold" }}
//           >
//             IMPORT PCTM REPORT{" "}
//           </Text>
//           <Text className="text-center" style={{ fontSize: 9 }}>
//             DGDC SEEPZ SEZ STRONG ROOM
//           </Text>
//           <Text className="text-center" style={{ fontSize: 9 }}>
//             MAIL LTD-CSI AIRPORT ,AIR CARGO COMPLEX,SAHAR MUMBAI-400099
//           </Text>
//           <Text
//             className="text-center"
//             style={{ ...styles.underline, fontSize: 9 }}
//           >
//             IMPORT - PRECIOUS CARGO TRANSFER MANIFEST
//           </Text>
//           <Text className="text-center" style={{ fontSize: 9 }}>
//             From Date : {formatedDate(startDate)} To Date :
//             {formatedDate(endDate)}
//           </Text>
//           <br />
//           <br />
//         </div>

//         <View style={styles.leftColumn}>
//           <Text style={{ fontSize: 9, fontWeight: "bold" }}>
//             REPORT DATE: {format(new Date(), "dd/MM/yyyy HH:mm")}
//           </Text>
//           <Text style={{ fontSize: 9, fontWeight: "bold" }}>
//             PCTM NO: {formatPctmNo(importPCTMData[0].pctmNo)}
//           </Text>
//           <Text style={{ fontSize: 9, fontWeight: "bold" }}>
//             IGM NO: {importPCTMData[0].igmNo}
//           </Text>
//         </View>
//         <View style={styles.hr}></View>
//         {/* <Text style={{ fontSize: 9, marginBottom: 4, marginTop: 5 }}>
//           Received from <span style={{ fontWeight: "bold" }}>Flight -</span>{" "}
//           {importPCTMData[0].airlineName} on{" "}
//           <Text style={{ fontWeight: "bold" }}>Flight Date & Time -</Text>{" "}
//           {importPCTMData[0].flightNo}{" "}
//           {formatedDate(importPCTMData[0].flightDate)} at DGDC SEEPZ SEZ STRONG
//           ROOM SAHAR in escort of
//         </Text> */}
//         <Text style={{ fontSize: 9, marginBottom: 4, marginTop: 5 }}>
//           Received from
//           <Text style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
//             {" Flight - "}
//           </Text>
//           {importPCTMData[0].airlineName}
//           {" on "}
//           <Text style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
//             {" Flight Date & Time - "}
//           </Text>
//           {importPCTMData[0].flightNo}{" "}
//           {formatedDate(importPCTMData[0].flightDate)}
//           {" at DGDC SEEPZ SEZ STRONG ROOM SAHAR in escort of"}
//         </Text>

//         {renderTable()}

//         <View style={{ flexDirection: "row", paddingTop: 4 }}>
//           <View style={{ marginRight: 27 }}>
//             <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//               Total No. Of Packages Received:{" "}
//               <Text style={{ fontWeight: "normal" }}>{totalPackages}</Text>
//             </Text>
//           </View>
//           <View style={{ marginRight: 27 }}>
//             <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//               By Flight No.: {importPCTMData[0].flightNo}
//             </Text>
//           </View>
//           <View style={{ marginRight: 20, fontWeight: "bold" }}>
//             <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//               Total Distinct MAWB No: {totalDistinctMAWBs}
//             </Text>
//           </View>
//           <View style={{ marginRight: 20 }}>
//             <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//               Total No. Of IGM No: {totalDistinctIGM}
//             </Text>
//           </View>
//         </View>

//         <View style={{ paddingTop: 9 }}>
//           <View style={{ flexDirection: "row" }}>
//             <View style={{ marginRight: 54 }}>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 ESCORT AND DEPOSITED :&nbsp;&nbsp;&nbsp; {totalPackages}
//               </Text>
//             </View>

//             <View style={{ marginRight: 54 }}>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 PKGS BY FLIGHT :&nbsp;&nbsp;&nbsp;{" "}
//                 {importPCTMData[0].airlineName}
//               </Text>
//             </View>
//             <View style={{ marginLeft: 27 }}>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 ON.:&nbsp;&nbsp;&nbsp;{" "}
//                 {formatedDate(importPCTMData[0].flightDate)}
//               </Text>
//             </View>
//           </View>
//         </View>

//         <Text
//           style={{
//             fontWeight: "bold",
//             fontSize: 9,
//             marginTop: 18,
//             paddingBottom: 9,
//           }}
//         >
//           TO DGDC SEEPZ SEZ STRONG ROOM UNDER CUSTOMS PREVENTIVE SUPERVISION
//         </Text>

//         <View style={{ paddingLeft: 18 }}>
//           <View style={{ flexDirection: "row" }}>
//             <View style={{ width: "50%" }}>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 Airline Staff Signature:_______________{"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 Name :_______________{"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 Date & time:_______________{"\n"}
//                 {"\n"}
//               </Text>
//             </View>

//             <View style={{ width: "50%", paddingRight: 18 }}>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 I.F.O. Signature :_______________{"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 Name :_______________{"\n"}
//                 {"\n"}
//               </Text>

//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 Date & time :_______________{"\n"}
//                 {"\n"}
//               </Text>
//             </View>
//           </View>
//         </View>

//         <Text style={{ fontWeight: "bold", fontSize: 9, paddingTop: 9 }}>
//           Received above consignment in full in apparent good order and
//           condition except as noted in the Remarks Column
//         </Text>
//         <View style={{ paddingTop: 9 }}>
//           <View style={{ flexDirection: "row" }}>
//             <View
//               style={{
//                 width: "33%",
//                 borderRight: "1px solid black",
//                 paddingRight: 4,
//                 paddingLeft: 5,
//               }}
//             >
//               <Text style={{ fontSize: 9 }}>
//                 Total No. Of Packages:&nbsp; {totalPackages}
//                 <Text style={{ fontWeight: "bold" }}>{totalRows}</Text>
//                 {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Receiver's Signature: {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Name: {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Asstt Custodian DGDC Seepz Sez, Strong room sahar Mumbai-99:{" "}
//                 {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Date: {"\n"}
//                 {"\n"}
//               </Text>
//             </View>

//             <View
//               style={{
//                 width: "33%",
//                 borderRight: "1px solid black",
//                 paddingRight: 4,
//                 paddingLeft: 5,
//               }}
//             >
//               <Text style={{ fontSize: 9 }}>
//                 Total No. Of Packages: &nbsp; {totalPackages}
//                 <Text style={{ fontWeight: "bold" }}>{totalRows}</Text>
//                 {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Receiver's Signature: {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Name: {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Asstt Custodian DGDC Seepz Sez, Strong room sahar Mumbai-99:{" "}
//                 {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Date: {"\n"}
//                 {"\n"}
//               </Text>
//             </View>

//             <View style={{ width: "33%", paddingLeft: 5 }}>
//               <Text style={{ fontSize: 9 }}>
//                 Total No. Of Packages: &nbsp; {totalPackages}
//                 <Text style={{ fontWeight: "bold" }}>{totalRows}</Text>
//                 {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Receiver's Signature: {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Name: {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Asstt Custodian DGDC Seepz Sez, Strong room sahar Mumbai-99:{" "}
//                 {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Date: {"\n"}
//                 {"\n"}
//               </Text>
//             </View>
//           </View>
//         </View>
//         <View style={styles.hr}></View>
//       </Page>
//     </Document>
//   );

//   const generatePDFReport = async () => {
//     const pdfBlob = await pdf(
//       <MyDocument
//         {...{
//           importPCTMData,
//           totalDistinctMAWBs,
//           totalDistinctIGM,
//           totalPackages,
//         }}
//       />
//     ).toBlob();
//     saveAs(pdfBlob, "Import_PCTM.pdf");
//   };

//   const generateAllPDFReport = async () => {
//     if (selectedItems.length === 0) {
//       // Show an error message if no rows are selected
//       toast.error("No rows selected. Please select rows to generate PCTM.", {
//         position: "top-center",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });
//       return; // Don't proceed with the API call
//     }
// else
// {
//   setLoading(true);
// }
//     try {
      
//       const response = await Rate_Chart_Service.updatePctmNumber(selectedItems);
//       // const reportData = response.data; // Data for the selected airline
//       setShowPDFReport(true);
//       // console.log("RemovedRecordArray", RemovedRecordArray);
//       // Pass the data to the PDFReport component
//       setPDFReportData(pdfReportData); // Update with the correct data
//       setImportAllData(importAllData); // Update with the correct data
//       setTotalEntries(importData.length);
//       handleShow();

//       // Display a toast notification when pctmNo is updated successfully
//       toast.success("pctmNo generated successfully", {
//         position: "top-center",
//         autoClose: 3000, // Close the notification after 3 seconds
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });

//       // Refresh the page
//       // window.location.reload();
//     } catch (error) {
//       console.error("Error fetching data:", error);

//       // Display an error toast notification if there's an error
//       toast.error("Error fetching data", {
//         position: "bottom-right",
//         autoClose: 5000, // Close the notification after 5 seconds
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });
//     } finally {
//       setLoading(false);
//     }
//   };


//   useEffect(() => {
//     if (!isAuthenticated) {
//       navigate(
//         "/login?message=You need to be authenticated to access this page."
//       );
//     }
//   }, [isAuthenticated, navigate]);

//   const [importAllDatalist, setImportAllDatalist] = useState([]);

//   useEffect(() => {
//     // Fetch data from your backend API
//     axios
//       .get(`https://${ipaddress}import/all/${companyid}/${branchId}`)
//       .then((response) => {
//         // Assuming your API response is an array of Import objects
//         setImportAllDatalist(importAllDatalist);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);
//   const formatDate = (inputDate) => {
//     const date = new Date(inputDate);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${day}/${month}/${year}`;
//   };

//   const handleAllSearch = async () => {
//     // Make an API request here to fetch the list of airline names based on the provided criteria
//     console.log("///////////////////////////////////////////////////");
//     console.log("StartDate " + formattedStartDate);
//     console.log("EndDate  " + formattedEndDate);
//     fetch(
//       `https://${ipaddress}import/Allairline-names?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         // console.warn(
//         //   "******************************************************************************"
//         // );
//         console.log(data);
//         // Ensure that 'data' is an array before updating the 'airlines' state
//         if (Array.isArray(data)) {
//           // Update the 'airlines' state with the fetched data
//           setSelectedAllAirline("Select");
//           setAirlineNames(data);
//         } else {
//           console.error("API response is not an array:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching airline names:", error);
//       });
//   };

//   const handleShowPctmData = () => {
//     if (selectedAllAirline && selectedAirlinePCTM) {
//       axios
//         .get(
//           `https://${ipaddress}import/allimportPCTMData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}&pctmNo=${selectedAirlinePCTM}`
//         )
//         .then((response) => {
//           const importPCTMData = response.data; // Data for the selected PCTM number
//           setImportedPCTMData(importPCTMData); // Update the state with the fetched data
//           setShowImportDataTable(true);
//           setTotalEntries(importPCTMData.length);
//         })
//         .catch((error) => {
//           console.error("Error fetching PCTM data:", error);
//         });
//     }
//   };

//   useEffect(() => {
//     // Fetch PCTM numbers based on the selected airline
//     if (selectedAllAirline) {
//       axios
//         .get(
//           `https://${ipaddress}import/getPctmNo?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}`
//         )
//         .then((response) => {
//           const data = response.data;
//           setPctmNumbers(data);
//         })
//         .catch((error) => {
//           console.error("Error fetching PCTM numbers:", error);
//         });
//     }
//   }, [selectedAllAirline]); // Run this effect whenever selectedAllAirline changes

//   const totalPackages = importPCTMData.reduce(
//     (total, item) => total + item.nop,
//     0
//   );

//   const handleXLSDownload = async () => {
//     const modifiedRecordList = await Promise.all(
//       importPCTMData.map(async (item, index) => {
//         // const companyname = await fetchCompanyName(item.companyId);
//         // const branchname = await fetchBranchName(item.companyId, item.branchId);
//         const importerId = await fetchExporterName(
//           item.companyId,
//           item.branchId,
//           item.importerId
//         );
//         return {
//           "Sr.No": index + 1,
//           // "Company Name": companyname,
//           // "Branch Name": branchname,
//           "SIR Date": formatedDate(item.sirDate) || "",
//           "SIR NO": item.sirNo || "",
//           "M.A.W.B": item.mawb || "",
//           "H.A.W.B": item.hawb.startsWith('000') ? '' : item.hawb || "", // Modify this to the actual field name
//           "NO OF PKGS": item.nop || "",
//           "Importer Names": getpartyId[item.importerId] || "",
//         };
//       })
//     );

//     // Calculate the total "SIR No" and "No of Pkgs"
//     const totalSIRNo = modifiedRecordList.reduce(
//       (total, item) => total + (item["SIR NO"] ? 1 : 0),
//       0
//     );

//     const totalNoOfPkgs = modifiedRecordList.reduce(
//       (total, item) => total + (item["NO OF PKGS"] || 0),
//       0
//     );
//     const distanceRow = {
//       "Sr.No": "",
//       // "Company Name": "",
//       // "Branch Name": "",
//       "SIR Date": "",
//       "SIR NO": "",
//       "M.A.W.B": "",
//       "H.A.W.B": "", // Modify this to the actual field name
//       "NO OF PKGS": "",
//       "Importer Names": "",
//     };
//     // Add a total row
//     const totalRow = {
//       "Sr.No": "Total ",
//       // "Company Name": "",
//       // "Branch Name": "",
//       "SIR Date": "",
//       "SIR No": totalSIRNo,
//       "M.A.W.B": "",
//       "H.A.W.B": "", //
//       "NO OF PKGS": totalNoOfPkgs,
//       "Importer Names": "",
//     };

//     const workbook = XLSX.utils.book_new();
//     const worksheet = XLSX.utils.json_to_sheet([
//       distanceRow,
//       ...modifiedRecordList,
//       distanceRow, // Insert the distance row
//       totalRow, // Insert the total row
//     ]);

//     // Add headers for all fields
//     const headers = Object.keys(modifiedRecordList[0]);
//     headers.forEach((header, index) => {
//       worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
//         t: "s",
//         v: header,
//         s: { font: { bold: true } },
//       };
//     });

//     // Set column widths based on data
//     const colWidths = headers.map((header) => ({
//       wch: header.length + 2, // You can adjust the width as needed
//     }));

//     worksheet["!cols"] = colWidths;

//     XLSX.utils.book_append_sheet(workbook, worksheet, "Import_Register");
//     const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
//     const blob = new Blob([s2ab(xlsFile)], {
//       type: "application/vnd.ms-excel",
//     });
//     saveAs(blob, "import_pctm.xls");
//   };

//   // Helper function to convert s2ab
//   const s2ab = (s) => {
//     const buf = new ArrayBuffer(s.length);
//     const view = new Uint8Array(buf);
//     for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
//     return buf;
//   };

//   const [importerNames, setImporterNames] = useState({});

//   const fetchImporterName = (importerId, partyId) => {
//     // Ensure that partyId is equal to importerId
//     if (partyId !== importerId) {
//       console.error(
//         `partyId (${partyId}) is not equal to importerId (${importerId}).`
//       );
//       return;
//     }

//     // Make an API request to fetch the importerName based on importerId
//   };

//   const totaleNoOfPackages = importData.reduce(
//     (total, item) => total + item.nop,
//     0
//   );

//   const [selectedRows, setSelectedRows] = useState([]);
//   const [selectAllChecked, setSelectAllChecked] = useState(false);
//   const [RemovedRecordArray, setRemovedRecordArray] = useState([]);

//   const handleRowSelection = (item) => {

//     const removedNumber = item.sirNo;
//     setRemovedRecordArray(prevArray => {
//       // Check if removedNumber is already in the array
//       const index = prevArray.indexOf(removedNumber);
//       console.log("gdgd", item);
//       if (index !== -1) {
//         // If it exists, remove it
//         return prevArray.slice(0, index).concat(prevArray.slice(index + 1));
//       } else {
//         // If it doesn't exist, add it
//         return [...prevArray, removedNumber];
//       }
//     });


//     const updatedSelectedRows = selectedRows.includes(item)
//       ? selectedRows.filter((row) => row !== item)
//       : [...selectedRows, item];
//     setSelectedRows(updatedSelectedRows);

//     setSelectAllChecked(updatedSelectedRows.length === importData.length);
//   };




//   // const handleSelectAll = () => {
//   //   const sirNoList = importData.map(item => item.sirNo);
//   //   setRemovedRecordArray(sirNoList);
//   //   // console.log(sirNoList);
//   //   if (selectAllChecked) {
//   //     setSelectedRows([]);
//   //     setSelectAllChecked(false);
//   //   } else {
//   //     setSelectedRows(importData);
//   //     setSelectAllChecked(true);
//   //   }
//   // };

//   const [selectAll, setSelectAll] = useState(false);
//   const [selectedItems, setSelectedItems] = useState([]);
//   useEffect(() => {
//     setSelectAll(selectedItems.length === importData.length);
//     console.log("select");
//     console.log(selectedItems);

//   }, [selectedItems, importData]);

//   const handleSelectAllToggle = () => {
//     console.log("select all");
//     console.log(selectedItems);
//     if (selectAll) {
//       setSelectedItems([]);
//     } else {
//       setSelectedItems(importData);
//     }
//     setSelectAll(!selectAll);
//   };

//   const handleRowCheckboxChange = (index) => {
//     const selectedItem = importData[index];

//     console.log("importData");
//     console.log(importData);

//     if (selectedItem) {
//       const selectedIndex = selectedItems.findIndex((item) => item.sirNo === selectedItem.sirNo);

//       if (selectedIndex !== -1) {
//         // Remove the item from the selected items
//         const updatedSelectedItems = [...selectedItems];
//         updatedSelectedItems.splice(selectedIndex, 1);
//         setSelectedItems(updatedSelectedItems);
//       } else {
//         // Add the item to the selected items
//         setSelectedItems([...selectedItems, selectedItem]);
//       }
//     }

//     console.log("Selected Items ");
//     console.log(selectedItems);
//   };



//   const [pctmNoMap, setPctmNoMap] = useState({});

//   // Function to generate pctmNo for selected rows
//   const generatePctmNoForSelectedRows = () => {
//     const pctmNoMapCopy = {};
//     selectedRows.forEach((row) => {
//       // Generate pctmNo for the selected row here
//       // You can customize the logic to generate pctmNo as needed
//       // For example, you can generate a unique pctmNo based on some data from the selected row.
//       const pctmNo = generatePctmNoBasedOnRowData(row);
//       pctmNoMapCopy[row.sirNo] = pctmNo;
//     });
//     setPctmNoMap(pctmNoMapCopy);
//   };

//   // Example function to generate a pctmNo based on row data (customize this according to your needs)
//   const generatePctmNoBasedOnRowData = (row) => {
//     // Replace this with your logic to generate a unique pctmNo for the row
//     return `${row.sirNo}_PCTM`;
//   };



//   //Import PCTM

//   const formatDateTime2 = (value) => {
//     if (!value) {
//       return "";
//     }
//     const date = new Date(value);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}/${month}/${year} `;
//   };
//   const getExcel = (imp) => {
//     const filename = `Import_PCTM_${formatDateTime2(new Date())}.xlsx`; // Note: Changed file extension to xlsx
//     axios.post(`https://${ipaddress}import/pctmexcel`, imp, { responseType: 'blob' }) // Added responseType: 'blob'
//       .then(async (response) => {
//         const blob = new Blob([response.data], { type: response.headers['content-type'] });

//         // Create a temporary URL for the blob
//         const url = window.URL.createObjectURL(blob);

//         // Create a link element to trigger the download
//         const a = document.createElement("a");
//         a.href = url;
//         a.download = filename;
//         document.body.appendChild(a);
//         a.click();

//         // Clean up
//         window.URL.revokeObjectURL(url);
//         document.body.removeChild(a);
//       })
//       .catch((error) => {
//         toast.error("Something went wrong", {
//           autoClose: 700
//         });
//       });
//   };

//   return (

//     <>


//       {loading && (
//         <div style={styles2.overlay}>
//           <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
//         </div>
//       )}






//       <div className="Container">
//         <h5
//           className="pageHead"
//           style={{
//             fontFamily: "Your-Heading-Font",
//             paddingLeft: "2%",
//             paddingRight: "-0px",
//           }}
//         >
//           {" "}
//           <FontAwesomeIcon
//             icon={faPlane}
//             style={{
//               marginRight: "8px",
//               color: "black", // Set the color to golden
//             }}
//           />
//           Import PCTM
//         </h5>
//         <Card>
//           <Container>
//             <div className="Container" style={{ marginTop: 20 }}>
//               <ul className="nav nav-tabs" id="myTab" role="tablist">
//                 <li className="nav-item tabspace" role="presentation">
//                   <button
//                     style={{ color: "gray" }}
//                     className="nav-link active"
//                     id="home-tab"
//                     data-bs-toggle="tab"
//                     data-bs-target="#home"
//                     type="button"
//                     role="tab"
//                     aria-controls="home"
//                     aria-selected="true"
//                     onClick={(e) => makeFieldEmpty()}
//                   >
//                     <h6>Generate</h6>
//                   </button>
//                 </li>

//                 <li className="nav-item tabspace" role="presentation">
//                   <button
//                     style={{ color: "gray" }}
//                     className="nav-link"
//                     id="contact-tab"
//                     data-bs-toggle="tab"
//                     data-bs-target="#contact"
//                     type="button"
//                     role="tab"
//                     aria-controls="contact"
//                     aria-selected="false"
//                     onClick={(e) => makeFieldEmpty()}
//                   >
//                     <h6>Print</h6>
//                   </button>
//                 </li>
//               </ul>
//               <div className="tab-content" id="myTabContent">
//                 <div
//                   className="tab-pane fade show active"
//                   id="home"
//                   role="tabpanel"
//                   aria-labelledby="home-tab"
//                 >
//                   <Card
//                     style={{
//                       marginTop: 25,
//                       marginBottom: 25,

//                       padding: 8,
//                     }}
//                   >
//                     <h5
//                       className="pageHead"
//                       style={{
//                         fontFamily: "Your-Heading-Font",
//                         paddingLeft: "3%",
//                       }}
//                     >
//                       {" "}
//                       <FontAwesomeIcon
//                         icon={faBolt}
//                         style={{
//                           marginRight: "8px",
//                           color: "black", // Set the color to golden
//                         }}
//                       />{" "}
//                       Generate{" "}
//                     </h5>
//                     <Container>
//                       <Form>
//                         <Row>
//                           <Col md={3}>
//                             <Form.Group>
//                               <Form.Label className="forlabel">
//                                 SIR Date From
//                               </Form.Label>
//                               <DatePicker
//                                 selected={startDate}
//                                 wrapperClassName="custom-react-datepicker-wrapper"
//                                 onChange={(date) => {
//                                   if (date) {
//                                     setStartDate(date);
//                                   }
//                                 }}
//                                 value={startDate}
//                                 dateFormat="dd/MM/yyyy"
//                                 className="form-control"
//                                 customInput={<input style={{ width: "100%" }} />}
//                               />
//                             </Form.Group>
//                           </Col>
//                           <Col md={3}>
//                             <Form.Group>
//                               <Form.Label className="forlabel">
//                                 SIR Date To
//                               </Form.Label>
//                               <DatePicker
//                                 selected={endDate}
//                                 wrapperClassName="custom-react-datepicker-wrapper"
//                                 onChange={(date) => {
//                                   if (date) {
//                                     setEndDate(date);
//                                   }
//                                 }}
//                                 value={endDate}
//                                 dateFormat="dd/MM/yyyy"
//                                 className="form-control"
//                                 customInput={<input style={{ width: "100%" }} />}
//                               />
//                             </Form.Group>
//                           </Col>
//                           <Col md={2}>
//                             <Button
//                               type="button"
//                               style={{
//                                 marginTop: 32,
//                               }}
//                               className="allbutton"
//                               variant="outline-primary"
//                               onClick={handleSearch}
//                             >
//                               <FontAwesomeIcon
//                                 icon={faSearch}
//                                 style={{ marginRight: "5px" }}
//                               />
//                               Search
//                             </Button>
//                           </Col>
//                           <Col md={2}>
//                             <Form.Group>
//                               <Form.Label className="forlabel">
//                                 Select Air Lines
//                               </Form.Label>
//                               <Select
//                                 name="airlines"
//                                 options={[
//                                   // Placeholder/default value
//                                   ...airlines.map((airline) => ({
//                                     label: getAirlineNameByAirCode[airline],
//                                     value: airline,
//                                   })),
//                                 ]}
//                                 value={{
//                                   label: getAirlineNameByAirCode[selectedAirline],
//                                   value: selectedAirline,
//                                 }}
//                                 onChange={(option) =>
//                                   setSelectedAirline(option.value)
//                                 }
//                               />
//                             </Form.Group>
//                           </Col>
//                           <Col md={2}>
//                             <Button
//                               type="button"
//                               style={{
//                                 marginTop: 32,
//                                 marginLeft: 5,
//                               }}
//                               className="allbutton"
//                               variant="outline-primary"
//                               onClick={handleShow}
//                             >
//                               <FontAwesomeIcon
//                                 icon={faArrowsToEye}
//                                 style={{ marginRight: "5px" }}
//                               />
//                               Show
//                             </Button>
//                           </Col>
//                         </Row>
//                       </Form>
//                     </Container>
//                   </Card>
//                 </div>

//                 {importData.length > 0 && (
//                   <div>
//                     <hr />
//                     <h5
//                       className="pageHead"
//                       style={{
//                         fontFamily: "Your-Heading-Font",
//                         paddingLeft: "4%",
//                         marginTop: "30px",
//                       }}
//                     >
//                       {" "}
//                       <FontAwesomeIcon
//                         icon={faFileAlt}
//                         style={{
//                           marginRight: "8px",
//                           color: "black", // Set the color to golden
//                         }}
//                       />{" "}
//                       Imported Data
//                     </h5>

//                     <div className="table-responsive">
//                       <Table className="table table-striped table-hover">
//                         <thead>
//                           <tr>
//                             {" "}
//                             <th
//                               style={{ backgroundColor: "#BADDDA" }}
//                               scope="col"
//                             >
//                               {/* <input type="checkbox" onChange={handleSelectAll} /> */}

//                               <input
//                                 type="checkbox"
//                                 checked={selectAll}
//                                 onChange={() => handleSelectAllToggle()}
//                               />
//                             </th>
//                             <th style={{ backgroundColor: "#BADDDA" }}>Sr.No</th>
//                             <th style={{ backgroundColor: "#BADDDA" }}>
//                               Airline Name
//                             </th>
//                             <th style={{ backgroundColor: "#BADDDA" }}>MAWB</th>
//                             <th style={{ backgroundColor: "#BADDDA" }}>Sir No</th>
//                             <th style={{ backgroundColor: "#BADDDA" }}>
//                               Sir Date
//                             </th>
//                             <th style={{ backgroundColor: "#BADDDA" }}>
//                               Parcle Type
//                             </th>
//                             <th style={{ backgroundColor: "#BADDDA" }}>HAWB</th>
//                             <th style={{ backgroundColor: "#BADDDA" }}>NOP</th>
//                             {/* Add more column headers as needed */}
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {importData.map((item, index) => (
//                             <tr key={index}>
//                               <td>
//                                 {/* <input
//                                 type="checkbox"
//                                 checked={selectedRows.includes(item)}
//                                 onChange={(e) => handleRowSelection(e, index)}
//                               /> */}

//                                 <input
//                                   type="checkbox"
//                                   name="checkimport"
//                                   checked={selectedItems.some((item) => item.sirNo === importData[index].sirNo)}
//                                   onChange={() => handleRowCheckboxChange(index)}
//                                 />
//                               </td>
//                               <td>{index + 1}</td>
//                               <td>{item.airlineName}</td>
//                               <td>{item.mawb}</td>
//                               <td>{item.sirNo}</td>
//                               <td>{formatDate(item.sirDate)}</td>
//                               <td>{item.parcelType}</td>
//                               <td>{item.hawb.startsWith('000') ? '' : item.hawb}</td>
//                               <td>{item.nop}</td>
//                               {/* Add more table cells as needed */}
//                             </tr>
//                           ))}
//                         </tbody>
//                       </Table>
//                     </div>

//                     <CardBody>
//                       <p>Total Packages Received - {totaleNoOfPackages}</p>
//                       <Button
//                         variant="outline-primary"
//                         style={{ float: "right" }}
//                         onClick={generateAllPDFReport}
//                       >
//                         {" "}
//                         <FontAwesomeIcon
//                           icon={faBolt}
//                           style={{ marginRight: "5px" }}
//                         />
//                         Generate PCTM
//                       </Button>
//                     </CardBody>
//                   </div>
//                 )}
//                 <hr className="mt-3" />
//                 <div
//                   className="tab-pane fade"
//                   id="contact"
//                   role="tabpanel"
//                   aria-labelledby="contact-tab"
//                 >
//                   <h5
//                     className="pageHead"
//                     style={{
//                       fontFamily: "Your-Heading-Font",
//                       paddingLeft: "3%",
//                       paddingRight: "-50px",
//                     }}
//                   >
//                     {" "}
//                     <FontAwesomeIcon
//                       icon={faPrint}
//                       style={{
//                         marginRight: "8px",
//                         color: "black", // Set the color to golden
//                       }}
//                     />{" "}
//                     Print
//                   </h5>

//                   <Card
//                     style={{
//                       marginTop: 25,
//                       marginRight: 18,
//                       marginLeft: 18,
//                       padding: 8,
//                     }}
//                   >
//                     <Container></Container>
//                     <Container>
//                       <Form>
//                         <Row>
//                           <Col md={2}>
//                             <Form.Group>
//                               <Form.Label className="forlabel">
//                                 SIR Date From
//                               </Form.Label>
//                               <DatePicker
//                                 selected={startDate}
//                                 wrapperClassName="custom-react-datepicker-wrapper"
//                                 onChange={(date) => {
//                                   if (date) {
//                                     setStartDate(date);
//                                   }
//                                 }}
//                                 value={startDate}
//                                 dateFormat="dd/MM/yyyy"
//                                 className="form-control"
//                                 customInput={<input style={{ width: "100%" }} />}
//                               />
//                             </Form.Group>
//                           </Col>
//                           <Col md={2}>
//                             <Form.Group>
//                               <Form.Label className="forlabel">
//                                 SIR Date To
//                               </Form.Label>
//                               <DatePicker
//                                 selected={endDate}
//                                 wrapperClassName="custom-react-datepicker-wrapper"
//                                 onChange={(date) => {
//                                   if (date) {
//                                     setEndDate(date);
//                                   }
//                                 }}
//                                 value={endDate}
//                                 dateFormat="dd/MM/yyyy"
//                                 className="form-control"
//                                 customInput={<input style={{ width: "100%" }} />}
//                               />
//                             </Form.Group>
//                           </Col>
//                           <Col md={2}>
//                             <Button
//                               type="button"
//                               style={{
//                                 marginTop: 32,
//                               }}
//                               className="allbutton"
//                               variant="outline-primary"
//                               onClick={handleAllSearch}
//                             >
//                               <FontAwesomeIcon
//                                 icon={faSearch}
//                                 style={{ marginRight: "5px" }}
//                               />
//                               Search
//                             </Button>
//                           </Col>

//                           <Col sm={2}>
//                             <Form.Group>
//                               <Form.Label className="forlabel">
//                                 Select Air Lines
//                               </Form.Label>
//                               {/* Use the Select component for the dropdown */}

//                               <Select
//                                 name="airlines"
//                                 options={[
//                                   // Placeholder/default value
//                                   ...airlineNames.map((airline) => ({
//                                     label: getAirlineNameByAirCode[airline],
//                                     value: airline,
//                                   })),
//                                 ]}
//                                 value={{
//                                   label:
//                                     getAirlineNameByAirCode[selectedAllAirline],
//                                   value: selectedAllAirline,
//                                 }}
//                                 onChange={(option) =>
//                                   setSelectedAllAirline(option.value)
//                                 }
//                               />
//                             </Form.Group>
//                           </Col>
//                           <Col sm={2}>
//                             <Form.Group>
//                               <Form.Label className="forlabel">
//                                 Select PCTM No
//                               </Form.Label>
//                               <Select
//                                 name="pctmNumbers"
//                                 options={pctmNumbers.map((pctm) => ({
//                                   label: formatPctmNo(pctm),
//                                   value: pctm,
//                                 }))}
//                                 value={{
//                                   label: formatPctmNo(selectedAirlinePCTM),
//                                   value: selectedAirlinePCTM,
//                                 }}
//                                 onChange={(option) =>
//                                   setSelectedAirlinePCTM(option.value)
//                                 }
//                               />
//                             </Form.Group>
//                           </Col>
//                           <Col sm={2}>
//                             <Form.Label className="forlabel">
//                               Search PCTM
//                             </Form.Label>
//                             <Button
//                               type="button"
//                               className="allbutton"
//                               variant="outline-primary"
//                               onClick={handleShowPctmData}
//                             >
//                               <FontAwesomeIcon
//                                 icon={faSearch}
//                                 style={{ marginRight: "5px" }}
//                               />
//                               PCTM
//                             </Button>
//                           </Col>
//                         </Row>
//                       </Form>
//                     </Container>
//                   </Card>

//                   <Card style={{ marginTop: 18, marginBottom: 18 }}>
//                     <Container>
//                       {importPCTMData.length > 0 && (
//                         <div>
//                           {importPCTMData.length > 0 && (
//                             <div>
//                               {/* ... Existing JSX ... */}
//                               <div>
//                                 <Button
//                                   type="button"
//                                   color="danger"
//                                   outline
//                                   style={{
//                                     float: "right",
//                                     marginLeft: '5x',
//                                     textDecoration: "none",
//                                   }}
//                                   onClick={handlePrintAllImportPctmData}
//                                 >
//                                   <FontAwesomeIcon
//                                     icon={faPrint}
//                                     style={{ marginRight: "5px" }}
//                                   />
//                                   Print
//                                 </Button>
//                                 <Button
//                                   type="button"
//                                   className="allbutton"
//                                   variant="outline-success"
//                                   onClick={() => getExcel(importPCTMData)}
//                                   // style={{ float: "right" }}
//                                   style={{ marginRight: "5px", float: "right" }}
//                                 >
//                                   <FontAwesomeIcon
//                                     icon={faFileExcel}
//                                     style={{ marginRight: "5px" }}
//                                   />
//                                   XLS
//                                 </Button>
//                                 {/* <BlobProvider
//                                 document={
//                                   <MyDocument
//                                     importPCTMData={importPCTMData}
//                                     totalDistinctIGM={totalDistinctIGM}
//                                     totalDistinctMAWBs={totalDistinctMAWBs}
//                                     totalPackages={totalPackages}
//                                   />
//                                 }
//                               >
//                                 {({ blob, url, loading, error }) => (
//                                   <a
//                                     href={url}
//                                     style={{ textDecoration: "none" }}
//                                     target="_blank"
//                                     rel="noopener noreferrer"
//                                   >
//                                     <Button
//                                       type="button"
//                                       color="danger"
//                                       outline
//                                       style={{
//                                         float: "right",
//                                         marginLeft: 4,
//                                         textDecoration: "none",
//                                       }}
//                                     >
//                                       <FontAwesomeIcon
//                                         icon={faPrint}
//                                         style={{ marginRight: "5px" }}
//                                       />
//                                       PRINT
//                                     </Button>
//                                   </a>
//                                 )}
//                               </BlobProvider> */}
//                                 <Button
//                                   outline
//                                   id="pdfButton"
//                                   color="danger"
//                                   style={{ marginRight: "5px", float: "right" }}
//                                   onClick={handlePdfImportPCTMData}
//                                 >
//                                   <FontAwesomeIcon
//                                     icon={faFilePdf}
//                                     style={{ marginLeft: "5px" }}
//                                   />
//                                   PDF
//                                 </Button>
//                               </div>
//                             </div>
//                           )}

//                           <h5
//                             style={{
//                               marginTop: 9,
//                               marginRight: 9,
//                               marginBottom: 27,
//                             }}
//                           >
//                             Imported PCTM Data
//                           </h5>

//                           <div className="table-responsive">
//                             <Table className="table table-striped table-hover">
//                               <thead>
//                                 <tr>
//                                   <th style={{ backgroundColor: "#BADDDA" }}>
//                                     Sr.No
//                                   </th>
//                                   <th style={{ backgroundColor: "#BADDDA" }}>
//                                     M.A.W.B. No
//                                   </th>
//                                   <th style={{ backgroundColor: "#BADDDA" }}>
//                                     Sir Date
//                                   </th>
//                                   <th style={{ backgroundColor: "#BADDDA" }}>
//                                     Sir No
//                                   </th>
//                                   <th style={{ backgroundColor: "#BADDDA" }}>
//                                     Parcle Type
//                                   </th>

//                                   <th style={{ backgroundColor: "#BADDDA" }}>
//                                     H.A.W.B. No
//                                   </th>

//                                   <th style={{ backgroundColor: "#BADDDA" }}>
//                                     No Of Pkgs
//                                   </th>
//                                   <th style={{ backgroundColor: "#BADDDA" }}>
//                                     Importer Name
//                                   </th>
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 {importPCTMData.map((item, index) => (
//                                   <tr key={index}>
//                                     <td>{index + 1}</td>
//                                     <td>{item.mawb}</td>
//                                     <td>{formatDate(item.sirDate)}</td>
//                                     <td>{item.sirNo}</td>
//                                     <td>{item.parcelType}</td>
//                                     <td>{item.hawb.startsWith('000') ? '' : item.hawb}</td>
//                                     <td>{item.nop}</td>
//                                     <td>{getpartyId[item.importerId]}</td>
//                                     {/* Add more table cells as needed */}
//                                   </tr>
//                                 ))}
//                               </tbody>
//                             </Table>
//                           </div>
//                           <p>Total Packages: {totalPackages}</p>

//                           {showImportPDFReport && (
//                             <PDFReport
//                               data={importPCTMData}
//                               startDate={startDate}
//                               endDate={endDate}
//                               selectedAirline={selectedAllAirline}
//                             //selectedAirlineFlightdate={selectedAirlineFlightdate}
//                             />
//                           )}
//                         </div>
//                       )}
//                     </Container>
//                   </Card>
//                 </div>
//               </div>
//             </div>
//           </Container>
//         </Card>
//       </div>

//     </>
//   );
// }




// import { Link, redirect } from "react-router-dom";
// import AuthContext from "../Components/AuthProvider";
// import { useNavigate } from "react-router-dom";
// import React, { useEffect, useState, useContext } from "react";
// import "../Components/Style.css";
// import DatePicker from "react-datepicker";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
// import { format } from "date-fns";
// import Form from "react-bootstrap/Form";
// import Card from "react-bootstrap/Card";
// import axios from "axios";
// import Select from "react-select"; // Import Select component for dropdown
// import { ButtonToggle, CardBody, Container, Table } from "reactstrap";
// import PDFReport from "./PDFReport";
// import { saveAs } from "file-saver"; // Import file-saver for triggering the download
// import * as XLSX from "xlsx"; // Import XLSX library for creating XLS files
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import ipaddress from "../Components/IpAddress";
// import { toast } from "react-toastify";
// import PartyListTable from "../Parent_Pages/PartyListTable";
// import dgdcimage from "../Images/DGDC.png";
// import moment from 'moment';

// import {
//   Page,
//   Text,
//   View,
//   Document,
//   StyleSheet,
//   PDFViewer,
//   Image,
//   BlobProvider,
//   pdf,
// } from "@react-pdf/renderer";
// import {
//   faArrowsToEye,
//   faBolt,
//   faFileAlt,
//   faFileExcel,
//   faFilePdf,
//   faPlane,
//   faPrint,
//   faSave,
//   faSearch,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Rate_Chart_Service from "../services/Rate_Chart_Service";

// export default function Import_PCTM() {
//   const navigate = useNavigate();
//   const { isAuthenticated } = useContext(AuthContext);
//   const [pctmNumbers, setPctmNumbers] = useState([]);

//   const currentDate = new Date();
//   const currentDateString = currentDate.toISOString().split("T")[0];

//   const [startDate, setStartDate] = useState(new Date());
//   const [endDate, setEndDate] = useState(new Date());
//   const reactPageName = "Import PCTM Data Party";
//   const [airlines, setAirlines] = useState([]);
//   const [selectedAirline, setSelectedAirline] = useState("");
//   const [airlineNames, setAirlineNames] = useState([]);
//   const [selectedAllAirline, setSelectedAllAirline] = useState("");
//   const [selectedAirlinePCTM, setSelectedAirlinePCTM] = useState("");
//   const [importPCTMData, setImportedPCTMData] = useState([]);
//   const [showPDFViewer, setShowPDFViewer] = useState(false);
//   const [getOneParty, setOneParty] = useState([]);

//   const [importData, setImportData] = useState([]);
//   const [importAllData, setImportAllData] = useState([]);
//   const [showTable, setShowTable] = useState(false);
//   const [showImportDataTable, setShowImportDataTable] = useState(false);
//   const [pdfReportData, setPDFReportData] = useState([]);
//   const [totalEntries, setTotalEntries] = useState(0);

//   const [showPDFReport, setShowPDFReport] = useState(false);

//   const [showImportPDFReport, setShowImportPDFReport] = useState(false); // State for PDF report visibility

//   const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
//   const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
//   // const formattedSerDate = sirDate.toISOString().split('T')[0];

//   const makeFieldEmpty = () => {
//     setStartDate(new Date());
//     setEndDate(new Date());
//     setAirlines([]);
//     setAirlineNames([]);
//     setImportedPCTMData([]);
//     setImportData([]);
//     setImportAllData([]);
//     setPDFReportData([]);
//     setSelectedAllAirline("");
//     setSelectedAirlinePCTM("");
//     setSelectedAirline("");
//     // setExportTpData([]);
//     // setExportData([]);
//     // setSummaryDataForTPData([]);
//   };

//   const {
//     jwtToken,
//     user_Id,
//     username,
//     branchId,
//     companyid,
//     role,
//     companyname,
//     branchname,
//     login,
//     logout,
//   } = useContext(AuthContext);

//   const importFields = [
//     "MAWB",
//     "HAWB",
//     "SIR_No",
//     "Imp_Trans_Date",
//     "IGM_No",
//     "IGM_Date",
//     "PCTM_No",
//     "TP_No",
//     "TP_Date",
//     "Airline_Name",
//     "Flight_No",
//     "Flight_Date",
//     "Country_Origin",
//     "Port_Origin",
//     "Importer_Id",
//     "IEC",
//     "SEZ_Entity_Id",
//     "Console",
//     "Package_Content_Type",
//     "Parcel_Type",
//     "UOM_Packages",
//     "Nop",
//     "Import_Remarks",
//     "Description_Of_Goods",
//     "CHA_CDE",
//     "Assessable_Value",
//     "Gross_Weight",
//     "BE_Request_ID",
//     "BE_No",
//     "BE_Date",
//     "Import_Address",
//     "Status",
//     "Sir_Date",
//     "Created_By",
//     "Created_Date",
//     "Edited_By",
//     "Edited_Date",
//     "Approved_By",
//     "Approved_Date",
//   ];

//   // const formatPctmNo = (pctmNumbers) => {
//   //   // Remove leading zeros using a regular expression
//   //   return pctmNumbers.replace(/^0+/, "");
//   // };



//   const formatPctmNo = (pctmNumbers) => {
//     if (pctmNumbers === null || pctmNumbers === undefined) {
//       return pctmNumbers; // Return null or undefined as it is
//     }  
//     // Remove leading zeros using a regular expression
//     return pctmNumbers.replace(/^0+/, "");
//   };

//   const CustomHeader = () => {
//     return (
//       <View style={styles.header}>
//         <Image src={dgdcimage} style={styles.image} />
//       </View>
//     );
//   };

//   const formatedDate = (inputDate) => {
//     const date = new Date(inputDate);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${day}/${month}/${year}`;
//   };

//   const commonTextStyle = {
//     fontSize: 9,
//   };

//   const styles = StyleSheet.create({
//     page: {
//       flexDirection: "column",
//       alignItems: "center",
//       width: "100%",
//       height: "100%",
//       paddingTop: 9,
//       paddingLeft: 27,
//       paddingRight: 27,
//       paddingTop: 27,
//     },
//     header: {
//       marginBottom: 1,
//     },
//     table: {
//       width: "100%",
//       borderCollapse: "collapse",
//       marginBottom: 5,
//     },
//     tableHeaderRow: {
//       flexDirection: "row",
//     },
//     tableHeaderCell: {
//       border: "0.5px solid #000",
//       padding: 1,
//       backgroundColor: "#f2f2f2",
//       textAlign: "center",
//       fontSize: 9,
//     },
//     tableRow: {
//       flexDirection: "row",
//       fontWeight: "bold",
//     },
//     tableCell: {
//       border: "0.4px solid #000",
//       padding: 1,
//       textAlign: "center",
//       fontSize: 7,
//     },
//     tableCellHeader: {
//       fontWeight: "bold",
//       textAlign: "center",
//       fontSize: 9,
//     },
//     image: {
//       width: 306,
//       height: 72,
//       // marginLeft: 18,
//       justifyContent: "center",
//     },

//     text: {
//       marginLeft: 18,
//       ...commonTextStyle,
//     },
//     hr: {
//       width: "100%",
//       borderBottom: 1,
//       marginTop: 5,
//     },
//     leftColumn: {
//       width: "100%",
//       paddingTop: 5,
//     },
//     underline: {
//       textDecoration: "underline",
//     },
//   });

//   const hrStyle = {
//     borderTop: "5px solid black", // Adjust the thickness and color as needed
//   };

//   // Create a form state object with initial values for each field
//   const initialFormState = {};
//   importFields.forEach((field) => {
//     initialFormState[field] = "";
//   });

//   const [formData, setFormData] = useState(initialFormState);
//   // Function to handle the "SEARCH" button click

//   const handleSearch = () => {
//     // Make an API request here to fetch the list of airline names based on the provided criteria

//     fetch(
//       `https://${ipaddress}import/airline-names?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         // Ensure that 'data' is an array before updating the 'airlines' state
//         if (Array.isArray(data)) {
//           // Update the 'airlines' state with the fetched data
//           setSelectedAirline("Select ");
//           console.log('airlines ', data);
//           setAirlines(data);
//         } else {
//           console.error("API response is not an array:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching airline names:", error);
//       });
//   };

//   useEffect(() => {
//     // Fetch airline names and preselect the first one when the component mounts
//     handleSearch();
//   }, []);

//   const handleShow = () => {
//     // You can use this function to display the fetched data in a table or perform other actions
//     axios
//       .get(
//         `https://${ipaddress}import/allimportData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAirline}`
//       )
//       .then((response) => {
//         const importData = response.data; // Data for the selected airline
//         //  setSelectedAirlineFilghtDate(selectedAirlineFlightdate);
//         setImportData(importData);
//         console.log("Results");
//         console.log(importData);
//         // Update the state with the fetched data
//         setShowTable(true);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   };

//   const [partys, setPartys] = useState([]);

//   const [getpartyId, setGetpartyId] = useState({});
//   const fetchPartyNames = async () => {
//     try {
//       const response = await fetch(
//         `https://${ipaddress}parties/getAll/${companyid}/${branchId}`
//       );
//       const data = await response.json();
//       const namesMap = {};
//       data.forEach((party) => {
//         namesMap[party.partyId] = party.partyName;
//       });
//       setGetpartyId(namesMap);
//       setPartys(data);
//     } catch (error) {
//       console.error("Error fetching party names:", error);
//     }
//   };

//   useEffect(() => {
//     fetchPartyNames();
//   }, []);

//   const [airlineNanmes, setAirlineNanmes] = useState([]);
//   const [getAirlineNameByAirCode, setAirlineNameByAirCode] = useState({});
//   const [namesMap, setNamesMap] = useState({});
//   const fetchAirlinesNames = async () => {
//     try {
//       const response = await fetch(
//         `https://${ipaddress}Airline/list/${companyid}/${branchId}`
//       );
//       const data = await response.json();
//       const namesAirMap = {};
//       data.forEach((air) => {
//         namesAirMap[air.airlineCode] = air.airlineName;
//       });
//       setNamesMap(namesAirMap);
//       setAirlineNameByAirCode(namesAirMap);
//       console.log("Airlines  Print", namesAirMap);
//       setAirlineNanmes(data);
//     } catch (error) {
//       console.error("Error fetching party names:", error);
//     }
//   };

//   useEffect(() => {
//     fetchAirlinesNames();
//   }, []);
//   const getAIRNames = airlineNanmes.map((airline) => ({
//     label: airline.airlineName, // Use the name from the namesMap
//     value: airline.airlineCode,
//   }));

//   function fetchCompanyName(companyId) {
//     // Make an Axios GET request to retrieve the company name based on companyId
//     return axios
//       .get(`https://${ipaddress}import/findCompanyname/${companyId}`)
//       .then(function (response) {
//         return response.data; // Return the retrieved company name
//       })
//       .catch(function (error) {
//         console.error("Error fetching company name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }
//   function fetchExporterName(companyId, branchId, partyId) {
//     // Make an Axios GET request to retrieve the company name based on companyId
//     return axios
//       .get(
//         `https://${ipaddress}import/findPartyName/${companyId}/${branchId}/${partyId}`
//       )
//       .then(function (response) {
//         return response.data; // Return the retrieved company name
//       })
//       .catch(function (error) {
//         console.error("Error fetching company name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }

//   function fetchBranchName(companyId, branchId) {
//     // Make an Axios GET request to retrieve the branch name based on branchId
//     return axios
//       .get(
//         `https://${ipaddress}import/findBranchName/${companyId}/${branchId}`
//       )
//       .then(function (response) {
//         return response.data; // Return the retrieved branch name
//       })
//       .catch(function (error) {
//         console.error("Error fetching branch name:", error);
//         return ""; // Return an empty string or handle the error as needed
//       });
//   }

//   const calculateDistinctMAWB = (importPCTMData) => {
//     const distinctMAWBCounts = {};

//     importPCTMData.forEach((item) => {
//       const mawb = item.mawb;
//       if (mawb) {
//         if (distinctMAWBCounts[mawb]) {
//           distinctMAWBCounts[mawb] += 1;
//         } else {
//           distinctMAWBCounts[mawb] = 1;
//         }
//       }
//     });

//     return distinctMAWBCounts;
//   };
//   const distinctMAWBCounts = calculateDistinctMAWB(importPCTMData);
//   const totalDistinctMAWBs = Object.keys(distinctMAWBCounts).length;

//   const calculateDistinctIGM = (importPCTMData) => {
//     const distinctIGMCounts = {};

//     importPCTMData.forEach((item) => {
//       const igmNo = item.igmNo;
//       if (igmNo) {
//         if (distinctIGMCounts[igmNo]) {
//           distinctIGMCounts[igmNo] += 1;
//         } else {
//           distinctIGMCounts[igmNo] = 1;
//         }
//       }
//     });

//     return distinctIGMCounts;
//   };
//   const [consoles, setConsoles] = useState([]);
//   const [getConsoleId, setGetConsoleId] = useState({});

//   const fetchConsoleNames = async () => {
//     try {
//       const response = await fetch(
//         `https://${ipaddress}externalParty/console/${companyid}/${branchId}`
//       );
//       const data = await response.json();
//       const consoleMap = {};
//       data.forEach((console) => {
//         consoleMap[console.externaluserId] = console.userName;
//       });
//       setGetConsoleId(consoleMap);
//       setConsoles(data);
//     } catch (error) {
//       console.error("Error fetching party names:", error);
//     }
//   };

//   useEffect(() => {
//     fetchConsoleNames();
//   }, []);
//   const distinctIGMCounts = calculateDistinctIGM(importPCTMData);

//   const totalDistinctIGM = Object.keys(distinctIGMCounts).length;

//   const renderTable = () => (
//     <View style={styles.table}>
//       <View style={styles.tableRow}>
//         <Text
//           style={[
//             styles.tableCell,
//             styles.tableCellHeader,
//             { width: 54, fontWeight: "bold" },
//           ]}
//         >
//           Sr.No
//         </Text>
//         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 75 }]}>
//           Sir No
//         </Text>
//         <Text
//           style={[styles.tableCell, styles.tableCellHeader, { width: 227 }]}
//         >
//           Importer Name
//         </Text>
//         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 81 }]}>
//           Origin Airport
//         </Text>
//         <Text
//           style={[styles.tableCell, styles.tableCellHeader, { width: 120 }]}
//         >
//           MAWB
//         </Text>
//         <Text
//           style={[styles.tableCell, styles.tableCellHeader, { width: 120 }]}
//         >
//           HAWB
//         </Text>
//         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 54 }]}>
//           NOP
//         </Text>
//         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 72 }]}>
//           Weight
//         </Text>
//         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 81 }]}>
//           Desc
//         </Text>
//         <Text style={[styles.tableCell, styles.tableCellHeader, { width: 72 }]}>
//           Console
//         </Text>
//         <Text
//           style={[styles.tableCell, styles.tableCellHeader, { width: 100 }]}
//         >
//           Remark Delivered
//         </Text>
//       </View>
//       {importPCTMData.map((item, index) => (
//         <View style={styles.tableRow} key={index}>
//           <Text style={{ ...styles.tableCell, width: 54 }}>{index + 1}</Text>
//           <Text style={{ ...styles.tableCell, width: 75 }}>
//             {item.sirNo || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 227 }}>
//             {getpartyId[item.importerId] || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 81 }}>
//             {item.portOrigin || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 120 }}>
//             {item.mawb || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 120 }}>
//             {item.hawb || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 54 }}>
//             {item.nop || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 72 }}>
//             {item.grossWeight || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 81 }}>
//             {item.descriptionOfGoods || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 72 }}>
//             {getConsoleId[item.consoleName] || ""}
//           </Text>
//           <Text style={{ ...styles.tableCell, width: 100 }}>
//             {item.importRemarks || ""}
//           </Text>
//         </View>
//       ))}
//     </View>
//   );
//   const handlePdfImportPCTMData = async () => {
//     try {
//       const response = await axios.post(
//         `https://${ipaddress}import/printOfImportPctm?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}&pctmNo=${selectedAirlinePCTM}`
//       );
//       // toast.success("GST Summary PDF Created Successfully ", { position: "top-center" ,autoClose: 900});

//       if (response.status === 200) {
//         const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF

//         // Create a Blob from the Base64 data
//         const pdfBlob = new Blob(
//           [Uint8Array.from(atob(pdfBase64), (c) => c.charCodeAt(0))],
//           { type: "application/pdf" }
//         );

//         // Create a URL for the Blob
//         const blobUrl = URL.createObjectURL(pdfBlob);

//         // Create an anchor element for downloading
//         const downloadLink = document.createElement("a");
//         downloadLink.href = blobUrl;
//         downloadLink.download = "import_pctm_report.pdf"; // Set the filename for the downloaded PDF
//         downloadLink.style.display = "none";
//         document.body.appendChild(downloadLink);

//         // Trigger the download
//         downloadLink.click();

//         // Clean up
//         document.body.removeChild(downloadLink);
//         window.URL.revokeObjectURL(blobUrl);

//         toast.success("Downloaded Import Pctm PDF Successfully !", {
//           position: toast.POSITION.TOP_CENTER,
//           autoClose: 900,
//         });
//       } else {
//         throw new Error("Failed to generate PDF");
//       }
//     } catch (error) { }
//   };
//   const handlePrintAllImportPctmData = async () => {
//     try {
//       const response = await axios.post(
//         `https://${ipaddress}import/printOfImportPctm?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}&pctmNo=${selectedAirlinePCTM}`
//       );
//       if (response.status === 200) {
//         const base64PDF = response.data;

//         // Create a new window for displaying the PDF
//         const newWindow = window.open("", "_blank");

//         // Write the HTML content to the new window
//         newWindow.document.write(`
//         <!DOCTYPE html>
//         <html>
//         <head>
//           <title>import_pctm_report Viewer</title>
//           <style>
//             body {
//               margin: 0;
//               padding: 0;
//               overflow: hidden;
//             }
//             embed {
//               width: 100vw;
//               height: 100vh;
//             }
//           </style>
//         </head>
//         <body>
//           <embed src="data:application/pdf;base64,${base64PDF}" type="application/pdf" width="100%" height="100%">
//         </body>
//         </html>
//       `);
//       } else {
//         throw new Error("Failed to generate PDF");
//       }
//     } catch (error) {
//       // Handle errors if any
//     }
//   };
//   const MyDocument = ({
//     importPCTMData,
//     selectedAllAirline,
//     selectedAirlinePCTM,
//     totalRows,
//     totalDistinctMAWBs,
//     totalDistinctIGM,
//     totalPackages,
//   }) => (
//     <Document>
//       <Page size="A4" style={styles.page}>
//         <CustomHeader />
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Text
//             className="text-center"
//             style={{ fontSize: 9, fontWeight: "bold" }}
//           >
//             IMPORT PCTM REPORT{" "}
//           </Text>
//           <Text className="text-center" style={{ fontSize: 9 }}>
//             DGDC SEEPZ SEZ STRONG ROOM
//           </Text>
//           <Text className="text-center" style={{ fontSize: 9 }}>
//             MAIL LTD-CSI AIRPORT ,AIR CARGO COMPLEX,SAHAR MUMBAI-400099
//           </Text>
//           <Text
//             className="text-center"
//             style={{ ...styles.underline, fontSize: 9 }}
//           >
//             IMPORT - PRECIOUS CARGO TRANSFER MANIFEST
//           </Text>
//           <Text className="text-center" style={{ fontSize: 9 }}>
//             From Date : {formatedDate(startDate)} To Date :
//             {formatedDate(endDate)}
//           </Text>
//           <br />
//           <br />
//         </div>

//         <View style={styles.leftColumn}>
//           <Text style={{ fontSize: 9, fontWeight: "bold" }}>
//             REPORT DATE: {format(new Date(), "dd/MM/yyyy HH:mm")}
//           </Text>
//           <Text style={{ fontSize: 9, fontWeight: "bold" }}>
//             PCTM NO: {formatPctmNo(importPCTMData[0].pctmNo)}
//           </Text>
//           <Text style={{ fontSize: 9, fontWeight: "bold" }}>
//             IGM NO: {importPCTMData[0].igmNo}
//           </Text>
//         </View>
//         <View style={styles.hr}></View>
//         {/* <Text style={{ fontSize: 9, marginBottom: 4, marginTop: 5 }}>
//           Received from <span style={{ fontWeight: "bold" }}>Flight -</span>{" "}
//           {importPCTMData[0].airlineName} on{" "}
//           <Text style={{ fontWeight: "bold" }}>Flight Date & Time -</Text>{" "}
//           {importPCTMData[0].flightNo}{" "}
//           {formatedDate(importPCTMData[0].flightDate)} at DGDC SEEPZ SEZ STRONG
//           ROOM SAHAR in escort of
//         </Text> */}
//         <Text style={{ fontSize: 9, marginBottom: 4, marginTop: 5 }}>
//           Received from
//           <Text style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
//             {" Flight - "}
//           </Text>
//           {importPCTMData[0].airlineName}
//           {" on "}
//           <Text style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
//             {" Flight Date & Time - "}
//           </Text>
//           {importPCTMData[0].flightNo}{" "}
//           {formatedDate(importPCTMData[0].flightDate)}
//           {" at DGDC SEEPZ SEZ STRONG ROOM SAHAR in escort of"}
//         </Text>

//         {renderTable()}

//         <View style={{ flexDirection: "row", paddingTop: 4 }}>
//           <View style={{ marginRight: 27 }}>
//             <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//               Total No. Of Packages Received:{" "}
//               <Text style={{ fontWeight: "normal" }}>{totalPackages}</Text>
//             </Text>
//           </View>
//           <View style={{ marginRight: 27 }}>
//             <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//               By Flight No.: {importPCTMData[0].flightNo}
//             </Text>
//           </View>
//           <View style={{ marginRight: 20, fontWeight: "bold" }}>
//             <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//               Total Distinct MAWB No: {totalDistinctMAWBs}
//             </Text>
//           </View>
//           <View style={{ marginRight: 20 }}>
//             <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//               Total No. Of IGM No: {totalDistinctIGM}
//             </Text>
//           </View>
//         </View>

//         <View style={{ paddingTop: 9 }}>
//           <View style={{ flexDirection: "row" }}>
//             <View style={{ marginRight: 54 }}>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 ESCORT AND DEPOSITED :&nbsp;&nbsp;&nbsp; {totalPackages}
//               </Text>
//             </View>

//             <View style={{ marginRight: 54 }}>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 PKGS BY FLIGHT :&nbsp;&nbsp;&nbsp;{" "}
//                 {importPCTMData[0].airlineName}
//               </Text>
//             </View>
//             <View style={{ marginLeft: 27 }}>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 ON.:&nbsp;&nbsp;&nbsp;{" "}
//                 {formatedDate(importPCTMData[0].flightDate)}
//               </Text>
//             </View>
//           </View>
//         </View>

//         <Text
//           style={{
//             fontWeight: "bold",
//             fontSize: 9,
//             marginTop: 18,
//             paddingBottom: 9,
//           }}
//         >
//           TO DGDC SEEPZ SEZ STRONG ROOM UNDER CUSTOMS PREVENTIVE SUPERVISION
//         </Text>

//         <View style={{ paddingLeft: 18 }}>
//           <View style={{ flexDirection: "row" }}>
//             <View style={{ width: "50%" }}>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 Airline Staff Signature:_______________{"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 Name :_______________{"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 Date & time:_______________{"\n"}
//                 {"\n"}
//               </Text>
//             </View>

//             <View style={{ width: "50%", paddingRight: 18 }}>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 I.F.O. Signature :_______________{"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 Name :_______________{"\n"}
//                 {"\n"}
//               </Text>

//               <Text style={{ fontWeight: "bold", fontSize: 9 }}>
//                 Date & time :_______________{"\n"}
//                 {"\n"}
//               </Text>
//             </View>
//           </View>
//         </View>

//         <Text style={{ fontWeight: "bold", fontSize: 9, paddingTop: 9 }}>
//           Received above consignment in full in apparent good order and
//           condition except as noted in the Remarks Column
//         </Text>
//         <View style={{ paddingTop: 9 }}>
//           <View style={{ flexDirection: "row" }}>
//             <View
//               style={{
//                 width: "33%",
//                 borderRight: "1px solid black",
//                 paddingRight: 4,
//                 paddingLeft: 5,
//               }}
//             >
//               <Text style={{ fontSize: 9 }}>
//                 Total No. Of Packages:&nbsp; {totalPackages}
//                 <Text style={{ fontWeight: "bold" }}>{totalRows}</Text>
//                 {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Receiver's Signature: {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Name: {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Asstt Custodian DGDC Seepz Sez, Strong room sahar Mumbai-99:{" "}
//                 {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Date: {"\n"}
//                 {"\n"}
//               </Text>
//             </View>

//             <View
//               style={{
//                 width: "33%",
//                 borderRight: "1px solid black",
//                 paddingRight: 4,
//                 paddingLeft: 5,
//               }}
//             >
//               <Text style={{ fontSize: 9 }}>
//                 Total No. Of Packages: &nbsp; {totalPackages}
//                 <Text style={{ fontWeight: "bold" }}>{totalRows}</Text>
//                 {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Receiver's Signature: {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Name: {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Asstt Custodian DGDC Seepz Sez, Strong room sahar Mumbai-99:{" "}
//                 {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Date: {"\n"}
//                 {"\n"}
//               </Text>
//             </View>

//             <View style={{ width: "33%", paddingLeft: 5 }}>
//               <Text style={{ fontSize: 9 }}>
//                 Total No. Of Packages: &nbsp; {totalPackages}
//                 <Text style={{ fontWeight: "bold" }}>{totalRows}</Text>
//                 {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Receiver's Signature: {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Name: {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Asstt Custodian DGDC Seepz Sez, Strong room sahar Mumbai-99:{" "}
//                 {"\n"}
//                 {"\n"}
//               </Text>
//               <Text style={{ fontSize: 9 }}>
//                 Date: {"\n"}
//                 {"\n"}
//               </Text>
//             </View>
//           </View>
//         </View>
//         <View style={styles.hr}></View>
//       </Page>
//     </Document>
//   );

//   const generatePDFReport = async () => {
//     const pdfBlob = await pdf(
//       <MyDocument
//         {...{
//           importPCTMData,
//           totalDistinctMAWBs,
//           totalDistinctIGM,
//           totalPackages,
//         }}
//       />
//     ).toBlob();
//     saveAs(pdfBlob, "Import_PCTM.pdf");
//   };

//   const generateAllPDFReport = () => {
//     if (selectedItems.length === 0) {
//       // Show an error message if no rows are selected
//       toast.error("No rows selected. Please select rows to generate PCTM.", {
//         position: "top-center",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//       });
//       return; // Don't proceed with the API call
//     }
//     // console.log("SENDING.......");
// // console.log(selectedItems);
//   Rate_Chart_Service.updatePctmNumber(selectedItems)   
//       .then((response) => {
//         const reportData = response.data; // Data for the selected airline
//         setShowPDFReport(true);
//         console.log("RemovedRecordArray", RemovedRecordArray);
//         // Pass the data to the PDFReport component
//         setPDFReportData(pdfReportData);
//         setImportAllData(importAllData);
//         setTotalEntries(importData.length);
//         handleShow();

//         // Display a toast notification when pctmNo is updated successfully
//         toast.success("pctmNo generated successfully", {
//           position: "top-center",
//           autoClose: 3000, // Close the notification after 3 seconds
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//         });

//         // Refresh the page
//         // window.location.reload();
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);

//         // Display an error toast notification if there's an error
//         toast.error("Error fetching data", {
//           position: "bottom-right",
//           autoClose: 5000, // Close the notification after 5 seconds
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//         });
//       });
//   };

//   useEffect(() => {
//     if (!isAuthenticated) {
//       navigate(
//         "/login?message=You need to be authenticated to access this page."
//       );
//     }
//   }, [isAuthenticated, navigate]);

//   const [importAllDatalist, setImportAllDatalist] = useState([]);

//   useEffect(() => {
//     // Fetch data from your backend API
//     axios
//       .get(`https://${ipaddress}import/all/${companyid}/${branchId}`)
//       .then((response) => {
//         // Assuming your API response is an array of Import objects
//         setImportAllDatalist(importAllDatalist);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);
//   const formatDate = (inputDate) => {
//     const date = new Date(inputDate);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${day}/${month}/${year}`;
//   };

//   const handleAllSearch = async () => {
//     // Make an API request here to fetch the list of airline names based on the provided criteria
//     console.log("///////////////////////////////////////////////////");
//     console.log("StartDate " + formattedStartDate);
//     console.log("EndDate  " + formattedEndDate);
//     fetch(
//       `https://${ipaddress}import/Allairline-names?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         // console.warn(
//         //   "******************************************************************************"
//         // );
//         console.log(data);
//         // Ensure that 'data' is an array before updating the 'airlines' state
//         if (Array.isArray(data)) {
//           // Update the 'airlines' state with the fetched data
//           setSelectedAllAirline("Select");
//           setAirlineNames(data);
//         } else {
//           console.error("API response is not an array:", data);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching airline names:", error);
//       });
//   };

//   const handleShowPctmData = () => {
//     if (selectedAllAirline && selectedAirlinePCTM) {
//       axios
//         .get(
//           `https://${ipaddress}import/allimportPCTMData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}&pctmNo=${selectedAirlinePCTM}`
//         )
//         .then((response) => {
//           const importPCTMData = response.data; // Data for the selected PCTM number
//           setImportedPCTMData(importPCTMData); // Update the state with the fetched data
//           setShowImportDataTable(true);
//           setTotalEntries(importPCTMData.length);
//         })
//         .catch((error) => {
//           console.error("Error fetching PCTM data:", error);
//         });
//     }
//   };

//   useEffect(() => {
//     // Fetch PCTM numbers based on the selected airline
//     if (selectedAllAirline) {
//       axios
//         .get(
//           `https://${ipaddress}import/getPctmNo?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}`
//         )
//         .then((response) => {
//           const data = response.data;
//           setPctmNumbers(data);
//         })
//         .catch((error) => {
//           console.error("Error fetching PCTM numbers:", error);
//         });
//     }
//   }, [selectedAllAirline]); // Run this effect whenever selectedAllAirline changes

//   const totalPackages = importPCTMData.reduce(
//     (total, item) => total + item.nop,
//     0
//   );

//   const handleXLSDownload = async () => {
//     const modifiedRecordList = await Promise.all(
//       importPCTMData.map(async (item, index) => {
//         // const companyname = await fetchCompanyName(item.companyId);
//         // const branchname = await fetchBranchName(item.companyId, item.branchId);
//         const importerId = await fetchExporterName(
//           item.companyId,
//           item.branchId,
//           item.importerId
//         );
//         return {
//           "Sr.No": index + 1,
//           // "Company Name": companyname,
//           // "Branch Name": branchname,
//           "SIR Date": formatedDate(item.sirDate) || "",
//           "SIR NO": item.sirNo || "",
//           "M.A.W.B": item.mawb || "",
//           "H.A.W.B": item.hawb.startsWith('000') ? '' : item.hawb || "", // Modify this to the actual field name
//           "NO OF PKGS": item.nop || "",
//           "Importer Names": getpartyId[item.importerId] || "",
//         };
//       })
//     );

//     // Calculate the total "SIR No" and "No of Pkgs"
//     const totalSIRNo = modifiedRecordList.reduce(
//       (total, item) => total + (item["SIR NO"] ? 1 : 0),
//       0
//     );

//     const totalNoOfPkgs = modifiedRecordList.reduce(
//       (total, item) => total + (item["NO OF PKGS"] || 0),
//       0
//     );
//     const distanceRow = {
//       "Sr.No": "",
//       // "Company Name": "",
//       // "Branch Name": "",
//       "SIR Date": "",
//       "SIR NO": "",
//       "M.A.W.B": "",
//       "H.A.W.B": "", // Modify this to the actual field name
//       "NO OF PKGS": "",
//       "Importer Names": "",
//     };
//     // Add a total row
//     const totalRow = {
//       "Sr.No": "Total ",
//       // "Company Name": "",
//       // "Branch Name": "",
//       "SIR Date": "",
//       "SIR No": totalSIRNo,
//       "M.A.W.B": "",
//       "H.A.W.B": "", //
//       "NO OF PKGS": totalNoOfPkgs,
//       "Importer Names": "",
//     };

//     const workbook = XLSX.utils.book_new();
//     const worksheet = XLSX.utils.json_to_sheet([
//       distanceRow,
//       ...modifiedRecordList,
//       distanceRow, // Insert the distance row
//       totalRow, // Insert the total row
//     ]);

//     // Add headers for all fields
//     const headers = Object.keys(modifiedRecordList[0]);
//     headers.forEach((header, index) => {
//       worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
//         t: "s",
//         v: header,
//         s: { font: { bold: true } },
//       };
//     });

//     // Set column widths based on data
//     const colWidths = headers.map((header) => ({
//       wch: header.length + 2, // You can adjust the width as needed
//     }));

//     worksheet["!cols"] = colWidths;

//     XLSX.utils.book_append_sheet(workbook, worksheet, "Import_Register");
//     const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
//     const blob = new Blob([s2ab(xlsFile)], {
//       type: "application/vnd.ms-excel",
//     });
//     saveAs(blob, "import_pctm.xls");
//   };

//   // Helper function to convert s2ab
//   const s2ab = (s) => {
//     const buf = new ArrayBuffer(s.length);
//     const view = new Uint8Array(buf);
//     for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
//     return buf;
//   };

//   const [importerNames, setImporterNames] = useState({});

//   const fetchImporterName = (importerId, partyId) => {
//     // Ensure that partyId is equal to importerId
//     if (partyId !== importerId) {
//       console.error(
//         `partyId (${partyId}) is not equal to importerId (${importerId}).`
//       );
//       return;
//     }

//     // Make an API request to fetch the importerName based on importerId
//   };

//   const totaleNoOfPackages = importData.reduce(
//     (total, item) => total + item.nop,
//     0
//   );

//   const [selectedRows, setSelectedRows] = useState([]);
//   const [selectAllChecked, setSelectAllChecked] = useState(false);
//   const [RemovedRecordArray, setRemovedRecordArray] = useState([]);

//   const handleRowSelection = (item) => {

//     const removedNumber = item.sirNo;
//     setRemovedRecordArray(prevArray => {
//       // Check if removedNumber is already in the array
//       const index = prevArray.indexOf(removedNumber);
//       console.log("gdgd", item);
//       if (index !== -1) {
//         // If it exists, remove it
//         return prevArray.slice(0, index).concat(prevArray.slice(index + 1));
//       } else {
//         // If it doesn't exist, add it
//         return [...prevArray, removedNumber];
//       }
//     });


//     const updatedSelectedRows = selectedRows.includes(item)
//       ? selectedRows.filter((row) => row !== item)
//       : [...selectedRows, item];
//     setSelectedRows(updatedSelectedRows);

//     setSelectAllChecked(updatedSelectedRows.length === importData.length);
//   };




//   // const handleSelectAll = () => {
//   //   const sirNoList = importData.map(item => item.sirNo);
//   //   setRemovedRecordArray(sirNoList);
//   //   // console.log(sirNoList);
//   //   if (selectAllChecked) {
//   //     setSelectedRows([]);
//   //     setSelectAllChecked(false);
//   //   } else {
//   //     setSelectedRows(importData);
//   //     setSelectAllChecked(true);
//   //   }
//   // };

//   const [selectAll, setSelectAll] = useState(false);
//   const [selectedItems, setSelectedItems] = useState([]);
//   useEffect(() => {
//     setSelectAll(selectedItems.length === importData.length);
//     console.log("select");
//     console.log(selectedItems);

//   }, [selectedItems, importData]);

//   const handleSelectAllToggle = () => {
//     console.log("select all");
//     console.log(selectedItems);
//     if (selectAll) {
//       setSelectedItems([]);
//     } else {
//       setSelectedItems(importData);
//     }
//     setSelectAll(!selectAll);
//   };

//   const handleRowCheckboxChange = (index) => {
//     const selectedItem = importData[index];

//     console.log("importData");
//     console.log(importData);

//     if (selectedItem) {
//       const selectedIndex = selectedItems.findIndex((item) => item.sirNo === selectedItem.sirNo);

//       if (selectedIndex !== -1) {
//         // Remove the item from the selected items
//         const updatedSelectedItems = [...selectedItems];
//         updatedSelectedItems.splice(selectedIndex, 1);
//         setSelectedItems(updatedSelectedItems);
//       } else {
//         // Add the item to the selected items
//         setSelectedItems([...selectedItems, selectedItem]);
//       }
//     }

//     console.log("Selected Items ");
//     console.log(selectedItems);
//   };



//   const [pctmNoMap, setPctmNoMap] = useState({});

//   // Function to generate pctmNo for selected rows
//   const generatePctmNoForSelectedRows = () => {
//     const pctmNoMapCopy = {};
//     selectedRows.forEach((row) => {
//       // Generate pctmNo for the selected row here
//       // You can customize the logic to generate pctmNo as needed
//       // For example, you can generate a unique pctmNo based on some data from the selected row.
//       const pctmNo = generatePctmNoBasedOnRowData(row);
//       pctmNoMapCopy[row.sirNo] = pctmNo;
//     });
//     setPctmNoMap(pctmNoMapCopy);
//   };

//   // Example function to generate a pctmNo based on row data (customize this according to your needs)
//   const generatePctmNoBasedOnRowData = (row) => {
//     // Replace this with your logic to generate a unique pctmNo for the row
//     return `${row.sirNo}_PCTM`;
//   };



//   //Import PCTM

// const formatDateTime2 = (value) => {
//   if (!value) {
//     return "";
//   }
//   const date = new Date(value);
//   const day = String(date.getDate()).padStart(2, "0");
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const year = date.getFullYear();
//   const hours = String(date.getHours()).padStart(2, "0");
//   const minutes = String(date.getMinutes()).padStart(2, "0");
//   const seconds = String(date.getSeconds()).padStart(2, "0");
//   return `${day}/${month}/${year} `;
// };
// const getExcel = (imp) => {
//   const filename = `Import_PCTM_${formatDateTime2(new Date())}.xlsx`; // Note: Changed file extension to xlsx
//   axios.post(`https://${ipaddress}import/pctmexcel`, imp, { responseType: 'blob' }) // Added responseType: 'blob'
//     .then(async (response) => {
//       const blob = new Blob([response.data], { type: response.headers['content-type'] });

//       // Create a temporary URL for the blob
//       const url = window.URL.createObjectURL(blob);

//       // Create a link element to trigger the download
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = filename;
//       document.body.appendChild(a);
//       a.click();

//       // Clean up
//       window.URL.revokeObjectURL(url);
//       document.body.removeChild(a);
//     })
//     .catch((error) => {
//       toast.error("Something went wrong", {
//         autoClose: 700
//       });
//     });
// };

//   return (
//     <div className="Container">
//       <h5
//         className="pageHead"
//         style={{
//           fontFamily: "Your-Heading-Font",
//           paddingLeft: "2%",
//           paddingRight: "-0px",
//         }}
//       >
//         {" "}
//         <FontAwesomeIcon
//           icon={faPlane}
//           style={{
//             marginRight: "8px",
//             color: "black", // Set the color to golden
//           }}
//         />
//         Import PCTM
//       </h5>
//       <Card>
//         <Container>
//           <div className="Container" style={{ marginTop: 20 }}>
//             <ul className="nav nav-tabs" id="myTab" role="tablist">
//               <li className="nav-item tabspace" role="presentation">
//                 <button
//                   style={{ color: "gray" }}
//                   className="nav-link active"
//                   id="home-tab"
//                   data-bs-toggle="tab"
//                   data-bs-target="#home"
//                   type="button"
//                   role="tab"
//                   aria-controls="home"
//                   aria-selected="true"
//                   onClick={(e) => makeFieldEmpty()}
//                 >
//                   <h6>Generate</h6>
//                 </button>
//               </li>

//               <li className="nav-item tabspace" role="presentation">
//                 <button
//                   style={{ color: "gray" }}
//                   className="nav-link"
//                   id="contact-tab"
//                   data-bs-toggle="tab"
//                   data-bs-target="#contact"
//                   type="button"
//                   role="tab"
//                   aria-controls="contact"
//                   aria-selected="false"
//                   onClick={(e) => makeFieldEmpty()}
//                 >
//                   <h6>Print</h6>
//                 </button>
//               </li>
//             </ul>
//             <div className="tab-content" id="myTabContent">
//               <div
//                 className="tab-pane fade show active"
//                 id="home"
//                 role="tabpanel"
//                 aria-labelledby="home-tab"
//               >
//                 <Card
//                   style={{
//                     marginTop: 25,
//                     marginBottom: 25,

//                     padding: 8,
//                   }}
//                 >
//                   <h5
//                     className="pageHead"
//                     style={{
//                       fontFamily: "Your-Heading-Font",
//                       paddingLeft: "3%",
//                     }}
//                   >
//                     {" "}
//                     <FontAwesomeIcon
//                       icon={faBolt}
//                       style={{
//                         marginRight: "8px",
//                         color: "black", // Set the color to golden
//                       }}
//                     />{" "}
//                     Generate{" "}
//                   </h5>
//                   <Container>
//                     <Form>
//                       <Row>
//                         <Col md={3}>
//                           <Form.Group>
//                             <Form.Label className="forlabel">
//                               SIR Date From
//                             </Form.Label>
//                             <DatePicker
//                               selected={startDate}
//                               wrapperClassName="custom-react-datepicker-wrapper"
//                               onChange={(date) => {
//                                 if (date) {
//                                   setStartDate(date);
//                                 }
//                               }}
//                               value={startDate}
//                               dateFormat="dd/MM/yyyy"
//                               className="form-control"
//                               customInput={<input style={{ width: "100%" }} />}
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col md={3}>
//                           <Form.Group>
//                             <Form.Label className="forlabel">
//                               SIR Date To
//                             </Form.Label>
//                             <DatePicker
//                               selected={endDate}
//                               wrapperClassName="custom-react-datepicker-wrapper"
//                               onChange={(date) => {
//                                 if (date) {
//                                   setEndDate(date);
//                                 }
//                               }}
//                               value={endDate}
//                               dateFormat="dd/MM/yyyy"
//                               className="form-control"
//                               customInput={<input style={{ width: "100%" }} />}
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col md={2}>
//                           <Button
//                             type="button"
//                             style={{
//                               marginTop: 32,
//                             }}
//                             className="allbutton"
//                             variant="outline-primary"
//                             onClick={handleSearch}
//                           >
//                             <FontAwesomeIcon
//                               icon={faSearch}
//                               style={{ marginRight: "5px" }}
//                             />
//                             Search
//                           </Button>
//                         </Col>
//                         <Col md={2}>
//                           <Form.Group>
//                             <Form.Label className="forlabel">
//                               Select Air Lines
//                             </Form.Label>
//                             <Select
//                               name="airlines"
//                               options={[
//                                 // Placeholder/default value
//                                 ...airlines.map((airline) => ({
//                                   label: getAirlineNameByAirCode[airline],
//                                   value: airline,
//                                 })),
//                               ]}
//                               value={{
//                                 label: getAirlineNameByAirCode[selectedAirline],
//                                 value: selectedAirline,
//                               }}
//                               onChange={(option) =>
//                                 setSelectedAirline(option.value)
//                               }
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col md={2}>
//                           <Button
//                             type="button"
//                             style={{
//                               marginTop: 32,
//                               marginLeft: 5,
//                             }}
//                             className="allbutton"
//                             variant="outline-primary"
//                             onClick={handleShow}
//                           >
//                             <FontAwesomeIcon
//                               icon={faArrowsToEye}
//                               style={{ marginRight: "5px" }}
//                             />
//                             Show
//                           </Button>
//                         </Col>
//                       </Row>
//                     </Form>
//                   </Container>
//                 </Card>
//               </div>

//               {importData.length > 0 && (
//                 <div>
//                   <hr />
//                   <h5
//                     className="pageHead"
//                     style={{
//                       fontFamily: "Your-Heading-Font",
//                       paddingLeft: "4%",
//                       marginTop: "30px",
//                     }}
//                   >
//                     {" "}
//                     <FontAwesomeIcon
//                       icon={faFileAlt}
//                       style={{
//                         marginRight: "8px",
//                         color: "black", // Set the color to golden
//                       }}
//                     />{" "}
//                     Imported Data
//                   </h5>

//                   <div className="table-responsive">
//                     <Table className="table table-striped table-hover">
//                       <thead>
//                         <tr>
//                           {" "}
//                           <th
//                             style={{ backgroundColor: "#BADDDA" }}
//                             scope="col"
//                           >
//                             {/* <input type="checkbox" onChange={handleSelectAll} /> */}

//                             <input
//                               type="checkbox"
//                               checked={selectAll}
//                               onChange={() => handleSelectAllToggle()}
//                             />
//                           </th>
//                           <th style={{ backgroundColor: "#BADDDA" }}>Sr.No</th>
//                           <th style={{ backgroundColor: "#BADDDA" }}>
//                             Airline Name
//                           </th>
//                           <th style={{ backgroundColor: "#BADDDA" }}>MAWB</th>
//                           <th style={{ backgroundColor: "#BADDDA" }}>Sir No</th>
//                           <th style={{ backgroundColor: "#BADDDA" }}>
//                             Sir Date
//                           </th>
//                           <th style={{ backgroundColor: "#BADDDA" }}>
//                             Parcle Type
//                           </th>
//                           <th style={{ backgroundColor: "#BADDDA" }}>HAWB</th>
//                           <th style={{ backgroundColor: "#BADDDA" }}>NOP</th>
//                           {/* Add more column headers as needed */}
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {importData.map((item, index) => (
//                           <tr key={index}>
//                             <td>
//                               {/* <input
//                                 type="checkbox"
//                                 checked={selectedRows.includes(item)}
//                                 onChange={(e) => handleRowSelection(e, index)}
//                               /> */}

//                               <input
//                                 type="checkbox"
//                                 name="checkimport"
//                                 checked={selectedItems.some((item) => item.sirNo === importData[index].sirNo)}
//                                 onChange={() => handleRowCheckboxChange(index)}
//                               />
//                             </td>
//                             <td>{index + 1}</td>
//                             <td>{item.airlineName}</td>
//                             <td>{item.mawb}</td>
//                             <td>{item.sirNo}</td>
//                             <td>{formatDate(item.sirDate)}</td>
//                             <td>{item.parcelType}</td>
//                             <td>{item.hawb.startsWith('000') ? '' : item.hawb}</td>
//                             <td>{item.nop}</td>
//                             {/* Add more table cells as needed */}
//                           </tr>
//                         ))}
//                       </tbody>
//                     </Table>
//                   </div>

//                   <CardBody>
//                     <p>Total Packages Received - {totaleNoOfPackages}</p>
//                     <Button
//                       variant="outline-primary"
//                       style={{ float: "right" }}
//                       onClick={generateAllPDFReport}
//                     >
//                       {" "}
//                       <FontAwesomeIcon
//                         icon={faBolt}
//                         style={{ marginRight: "5px" }}
//                       />
//                       Generate PCTM
//                     </Button>
//                   </CardBody>
//                 </div>
//               )}
//               <hr className="mt-3" />
//               <div
//                 className="tab-pane fade"
//                 id="contact"
//                 role="tabpanel"
//                 aria-labelledby="contact-tab"
//               >
//                 <h5
//                   className="pageHead"
//                   style={{
//                     fontFamily: "Your-Heading-Font",
//                     paddingLeft: "3%",
//                     paddingRight: "-50px",
//                   }}
//                 >
//                   {" "}
//                   <FontAwesomeIcon
//                     icon={faPrint}
//                     style={{
//                       marginRight: "8px",
//                       color: "black", // Set the color to golden
//                     }}
//                   />{" "}
//                   Print
//                 </h5>

//                 <Card
//                   style={{
//                     marginTop: 25,
//                     marginRight: 18,
//                     marginLeft: 18,
//                     padding: 8,
//                   }}
//                 >
//                   <Container></Container>
//                   <Container>
//                     <Form>
//                       <Row>
//                         <Col md={2}>
//                           <Form.Group>
//                             <Form.Label className="forlabel">
//                               SIR Date From
//                             </Form.Label>
//                             <DatePicker
//                               selected={startDate}
//                               wrapperClassName="custom-react-datepicker-wrapper"
//                               onChange={(date) => {
//                                 if (date) {
//                                   setStartDate(date);
//                                 }
//                               }}
//                               value={startDate}
//                               dateFormat="dd/MM/yyyy"
//                               className="form-control"
//                               customInput={<input style={{ width: "100%" }} />}
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col md={2}>
//                           <Form.Group>
//                             <Form.Label className="forlabel">
//                               SIR Date To
//                             </Form.Label>
//                             <DatePicker
//                               selected={endDate}
//                               wrapperClassName="custom-react-datepicker-wrapper"
//                               onChange={(date) => {
//                                 if (date) {
//                                   setEndDate(date);
//                                 }
//                               }}
//                               value={endDate}
//                               dateFormat="dd/MM/yyyy"
//                               className="form-control"
//                               customInput={<input style={{ width: "100%" }} />}
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col md={2}>
//                           <Button
//                             type="button"
//                             style={{
//                               marginTop: 32,
//                             }}
//                             className="allbutton"
//                             variant="outline-primary"
//                             onClick={handleAllSearch}
//                           >
//                             <FontAwesomeIcon
//                               icon={faSearch}
//                               style={{ marginRight: "5px" }}
//                             />
//                             Search
//                           </Button>
//                         </Col>

//                         <Col sm={2}>
//                           <Form.Group>
//                             <Form.Label className="forlabel">
//                               Select Air Lines
//                             </Form.Label>
//                             {/* Use the Select component for the dropdown */}

//                             <Select
//                               name="airlines"
//                               options={[
//                                 // Placeholder/default value
//                                 ...airlineNames.map((airline) => ({
//                                   label: getAirlineNameByAirCode[airline],
//                                   value: airline,
//                                 })),
//                               ]}
//                               value={{
//                                 label:
//                                   getAirlineNameByAirCode[selectedAllAirline],
//                                 value: selectedAllAirline,
//                               }}
//                               onChange={(option) =>
//                                 setSelectedAllAirline(option.value)
//                               }
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col sm={2}>
//                           <Form.Group>
//                             <Form.Label className="forlabel">
//                               Select PCTM No
//                             </Form.Label>
//                             <Select
//                               name="pctmNumbers"
//                               options={pctmNumbers.map((pctm) => ({
//                                 label: formatPctmNo(pctm),
//                                 value: pctm,
//                               }))}
//                               value={{
//                                 label: formatPctmNo(selectedAirlinePCTM),
//                                 value: selectedAirlinePCTM,
//                               }}
//                               onChange={(option) =>
//                                 setSelectedAirlinePCTM(option.value)
//                               }
//                             />
//                           </Form.Group>
//                         </Col>
//                         <Col sm={2}>
//                           <Form.Label className="forlabel">
//                             Search PCTM
//                           </Form.Label>
//                           <Button
//                             type="button"
//                             className="allbutton"
//                             variant="outline-primary"
//                             onClick={handleShowPctmData}
//                           >
//                             <FontAwesomeIcon
//                               icon={faSearch}
//                               style={{ marginRight: "5px" }}
//                             />
//                             PCTM
//                           </Button>
//                         </Col>
//                       </Row>
//                     </Form>
//                   </Container>
//                 </Card>

//                 <Card style={{ marginTop: 18, marginBottom: 18 }}>
//                   <Container>
//                     {importPCTMData.length > 0 && (
//                       <div>
//                         {importPCTMData.length > 0 && (
//                           <div>
//                             {/* ... Existing JSX ... */}
//                             <div>
//                               <Button
//                                 type="button"
//                                 color="danger"
//                                 outline
//                                 style={{
//                                   float: "right",
//                                   marginLeft: '5x',
//                                   textDecoration: "none",
//                                 }}
//                                 onClick={handlePrintAllImportPctmData}
//                               >
//                                 <FontAwesomeIcon
//                                   icon={faPrint}
//                                   style={{ marginRight: "5px" }}
//                                 />
//                                 Print
//                               </Button>
//                               <Button
//                                 type="button"
//                                 className="allbutton"
//                                 variant="outline-success"
//                                 onClick={()=>getExcel(importPCTMData)}
//                                 // style={{ float: "right" }}
//                                 style={{ marginRight: "5px" , float: "right"}}
//                               >
//                                 <FontAwesomeIcon
//                                   icon={faFileExcel}
//                                   style={{ marginRight: "5px" }}
//                                 />
//                                 XLS
//                               </Button>
//                               {/* <BlobProvider
//                                 document={
//                                   <MyDocument
//                                     importPCTMData={importPCTMData}
//                                     totalDistinctIGM={totalDistinctIGM}
//                                     totalDistinctMAWBs={totalDistinctMAWBs}
//                                     totalPackages={totalPackages}
//                                   />
//                                 }
//                               >
//                                 {({ blob, url, loading, error }) => (
//                                   <a
//                                     href={url}
//                                     style={{ textDecoration: "none" }}
//                                     target="_blank"
//                                     rel="noopener noreferrer"
//                                   >
//                                     <Button
//                                       type="button"
//                                       color="danger"
//                                       outline
//                                       style={{
//                                         float: "right",
//                                         marginLeft: 4,
//                                         textDecoration: "none",
//                                       }}
//                                     >
//                                       <FontAwesomeIcon
//                                         icon={faPrint}
//                                         style={{ marginRight: "5px" }}
//                                       />
//                                       PRINT
//                                     </Button>
//                                   </a>
//                                 )}
//                               </BlobProvider> */}
//                               <Button
//                                 outline
//                                 id="pdfButton"
//                                 color="danger"
//                                 style={{  marginRight: "5px", float: "right" }}
//                                 onClick={handlePdfImportPCTMData}
//                               >
//                                 <FontAwesomeIcon
//                                   icon={faFilePdf}
//                                   style={{ marginLeft: "5px" }}
//                                 />
//                                 PDF
//                               </Button>
//                             </div>
//                           </div>
//                         )}

//                         <h5
//                           style={{
//                             marginTop: 9,
//                             marginRight: 9,
//                             marginBottom: 27,
//                           }}
//                         >
//                           Imported PCTM Data
//                         </h5>

//                         <div className="table-responsive">
//                           <Table className="table table-striped table-hover">
//                             <thead>
//                               <tr>
//                                 <th style={{ backgroundColor: "#BADDDA" }}>
//                                   Sr.No
//                                 </th>
//                                 <th style={{ backgroundColor: "#BADDDA" }}>
//                                   M.A.W.B. No
//                                 </th>
//                                 <th style={{ backgroundColor: "#BADDDA" }}>
//                                   Sir Date
//                                 </th>
//                                 <th style={{ backgroundColor: "#BADDDA" }}>
//                                   Sir No
//                                 </th>
//                                 <th style={{ backgroundColor: "#BADDDA" }}>
//                                   Parcle Type
//                                 </th>

//                                 <th style={{ backgroundColor: "#BADDDA" }}>
//                                   H.A.W.B. No
//                                 </th>

//                                 <th style={{ backgroundColor: "#BADDDA" }}>
//                                   No Of Pkgs
//                                 </th>
//                                 <th style={{ backgroundColor: "#BADDDA" }}>
//                                   Importer Name
//                                 </th>
//                               </tr>
//                             </thead>
//                             <tbody>
//                               {importPCTMData.map((item, index) => (
//                                 <tr key={index}>
//                                   <td>{index + 1}</td>
//                                   <td>{item.mawb}</td>
//                                   <td>{formatDate(item.sirDate)}</td>
//                                   <td>{item.sirNo}</td>
//                                   <td>{item.parcelType}</td>
//                                   <td>{item.hawb.startsWith('000') ? '' : item.hawb}</td>
//                                   <td>{item.nop}</td>
//                                   <td>{getpartyId[item.importerId]}</td>
//                                   {/* Add more table cells as needed */}
//                                 </tr>
//                               ))}
//                             </tbody>
//                           </Table>
//                         </div>
//                         <p>Total Packages: {totalPackages}</p>

//                         {showImportPDFReport && (
//                           <PDFReport
//                             data={importPCTMData}
//                             startDate={startDate}
//                             endDate={endDate}
//                             selectedAirline={selectedAllAirline}
//                           //selectedAirlineFlightdate={selectedAirlineFlightdate}
//                           />
//                         )}
//                       </div>
//                     )}
//                   </Container>
//                 </Card>
//               </div>
//             </div>
//           </div>
//         </Container>
//       </Card>
//     </div>
//   );
// }



import { Link, redirect } from "react-router-dom";
import AuthContext from "../Components/AuthProvider";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import "../Components/Style.css";
import DatePicker from "react-datepicker";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { format } from "date-fns";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import axios from "axios";
import Select from "react-select"; // Import Select component for dropdown
import { ButtonToggle, CardBody, Container, Table } from "reactstrap";
import PDFReport from "./PDFReport";
import { saveAs } from "file-saver"; // Import file-saver for triggering the download
import * as XLSX from "xlsx"; // Import XLSX library for creating XLS files
import "@react-pdf-viewer/core/lib/styles/index.css";
import ipaddress from "../Components/IpAddress";
import { toast } from "react-toastify";
import PartyListTable from "../Parent_Pages/PartyListTable";
import dgdcimage from "../Images/DGDC.png";
import moment from 'moment';

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  BlobProvider,
  pdf,
} from "@react-pdf/renderer";
import {
  faArrowsToEye,
  faBolt,
  faFileAlt,
  faFileExcel,
  faFilePdf,
  faPlane,
  faPrint,
  faSave,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Rate_Chart_Service from "../services/Rate_Chart_Service";
import ReactLoading from 'react-loading';


export default function Import_PCTM() {


  const styles2 = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999, // Ensure the overlay is above other elements
    },
  };


  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const [pctmNumbers, setPctmNumbers] = useState([]);

  const currentDate = new Date();
  const currentDateString = currentDate.toISOString().split("T")[0];

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const reactPageName = "Import PCTM Data Party";
  const [airlines, setAirlines] = useState([]);
  const [selectedAirline, setSelectedAirline] = useState("");
  const [airlineNames, setAirlineNames] = useState([]);
  const [selectedAllAirline, setSelectedAllAirline] = useState("");
  const [selectedAirlinePCTM, setSelectedAirlinePCTM] = useState("");
  const [importPCTMData, setImportedPCTMData] = useState([]);
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [getOneParty, setOneParty] = useState([]);
  const [loading, setLoading] = useState(false);




  const [importData, setImportData] = useState([]);
  const [importAllData, setImportAllData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showImportDataTable, setShowImportDataTable] = useState(false);
  const [pdfReportData, setPDFReportData] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);

  const [showPDFReport, setShowPDFReport] = useState(false);

  const [showImportPDFReport, setShowImportPDFReport] = useState(false); // State for PDF report visibility

  const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
  const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
  // const formattedSerDate = sirDate.toISOString().split('T')[0];

  const makeFieldEmpty = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setAirlines([]);
    setAirlineNames([]);
    setImportedPCTMData([]);
    setImportData([]);
    setImportAllData([]);
    setPDFReportData([]);
    setSelectedAllAirline("");
    setSelectedAirlinePCTM("");
    setSelectedAirline("");
    // setExportTpData([]);
    // setExportData([]);
    // setSummaryDataForTPData([]);
  };

  const {
    jwtToken,
    user_Id,
    username,
    branchId,
    companyid,
    role,
    companyname,
    branchname,
    login,
    logout,
  } = useContext(AuthContext);

  const importFields = [
    "MAWB",
    "HAWB",
    "SIR_No",
    "Imp_Trans_Date",
    "IGM_No",
    "IGM_Date",
    "PCTM_No",
    "TP_No",
    "TP_Date",
    "Airline_Name",
    "Flight_No",
    "Flight_Date",
    "Country_Origin",
    "Port_Origin",
    "Importer_Id",
    "IEC",
    "SEZ_Entity_Id",
    "Console",
    "Package_Content_Type",
    "Parcel_Type",
    "UOM_Packages",
    "Nop",
    "Import_Remarks",
    "Description_Of_Goods",
    "CHA_CDE",
    "Assessable_Value",
    "Gross_Weight",
    "BE_Request_ID",
    "BE_No",
    "BE_Date",
    "Import_Address",
    "Status",
    "Sir_Date",
    "Created_By",
    "Created_Date",
    "Edited_By",
    "Edited_Date",
    "Approved_By",
    "Approved_Date",
  ];

  // const formatPctmNo = (pctmNumbers) => {
  //   // Remove leading zeros using a regular expression
  //   return pctmNumbers.replace(/^0+/, "");
  // };



  const formatPctmNo = (pctmNumbers) => {
    if (pctmNumbers === null || pctmNumbers === undefined) {
      return pctmNumbers; // Return null or undefined as it is
    }
    // Remove leading zeros using a regular expression
    return pctmNumbers.replace(/^0+/, "");
  };

  const CustomHeader = () => {
    return (
      <View style={styles.header}>
        <Image src={dgdcimage} style={styles.image} />
      </View>
    );
  };

  const formatedDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  const commonTextStyle = {
    fontSize: 9,
  };

  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      height: "100%",
      paddingTop: 9,
      paddingLeft: 27,
      paddingRight: 27,
      paddingTop: 27,
    },
    header: {
      marginBottom: 1,
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: 5,
    },
    tableHeaderRow: {
      flexDirection: "row",
    },
    tableHeaderCell: {
      border: "0.5px solid #000",
      padding: 1,
      backgroundColor: "#f2f2f2",
      textAlign: "center",
      fontSize: 9,
    },
    tableRow: {
      flexDirection: "row",
      fontWeight: "bold",
    },
    tableCell: {
      border: "0.4px solid #000",
      padding: 1,
      textAlign: "center",
      fontSize: 7,
    },
    tableCellHeader: {
      fontWeight: "bold",
      textAlign: "center",
      fontSize: 9,
    },
    image: {
      width: 306,
      height: 72,
      // marginLeft: 18,
      justifyContent: "center",
    },

    text: {
      marginLeft: 18,
      ...commonTextStyle,
    },
    hr: {
      width: "100%",
      borderBottom: 1,
      marginTop: 5,
    },
    leftColumn: {
      width: "100%",
      paddingTop: 5,
    },
    underline: {
      textDecoration: "underline",
    },
  });

  const hrStyle = {
    borderTop: "5px solid black", // Adjust the thickness and color as needed
  };

  // Create a form state object with initial values for each field
  const initialFormState = {};
  importFields.forEach((field) => {
    initialFormState[field] = "";
  });

  const [formData, setFormData] = useState(initialFormState);
  // Function to handle the "SEARCH" button click

  const handleSearch = () => {
    // Make an API request here to fetch the list of airline names based on the provided criteria
    setLoading(true);

    try {

      fetch(
        `https://${ipaddress}import/airline-names?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
      )
        .then((response) => response.json())
        .then((data) => {
          // Ensure that 'data' is an array before updating the 'airlines' state

          console.log("Loading  In Try *** : " + loading);
          if (Array.isArray(data)) {
            // Update the 'airlines' state with the fetched data
            setSelectedAirline("Select ");

            setAirlines(data);
          } else {
            console.error("API response is not an array:", data);
          }
        })
    }
    catch (error) {
      toast.error('Something went wrong', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 600,
      });
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch airline names and preselect the first one when the component mounts
    handleSearch();
  }, []);

  const handleShow = () => {
    // You can use this function to display the fetched data in a table or perform other actions
    axios
      .get(
        `https://${ipaddress}import/allimportData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAirline}`
      )
      .then((response) => {
        const importData = response.data; // Data for the selected airline
        //  setSelectedAirlineFilghtDate(selectedAirlineFlightdate);
        setImportData(importData);
        // console.log("Results");
        // console.log(importData);
        // Update the state with the fetched data
        setShowTable(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const [partys, setPartys] = useState([]);

  const [getpartyId, setGetpartyId] = useState({});
  const fetchPartyNames = async () => {
    try {
      const response = await fetch(
        `https://${ipaddress}parties/getAll/${companyid}/${branchId}`
      );
      const data = await response.json();
      const namesMap = {};
      data.forEach((party) => {
        namesMap[party.partyId] = party.partyName;
      });
      setGetpartyId(namesMap);
      setPartys(data);
    } catch (error) {
      console.error("Error fetching party names:", error);
    }
  };

  useEffect(() => {
    fetchPartyNames();
  }, []);

  const [airlineNanmes, setAirlineNanmes] = useState([]);
  const [getAirlineNameByAirCode, setAirlineNameByAirCode] = useState({});
  const [namesMap, setNamesMap] = useState({});
  const fetchAirlinesNames = async () => {
    try {
      const response = await fetch(
        `https://${ipaddress}Airline/list/${companyid}/${branchId}`
      );
      const data = await response.json();
      const namesAirMap = {};
      data.forEach((air) => {
        namesAirMap[air.airlineCode] = air.airlineName;
      });
      setNamesMap(namesAirMap);
      setAirlineNameByAirCode(namesAirMap);
      console.log("Airlines  Print", namesAirMap);
      setAirlineNanmes(data);
    } catch (error) {
      console.error("Error fetching party names:", error);
    }
  };

  useEffect(() => {
    fetchAirlinesNames();
  }, []);
  const getAIRNames = airlineNanmes.map((airline) => ({
    label: airline.airlineName, // Use the name from the namesMap
    value: airline.airlineCode,
  }));

  function fetchCompanyName(companyId) {
    // Make an Axios GET request to retrieve the company name based on companyId
    return axios
      .get(`https://${ipaddress}import/findCompanyname/${companyId}`)
      .then(function (response) {
        return response.data; // Return the retrieved company name
      })
      .catch(function (error) {
        console.error("Error fetching company name:", error);
        return ""; // Return an empty string or handle the error as needed
      });
  }
  function fetchExporterName(companyId, branchId, partyId) {
    // Make an Axios GET request to retrieve the company name based on companyId
    return axios
      .get(
        `https://${ipaddress}import/findPartyName/${companyId}/${branchId}/${partyId}`
      )
      .then(function (response) {
        return response.data; // Return the retrieved company name
      })
      .catch(function (error) {
        console.error("Error fetching company name:", error);
        return ""; // Return an empty string or handle the error as needed
      });
  }

  function fetchBranchName(companyId, branchId) {
    // Make an Axios GET request to retrieve the branch name based on branchId
    return axios
      .get(
        `https://${ipaddress}import/findBranchName/${companyId}/${branchId}`
      )
      .then(function (response) {
        return response.data; // Return the retrieved branch name
      })
      .catch(function (error) {
        console.error("Error fetching branch name:", error);
        return ""; // Return an empty string or handle the error as needed
      });
  }

  const calculateDistinctMAWB = (importPCTMData) => {
    const distinctMAWBCounts = {};

    importPCTMData.forEach((item) => {
      const mawb = item.mawb;
      if (mawb) {
        if (distinctMAWBCounts[mawb]) {
          distinctMAWBCounts[mawb] += 1;
        } else {
          distinctMAWBCounts[mawb] = 1;
        }
      }
    });

    return distinctMAWBCounts;
  };
  const distinctMAWBCounts = calculateDistinctMAWB(importPCTMData);
  const totalDistinctMAWBs = Object.keys(distinctMAWBCounts).length;

  const calculateDistinctIGM = (importPCTMData) => {
    const distinctIGMCounts = {};

    importPCTMData.forEach((item) => {
      const igmNo = item.igmNo;
      if (igmNo) {
        if (distinctIGMCounts[igmNo]) {
          distinctIGMCounts[igmNo] += 1;
        } else {
          distinctIGMCounts[igmNo] = 1;
        }
      }
    });

    return distinctIGMCounts;
  };
  const [consoles, setConsoles] = useState([]);
  const [getConsoleId, setGetConsoleId] = useState({});

  const fetchConsoleNames = async () => {
    try {
      const response = await fetch(
        `https://${ipaddress}externalParty/console/${companyid}/${branchId}`
      );
      const data = await response.json();
      const consoleMap = {};
      data.forEach((console) => {
        consoleMap[console.externaluserId] = console.userName;
      });
      setGetConsoleId(consoleMap);
      setConsoles(data);
    } catch (error) {
      console.error("Error fetching party names:", error);
    }
  };

  useEffect(() => {
    fetchConsoleNames();
  }, []);
  const distinctIGMCounts = calculateDistinctIGM(importPCTMData);

  const totalDistinctIGM = Object.keys(distinctIGMCounts).length;

  const renderTable = () => (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <Text
          style={[
            styles.tableCell,
            styles.tableCellHeader,
            { width: 54, fontWeight: "bold" },
          ]}
        >
          Sr.No
        </Text>
        <Text style={[styles.tableCell, styles.tableCellHeader, { width: 75 }]}>
          Sir No
        </Text>
        <Text
          style={[styles.tableCell, styles.tableCellHeader, { width: 227 }]}
        >
          Importer Name
        </Text>
        <Text style={[styles.tableCell, styles.tableCellHeader, { width: 81 }]}>
          Origin Airport
        </Text>
        <Text
          style={[styles.tableCell, styles.tableCellHeader, { width: 120 }]}
        >
          MAWB
        </Text>
        <Text
          style={[styles.tableCell, styles.tableCellHeader, { width: 120 }]}
        >
          HAWB
        </Text>
        <Text style={[styles.tableCell, styles.tableCellHeader, { width: 54 }]}>
          NOP
        </Text>
        <Text style={[styles.tableCell, styles.tableCellHeader, { width: 72 }]}>
          Weight
        </Text>
        <Text style={[styles.tableCell, styles.tableCellHeader, { width: 81 }]}>
          Desc
        </Text>
        <Text style={[styles.tableCell, styles.tableCellHeader, { width: 72 }]}>
          Console
        </Text>
        <Text
          style={[styles.tableCell, styles.tableCellHeader, { width: 100 }]}
        >
          Remark Delivered
        </Text>
      </View>
      {importPCTMData.map((item, index) => (
        <View style={styles.tableRow} key={index}>
          <Text style={{ ...styles.tableCell, width: 54 }}>{index + 1}</Text>
          <Text style={{ ...styles.tableCell, width: 75 }}>
            {item.sirNo || ""}
          </Text>
          <Text style={{ ...styles.tableCell, width: 227 }}>
            {getpartyId[item.importerId] || ""}
          </Text>
          <Text style={{ ...styles.tableCell, width: 81 }}>
            {item.portOrigin || ""}
          </Text>
          <Text style={{ ...styles.tableCell, width: 120 }}>
            {item.mawb || ""}
          </Text>
          <Text style={{ ...styles.tableCell, width: 120 }}>
            {item.hawb || ""}
          </Text>
          <Text style={{ ...styles.tableCell, width: 54 }}>
            {item.nop || ""}
          </Text>
          <Text style={{ ...styles.tableCell, width: 72 }}>
            {item.grossWeight || ""}
          </Text>
          <Text style={{ ...styles.tableCell, width: 81 }}>
            {item.descriptionOfGoods || ""}
          </Text>
          <Text style={{ ...styles.tableCell, width: 72 }}>
            {getConsoleId[item.consoleName] || ""}
          </Text>
          <Text style={{ ...styles.tableCell, width: 100 }}>
            {item.importRemarks || ""}
          </Text>
        </View>
      ))}
    </View>
  );
  const handlePdfImportPCTMData = async () => {
    try {
      const response = await axios.post(
        `https://${ipaddress}import/printOfImportPctm?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}&pctmNo=${selectedAirlinePCTM}`
      );
      // toast.success("GST Summary PDF Created Successfully ", { position: "top-center" ,autoClose: 900});

      if (response.status === 200) {
        const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF

        // Create a Blob from the Base64 data
        const pdfBlob = new Blob(
          [Uint8Array.from(atob(pdfBase64), (c) => c.charCodeAt(0))],
          { type: "application/pdf" }
        );

        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(pdfBlob);

        // Create an anchor element for downloading
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = "import_pctm_report.pdf"; // Set the filename for the downloaded PDF
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);

        // Trigger the download
        downloadLink.click();

        // Clean up
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(blobUrl);

        toast.success("Downloaded Import Pctm PDF Successfully !", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 900,
        });
      } else {
        throw new Error("Failed to generate PDF");
      }
    } catch (error) { }
  };
  const handlePrintAllImportPctmData = async () => {
    try {
      const response = await axios.post(
        `https://${ipaddress}import/printOfImportPctm?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}&pctmNo=${selectedAirlinePCTM}`
      );
      if (response.status === 200) {
        const base64PDF = response.data;

        // Create a new window for displaying the PDF
        const newWindow = window.open("", "_blank");

        // Write the HTML content to the new window
        newWindow.document.write(`
        <!DOCTYPE html>
        <html>
        <head>
          <title>import_pctm_report Viewer</title>
          <style>
            body {
              margin: 0;
              padding: 0;
              overflow: hidden;
            }
            embed {
              width: 100vw;
              height: 100vh;
            }
          </style>
        </head>
        <body>
          <embed src="data:application/pdf;base64,${base64PDF}" type="application/pdf" width="100%" height="100%">
        </body>
        </html>
      `);
      } else {
        throw new Error("Failed to generate PDF");
      }
    } catch (error) {
      // Handle errors if any
    }
  };


  const generateAllPDFReport = async () => {
    if (selectedItems.length === 0) {
      // Show an error message if no rows are selected
      toast.error("No rows selected. Please select rows to generate PCTM.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return; // Don't proceed with the API call
    }
    else {
      setLoading(true);
    }
    try {

      const response = await Rate_Chart_Service.updatePctmNumber(selectedItems);
      // const reportData = response.data; // Data for the selected airline
      setShowPDFReport(true);
      // console.log("RemovedRecordArray", RemovedRecordArray);
      // Pass the data to the PDFReport component
      setPDFReportData(pdfReportData); // Update with the correct data
      setImportAllData(importAllData); // Update with the correct data
      setTotalEntries(importData.length);
      handleShow();

      // Display a toast notification when pctmNo is updated successfully
      toast.success("pctmNo generated successfully", {
        position: "top-center",
        autoClose: 3000, // Close the notification after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Refresh the page
      // window.location.reload();
    } catch (error) {
      console.error("Error fetching data:", error);

      // Display an error toast notification if there's an error
      toast.error("Error fetching data", {
        position: "bottom-right",
        autoClose: 5000, // Close the notification after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (!isAuthenticated) {
      navigate(
        "/login?message=You need to be authenticated to access this page."
      );
    }
  }, [isAuthenticated, navigate]);

  const [importAllDatalist, setImportAllDatalist] = useState([]);


  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  const handleAllSearch = async () => {
    // Make an API request here to fetch the list of airline names based on the provided criteria
    setSelectedAirlinePCTM('');
    setPctmNumbers([]);
    fetch(
      `https://${ipaddress}import/Allairline-names?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          // Update the 'airlines' state with the fetched data
          setSelectedAllAirline("Select");
          setAirlineNames(data);
        } else {
          console.error("API response is not an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching airline names:", error);
      });
  };

  const handleShowPctmData = () => {
    if (selectedAllAirline && selectedAirlinePCTM) {
      axios
        .get(
          `https://${ipaddress}import/allimportPCTMData?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${selectedAllAirline}&pctmNo=${selectedAirlinePCTM}`
        )
        .then((response) => {
          const importPCTMData = response.data; // Data for the selected PCTM number
          setImportedPCTMData(importPCTMData); // Update the state with the fetched data
          setShowImportDataTable(true);
          setTotalEntries(importPCTMData.length);
        })
        .catch((error) => {
          console.error("Error fetching PCTM data:", error);
        });
    }
  };



  const totalPackages = importPCTMData.reduce(
    (total, item) => total + item.nop,
    0
  );

  const handleXLSDownload = async () => {
    const modifiedRecordList = await Promise.all(
      importPCTMData.map(async (item, index) => {
        // const companyname = await fetchCompanyName(item.companyId);
        // const branchname = await fetchBranchName(item.companyId, item.branchId);
        const importerId = await fetchExporterName(
          item.companyId,
          item.branchId,
          item.importerId
        );
        return {
          "Sr.No": index + 1,
          // "Company Name": companyname,
          // "Branch Name": branchname,
          "SIR Date": formatedDate(item.sirDate) || "",
          "SIR NO": item.sirNo || "",
          "M.A.W.B": item.mawb || "",
          "H.A.W.B": item.hawb.startsWith('000') ? '' : item.hawb || "", // Modify this to the actual field name
          "NO OF PKGS": item.nop || "",
          "Importer Names": getpartyId[item.importerId] || "",
        };
      })
    );

    // Calculate the total "SIR No" and "No of Pkgs"
    const totalSIRNo = modifiedRecordList.reduce(
      (total, item) => total + (item["SIR NO"] ? 1 : 0),
      0
    );

    const totalNoOfPkgs = modifiedRecordList.reduce(
      (total, item) => total + (item["NO OF PKGS"] || 0),
      0
    );
    const distanceRow = {
      "Sr.No": "",
      // "Company Name": "",
      // "Branch Name": "",
      "SIR Date": "",
      "SIR NO": "",
      "M.A.W.B": "",
      "H.A.W.B": "", // Modify this to the actual field name
      "NO OF PKGS": "",
      "Importer Names": "",
    };
    // Add a total row
    const totalRow = {
      "Sr.No": "Total ",
      // "Company Name": "",
      // "Branch Name": "",
      "SIR Date": "",
      "SIR No": totalSIRNo,
      "M.A.W.B": "",
      "H.A.W.B": "", //
      "NO OF PKGS": totalNoOfPkgs,
      "Importer Names": "",
    };

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([
      distanceRow,
      ...modifiedRecordList,
      distanceRow, // Insert the distance row
      totalRow, // Insert the total row
    ]);

    // Add headers for all fields
    const headers = Object.keys(modifiedRecordList[0]);
    headers.forEach((header, index) => {
      worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
        t: "s",
        v: header,
        s: { font: { bold: true } },
      };
    });

    // Set column widths based on data
    const colWidths = headers.map((header) => ({
      wch: header.length + 2, // You can adjust the width as needed
    }));

    worksheet["!cols"] = colWidths;

    XLSX.utils.book_append_sheet(workbook, worksheet, "Import_Register");
    const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
    const blob = new Blob([s2ab(xlsFile)], {
      type: "application/vnd.ms-excel",
    });
    saveAs(blob, "import_pctm.xls");
  };

  // Helper function to convert s2ab
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const [importerNames, setImporterNames] = useState({});

  const fetchImporterName = (importerId, partyId) => {
    // Ensure that partyId is equal to importerId
    if (partyId !== importerId) {
      console.error(
        `partyId (${partyId}) is not equal to importerId (${importerId}).`
      );
      return;
    }

    // Make an API request to fetch the importerName based on importerId
  };

  const totaleNoOfPackages = importData.reduce(
    (total, item) => total + item.nop,
    0
  );

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [RemovedRecordArray, setRemovedRecordArray] = useState([]);

  const handleRowSelection = (item) => {

    const removedNumber = item.sirNo;
    setRemovedRecordArray(prevArray => {
      // Check if removedNumber is already in the array
      const index = prevArray.indexOf(removedNumber);
      console.log("gdgd", item);
      if (index !== -1) {
        // If it exists, remove it
        return prevArray.slice(0, index).concat(prevArray.slice(index + 1));
      } else {
        // If it doesn't exist, add it
        return [...prevArray, removedNumber];
      }
    });


    const updatedSelectedRows = selectedRows.includes(item)
      ? selectedRows.filter((row) => row !== item)
      : [...selectedRows, item];
    setSelectedRows(updatedSelectedRows);

    setSelectAllChecked(updatedSelectedRows.length === importData.length);
  };




  // const handleSelectAll = () => {
  //   const sirNoList = importData.map(item => item.sirNo);
  //   setRemovedRecordArray(sirNoList);
  //   // console.log(sirNoList);
  //   if (selectAllChecked) {
  //     setSelectedRows([]);
  //     setSelectAllChecked(false);
  //   } else {
  //     setSelectedRows(importData);
  //     setSelectAllChecked(true);
  //   }
  // };

  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  useEffect(() => {
    setSelectAll(selectedItems.length === importData.length);
    console.log("select");
    console.log(selectedItems);

  }, [selectedItems, importData]);

  const handleSelectAllToggle = () => {
    console.log("select all");
    console.log(selectedItems);
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(importData);
    }
    setSelectAll(!selectAll);
  };

  const handleRowCheckboxChange = (index) => {
    const selectedItem = importData[index];

    console.log("importData");
    console.log(importData);

    if (selectedItem) {
      const selectedIndex = selectedItems.findIndex((item) => item.sirNo === selectedItem.sirNo);

      if (selectedIndex !== -1) {
        // Remove the item from the selected items
        const updatedSelectedItems = [...selectedItems];
        updatedSelectedItems.splice(selectedIndex, 1);
        setSelectedItems(updatedSelectedItems);
      } else {
        // Add the item to the selected items
        setSelectedItems([...selectedItems, selectedItem]);
      }
    }

    console.log("Selected Items ");
    console.log(selectedItems);
  };



  const [pctmNoMap, setPctmNoMap] = useState({});

  // Function to generate pctmNo for selected rows
  const generatePctmNoForSelectedRows = () => {
    const pctmNoMapCopy = {};
    selectedRows.forEach((row) => {
      // Generate pctmNo for the selected row here
      // You can customize the logic to generate pctmNo as needed
      // For example, you can generate a unique pctmNo based on some data from the selected row.
      const pctmNo = generatePctmNoBasedOnRowData(row);
      pctmNoMapCopy[row.sirNo] = pctmNo;
    });
    setPctmNoMap(pctmNoMapCopy);
  };

  // Example function to generate a pctmNo based on row data (customize this according to your needs)
  const generatePctmNoBasedOnRowData = (row) => {
    // Replace this with your logic to generate a unique pctmNo for the row
    return `${row.sirNo}_PCTM`;
  };



  //Import PCTM

  const formatDateTime2 = (value) => {
    if (!value) {
      return "";
    }
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}/${month}/${year} `;
  };
  const getExcel = (imp) => {
    const filename = `Import_PCTM_${formatDateTime2(new Date())}.xlsx`; // Note: Changed file extension to xlsx
    axios.post(`https://${ipaddress}import/pctmexcel`, imp, { responseType: 'blob' }) // Added responseType: 'blob'
      .then(async (response) => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          autoClose: 700
        });
      });
  };




  const handleAirlineChange = (e) => {
    setSelectedAirlinePCTM('');
    setPctmNumbers([]);
    setSelectedAllAirline(e.value);
    // console.log("selected Airline : "+ e.value);
    try {
      axios
        .get(
          `https://${ipaddress}import/getPctmNo?companyId=${companyid}&branchId=${branchId}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&flightNo=${e.value}`
        )
        .then((response) => {

          setPctmNumbers(response.data);
        })
        .catch((error) => {
          console.error("Error fetching PCTM numbers:", error);
        });
    } catch (error) {
      console.error("Error occurred during API request:", error);
    }
  };












  return (

    <>


      {loading && (
        <div style={styles2.overlay}>
          <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
        </div>
      )}






      <div className="Container">
        <h5
          className="pageHead"
          style={{
            fontFamily: "Your-Heading-Font",
            paddingLeft: "2%",
            paddingRight: "-0px",
          }}
        >
          {" "}
          <FontAwesomeIcon
            icon={faPlane}
            style={{
              marginRight: "8px",
              color: "black", // Set the color to golden
            }}
          />
          Import PCTM
        </h5>
        <Card>
          <Container>
            <div className="Container" style={{ marginTop: 20 }}>
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item tabspace" role="presentation">
                  <button
                    style={{ color: "gray" }}
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    onClick={(e) => makeFieldEmpty()}
                  >
                    <h6>Generate</h6>
                  </button>
                </li>

                <li className="nav-item tabspace" role="presentation">
                  <button
                    style={{ color: "gray" }}
                    className="nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                    onClick={(e) => makeFieldEmpty()}
                  >
                    <h6>Print</h6>
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
             
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                   <hr className="mt-3" />

                  <h5
                    className="pageHead"
                    style={{
                      fontFamily: "Your-Heading-Font",
                      paddingLeft: "3%",
                      marginTop:'10px'
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon
                      icon={faBolt}
                      style={{
                        marginRight: "8px",
                        color: "black", // Set the color to golden
                      }}
                    />{" "}
                    Generate{" "}
                  </h5>
                  <Container>
                    <Form style={{marginBottom:20}}>
                      <Row>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label className="forlabel">
                              SIR Date From
                            </Form.Label>
                            <DatePicker
                              selected={startDate}
                              wrapperClassName="custom-react-datepicker-wrapper"
                              onChange={(date) => {
                                if (date) {
                                  setStartDate(date);
                                }
                              }}
                              value={startDate}
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              customInput={<input style={{ width: "100%" }} />}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group>
                            <Form.Label className="forlabel">
                              SIR Date To
                            </Form.Label>
                            <DatePicker
                              selected={endDate}
                              wrapperClassName="custom-react-datepicker-wrapper"
                              onChange={(date) => {
                                if (date) {
                                  setEndDate(date);
                                }
                              }}
                              value={endDate}
                              dateFormat="dd/MM/yyyy"
                              className="form-control"
                              customInput={<input style={{ width: "100%" }} />}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={2}>
                          <Button
                            type="button"
                            style={{
                              marginTop: 32,
                            }}
                            className="allbutton"
                            variant="outline-primary"
                            onClick={handleSearch}
                          >
                            <FontAwesomeIcon
                              icon={faSearch}
                              style={{ marginRight: "5px" }}
                            />
                            Search
                          </Button>
                        </Col>
                        <Col md={2}>
                          <Form.Group>
                            <Form.Label className="forlabel">
                              Select Air Lines
                            </Form.Label>
                            <Select
                              name="airlines"
                              options={[
                                // Placeholder/default value
                                ...airlines.map((airline) => ({
                                  label: getAirlineNameByAirCode[airline],
                                  value: airline,
                                })),
                              ]}
                              value={{
                                label: getAirlineNameByAirCode[selectedAirline],
                                value: selectedAirline,
                              }}
                              onChange={(option) =>
                                setSelectedAirline(option.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col md={2}>
                          <Button
                            type="button"
                            style={{
                              marginTop: 32,
                              marginLeft: 5,
                            }}
                            className="allbutton"
                            variant="outline-primary"
                            onClick={handleShow}
                          >
                            <FontAwesomeIcon
                              icon={faArrowsToEye}
                              style={{ marginRight: "5px" }}
                            />
                            Show
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Container>
                  {/* </Card> */}
                </div>

                {importData.length > 0 && (
                  <div>
                    <hr />
                    <h5
                      className="pageHead"
                      style={{
                        fontFamily: "Your-Heading-Font",
                        paddingLeft: "4%",
                        marginTop: "30px",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon={faFileAlt}
                        style={{
                          marginRight: "8px",
                          color: "black", // Set the color to golden
                        }}
                      />{" "}
                      Imported Data
                    </h5>

                    <div className="table-responsive">
                      <Table className="table table-bordered custom-table">
                        <thead>
                          <tr className="text-center">
                            {" "}
                            <th
                              style={{ backgroundColor: "#BADDDA" }}
                              scope="col"
                            >

                              <input
                                className="form-check-input radios"
                                type="checkbox"
                                style={{ width: '1.2vw', height: '1.2vw' }}
                                checked={selectAll}
                                onChange={() => handleSelectAllToggle()}
                              />
                            </th>
                            <th style={{ backgroundColor: "#BADDDA" }}>Sr.No</th>
                            <th style={{ backgroundColor: "#BADDDA" }}>
                              Airline Name
                            </th>
                            <th style={{ backgroundColor: "#BADDDA" }}>MAWB</th>
                            <th style={{ backgroundColor: "#BADDDA" }}>Sir No</th>
                            <th style={{ backgroundColor: "#BADDDA" }}>
                              Sir Date
                            </th>
                            <th style={{ backgroundColor: "#BADDDA" }}>
                              Parcle Type
                            </th>
                            <th style={{ backgroundColor: "#BADDDA" }}>HAWB</th>
                            <th style={{ backgroundColor: "#BADDDA" }}>NOP</th>
                            {/* Add more column headers as needed */}
                          </tr>
                        </thead>
                        <tbody>
                          {importData.map((item, index) => (
                            <tr key={index} className="text-center">
                              <td>
                                {/* <input
                                type="checkbox"
                                checked={selectedRows.includes(item)}
                                onChange={(e) => handleRowSelection(e, index)}
                              /> */}

                                <input
                                  className="form-check-input radios"
                                  type="checkbox"
                                  style={{ width: '1.2vw', height: '1.2vw' }}
                                  name="checkimport"
                                  checked={selectedItems.some((item) => item.sirNo === importData[index].sirNo)}
                                  onChange={() => handleRowCheckboxChange(index)}
                                />
                              </td>
                              <td>{index + 1}</td>
                              <td>{item.airlineName}</td>
                              <td>{item.mawb}</td>
                              <td>{item.sirNo}</td>
                              <td>{formatDate(item.sirDate)}</td>
                              <td>{item.parcelType}</td>
                              <td>{item.hawb.startsWith('000') ? '' : item.hawb}</td>
                              <td>{item.nop}</td>
                              {/* Add more table cells as needed */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>

                    <CardBody>
                      <p>Total Packages Received - {totaleNoOfPackages}</p>
                      <Button
                        variant="outline-primary"
                        style={{ float: "right" }}
                        onClick={generateAllPDFReport}
                      >
                        {" "}
                        <FontAwesomeIcon
                          icon={faBolt}
                          style={{ marginRight: "5px" }}
                        />
                        Generate PCTM
                      </Button>
                    </CardBody>
                  </div>
                )}
                
                <div
                  className="tab-pane fade"
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <hr className="mt-3" />
                  <h5
                    className="pageHead"
                    style={{
                      fontFamily: "Your-Heading-Font",
                      paddingLeft: "3%",
                      paddingRight: "-50px",
                    }}
                  >
                    {" "}
                    <FontAwesomeIcon
                      icon={faPrint}
                      style={{
                        marginRight: "8px",
                        color: "black", // Set the color to golden
                      }}
                    />{" "}
                    Print
                  </h5>

                  {/* <Card
                    style={{
                      marginTop: 25,
                      marginRight: 18,
                      marginLeft: 18,
                      padding: 8,
                    }}
                  > */}
                   
                    <Container>
                      <Form style={{marginBottom:20}}>
                        <Row>
                          <Col md={2}>
                            <Form.Group>
                              <Form.Label className="forlabel">
                                SIR Date From
                              </Form.Label>
                              <DatePicker
                                selected={startDate}
                                wrapperClassName="custom-react-datepicker-wrapper"
                                onChange={(date) => {
                                  if (date) {
                                    setStartDate(date);
                                  }
                                }}
                                value={startDate}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                customInput={<input style={{ width: "100%" }} />}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={2}>
                            <Form.Group>
                              <Form.Label className="forlabel">
                                SIR Date To
                              </Form.Label>
                              <DatePicker
                                selected={endDate}
                                wrapperClassName="custom-react-datepicker-wrapper"
                                onChange={(date) => {
                                  if (date) {
                                    setEndDate(date);
                                  }
                                }}
                                value={endDate}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                customInput={<input style={{ width: "100%" }} />}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={2}>
                            <Button
                              type="button"
                              style={{
                                marginTop: 32,
                              }}
                              className="allbutton"
                              variant="outline-primary"
                              onClick={handleAllSearch}
                            >
                              <FontAwesomeIcon
                                icon={faSearch}
                                style={{ marginRight: "5px" }}
                              />
                              Search
                            </Button>
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label className="forlabel">
                                Select Air Lines
                              </Form.Label>
                              {/* Use the Select component for the dropdown */}

                              <Select
                                name="airlines"
                                options={[
                                  // Placeholder/default value
                                  ...airlineNames.map((airline) => ({
                                    label: getAirlineNameByAirCode[airline],
                                    value: airline,
                                  })),
                                ]}
                                value={{
                                  label:
                                    getAirlineNameByAirCode[selectedAllAirline],
                                  value: selectedAllAirline,
                                }}
                                onChange={(option) => handleAirlineChange(option)}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label className="forlabel">
                                Select PCTM No
                              </Form.Label>
                              <Select
                                name="pctmNumbers"
                                options={pctmNumbers.map((pctm) => ({
                                  label: formatPctmNo(pctm),
                                  value: pctm,
                                }))}
                                value={{
                                  label: formatPctmNo(selectedAirlinePCTM),
                                  value: selectedAirlinePCTM,
                                }}
                                onChange={(option) =>
                                  setSelectedAirlinePCTM(option.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Label className="forlabel">
                              Search PCTM
                            </Form.Label>
                            <Button
                              type="button"
                              className="allbutton"
                              variant="outline-primary"
                              onClick={handleShowPctmData}
                            >
                              <FontAwesomeIcon
                                icon={faSearch}
                                style={{ marginRight: "5px" }}
                              />
                              PCTM
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Container>
                  {/* </Card> */}

                  {/* <Card style={{ marginTop: 18, marginBottom: 18 }}> */}
                    <Container>
                      {importPCTMData.length > 0 && (
                        <div>
                          {importPCTMData.length > 0 && (
                            <div>
                              {/* ... Existing JSX ... */}
                              <div>
                                <Button
                                  type="button"
                                  color="danger"
                                  outline
                                  style={{
                                    float: "right",
                                    marginLeft: '5x',
                                    textDecoration: "none",
                                  }}
                                  onClick={handlePrintAllImportPctmData}
                                >
                                  <FontAwesomeIcon
                                    icon={faPrint}
                                    style={{ marginRight: "5px" }}
                                  />
                                  Print
                                </Button>
                                <Button
                                  type="button"
                                  className="allbutton"
                                  variant="outline-success"
                                  onClick={() => getExcel(importPCTMData)}
                                  // style={{ float: "right" }}
                                  style={{ marginRight: "5px", float: "right" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faFileExcel}
                                    style={{ marginRight: "5px" }}
                                  />
                                  XLS
                                </Button>

                                <Button
                                  outline
                                  id="pdfButton"
                                  color="danger"
                                  style={{ marginRight: "5px", float: "right" }}
                                  onClick={handlePdfImportPCTMData}
                                >
                                  <FontAwesomeIcon
                                    icon={faFilePdf}
                                    style={{ marginLeft: "5px" }}
                                  />
                                  PDF
                                </Button>
                              </div>
                            </div>
                          )}

                          <h5
                            style={{
                              marginTop: 9,
                              marginRight: 9,
                              marginBottom: 27,
                            }}
                          >
                            Imported PCTM Data
                          </h5>

                          <div className="table-responsive">
                            <Table className="table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th style={{ backgroundColor: "#BADDDA" }}>
                                    Sr.No
                                  </th>
                                  <th style={{ backgroundColor: "#BADDDA" }}>
                                    M.A.W.B. No
                                  </th>
                                  <th style={{ backgroundColor: "#BADDDA" }}>
                                    Sir Date
                                  </th>
                                  <th style={{ backgroundColor: "#BADDDA" }}>
                                    Sir No
                                  </th>
                                  <th style={{ backgroundColor: "#BADDDA" }}>
                                    Parcle Type
                                  </th>

                                  <th style={{ backgroundColor: "#BADDDA" }}>
                                    H.A.W.B. No
                                  </th>

                                  <th style={{ backgroundColor: "#BADDDA" }}>
                                    No Of Pkgs
                                  </th>
                                  <th style={{ backgroundColor: "#BADDDA" }}>
                                    Importer Name
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {importPCTMData.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.mawb}</td>
                                    <td>{formatDate(item.sirDate)}</td>
                                    <td>{item.sirNo}</td>
                                    <td>{item.parcelType}</td>
                                    <td>{item.hawb.startsWith('000') ? '' : item.hawb}</td>
                                    <td>{item.nop}</td>
                                    <td>{item.importernameOnParcel}</td>
                                    {/* Add more table cells as needed */}
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          <p>Total Packages: {totalPackages}</p>

                          {showImportPDFReport && (
                            <PDFReport
                              data={importPCTMData}
                              startDate={startDate}
                              endDate={endDate}
                              selectedAirline={selectedAllAirline}
                            //selectedAirlineFlightdate={selectedAirlineFlightdate}
                            />
                          )}
                        </div>
                      )}
                    </Container>
                  {/* </Card> */}
                </div>
              </div>
            </div>
          </Container>
        </Card>
      </div>

    </>
  );
}