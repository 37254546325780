// import AuthContext from "../Components/AuthProvider";
// import { useNavigate } from "react-router-dom";
// import React, { useEffect, useState, useContext } from "react";
// import "../Components/Style.css";
// import { Button } from "react-bootstrap";
// import ipaddress from "../Components/IpAddress";

// import Table from "react-bootstrap/Table";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//     faIdCard,
//     faPlaneDeparture,
//     faRefresh,
//     faSearch,
//     faUser,
//     faUserCheck,
//     faUserCircle,
// } from "@fortawesome/free-solid-svg-icons";
// import {
//     Card,
//     CardBody,
//     Container,
//     Row,
//     Col,
//     Form,
//     FormGroup,
//     Label,
//     Input,
// } from "reactstrap";
// import axios from "axios";

// export default function Representative_Details() {
//     const navigate = useNavigate();
//     const { isAuthenticated } = useContext(AuthContext);

//     useEffect(() => {
//         if (!isAuthenticated) {
//             navigate(
//                 "/login?message=You need to be authenticated to access this page."
//             );
//         }
//     }, [isAuthenticated, navigate]);

//     const {
//         jwtToken,
//         userId,
//         username,
//         branchId,
//         companyid,
//         role,
//         companyname,
//         branchname,
//         login,
//         logout,
//     } = useContext(AuthContext);

//     const [representatives, setRepresentatives] = useState([]);
//     // const [entityId, setEntityId] = useState('');
//     const [PartyList, setPartyList] = useState([]);
//     const [ExternalPartyList, setExternalPartyList] = useState([]);

//     const [searchQuery, setSearchQuery] = useState("");
//     const [filteredRepresentatives, setFilteredRepresentatives] = useState([]);

//     const getNameByPartyId = (partyId) => {
//         const foundParty = PartyList.find((party) => party.partyId === partyId);

//         if (foundParty) {
//             return foundParty.partyName;

//         } else {
//             const exfoundParty = ExternalPartyList.find((party) => party.externaluserId === partyId);
//             if (exfoundParty) {
//                 return exfoundParty.userName;

//             } else {
//                 return `Party name ${partyId} not found.`;
//             }
//         }
//     };

//     useEffect(() => {
//         if (!isAuthenticated) {
//             navigate(
//                 "/login?message=You need to be authenticated to access this page."
//             );
//         } else {
//             // Make an API request to your Spring Boot backend
//             axios
//                 .get(
//                     `https://${ipaddress}parties/getAll/${companyid}/${branchId}`,
//                     {
//                         headers: {
//                             Authorization: `Bearer ${jwtToken}`,
//                         },
//                     }
//                 )
//                 .then((response) => {
//                     setPartyList(response.data); // Assuming your data is an array
//                 })
//                 .catch((error) => {
//                     console.error("Error fetching data:", error);
//                 });

//             axios
//                 .get(
//                     `https://${ipaddress}externalParty/${companyid}/${branchId}/getAll`,
//                     {
//                         headers: {
//                             Authorization: `Bearer ${jwtToken}`,
//                         },
//                     }
//                 )
//                 .then((response) => {
//                     setExternalPartyList(response.data); // Assuming your data is an array
//                 })
//                 .catch((error) => {
//                     console.error("Error fetching data:", error);
//                 });
//         }
//     }, [isAuthenticated, navigate, jwtToken]);

//     // console.log(searchQuery);

//     const fetchPartiesData = async () => {
//         try {
//             const response = await axios.get(
//                 `https://${ipaddress}NewReprentative/getPartyRepresentative/${companyid}/${branchId}`
//             );
//             const userList = response.data;
//             //   setRepresentatives(userList);

//             //   Fetch party images concurrently
//             const imagePromises = userList.map((user) =>
//                 fetchPartyRepresentativeImage(user)
//             );
//             const partyImages = await Promise.all(imagePromises);

//             // Combine party data and images
//             const combinedData = userList.map((user, index) => ({
//                 ...user,
//                 imageURL: partyImages[index], // Add imageURL to user object
//             }));
//             // setrepresentativeImage1(response.data);
//             setRepresentatives(combinedData);
//         } catch (error) {
//             console.error("Failed to fetch parties data. Please try again.", error);
//             alert("Failed to fetch parties data. Please try again.");
//         }
//     };
//     // const handleSearch = () => {
//     //     const lowercaseQuery = searchQuery.toLowerCase(); // Convert search query to lowercase

//     //     const filteredList = representatives.filter((rep) =>
//     //     rep.firstName.toLowerCase().includes(lowercaseQuery)
//     //     );
//     // };

//     const handleSearch = () => {
//         const trimmedQuery = searchQuery.trim(); // Remove leading and trailing spaces
//         const lowercaseQuery = trimmedQuery.toLowerCase(); // Convert search query to lowercase

//         const filteredListFname = representatives.filter((rep) =>
//             rep.firstName.toLowerCase().includes(lowercaseQuery)
//         );
//         const filteredListMname = representatives.filter((rep) =>
//             rep.middleName.toLowerCase().includes(lowercaseQuery)
//         );
//         const filteredListLname = representatives.filter((rep) =>
//             rep.lastName.toLowerCase().includes(lowercaseQuery)
//         );

//         if (filteredListFname.length > 0) {
//             setFilteredRepresentatives(filteredListFname);
//         } else if (filteredListMname > 0) {
//             setFilteredRepresentatives(filteredListMname);
//         } else if (filteredListLname.length > 0) {
//             setFilteredRepresentatives(filteredListLname);
//         } else {
//             setFilteredRepresentatives([]);
//         }
//     };

//     const handleReset = () => {
//         setFilteredRepresentatives(representatives);
//         setSearchQuery("");
//     };

//     useEffect(() => {
//         fetchPartiesData();
//     }, [companyid, branchId]);

//     useEffect(() => {
//         setFilteredRepresentatives(representatives);

//         // console.log(representatives);
//     }, [representatives]);

//     const fetchPartyRepresentativeImage = async (user) => {
//         try {
//             const response = await axios.get(
//                 `https://${ipaddress}NewReprentative/getImage1/${user.companyId}/${user.branchId}/${user.userId}/${user.representativeId}`
//             );
//             // console.log(response.data);
//             // setrepresentativeImage(response.data);
//             // setrepresentativeImage1(response.data);
//             // Return the image URL or the whole response based on your API structure
//             return response.data;
//         } catch (error) {
//             console.error("Failed to fetch party image. Please try again.", error);
//             throw error; // Rethrow the error for handling in the calling function
//         }
//     };
//     return (
//         <div className="Container">
//             <h5
//                 className="pageHead"
//                 style={{
//                     fontFamily: "Your-Heading-Font",
//                     paddingLeft: "4%",
//                     paddingRight: "-50px",
//                 }}
//             >
//                 {" "}
//                 <FontAwesomeIcon
//                     icon={faIdCard}
//                     style={{
//                         marginRight: "8px",
//                         color: "black", // Set the color to golden
//                     }}
//                 />
//                 Representative Details
//             </h5>

//             <div className="containerservices">
//                 <div className="card mt-2">
//                     <div className="card-body" >
//                         <Row>
//                             <Col md={4}>
//                                 <label htmlFor="Entity Id">Representative Name</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     style={{ height: "50px" }}
//                                     name="searchQuery"
//                                     placeholder="Enter Representative Details"
//                                     value={searchQuery}
//                                     onChange={(e) => {
//                                         setSearchQuery(e.target.value);
//                                         // handleSearch();
//                                     }}
//                                 />
//                             </Col>

//                             <Col md={4}>
//                                 <button
//                                     type="button"
//                                     className="btn gap-2  btn-outline-primary"
//                                     style={{ marginTop: "2.1vw", marginRight: "10px" }}
//                                     onClick={handleSearch}
//                                 >
//                                     {" "}
//                                     <FontAwesomeIcon
//                                         icon={faSearch}
//                                         style={{ marginRight: "5px" }}
//                                     />
//                                     Search
//                                 </button>
//                                 <button
//                                     type="button"
//                                     className="btn gap-2  btn-outline-danger"
//                                     style={{ marginTop: "2.1vw", marginRight: "10px" }}
//                                     onClick={handleReset}
//                                 >
//                                     {" "}
//                                     <FontAwesomeIcon
//                                         icon={faRefresh}
//                                         style={{ marginRight: "5px" }}
//                                     />
//                                     Clear
//                                 </button>
//                             </Col>

//                             <Col md={4}></Col>
//                         </Row>

//                         <div className="table-responsive" style={{ marginTop: "10px" }}>
//                             <Table className="table table-striped table-hover">
//                                 <thead style={{ backgroundColor: "rgb(226 232 240)" }}>
//                                     <tr className="text-left">
//                                         <th style={{ backgroundColor: "#BADDDA" }} scope="col">
//                                             Party/CHA/Console Name
//                                         </th>
//                                         <th style={{ backgroundColor: "#BADDDA" }} scope="col">
//                                             Name
//                                         </th>
//                                         <th style={{ backgroundColor: "#BADDDA" }} scope="col">
//                                             Image
//                                         </th>
//                                         <th style={{ backgroundColor: "#BADDDA" }} scope="col">
//                                             Mobile No
//                                         </th>
//                                         <th style={{ backgroundColor: "#BADDDA" }} scope="col">
//                                             Status
//                                         </th>
//                                         <th style={{ backgroundColor: "#BADDDA" }} scope="col">
//                                             Deleted
//                                         </th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {filteredRepresentatives.length > 0 ? (
//                                         filteredRepresentatives.map((rep, index) => (
//                                             <tr className="text-left" key={index}>
//                                                 <td className="table-column">
//                                                     {getNameByPartyId(rep.userId)}
//                                                 </td>
//                                                 <td className="table-column">
//                                                     {rep.firstName +
//                                                         " " +
//                                                         rep.middleName +
//                                                         " " +
//                                                         rep.lastName}
//                                                 </td>

//                                                 <td>
//                                                     {rep.imageURL ? (
//                                                         <img
//                                                             src={rep.imageURL}
//                                                             alt={rep.imageURL} //${rep.imagePath}
//                                                             style={{ width: "110px", height: "100px" }}
//                                                         />
//                                                     ) : (
//                                                         "No Image Available"
//                                                     )}
//                                                 </td>

//                                                 <td className="table-column">{rep.mobile}</td>
//                                                 <td className="table-column">
//                                                     {rep.status === "A" ? "Active" : rep.userStatus === "I" ? "Inactive" : rep.status}
//                                                 </td>

//                                                 <td className="table-column">{rep.deleted}</td>
//                                             </tr>
//                                         ))
//                                     ) : (
//                                         <React.Fragment>
//                                             <tr>
//                                                 <td colSpan="6" className="text-center">
//                                                     No results found !
//                                                 </td>
//                                             </tr>
//                                         </React.Fragment>
//                                     )}
//                                 </tbody>
//                             </Table>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }



import AuthContext from "../Components/AuthProvider";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import "../Components/Style.css";
import { Button } from "react-bootstrap";
import ipaddress from "../Components/IpAddress";

import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdCard, faRefresh, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, } from "reactstrap";
import axios from "axios";
import { Pagination } from "react-bootstrap";
import ReactLoading from 'react-loading';


export default function Representative_Details() {


    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };


    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);

    const { companyid, branchId } = useContext(AuthContext);
    const [representatives, setRepresentatives] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setloading] = useState(false);



    useEffect(() => {
        handleSearch(companyid, branchId, '');
    }, []);

    const handleSearch = async (companyId, branchId, searchValue) => {
        setloading(true);
        try {
            const response = await axios.get(`https://${ipaddress}NewReprentative/getPartyRepresentative`, {
                params: {
                    companyId: companyId,
                    branchId: branchId,
                    searchvalue: searchValue
                }
            });
            setRepresentatives(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setloading(false);
        }
    };

    const handleReset = async () => {
        setCurrentPage(1);
        setSearchQuery('');
        handleSearch(companyid, branchId, '');
    };

    // Pagination Code Updated By Tukaram Gurav On 30/03/2024

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20; // Number of items to display per page
    const totalPages = Math.ceil(representatives.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const currentfilteredRepresentatives = representatives.slice(indexOfFirstItem, indexOfLastItem);

    const displayPages = () => {
        const centerPageCount = 5;
        const middlePage = Math.floor(centerPageCount / 2);
        let startPage = currentPage - middlePage;
        let endPage = currentPage + middlePage;

        if (startPage < 1) {
            startPage = 1;
            endPage = Math.min(totalPages, centerPageCount);
        }

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(1, totalPages - centerPageCount + 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };














    return (
        <div className="Container">

            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}

            <h5
                className="pageHead"
                style={{
                    fontFamily: "Your-Heading-Font",
                    paddingLeft: "4%",
                    paddingRight: "-50px",
                }}
            >
                {" "}
                <FontAwesomeIcon
                    icon={faIdCard}
                    style={{
                        marginRight: "8px",
                        color: "black", // Set the color to golden
                    }}
                />
                Representative Details
            </h5>

            <div className="containerservices">
                <div className="card mt-2">
                    <div className="card-body" >
                        <Row>
                            <Col md={4}>
                                <label htmlFor="Entity Id">Party/Cha/Console/Representative Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    style={{ height: "50px" }}
                                    name="searchQuery"
                                    placeholder="Enter Party/Cha/Console/Representative Details"
                                    value={searchQuery}
                                    onChange={(e) => {
                                        setSearchQuery(e.target.value);
                                        // handleSearch();
                                    }}
                                />
                            </Col>

                            <Col md={4}>
                                <button
                                    type="button"
                                    className="btn gap-2  btn-outline-primary"
                                    style={{ marginTop: "2.1vw", marginRight: "10px" }}
                                    onClick={(e) => handleSearch(companyid, branchId, searchQuery)}
                                >
                                    {" "}
                                    <FontAwesomeIcon
                                        icon={faSearch}
                                        style={{ marginRight: "5px" }}
                                    />
                                    Search
                                </button>
                                <button
                                    type="button"
                                    className="btn gap-2  btn-outline-danger"
                                    style={{ marginTop: "2.1vw", marginRight: "10px" }}
                                    onClick={handleReset}
                                >
                                    {" "}
                                    <FontAwesomeIcon
                                        icon={faRefresh}
                                        style={{ marginRight: "5px" }}
                                    />
                                    Clear
                                </button>
                            </Col>

                            <Col md={4}></Col>
                        </Row>

                        <div className="table-responsive" style={{ marginTop: "10px" }}>
                            <Table className="table table-bordered custom-table mt-3">
                                <thead style={{ backgroundColor: "rgb(226 232 240)" }}>
                                    <tr className="text-left">
                                        <th style={{ backgroundColor: "#BADDDA" }} scope="col">Sr No</th>
                                        <th style={{ backgroundColor: "#BADDDA" }} scope="col">Party/CHA/Console Name</th>
                                        <th style={{ backgroundColor: "#BADDDA" }} scope="col">Name</th>
                                        <th style={{ backgroundColor: "#BADDDA" }} scope="col">Mobile No</th>
                                        <th style={{ backgroundColor: "#BADDDA" }} scope="col">Representative</th>
                                        <th style={{ backgroundColor: "#BADDDA" }} scope="col">Sign</th>
                                        <th style={{ backgroundColor: "#BADDDA" }} scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentfilteredRepresentatives.map((rep, index) =>
                                        <tr className="text-center" key={index}>
                                            <td className="table-column">{((currentPage - 1) * itemsPerPage) + index + 1}</td>
                                            <td className="table-column">{rep.userId}</td>
                                            <td className="table-column">{rep.firstName + " " + rep.middleName + " " + rep.lastName}</td>
                                            <td className="table-column">{rep.mobile}</td>
                                            <td>
                                                {rep.imagePath ? (
                                                    <img
                                                        src={rep.imagePath}
                                                        alt={rep.imagePath}
                                                        style={{ width: "110px", height: "100px" }}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td>
                                                {rep.signImagePath ? (
                                                    <img
                                                        src={rep.signImagePath}
                                                        alt={rep.signImagePath}
                                                        style={{ width: "110px", height: "100px" }}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                            <td className="table-column">{rep.userStatus === "A" ? "Active" : rep.userStatus === "I" ? "Inactive" : ''}</td>
                                        
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
                                <Pagination.First onClick={() => handlePageChange(1)} />
                                <Pagination.Prev
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                />
                                <Pagination.Ellipsis />

                                {displayPages().map((pageNumber) => (
                                    <Pagination.Item
                                        key={pageNumber}
                                        active={pageNumber === currentPage}
                                        onClick={() => handlePageChange(pageNumber)}
                                    >
                                        {pageNumber}
                                    </Pagination.Item>
                                ))}

                                <Pagination.Ellipsis />
                                <Pagination.Next
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                />
                                <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                            </Pagination>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}