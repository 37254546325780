// import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import AuthContext from "../Components/AuthProvider";
// import { Pagination } from "react-bootstrap";
// import {
//     Card,
//     CardBody,
//     Col,
//     FormGroup,
//     Row,
//     Label,
//     Input,
//     Button,
//     Table
// } from "reactstrap";
// import { toast } from "react-toastify";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAtom, faEye, faFileExcel, faFilePdf, faPrint, faReceipt, faSearch, faTrashAlt, faTruck, faTruckArrowRight, faTruckFast, faTruckFront, faTruckPickup } from '@fortawesome/free-solid-svg-icons';
// import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
// import Select from 'react-select';
// import ReactLoading from 'react-loading';
// import moment from 'moment';
// import DatePicker from "react-datepicker";
// import Rate_Chart_Service from "../services/Rate_Chart_Service";


// export default function ExportTrack(props) {
//     const {
//         userId,
//         branchId,
//         companyid
//     } = useContext(AuthContext);


//     const navigate = useNavigate();
//     const { isAuthenticated } = useContext(AuthContext);

//     useEffect(() => {
//         if (!isAuthenticated) {
//             navigate(
//                 "/login?message=You need to be authenticated to access this page."
//             );
//         }
//     }, [isAuthenticated, navigate]);


//     const [filteredData, setFilteredData] = useState([]);

//     const [totals, setTotals] = useState({
//         noOfPackages: 0,
//         grossWeight: 0,
//         fobValueInINR: 0
//     });

//     const initialSearchCriteria = {
//         companyId: companyid,
//         branchId: branchId,
//         startDate: moment(new Date()).format('YYYY-MM-DD'),
//         endDate: moment(new Date()).format('YYYY-MM-DD'),
//         searchValue: '',
//         dgdcStatus: ''
//     };


//     const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);
//     const [loading, setLoading] = useState(false);
//     const styles = {
//         overlay: {
//             position: 'fixed',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             backgroundColor: 'rgba(255, 255, 255, 0.8)',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             zIndex: 9999,
//         },
//     };



//     const findDgdcStatus = async () => {
//         const PCKTYPEResponse = await Rate_Chart_Service.getJarByDtl(companyid, 'J00009');
//         const partyOptions = PCKTYPEResponse.data.map(jar => ({
//             value: jar[1],
//             label: jar[1]
//         }));
//         setDgdcStatusArray(partyOptions);
//     };


//     useEffect(() => {
//         const fetchData = async () => {
//             handleSearch(initialSearchCriteria);
//             await findDgdcStatus();
//         };

//         fetchData();
//     }, []);




//     const [currentPage, setCurrentPage] = useState(1);
//     const [itemsPerPage] = useState(30);

//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
//     const totalPages = Math.ceil(filteredData.length / itemsPerPage);


//     const [dgdcStatusArray, setDgdcStatusArray] = useState([])
//     const [selectedStatus, setSelectedStatus] = useState(null);

//     // Function to handle page change
//     const handlePageChange = (page) => {
//         if (page >= 1 && page <= totalPages) {
//             setCurrentPage(page);
//         }
//     };
//     const displayPages = () => {
//         const centerPageCount = 5;
//         const middlePage = Math.floor(centerPageCount / 2);
//         let startPage = currentPage - middlePage;
//         let endPage = currentPage + middlePage;

//         if (startPage < 1) {
//             startPage = 1;
//             endPage = Math.min(totalPages, centerPageCount);
//         }

//         if (endPage > totalPages) {
//             endPage = totalPages;
//             startPage = Math.max(1, totalPages - centerPageCount + 1);
//         }

//         return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
//     };




//     const handleSearch = async (searchCriteria) => {
//         setCurrentPage(1);
//         setLoading(true);
//         try {
//             const response = await Rate_Chart_Service.searchTrackReport({ params: searchCriteria });
//             setFilteredData(response.data);
//             // console.log("filteredData");
//             // console.log(filteredData);
//         }
//         catch {
//             toast.error('Oops something went wrong!', {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 700,
//             });
//         }
//         finally {
//             setLoading(false);
//         }
//     };



//     const handleReset = () => {
//         setSearchCriteria(initialSearchCriteria);
//         setSelectedStatus(null);
//         handleSearch(initialSearchCriteria);
//     };

//     const formatDateTimeMonth = (value) => {
//         if (!value) {
//             return "";
//         }

//         const date = new Date(value);
//         const day = date.getDate(); // Get the day of the month
//         const year = date.getFullYear(); // Get the year

//         // Format the date as dd/mm/yyyy
//         const formattedDate = `${day.toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${year}`;

//         return formattedDate;
//     };


//     const formatDateTimeMonthTime = (value) => {
//         if (!value) {
//             return "";
//         }

//         const date = new Date(value);
//         const day = date.getDate(); // Get the day of the month
//         const month = date.getMonth() + 1; // Get the month
//         const year = date.getFullYear(); // Get the year
//         const hours = date.getHours(); // Get the hours
//         const minutes = date.getMinutes(); // Get the minutes
//         const seconds = date.getSeconds(); // Get the seconds

//         // Format the date as dd/mm/yyyy hh:mm:ss
//         const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
//         const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

//         return `${formattedDate} ${formattedTime}`;
//     };



//     const handlePrint = async (type) => {
//         setLoading(true);
//         try {
//             const response = await Rate_Chart_Service.generateTrackReportPrint(searchCriteria);

//             if (response.status === 200) {
//                 const pdfData = response.data;
//                 const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
//                 const blobUrl = URL.createObjectURL(pdfBlob);

//                 if (type === "PDF") {
//                     // Create an anchor element for downloading
//                     const downloadLink = document.createElement('a');
//                     downloadLink.href = blobUrl;
//                     downloadLink.download = 'Export_Track_Report.pdf';
//                     downloadLink.style.display = 'none';
//                     document.body.appendChild(downloadLink);
//                     // Trigger the download
//                     downloadLink.click();
//                     // Clean up
//                     document.body.removeChild(downloadLink);
//                     window.URL.revokeObjectURL(blobUrl);
//                 }
//                 if (type === 'PRINT') {
//                     window.open(blobUrl, '_blank');
//                 }
//                 toast.success("Downloading Pdf!", {
//                     position: toast.POSITION.TOP_CENTER,
//                     autoClose: 800,
//                 });
//             } else {
//                 toast.error("error downLoading file!", {
//                     position: toast.POSITION.TOP_CENTER,
//                     autoClose: 800,
//                 });
//             }
//         } catch (error) {
//             console.error("Error downloading PDF:", error);
//         }
//         finally {
//             setLoading(false);
//         }
//     };


//     const handleXLSdownLoad = async () => {
//         setLoading(true);
//         try {
//             const response = await Rate_Chart_Service.downLoadTrackReportXLS(searchCriteria);

//             if (response.status === 200) {
//                 const blob = new Blob([response.data], { type: response.headers['content-type'] });

//                 // Create a temporary URL for the blob
//                 const url = window.URL.createObjectURL(blob);

//                 // Create a link element to trigger the download
//                 const a = document.createElement("a");
//                 a.href = url;
//                 a.download = 'Export_Track_Report.xlsx';
//                 document.body.appendChild(a);
//                 a.click();

//                 // Clean up
//                 window.URL.revokeObjectURL(url);
//                 document.body.removeChild(a);
//             }
//         }
//         catch {
//             toast.error("error downLoading file!", {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 800,
//             });
//         }
//         finally {
//             setLoading(false);
//         }
//     };




//     useEffect(() => {
//         const calculateTotals = () => {
//             const totalNoOfPackages = calculateTotalColumnObject(filteredData, 7);
//             const totalGrossWeight = calculateTotalColumnObject(filteredData, 8);
//             const totalFobValueInINR = calculateTotalColumnObject(filteredData, 10);

//             setTotals({
//                 noOfPackages: totalNoOfPackages,
//                 grossWeight: totalGrossWeight,
//                 fobValueInINR: totalFobValueInINR
//             });


//             setSearchCriteria(prevState => ({
//                 ...prevState,
//                 noOfPackages: totalNoOfPackages,
//                 grossWeight: totalGrossWeight,
//                 fobValueInINR: totalFobValueInINR,
//                 sbNo: filteredData.length
//             }));
//         };

//         calculateTotals();
//     }, [filteredData]);

//     const calculateTotalColumnObject = (data, columnIndex) => {
//         const total = data.reduce((sum, row) => {
//             let value = parseFloat(row[columnIndex]) || 0;
//             if (row[9] === 'KILO GRAMS' && columnIndex === 8) {
//                 value *= 1000;
//             }
//             return sum + value;
//         }, 0);

//         if (columnIndex === 8 || columnIndex === 10) {
//             return parseFloat(total.toFixed(2));
//         } else {
//             return Math.round(total);
//         }
//     };

//     const handleStatusChange = selectedOption => {
//         setSelectedStatus(selectedOption);
//         setSearchCriteria({ ...searchCriteria, dgdcStatus: selectedOption ? selectedOption.value : '' });
//     };



//     return (
//         <>
//             {loading && (
//                 <div style={styles.overlay}>
//                     <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
//                 </div>
//             )}



//             <div className="Container">
//                 <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }}>
//                     <FontAwesomeIcon
//                         icon={faReceipt}
//                         style={{
//                             marginRight: '8px',
//                             color: 'black',
//                         }}
//                     />Export Track Report</h5>
//                 <Card style={{
//                     backgroundSize: 'cover',
//                     backgroundRepeat: 'no-repeat',
//                     backgroundPosition: 'center',
//                     display: 'flex',
//                     flexDirection: 'column',
//                     minHeight: '40vw',
//                     overflowX: "hidden",
//                 }} >
//                     <CardBody style={{ paddingBottom: '-5px' }} >

//                         <Row>


//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="customerName">Search By</Label>

//                                     <Input
//                                         type="text"
//                                         name="searchValue"
//                                         className="form-control inputField"
//                                         placeholder="Enter SB No/ Ser No/ Req Id/ Exporter Name"
//                                         value={searchCriteria.searchValue}
//                                         onChange={(e) => setSearchCriteria({ ...searchCriteria, searchValue: e.target.value })}
//                                         maxLength={100}
//                                     />

//                                 </FormGroup>
//                             </Col>


//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">Entry Date From</Label>
//                                     <div> {/* Wrap in an input group */}

//                                         <DatePicker
//                                             selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
//                                             wrapperClassName="custom-react-datepicker-wrapper"
//                                             onChange={(date) => {
//                                                 if (date) {
//                                                     // Set the time to 12:00 AM in UTC time
//                                                     date.setHours(12, 0, 0, 0);
//                                                     // Convert the selected date and time to ISO format
//                                                     const formattedDate = date.toISOString();
//                                                     setSearchCriteria({ ...searchCriteria, startDate: formattedDate });
//                                                 } else {
//                                                     setSearchCriteria({ ...searchCriteria, startDate: null });
//                                                 }
//                                             }}
//                                             dateFormat="dd/MM/yyyy" // Specify the combined format
//                                             className="form-control border-right-0 inputField"
//                                             customInput={<input style={{ width: '100%' }} />}

//                                         />
//                                     </div>
//                                 </FormGroup>
//                             </Col>


//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">Entry Date To</Label>
//                                     <div> {/* Wrap in an input group */}
//                                         <DatePicker
//                                             selected={searchCriteria.endDate ? new Date(searchCriteria.endDate) : null} // Use endDate from searchCriteria if it's defined
//                                             wrapperClassName="custom-react-datepicker-wrapper"
//                                             onChange={(date) => {
//                                                 if (date) {
//                                                     // Set the time to 12:00 PM (noon)
//                                                     date.setHours(12, 0, 0, 0);
//                                                     const formattedDate = date.toISOString(); // Convert to ISO format
//                                                     setSearchCriteria({ ...searchCriteria, endDate: formattedDate });
//                                                 } else {
//                                                     setSearchCriteria({ ...searchCriteria, endDate: null });
//                                                 }
//                                             }}
//                                             dateFormat="dd/MM/yyyy"
//                                             className="form-control border-right-0 inputField"
//                                             customInput={<input style={{ width: '100%' }} />}
//                                         />
//                                     </div>
//                                 </FormGroup>
//                             </Col>
//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">Select Dgdc Status</Label>
//                                     <Select
//                                         options={dgdcStatusArray}
//                                         value={selectedStatus}
//                                         onChange={handleStatusChange}
//                                         isClearable
//                                         placeholder="Select Dgdc Status"
//                                         styles={{
//                                             control: (provided, state) => ({
//                                                 ...provided,
//                                                 border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                                                 boxShadow: 'none',
//                                                 '&:hover': {
//                                                     border: '1px solid #ccc'
//                                                 }
//                                             }),
//                                             indicatorSeparator: () => ({
//                                                 display: 'none'
//                                             }),
//                                             dropdownIndicator: () => ({
//                                                 display: 'none'
//                                             })
//                                         }}
//                                     />
//                                 </FormGroup>
//                             </Col>

//                         </Row>

//                         <Row>

//                             <div className="text-center" style={{ marginTop: '10px' }}>

//                                 <Button
//                                     color="primary"
//                                     outline
//                                     onClick={() => handleSearch(searchCriteria)}
//                                     className="mr-2"
//                                     style={{ marginRight: '25px', fontWeight: 'bold' }}
//                                 >
//                                     <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
//                                     SEARCH
//                                 </Button>
//                                 <Button
//                                     color="danger"
//                                     outline
//                                     onClick={handleReset}
//                                     className="mr-2"
//                                     style={{ marginRight: '5px', fontWeight: 'bold' }}
//                                 >
//                                     <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
//                                     RESET
//                                 </Button>
//                             </div>

//                         </Row>



//                         <hr />


//                         {filteredData && filteredData.length > 0 && (
//                             <>



//                                 <Row className='mt-1 mb-1'>
//                                     <Col className="text-end mt-1">
//                                         <Button
//                                             color="success"
//                                             outline
//                                             style={{ marginRight: 10, marginLeft: 5, fontWeight: 'bold' }}
//                                             onClick={() => handlePrint("PRINT")}
//                                         >
//                                             <FontAwesomeIcon
//                                                 icon={faPrint}
//                                                 style={{ marginRight: "5px" }}
//                                             />
//                                             Print
//                                         </Button>

//                                         <Button
//                                             color="primary"
//                                             outline
//                                             style={{ marginRight: 10, marginLeft: 5, fontWeight: 'bold' }}
//                                             onClick={() => handlePrint("PDF")}
//                                         >
//                                             <FontAwesomeIcon
//                                                 icon={faFilePdf}
//                                                 style={{ marginRight: "5px" }}
//                                             />
//                                             PDF
//                                         </Button>

//                                         <Button
//                                             color="success"
//                                             outline
//                                             style={{ marginLeft: 5, fontWeight: 'bold' }}
//                                             onClick={handleXLSdownLoad}
//                                         >
//                                             <FontAwesomeIcon
//                                                 icon={faFileExcel}
//                                                 style={{ marginRight: "5px" }}
//                                             />
//                                             XLS
//                                         </Button>
//                                     </Col>

//                                 </Row>

//                                 <div className="table-responsive mt-3">

//                                     <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
//                                         <thead className="thead-dark bg-dark"  >
//                                             <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>Sr No</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>SB No</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>SB Date</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Ser No</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Exporter</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>AirWayBill No</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>PKGS</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Weight</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Value(RS)</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Console</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Entry Date</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Seepz Scan In Date</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Seepz Ser Date</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Carting Date</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Seepz Scan Out Date</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Cargo Scan In Date</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Cargo In Date</th>
//                                             </tr>

//                                             <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Total</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>{filteredData.length}</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>{totals.noOfPackages}</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>{totals.grossWeight} GMS</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>{totals.fobValueInINR}</th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
//                                                 <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
//                                             </tr>

//                                         </thead>
//                                         <tbody>
//                                             {currentItems.map((item, index) => (
//                                                 <tr key={item.id} className='text-center'>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{((currentPage - 1) * itemsPerPage) + index + 1}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[2]}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonth(item[3])}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[4]}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[5]}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[6]}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[7]}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[8] + ' (' + (item[9] === 'KILO GRAMS' ? 'KGS' : 'GMS') + ')'}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[10]}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[11]}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonthTime(item[12])}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonthTime(item[13])}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonthTime(item[14])}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonthTime(item[15])}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonthTime(item[16])}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonthTime(item[17])}</td>
//                                                     <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonthTime(item[18])}</td>
//                                                 </tr>
//                                             ))}
//                                         </tbody>
//                                     </Table>

//                                     <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
//                                         <Pagination.First onClick={() => handlePageChange(1)} />
//                                         <Pagination.Prev
//                                             onClick={() => handlePageChange(currentPage - 1)}
//                                             disabled={currentPage === 1}
//                                         />
//                                         <Pagination.Ellipsis />

//                                         {displayPages().map((pageNumber) => (
//                                             <Pagination.Item
//                                                 key={pageNumber}
//                                                 active={pageNumber === currentPage}
//                                                 onClick={() => handlePageChange(pageNumber)}
//                                             >
//                                                 {pageNumber}
//                                             </Pagination.Item>
//                                         ))}

//                                         <Pagination.Ellipsis />
//                                         <Pagination.Next
//                                             onClick={() => handlePageChange(currentPage + 1)}
//                                             disabled={currentPage === totalPages}
//                                         />
//                                         <Pagination.Last onClick={() => handlePageChange(totalPages)} />
//                                     </Pagination>
//                                 </div>
//                             </>
//                         )}
//                     </CardBody>
//                 </Card>
//             </div>
//         </>
//     );
// }





import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../Components/AuthProvider";
import { Pagination } from "react-bootstrap";
import {
    Card,
    CardBody,
    Col,
    FormGroup,
    Row,
    Label,
    Input,
    Button,
    Table
} from "reactstrap";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAtom, faEye, faFileExcel, faFilePdf, faPrint, faReceipt, faSearch, faTrashAlt, faTruck, faTruckArrowRight, faTruckFast, faTruckFront, faTruckPickup } from '@fortawesome/free-solid-svg-icons';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import ReactLoading from 'react-loading';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Rate_Chart_Service from "../services/Rate_Chart_Service";


export default function ExportTrack(props) {
    const {
        userId,
        branchId,
        companyid
    } = useContext(AuthContext);


    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);


    const [filteredData, setFilteredData] = useState([]);

    const [totals, setTotals] = useState({
        noOfPackages: 0,
        grossWeight: 0,
        fobValueInINR: 0
    });

    const initialSearchCriteria = {
        companyId: companyid,
        branchId: branchId,
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        searchValue: '',
        dgdcStatus: '',
        dateType: 'gateIn'
    };


    const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);
    const [loading, setLoading] = useState(false);
    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
        },
    };



    const findDgdcStatus = async () => {
        const PCKTYPEResponse = await Rate_Chart_Service.getJarByDtl(companyid, 'J00009');
        const partyOptions = PCKTYPEResponse.data.map(jar => ({
            value: jar[1],
            label: jar[1]
        }));
        setDgdcStatusArray(partyOptions);
    };


    useEffect(() => {
        const fetchData = async () => {
            handleSearch(initialSearchCriteria);
            await findDgdcStatus();
        };

        fetchData();
    }, []);




    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(30);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);


    const [dgdcStatusArray, setDgdcStatusArray] = useState([])
    const [selectedStatus, setSelectedStatus] = useState(null);

    // Function to handle page change
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };
    const displayPages = () => {
        const centerPageCount = 5;
        const middlePage = Math.floor(centerPageCount / 2);
        let startPage = currentPage - middlePage;
        let endPage = currentPage + middlePage;

        if (startPage < 1) {
            startPage = 1;
            endPage = Math.min(totalPages, centerPageCount);
        }

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = Math.max(1, totalPages - centerPageCount + 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };




    const handleSearch = async (searchCriteria) => {
        setCurrentPage(1);
        setLoading(true);
        try {
            const response = await Rate_Chart_Service.searchTrackReport({ params: searchCriteria });
            setFilteredData(response.data);
            // console.log("filteredData");
            // console.log(filteredData);
        }
        catch {
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 700,
            });
        }
        finally {
            setLoading(false);
        }
    };



    const handleReset = () => {
        setSearchCriteria(initialSearchCriteria);
        setSelectedStatus(null);
        handleSearch(initialSearchCriteria);
    };

    const formatDateTimeMonth = (value) => {
        if (!value) {
            return "";
        }

        const date = new Date(value);
        const day = date.getDate(); // Get the day of the month
        const year = date.getFullYear(); // Get the year

        // Format the date as dd/mm/yyyy
        const formattedDate = `${day.toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${year}`;

        return formattedDate;
    };


    const formatDateTimeMonthTime = (value) => {
        if (!value) {
            return "";
        }

        const date = new Date(value);
        const day = date.getDate(); // Get the day of the month
        const month = date.getMonth() + 1; // Get the month
        const year = date.getFullYear(); // Get the year
        const hours = date.getHours(); // Get the hours
        const minutes = date.getMinutes(); // Get the minutes
        const seconds = date.getSeconds(); // Get the seconds

        // Format the date as dd/mm/yyyy hh:mm:ss
        const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        return `${formattedDate} ${formattedTime}`;
    };



    const handlePrint = async (type) => {
        setLoading(true);
        try {
            const response = await Rate_Chart_Service.generateTrackReportPrint(searchCriteria);

            if (response.status === 200) {
                const pdfData = response.data;
                const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
                const blobUrl = URL.createObjectURL(pdfBlob);

                if (type === "PDF") {
                    // Create an anchor element for downloading
                    const downloadLink = document.createElement('a');
                    downloadLink.href = blobUrl;
                    downloadLink.download = 'Export_Track_Report.pdf';
                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);
                    // Trigger the download
                    downloadLink.click();
                    // Clean up
                    document.body.removeChild(downloadLink);
                    window.URL.revokeObjectURL(blobUrl);
                }
                if (type === 'PRINT') {
                    window.open(blobUrl, '_blank');
                }
                toast.success("Downloading Pdf!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 800,
                });
            } else {
                toast.error("error downLoading file!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 800,
                });
            }
        } catch (error) {
            console.error("Error downloading PDF:", error);
        }
        finally {
            setLoading(false);
        }
    };


    const handleXLSdownLoad = async () => {
        setLoading(true);
        try {
            const response = await Rate_Chart_Service.downLoadTrackReportXLS(searchCriteria);

            if (response.status === 200) {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a link element to trigger the download
                const a = document.createElement("a");
                a.href = url;
                a.download = 'Export_Track_Report.xlsx';
                document.body.appendChild(a);
                a.click();

                // Clean up
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        }
        catch {
            toast.error("error downLoading file!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 800,
            });
        }
        finally {
            setLoading(false);
        }
    };




    useEffect(() => {
        const calculateTotals = () => {
            const totalNoOfPackages = calculateTotalColumnObject(filteredData, 7);
            const totalGrossWeight = calculateTotalColumnObject(filteredData, 8);
            const totalFobValueInINR = calculateTotalColumnObject(filteredData, 10);

            setTotals({
                noOfPackages: totalNoOfPackages,
                grossWeight: totalGrossWeight,
                fobValueInINR: totalFobValueInINR
            });


            setSearchCriteria(prevState => ({
                ...prevState,
                noOfPackages: totalNoOfPackages,
                grossWeight: totalGrossWeight,
                fobValueInINR: totalFobValueInINR,
                sbNo: filteredData.length
            }));
        };

        calculateTotals();
    }, [filteredData]);

    const calculateTotalColumnObject = (data, columnIndex) => {
        const total = data.reduce((sum, row) => {
            let value = parseFloat(row[columnIndex]) || 0;
            if (row[9] === 'KILO GRAMS' && columnIndex === 8) {
                value *= 1000;
            }
            return sum + value;
        }, 0);

        if (columnIndex === 8 || columnIndex === 10) {
            return parseFloat(total.toFixed(2));
        } else {
            return Math.round(total);
        }
    };

    const handleStatusChange = selectedOption => {
        setSelectedStatus(selectedOption);
        setSearchCriteria({ ...searchCriteria, dgdcStatus: selectedOption ? selectedOption.value : '' });
    };



    return (
        <>
            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}



            <div className="Container">
                <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }}>
                    <FontAwesomeIcon
                        icon={faReceipt}
                        style={{
                            marginRight: '8px',
                            color: 'black',
                        }}
                    />Export Track Report</h5>
                <Card style={{
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '40vw',
                    overflowX: "hidden",
                }} >
                    <CardBody style={{ paddingBottom: '-5px' }} >

                        <Row>


                            <Col md={2}>
                                <FormGroup>
                                    <Label className="forlabel" for="customerName">Search By</Label>

                                    <Input
                                        type="text"
                                        name="searchValue"
                                        className="form-control inputField"
                                        placeholder="Enter SB No/ Ser No/ Req Id/ Exporter Name"
                                        value={searchCriteria.searchValue}
                                        onChange={(e) => setSearchCriteria({ ...searchCriteria, searchValue: e.target.value })}
                                        maxLength={100}
                                    />

                                </FormGroup>
                            </Col>


                            <Col md={2}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Date From</Label>
                                    <div> {/* Wrap in an input group */}

                                        <DatePicker
                                            selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
                                            wrapperClassName="custom-react-datepicker-wrapper"
                                            onChange={(date) => {
                                                if (date) {
                                                    // Set the time to 12:00 AM in UTC time
                                                    date.setHours(12, 0, 0, 0);
                                                    // Convert the selected date and time to ISO format
                                                    const formattedDate = date.toISOString();
                                                    setSearchCriteria({ ...searchCriteria, startDate: formattedDate });
                                                } else {
                                                    setSearchCriteria({ ...searchCriteria, startDate: null });
                                                }
                                            }}
                                            dateFormat="dd/MM/yyyy" // Specify the combined format
                                            className="form-control border-right-0 inputField"
                                            customInput={<input style={{ width: '100%' }} />}

                                        />
                                    </div>
                                </FormGroup>
                            </Col>


                            <Col md={2}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Date To</Label>
                                    <div> {/* Wrap in an input group */}
                                        <DatePicker
                                            selected={searchCriteria.endDate ? new Date(searchCriteria.endDate) : null} // Use endDate from searchCriteria if it's defined
                                            wrapperClassName="custom-react-datepicker-wrapper"
                                            onChange={(date) => {
                                                if (date) {
                                                    // Set the time to 12:00 PM (noon)
                                                    date.setHours(12, 0, 0, 0);
                                                    const formattedDate = date.toISOString(); // Convert to ISO format
                                                    setSearchCriteria({ ...searchCriteria, endDate: formattedDate });
                                                } else {
                                                    setSearchCriteria({ ...searchCriteria, endDate: null });
                                                }
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control border-right-0 inputField"
                                            customInput={<input style={{ width: '100%' }} />}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Select Dgdc Status</Label>
                                    <Select
                                        options={dgdcStatusArray}
                                        value={selectedStatus}
                                        onChange={handleStatusChange}
                                        isClearable
                                        placeholder="Select Dgdc Status"
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    border: '1px solid #ccc'
                                                }
                                            }),
                                            indicatorSeparator: () => ({
                                                display: 'none'
                                            }),
                                            dropdownIndicator: () => ({
                                                display: 'none'
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={3}>

                              

                                <div className="row">
                                    <div className="col-md-12">
                                        <FormGroup>
                                            <Label className="forlabel" for="branchId">Date Type</Label>
                                            <div className="row">
                                                <Col>
                                                    <FormGroup>
                                                        <div className="row">
                                                            <div className="col-6 d-flex align-items-center ">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input radios"
                                                                        type="radio" name="dateType" value="gateIn"
                                                                        checked={searchCriteria.dateType === 'gateIn'}
                                                                        onChange={(e) =>
                                                                            setSearchCriteria(prevState => ({
                                                                                ...prevState,
                                                                                dateType: e.target.value
                                                                            }))
                                                                        }
                                                                    />

                                                                    <Label className="form-check-label">Gate In</Label>
                                                                </div>
                                                            </div>

                                                            <div className="col-6 d-flex align-items-center">
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input radios"
                                                                        type="radio" name="dateType" value="gateOut"
                                                                        checked={searchCriteria.dateType === 'gateOut'}
                                                                        onChange={(e) =>
                                                                            setSearchCriteria(prevState => ({
                                                                                ...prevState,
                                                                                dateType: e.target.value
                                                                            }))
                                                                        }
                                                                    />
                                                                    <Label className="form-check-label">Tp Date</Label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </div>
                                        </FormGroup>
                                    </div>


                                </div>


                            </Col>

                        </Row>

                        <Row>

                            <div className="text-center" style={{ marginTop: '10px' }}>

                                <Button
                                    color="primary"
                                    outline
                                    onClick={() => handleSearch(searchCriteria)}
                                    className="mr-2"
                                    style={{ marginRight: '25px', fontWeight: 'bold' }}
                                >
                                    <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
                                    SEARCH
                                </Button>
                                <Button
                                    color="danger"
                                    outline
                                    onClick={handleReset}
                                    className="mr-2"
                                    style={{ marginRight: '5px', fontWeight: 'bold' }}
                                >
                                    <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: '5px' }} />
                                    RESET
                                </Button>
                            </div>

                        </Row>



                        <hr />


                        {filteredData && filteredData.length > 0 && (
                            <>



                                <Row className='mt-1 mb-1'>
                                    <Col className="text-end mt-1">
                                        <Button
                                            color="success"
                                            outline
                                            style={{ marginRight: 10, marginLeft: 5, fontWeight: 'bold' }}
                                            onClick={() => handlePrint("PRINT")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPrint}
                                                style={{ marginRight: "5px" }}
                                            />
                                            Print
                                        </Button>

                                        <Button
                                            color="primary"
                                            outline
                                            style={{ marginRight: 10, marginLeft: 5, fontWeight: 'bold' }}
                                            onClick={() => handlePrint("PDF")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFilePdf}
                                                style={{ marginRight: "5px" }}
                                            />
                                            PDF
                                        </Button>

                                        <Button
                                            color="success"
                                            outline
                                            style={{ marginLeft: 5, fontWeight: 'bold' }}
                                            onClick={handleXLSdownLoad}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFileExcel}
                                                style={{ marginRight: "5px" }}
                                            />
                                            XLS
                                        </Button>
                                    </Col>

                                </Row>

                                <div className="table-responsive mt-3">

                                    <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                                        <thead className="thead-dark bg-dark"  >
                                            <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>Sr No</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>SB No</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>SB Date</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Ser No</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Exporter</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>AirWayBill No</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>PKGS</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Weight</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Value(RS)</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Console</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Entry Date</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Seepz Scan In Date</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Seepz Ser Date</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Carting Date</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Seepz Scan Out Date</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Cargo Scan In Date</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Cargo In Date</th>
                                            </tr>

                                            <tr style={{ fontWeight: 'bold', border: '2px solid black', fontSize: '20px' }}>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>Total</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>{filteredData.length}</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>{totals.noOfPackages}</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>{totals.grossWeight} GMS</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}>{totals.fobValueInINR}</th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                                <th scope="col" className="text-center" style={{ backgroundColor: '#BADDDA', color: 'black', fontFamily: 'Your-Heading-Font' }}></th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {currentItems.map((item, index) => (
                                                <tr key={item.id} className='text-center'>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{((currentPage - 1) * itemsPerPage) + index + 1}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[2]}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonth(item[3])}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[4]}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[5]}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[6]}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[7]}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[8] + ' (' + (item[9] === 'KILO GRAMS' ? 'KGS' : 'GMS') + ')'}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[10]}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{item[11]}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonthTime(item[12])}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonthTime(item[13])}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonthTime(item[14])}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonthTime(item[15])}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonthTime(item[16])}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonthTime(item[17])}</td>
                                                    <td style={{ textAlign: 'center', fontFamily: 'Your-Data-Font' }}>{formatDateTimeMonthTime(item[18])}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                    <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
                                        <Pagination.First onClick={() => handlePageChange(1)} />
                                        <Pagination.Prev
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        />
                                        <Pagination.Ellipsis />

                                        {displayPages().map((pageNumber) => (
                                            <Pagination.Item
                                                key={pageNumber}
                                                active={pageNumber === currentPage}
                                                onClick={() => handlePageChange(pageNumber)}
                                            >
                                                {pageNumber}
                                            </Pagination.Item>
                                        ))}

                                        <Pagination.Ellipsis />
                                        <Pagination.Next
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                        />
                                        <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                                    </Pagination>
                                </div>
                            </>
                        )}
                    </CardBody>
                </Card>
            </div>
        </>
    );
}