// import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
// import AuthContext from "../Components/AuthProvider";
// import { Button } from "react-bootstrap";
// import DatePicker from "react-datepicker";
// import Select from 'react-select';
// import Rate_Chart_Service from "../services/Rate_Chart_Service";
// import { Card, CardBody, Container, Row, Col, Form, FormGroup, Label, Input, Table } from "reactstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBackward, faClose, faPlaneCircleCheck, faPlaneDeparture, faPlaneUp, faRefresh, faSearch, faTruckArrowRight, faTruckFast, faUserCircle } from '@fortawesome/free-solid-svg-icons';
// import { faSave } from '@fortawesome/free-solid-svg-icons';
// import { toast } from 'react-toastify';
// import ReactLoading from 'react-loading';
// import { useNavigate, useLocation } from "react-router-dom";


// function AddExportExternal(props) {
//     const styles = {
//         overlay: {
//             position: 'fixed',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             zIndex: 9999, // Ensure the overlay is above other elements
//         },
//     };

//     const location = useLocation();
//     const navigate = useNavigate();
//     const requestId = location.state?.requestId;
//     const sbNo = location.state?.sbNo;
//     const partyId = location.state?.partyId;
//     const operationPage = location.state?.operation;
//     const searchCriteria = location.state?.searchCriteria;
//     const currentPage = location.state?.currentPage;

//     const [operation, setOperation] = useState(operationPage);
//     const [loading, setLoading] = useState(false);
//     const { isAuthenticated } = useContext(AuthContext);
//     const [errors, setErrors] = useState({});
//     const [consoles, setConsoles] = useState([]);
//     const [chas, setCHAS] = useState([]);
//     const [parties, setPartiesAll] = useState([]);
//     const [selectedParty, setSelectedParty] = useState(null);
//     const [selectedConsole, setSelectedConsole] = useState(null);
//     const [selectedCha, setSelectedCha] = useState(null);
//     const [FlightS, setFlightS] = useState([]);
//     // const [nsdlStatusArray, setNsdlStatusArray] = useState([]);







//     useEffect(() => {
//         if (!isAuthenticated) {
//             navigate(
//                 "/login?message=You need to be authenticated to access this page."
//             );
//         }
//     }, [isAuthenticated, navigate]);

//     const {
//         jwtToken,
//         userId,
//         username,
//         branchId,
//         companyid,
//         role,
//         companyname,
//         branchname,
//         logintype,
//         logintypeid,
//         login,
//         logout,
//     } = useContext(AuthContext);




//     const initialExportData = {
//         companyId: companyid,
//         branchId: branchId,
//         sbNo: '',
//         sbRequestId: '',
//         sbDate: '',
//         iecCode: '',
//         entityId: '',
//         nameOfExporter: '',
//         gateInId: '',
//         gateInDate: '',
//         serNo: '',
//         serDate: '',
//         grossWeight: 0.0,
//         uomGrossWeight: 'GRAMS',
//         countryOfDestination: '',
//         portOfDestination: '',
//         airwayBillNo: '',
//         descriptionOfGoods: '',
//         nsdlStatus: 'Pending',
//         dgdcStatus: '',
//         chaNo: '',
//         chaName: '',
//         consoleAgent: '',
//         fobValueInINR: 0.0,
//         noOfPackages: 1,
//         uomOfPackages: '',
//         status: '',
//         createdBy: userId,
//         createdDate: new Date(),
//         editedBy: userId,
//         editedDate: new Date(),
//         approvedBy: userId,
//         approvedDate: new Date(),
//         cartingAgent: '',
//         partyRepresentativeId: '',
//         qrcodeUrl: '',
//         pctmNo: '',
//         tpNo: '',
//         tpDate: '',
//         pctmDate: '',
//         mawb: '',
//         airlineName: '',
//         flightNo: '',
//         airlineCode: '',
//         flightDate: '',
//         holdStatus: 'N',
//         scStatus: 'N',
//         pcStatus: 'N',
//         hpStatus: 'N',
//         hppackageno: '',
//         hpWeight: 0.0,
//         imposePenaltyAmount: 0.0,
//         imposePenaltyRemarks: '',
//         reasonforOverride: '',
//         overrideDocument: '',
//         cancelStatus: '',
//         cancelRemarks: '',
//         gatePassVehicleNo: '',
//         pOName: '',
//         gatePassStatus: '',
//         imagePath: '',
//         redepositeRemark: '',
//         backtotownRemark: '',
//         backtotownFilePath: '',
//         epCopyDocument: '',
//         noc: 0,
//         dgdc_seepz_in_scan: 0,
//         dgdc_seepz_out_scan: 0,
//         dgdc_cargo_in_scan: 0,
//         dgdc_cargo_out_scan: 0,
//         outDate: '',
//         mopStatus: '',
//         pcGatePassDate: '',
//         pcGatePassId: '',
//         mopId: '',
//         airLineDate: '',
//         partyName: ''
//     };


//     const [exportData, setExportData] = useState(initialExportData);


//     useEffect(() => {
//         if (operationPage && requestId && sbNo && partyId) {
//             getExportByPrimary(sbNo, requestId, partyId)
//         }
//     }, []);


//     const getExportByPrimary = async (sbNo, requestId, partyId) => {
//         setLoading(true);
//         try {
//             const response = await Rate_Chart_Service.getExportByPrimary(companyid, branchId, sbNo, requestId, partyId);
//             setExportData(response.data);
//         }
//         catch {
//             toast.error("Something went wrong!", {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 1200,
//                 style: { width: '27vw' },
//             });
//         }
//         finally {
//             setLoading(false);
//         }
//     };


//     useEffect(() => {
//         const fetchAllData = async () => {
//             try {
//                 await findPartiesAll();
//                 await findConsoles();
//                 await findCHA();
//                 await findAirlines();
//                 // await findNsdlStatus();
//             } catch (error) {
//                 console.error("Error fetching data:", error);
//             }
//         };
//         fetchAllData();
//     }, []);


//     const handleBlur = (e) => {
//         if (!exportData.countryOfDestination) {
//             handleChange({
//                 target: {
//                     name: 'countryOfDestination',
//                     value: exportData.portOfDestination
//                 }
//             });
//         }
//     };


//     // const findNsdlStatus = async () => {
//     //     const PCKTYPEResponse = await Rate_Chart_Service.getJarByDtl(companyid, 'J00041');
//     //     const partyOptions = PCKTYPEResponse.data.map(jar => ({
//     //         value: jar[1],
//     //         label: jar[1]
//     //     }));      
//     //     setNsdlStatusArray(partyOptions);
//     // };


//     const findAirlines = async () => {
//         const AirResponse = await Rate_Chart_Service.getAllairline(companyid, branchId);
//         setFlightS(AirResponse.data);
//     };
//     const findConsoles = async () => {
//         const partyOptions = await findExternalPartyByType(companyid, branchId, 'console')
//         setConsoles(partyOptions);
//     };
//     const findCHA = async () => {
//         const partyOptions = await findExternalPartyByType(companyid, branchId, 'CHA')
//         setCHAS(partyOptions);

//         if (logintype === 'CHA' && partyOptions.length > 0) {

//             setExportData(prevState => ({
//                 ...prevState,
//                 chaNo: logintypeid,
//             }));



//         }
//     };

//     const findExternalPartyByType = async (compid, branchid, Type) => {
//         const partyResponse = await Rate_Chart_Service.getAllActiveExternalParties(compid, branchid, Type);
//         const partyOptions = partyResponse.data.map(externalUser => ({
//             value: externalUser[0],
//             label: externalUser[1]
//         }));
//         return partyOptions;
//     };


//     const findPartiesAll = async () => {
//         let partyResponse;
//         if (logintype === 'Party' || logintype === 'CHA') {
//             partyResponse = await Rate_Chart_Service.getAllActivePartiesLoginType(companyid, branchId, logintypeid, logintype);
//         }
//         else {
//             partyResponse = await Rate_Chart_Service.getAllActiveParties(companyid, branchId);
//         }

//         const partyOptions = partyResponse.data.map(party => ({
//             value: party[0],
//             label: party[1]
//         }));
//         setPartiesAll(partyOptions);
//         if (logintype === 'Party' && partyOptions.length > 0) {
//             await handlePartyChange(partyOptions[0]);
//         }
//     };


//     const handlePartyChange = async (selectedOption) => {
//         setSelectedParty(selectedOption ? selectedOption : null);
//         if (selectedOption) {
//             setExportData(prevState => ({
//                 ...prevState,
//                 nameOfExporter: selectedOption ? selectedOption.value : '',
//                 partyName: selectedOption ? selectedOption.label : ''
//             }));
//             await getPartyByID(selectedOption ? selectedOption.value : '');
//         }
//         else {
//             setExportData(prevState => ({
//                 ...prevState,
//                 nameOfExporter: '',
//                 iecCode: '',
//                 entityId: '',
//                 partyName: ''
//             }));
//         }
//         setErrors(prevErrors => ({
//             ...prevErrors,
//             party: '',
//         }));
//     };

//     const handleConsoleChange = async (selectedOption) => {
//         setSelectedConsole(selectedOption ? selectedOption : null);

//         const value = selectedOption ? selectedOption.value : '';

//         setExportData(prevState => ({
//             ...prevState,
//             consoleAgent: value,
//         }));

//         setErrors(prevErrors => ({
//             ...prevErrors,
//             console: '',
//         }));
//     };



//     const handleChaChange = async (selectedOption) => {
//         setSelectedCha(selectedOption ? selectedOption : null);

//         setExportData(prevState => ({
//             ...prevState,
//             chaNo: selectedOption ? selectedOption.value : '',
//             chaName: selectedOption ? selectedOption.label : ''
//         }));
//         setErrors(prevErrors => ({
//             ...prevErrors,
//             cha: '',
//         }));

//     };

//     useEffect(() => {
//         const foundType = chas.find(type => type.value === exportData.chaNo);
//         setSelectedCha(foundType ? foundType : null);

//         if (foundType) {
//             setErrors(prevErrors => ({
//                 ...prevErrors,
//                 cha: '',
//             }));
//         }

//     }, [chas, exportData.chaNo]);

//     useEffect(() => {
//         const foundType2 = consoles.find(type => type.value === exportData.consoleAgent);
//         setSelectedConsole(foundType2 ? foundType2 : null);
//         if (foundType2) {
//             setErrors(prevErrors => ({
//                 ...prevErrors,
//                 console: '',
//             }));
//         }
//     }, [consoles, exportData.consoleAgent]);

//     useEffect(() => {
//         const foundType2 = parties.find(type => type.value === exportData.nameOfExporter);
//         setSelectedParty(foundType2 ? foundType2 : null);
//         if (foundType2) {
//             setErrors(prevErrors => ({
//                 ...prevErrors,
//                 party: '',
//             }));
//         }

//     }, [parties, exportData.nameOfExporter]);




//     const getPartyByID = async (partyId) => {
//         try {
//             const res = await Rate_Chart_Service.getPartyByIdNew(companyid, branchId, partyId, "EXP");
//             if (res.data) {
//                 const party = res.data;

//                 const foundType = chas.find(type => type.value === party.status);


//                 const foundType2 = consoles.find(type => type.value === party.createdBy);


//                 setExportData(prevState => ({
//                     ...prevState,
//                     iecCode: party.iecNo || '',
//                     entityId: party.entityId || '',
//                     partyName: party.partyName || '',
//                     chaNo: foundType ? foundType.value : party.status,
//                     chaName: foundType ? foundType.label : '',
//                     consoleAgent: foundType2 ? foundType2.value : party.createdBy,
//                 }));

//                 console.log('foundType ' + foundType + 'foundType2 ' + foundType2);

//             } else {
//                 console.log("No data received from getPartyById API");
//             }
//         } catch (error) {
//             console.error("Error fetching party data:", error);
//         }
//     };

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         const formattedValue = formatValue(name, value);

//         setErrors((prevErrors) => ({
//             ...prevErrors,
//             [name]: '',
//         }));

//         setExportData({
//             ...exportData,
//             [name]: formattedValue
//         });
//     };


//     const formatValue = (name, value) => {
//         const numericFields = ['sbNo', 'sbRequestId', 'airwayBillNo', 'noOfPackages'];
//         const decimalFields = ['grossWeight', 'fobValueInINR'];

//         if (numericFields.includes(name)) {
//             return value.replace(/\D/g, '');
//         }
//         if (decimalFields.includes(name)) {
//             const match = value.match(/^\d*(\.\d{0,3})?/);
//             return match ? match[0] : '';
//         }
//         return value;
//     };



//     const handleDateChange = (date, name) => {
//         setErrors((prevErrors) => ({
//             ...prevErrors,
//             [name]: '', // Clear the specific error when the input changes
//         }));

//         setExportData({
//             ...exportData,
//             [name]: date
//         });
//     };

//     const handleKeyDown = (event, name) => {
//         if (event.key === 'Enter') {
//             setExportData((exportData) => ({
//                 ...exportData,
//                 [name]: new Date()
//             }));
//         }
//     };



//     const handleFlightNumberChange = (event) => {
//         const enteredFlightNumber = event.target.value;
//         const firstTwoLetters = enteredFlightNumber.substring(0, 2).toUpperCase();
//         const matchingAirline = FlightS.find(airline => airline.airlineShortName === firstTwoLetters);

//         setExportData(prevState => ({
//             ...prevState,
//             flightNo: enteredFlightNumber,
//             airlineCode: matchingAirline ? matchingAirline.airlineCode : '',
//             airlineName: matchingAirline ? matchingAirline.airlineName : ''
//         }));
//     };

//     const Handleback = () => {
//         navigate(`/parent/export`, { state: { searchCriteria: searchCriteria, currentPage: currentPage } })
//     };



//     // const handleSubmit = async (e) => {
//     //     e.preventDefault();

//     //     // Validate the form before submitting
//     //     if (!validateForm()) {
//     //         toast.error('check the required values!', {
//     //             position: toast.POSITION.TOP_CENTER,
//     //             autoClose: 700,
//     //         });
//     //         return;
//     //     }


//     //     setLoading(true);
//     //     try {
//     //         const response = await Rate_Chart_Service.addExport(companyid, branchId, userId, logintype, exportData);
//     //         let toastContent;
//     //         if (response.data.serNo) {
//     //             toastContent = `Export with SER No  ${response.data.serNo}  Added Successfully!`;
//     //         }
//     //         else {
//     //             toastContent = `Export Data Added Successfully!`;
//     //         }
//     //         toast.success(<div dangerouslySetInnerHTML={{ __html: toastContent }} />, {
//     //             position: toast.POSITION.TOP_CENTER,
//     //             autoClose: 1200,
//     //             style: { width: '29vw' },
//     //         });
//     //     } catch (error) {
//     //         const errorMessage = error.response ? error.response.data : "An error occurred during export.";
//     //         toast.error(errorMessage, {
//     //             position: toast.POSITION.TOP_CENTER,
//     //             autoClose: 2000,
//     //             style: { width: '29vw' },
//     //         });
//     //         console.error('Error saving export data:', error);
//     //     }
//     //     finally {
//     //         setLoading(false);
//     //     }
//     // };


//     const handleSave = async (e) => {
//         e.preventDefault();

//         if (!validateForm()) {
//             toast.error('check the required values!', {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 700,
//             });
//             return;
//         }
//         if (exportData.status) {
//             handleUpdate(e);
//         } else {
//             handleSubmit(e);
//         }

//     }


//     const handleSubmit = async (e) => {
//         try {
//             const response = await Rate_Chart_Service.addExport(companyid, branchId, userId, logintype, exportData);

//             setExportData(response.data);
//             // Check if response status is in the success range (2xx)
//             if (response.status >= 200 && response.status < 300) {
//                 setOperation('U');
//                 let toastContent;
//                 if (response.data.serNo) {
//                     toastContent = `Export with SER No <strong> ${response.data.serNo} </strong> Added Successfully!`;
//                 }
//                 else {
//                     toastContent = `Export Data Added Successfully!`;
//                 }
//                 toast.success(<div dangerouslySetInnerHTML={{ __html: toastContent }} />, {
//                     position: toast.POSITION.TOP_CENTER,
//                     autoClose: 1200,
//                     style: { width: '32vw' },
//                 });
//             }
//         } catch (error) {
//             if (error.response) {
//                 const errorData = error.response.data;
//                 console.log(errorData);

//                 if (errorData.error && errorData.field) {
//                     toast.error(errorData.error, {
//                         position: toast.POSITION.TOP_CENTER,
//                         autoClose: 1200,
//                         style: { width: '27vw' },
//                     });

//                     setErrors({ [errorData.field]: errorData.error });
//                 } else {
//                     toast.error("Something went wrong!", {
//                         position: toast.POSITION.TOP_CENTER,
//                         autoClose: 1200,
//                         style: { width: '27vw' },
//                     });
//                 }
//             } else {
//                 console.error('Error saving export data:', error);
//                 toast.error("An error occurred while saving export data",
//                     {
//                         position: toast.POSITION.TOP_CENTER,
//                         autoClose: 1200,
//                         style: { width: '31vw' },
//                     }
//                 );
//             }
//         } finally {
//             setLoading(false);
//         }
//     };


//     const makefieldEmpty = () => {

//         const partyInitial = {
//             companyId: companyid,
//             branchId: branchId,
//             sbNo: '',
//             sbRequestId: '',
//             sbDate: '',
//             iecCode: exportData.iecCode,
//             entityId: exportData.entityId,
//             nameOfExporter: selectedParty ? selectedParty.value : '',
//             gateInId: '',
//             gateInDate: '',
//             serNo: '',
//             serDate: '',
//             grossWeight: 0.0,
//             uomGrossWeight: 'GRAMS',
//             countryOfDestination: '',
//             portOfDestination: '',
//             airwayBillNo: '',
//             descriptionOfGoods: '',
//             nsdlStatus: 'Pending',
//             dgdcStatus: '',
//             chaNo: selectedCha ? selectedCha.value : '',
//             chaName: selectedCha ? selectedCha.label : '',
//             consoleAgent: selectedConsole ? selectedConsole.value : '',
//             fobValueInINR: 0.0,
//             noOfPackages: 1,
//             uomOfPackages: '',
//             status: '',
//             createdBy: userId,
//             createdDate: new Date(),
//             editedBy: userId,
//             editedDate: new Date(),
//             approvedBy: userId,
//             approvedDate: new Date(),
//             cartingAgent: '',
//             partyRepresentativeId: '',
//             qrcodeUrl: '',
//             pctmNo: '',
//             tpNo: '',
//             tpDate: '',
//             pctmDate: '',
//             mawb: '',
//             airlineName: '',
//             flightNo: '',
//             airlineCode: '',
//             flightDate: '',
//             holdStatus: 'N',
//             scStatus: 'N',
//             pcStatus: 'N',
//             hpStatus: 'N',
//             hppackageno: '',
//             hpWeight: 0.0,
//             imposePenaltyAmount: 0.0,
//             imposePenaltyRemarks: '',
//             reasonforOverride: '',
//             overrideDocument: '',
//             cancelStatus: '',
//             cancelRemarks: '',
//             gatePassVehicleNo: '',
//             pOName: '',
//             gatePassStatus: '',
//             imagePath: '',
//             redepositeRemark: '',
//             backtotownRemark: '',
//             backtotownFilePath: '',
//             epCopyDocument: '',
//             noc: 0,
//             dgdc_seepz_in_scan: 0,
//             dgdc_seepz_out_scan: 0,
//             dgdc_cargo_in_scan: 0,
//             dgdc_cargo_out_scan: 0,
//             outDate: '',
//             mopStatus: '',
//             pcGatePassDate: '',
//             pcGatePassId: '',
//             mopId: '',
//             airLineDate: '',
//             partyName: selectedParty ? selectedParty.label : ''
//         };



//         const chaInitial = {
//             companyId: companyid,
//             branchId: branchId,
//             sbNo: '',
//             sbRequestId: '',
//             sbDate: '',
//             iecCode: '',
//             entityId: '',
//             nameOfExporter: '',
//             gateInId: '',
//             gateInDate: '',
//             serNo: '',
//             serDate: '',
//             grossWeight: 0.0,
//             uomGrossWeight: 'GRAMS',
//             countryOfDestination: '',
//             portOfDestination: '',
//             airwayBillNo: '',
//             descriptionOfGoods: '',
//             nsdlStatus: 'Pending',
//             dgdcStatus: '',
//             chaNo: selectedCha ? selectedCha.value : '',
//             chaName: selectedCha ? selectedCha.label : '',
//             consoleAgent: '',
//             fobValueInINR: 0.0,
//             noOfPackages: 1,
//             uomOfPackages: '',
//             status: '',
//             createdBy: userId,
//             createdDate: new Date(),
//             editedBy: userId,
//             editedDate: new Date(),
//             approvedBy: userId,
//             approvedDate: new Date(),
//             cartingAgent: '',
//             partyRepresentativeId: '',
//             qrcodeUrl: '',
//             pctmNo: '',
//             tpNo: '',
//             tpDate: '',
//             pctmDate: '',
//             mawb: '',
//             airlineName: '',
//             flightNo: '',
//             airlineCode: '',
//             flightDate: '',
//             holdStatus: 'N',
//             scStatus: 'N',
//             pcStatus: 'N',
//             hpStatus: 'N',
//             hppackageno: '',
//             hpWeight: 0.0,
//             imposePenaltyAmount: 0.0,
//             imposePenaltyRemarks: '',
//             reasonforOverride: '',
//             overrideDocument: '',
//             cancelStatus: '',
//             cancelRemarks: '',
//             gatePassVehicleNo: '',
//             pOName: '',
//             gatePassStatus: '',
//             imagePath: '',
//             redepositeRemark: '',
//             backtotownRemark: '',
//             backtotownFilePath: '',
//             epCopyDocument: '',
//             noc: 0,
//             dgdc_seepz_in_scan: 0,
//             dgdc_seepz_out_scan: 0,
//             dgdc_cargo_in_scan: 0,
//             dgdc_cargo_out_scan: 0,
//             outDate: '',
//             mopStatus: '',
//             pcGatePassDate: '',
//             pcGatePassId: '',
//             mopId: '',
//             airLineDate: '',
//             partyName: ''
//         };


//         setErrors([]);
//         if (logintype === 'Party') {
//             setExportData(partyInitial);
//         }
//         else if (logintype === 'CHA') {

//             setExportData(chaInitial);
//             setSelectedConsole(null);           
//             setSelectedParty(null);
//         }
//         else {
//             setExportData(initialExportData);
//             setSelectedConsole(null);
//             setSelectedCha(null);
//             setSelectedParty(null);
//         }
//         setOperation('');
//     };

//     const validateForm = () => {
//         const {
//             sbNo,
//             sbRequestId,
//             sbDate,
//             nameOfExporter,
//             grossWeight,
//             countryOfDestination,
//             portOfDestination,
//             chaNo,
//             consoleAgent,
//             fobValueInINR,
//             noOfPackages
//         } = exportData;

//         const newErrors = {};

//         // Check if required fields are missing
//         if (!sbNo) {
//             newErrors.sbNo = 'SB No is required';
//         }
//         if (!sbRequestId) {
//             newErrors.sbRequestId = 'SB Request ID is required';
//         }
//         if (!sbDate) {
//             newErrors.sbDate = 'SB Date is required';
//         }
//         if (!nameOfExporter) {
//             newErrors.party = 'Select Party';
//         }
//         if (!grossWeight) {
//             newErrors.grossWeight = 'Gross Weight is required';
//         }
//         if (!countryOfDestination) {
//             newErrors.countryOfDestination = 'Country of Destination is required';
//         }
//         if (!portOfDestination) {
//             newErrors.portOfDestination = 'Port of Destination is required';
//         }
//         if (!chaNo) {
//             newErrors.cha = 'Select Cha';
//         }
//         if (!consoleAgent) {
//             newErrors.console = 'Select Console';
//         }
//         if (!fobValueInINR) {
//             newErrors.fobValueInINR = 'Value is required';
//         }
//         if (!noOfPackages) {
//             newErrors.noOfPackages = 'Number of Packages is required';
//         }

//         setErrors(newErrors);

//         return Object.keys(newErrors).length === 0;
//     };


//     const handleUpdate = async (e) => {
//         setLoading(true);
//         try {
//             const response = await Rate_Chart_Service.updateExport(companyid, branchId, userId, logintype, exportData);

//             toast.success('Export Data updated Successfully', {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 1200,
//                 style: { width: '29vw' },
//             });
//         } catch (error) {
//             if (error.response) {
//                 const errorData = error.response.data;
//                 console.log(errorData);

//                 if (errorData.error && errorData.field) {
//                     toast.error(errorData.error, {
//                         position: toast.POSITION.TOP_CENTER,
//                         autoClose: 1200,
//                         style: { width: '27vw' },
//                     });

//                     setErrors({ [errorData.field]: errorData.error });
//                 } else {
//                     toast.error("Something went wrong!", {
//                         position: toast.POSITION.TOP_CENTER,
//                         autoClose: 1200,
//                         style: { width: '27vw' },
//                     });
//                 }
//             } else {
//                 console.error('Error saving export data:', error);
//                 toast.error("An error occurred while saving export data",
//                     {
//                         position: toast.POSITION.TOP_CENTER,
//                         autoClose: 1200,
//                         style: { width: '31vw' },
//                     }
//                 );
//             }
//         } finally {
//             setLoading(false);
//         }
//     };

//     const excludedLoginTypes = ['Party', 'CHA', 'Console'];
//     return (
//         <>
//             {loading && (
//                 <div style={styles.overlay}>
//                     <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
//                 </div>
//             )}

//             <div className="Container" >
//                 <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-10px' }} > <FontAwesomeIcon
//                     icon={faPlaneDeparture}
//                     style={{
//                         marginRight: '5px',
//                         color: 'black',
//                     }}
//                 />Add New Export</h5>


//                 <Card style={{ backgroundColor: "#F8F8F8" }}>
//                     <CardBody>



//                         <Row>
//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="vehicleId">SB Request Id</Label><span className='error'>*</span>
//                                     <div style={{ position: 'relative' }}>
//                                         <Input
//                                             type="text"
//                                             name="sbRequestId"
//                                             className={`form-control inputField ${errors.sbRequestId ? 'error-border' : ' '}`}
//                                             placeholder="SB Request Id"
//                                             value={exportData.sbRequestId}
//                                             onChange={handleChange}
//                                             maxLength={18}
//                                             readOnly={operation}
//                                             id={operation ? 'service' : ''}
//                                         />
//                                         {errors.sbRequestId && (
//                                             <div className="error-message">
//                                                 {errors.sbRequestId}
//                                             </div>
//                                         )}
//                                     </div>
//                                 </FormGroup>
//                             </Col>

//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="customerName">SB Number</Label><span className='error'>*</span>
//                                     <div style={{ position: 'relative' }}>
//                                         <Input
//                                             type="text"
//                                             name="sbNo"
//                                             className={`form-control inputField ${errors.sbNo ? 'error-border' : ' '}`}
//                                             placeholder="Enter SB Number"
//                                             value={exportData.sbNo}
//                                             onChange={handleChange}
//                                             readOnly={operation}
//                                             id={operation ? 'service' : ''}
//                                             maxLength={15}
//                                         />
//                                         {errors.sbNo && (
//                                             <div className="error-message">
//                                                 {errors.sbNo}
//                                             </div>
//                                         )}
//                                     </div>
//                                 </FormGroup>
//                             </Col>

//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="currentStatus">SB Date</Label><span className='error'>*</span>
//                                     <DatePicker
//                                         selected={exportData.sbDate}
//                                         onChange={(date) => handleDateChange(date, 'sbDate')}
//                                         placeholderText="Enter SB Date"
//                                         dateFormat="dd/MM/yyyy"
//                                         className={`form-control inputField ${errors.sbDate ? 'error-border' : ' '}`}
//                                         wrapperClassName="custom-react-datepicker-wrapper"
//                                         isClearable={false}
//                                         readOnly={operation}
//                                         id={operation ? 'service' : ''}
//                                         onKeyDown={(event) => handleKeyDown(event, 'sbDate')}
//                                     />
//                                 </FormGroup>
//                             </Col>


//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="vehicleId">Ser No</Label>
//                                     <div style={{ position: 'relative' }}>
//                                         <Input
//                                             type="text"
//                                             name="Ser No"
//                                             className={`form-control inputField`}
//                                             placeholder="Ser No"
//                                             value={exportData.serNo}
//                                             readOnly
//                                             id='service'
//                                             tabIndex='-1'
//                                         />
//                                     </div>
//                                 </FormGroup>
//                             </Col>
//                         </Row>

//                         <Row>
//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">Select Party</Label>

//                                     <div style={{ position: 'relative' }}>
//                                         <Select
//                                             options={parties}
//                                             value={selectedParty}
//                                             onChange={handlePartyChange}
//                                             className={errors.party ? 'error-border' : ''}
//                                             placeholder="Select a Party"
//                                             isClearable
//                                             isDisabled={operation === 'E' || logintype === 'Party'}
//                                             id={operation === 'E' ? 'service' : ''}
//                                             styles={{
//                                                 control: (provided, state) => ({
//                                                     ...provided,
//                                                     border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                                                     boxShadow: 'none',
//                                                     '&:hover': {
//                                                         border: '1px solid #ccc'
//                                                     }
//                                                 }),
//                                                 indicatorSeparator: () => ({
//                                                     display: 'none'
//                                                 }),
//                                                 dropdownIndicator: () => ({
//                                                     display: 'none'
//                                                 })
//                                             }}
//                                         />
//                                         {errors.party && (
//                                             <div className="error-message">
//                                                 {errors.party}
//                                             </div>
//                                         )}
//                                     </div>
//                                 </FormGroup>
//                             </Col>

//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="customerName">IEC No</Label>
//                                     <Input
//                                         type="text"
//                                         name="iecCode"
//                                         className={`form-control inputField`}
//                                         placeholder="IEC No"
//                                         value={exportData.iecCode}
//                                         readOnly
//                                         id='service'
//                                         tabIndex='-1'
//                                     />
//                                 </FormGroup>
//                             </Col>

//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="currentStatus">Entity Id</Label>
//                                     <Input
//                                         type="text"
//                                         name="entityId"
//                                         className={`form-control inputField`}
//                                         placeholder="Entity Id"
//                                         value={exportData.entityId}
//                                         readOnly
//                                         id='service'
//                                         tabIndex='-1'
//                                     />
//                                 </FormGroup>
//                             </Col>


//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="vehicleId">Select Console</Label><span className='error'>*</span>

//                                     <div style={{ position: 'relative' }}>
//                                         <Select
//                                             options={consoles}
//                                             value={selectedConsole}
//                                             onChange={handleConsoleChange}
//                                             className={errors.console ? 'error-border' : ''}
//                                             placeholder="Select a Console"
//                                             isClearable
//                                             isDisabled={operation === 'E'}
//                                             id={operation === 'E' ? 'service' : ''}
//                                             styles={{
//                                                 control: (provided, state) => ({
//                                                     ...provided,
//                                                     border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                                                     boxShadow: 'none',
//                                                     '&:hover': {
//                                                         border: '1px solid #ccc'
//                                                     }
//                                                 }),
//                                                 indicatorSeparator: () => ({
//                                                     display: 'none'
//                                                 }),
//                                                 dropdownIndicator: () => ({
//                                                     display: 'none'
//                                                 })
//                                             }}
//                                         />
//                                         {errors.console && (
//                                             <div className="error-message">
//                                                 {errors.console}
//                                             </div>
//                                         )}
//                                     </div>
//                                 </FormGroup>
//                             </Col>
//                         </Row>




//                         {!(excludedLoginTypes.includes(logintype) && (!exportData.dgdcStatus || exportData.dgdcStatus === 'Entry Made by Party/CHA')) && (

//                             <Row>

//                                 <Col md={3} >
//                                     <FormGroup>
//                                         <Label className="forlabel" for="branchId">Flight No</Label>
//                                         <div style={{ position: 'relative' }}>
//                                             <Input
//                                                 type="text" name="flightNo"
//                                                 className={errors.flightNo ? 'error-border' : ''}
//                                                 placeholder="Enter Flight Number"
//                                                 value={exportData.flightNo}
//                                                 id={operation === 'E' ? 'service' : 'mawb'}
//                                                 readOnly={operation === 'E'}
//                                                 onChange={handleFlightNumberChange}
//                                                 maxLength={10}
//                                             />
//                                             {errors.flightNo && (
//                                                 <div className="error-message">
//                                                     {errors.flightNo}
//                                                 </div>
//                                             )}
//                                         </div>
//                                     </FormGroup>

//                                 </Col>
//                                 <Col md={3} >
//                                     <FormGroup>
//                                         <Label className="forlabel" for="branchId">Flight Date</Label>
//                                         <div>
//                                             <DatePicker
//                                                 selected={exportData.flightDate}
//                                                 placeholderText="Enter Flight Date"
//                                                 onChange={(date) => handleDateChange(date, 'flightDate')}
//                                                 wrapperClassName="custom-react-datepicker-wrapper"
//                                                 id={operation === 'E' ? 'service' : 'mawb'}
//                                                 disabled={operation === 'E'}
//                                                 dateFormat="dd/MM/yyyy"
//                                                 value={exportData.flightDate}
//                                                 onKeyDown={(event) => handleKeyDown(event, 'flightDate')}
//                                                 className="form-control InputField"
//                                             />

//                                         </div>
//                                     </FormGroup>
//                                 </Col>
//                                 <Col md={3} >
//                                     <FormGroup>
//                                         <Label className="forlabel" for="branchId">Airline Name</Label>
//                                         <Input
//                                             type="text" name="airlineName"
//                                             className="form-control inputField"
//                                             placeholder="Airline"
//                                             value={exportData.airlineName}
//                                             id='service' readOnly
//                                             tabIndex="-1"
//                                         />
//                                     </FormGroup>
//                                 </Col>
//                                 <Col md={3} >
//                                     <FormGroup>
//                                         <Label className="forlabel" for="branchId">Airway Bill No</Label>
//                                         <div style={{ position: 'relative' }}>
//                                             <Input
//                                                 type="text" name="airwayBillNo"
//                                                 value={exportData.airwayBillNo}
//                                                 placeholder="Enter Airway Bill Number"
//                                                 id={operation === 'E' ? 'service' : 'mawb'}
//                                                 readOnly={operation === 'E'}
//                                                 onChange={handleChange}
//                                                 maxLength={15}
//                                             />
//                                         </div>
//                                     </FormGroup>
//                                 </Col>
//                             </Row>

//                         )}

//                         <Row>

//                             <Col md={3} >
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">No of packages</Label><span className='error'>*</span>
//                                     <div style={{ position: 'relative' }}>
//                                         <Input
//                                             type="text" name="noOfPackages"
//                                             className={errors.noOfPackages ? 'error-border' : ''}
//                                             value={exportData.noOfPackages}
//                                             placeholder="Enter No of packages"
//                                             id={operation === 'E' ? 'service' : 'mawb'}
//                                             readOnly={operation === 'E'}
//                                             onChange={handleChange}
//                                             maxLength={2}
//                                         />
//                                         {errors.noOfPackages && (
//                                             <div className="error-message">
//                                                 {errors.noOfPackages}
//                                             </div>
//                                         )}
//                                     </div>
//                                 </FormGroup>
//                             </Col>

//                             <Col md={3} >
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">Gross Weight</Label><span className='error'>*</span>
//                                     <div style={{ position: 'relative' }}>
//                                         <Input
//                                             type="text" name="grossWeight"
//                                             className={errors.grossWeight ? 'error-border' : ''}
//                                             value={exportData.grossWeight}
//                                             placeholder="Enter Parcel's Gross Weight"
//                                             id={operation === 'E' ? 'service' : 'mawb'}
//                                             readOnly={operation === 'E'}
//                                             onChange={handleChange}
//                                             maxLength={8}
//                                         />
//                                         {errors.grossWeight && (
//                                             <div className="error-message">
//                                                 {errors.grossWeight}
//                                             </div>
//                                         )}
//                                     </div>
//                                 </FormGroup>
//                             </Col>

//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">Gross Weight Unit</Label>

//                                     <Input
//                                         type="select"
//                                         name="uomGrossWeight"
//                                         className={errors.uomGrossWeight ? 'error-border' : ''}
//                                         value={exportData.uomGrossWeight}
//                                         id={operation === 'E' ? 'service' : 'mawb'}
//                                         disabled={operation === 'E'}
//                                         onChange={handleChange}
//                                     >
//                                         <option value="GRAMS">GRAMS</option>
//                                         <option value="KILO GRAMS">KILO GRAMS</option>
//                                     </Input>

//                                 </FormGroup>
//                             </Col>


//                             <Col md={3} >
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">Description Of Goods</Label>

//                                     <Input
//                                         type="text" name="descriptionOfGoods"
//                                         className={errors.descriptionOfGoods ? 'error-border' : ''}
//                                         value={exportData.descriptionOfGoods}
//                                         placeholder="Enter Description Of Goods"
//                                         id={operation === 'E' ? 'service' : 'mawb'}
//                                         readOnly={operation === 'E'}
//                                         onChange={handleChange}
//                                         maxLength={75}
//                                     />
//                                 </FormGroup>
//                             </Col>
//                         </Row>
//                         <Row>
//                             <Col md={3} >
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">FOB Value In INR</Label><span className='error'>*</span>
//                                     <div style={{ position: 'relative' }}>
//                                         <Input
//                                             type="text" name="fobValueInINR"
//                                             className={errors.fobValueInINR ? 'error-border' : ''}
//                                             value={exportData.fobValueInINR}
//                                             placeholder="Enter Parcel value in INR"
//                                             id={operation === 'E' ? 'service' : 'mawb'}
//                                             readOnly={operation === 'E'}
//                                             onChange={handleChange}
//                                             maxLength={15}
//                                         />
//                                         {errors.fobValueInINR && (
//                                             <div className="error-message">
//                                                 {errors.fobValueInINR}
//                                             </div>
//                                         )}
//                                     </div>
//                                 </FormGroup>
//                             </Col>


//                             <Col md={3} >
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">Port Of Destination</Label><span className='error'>*</span>
//                                     <div style={{ position: 'relative' }}>
//                                         <Input
//                                             type="text" name="portOfDestination"
//                                             className={errors.portOfDestination ? 'error-border' : ''}
//                                             value={exportData.portOfDestination}
//                                             placeholder="Enter Port Of Destination"
//                                             id={operation === 'E' ? 'service' : 'mawb'}
//                                             readOnly={operation === 'E'}
//                                             onChange={handleChange}
//                                             maxLength={60}
//                                             onBlur={handleBlur}
//                                         />
//                                         {errors.portOfDestination && (
//                                             <div className="error-message">
//                                                 {errors.portOfDestination}
//                                             </div>
//                                         )}
//                                     </div>
//                                 </FormGroup>
//                             </Col>
//                             <Col md={3} >
//                                 <FormGroup>
//                                     <Label className="forlabel" for="branchId">Country Of Destination</Label><span className='error'>*</span>
//                                     <div style={{ position: 'relative' }}>
//                                         <Input
//                                             type="text" name="countryOfDestination"
//                                             className={errors.countryOfDestination ? 'error-border' : ''}
//                                             value={exportData.countryOfDestination}
//                                             placeholder="Enter Country Of Destination"
//                                             id={operation === 'E' ? 'service' : 'mawb'}
//                                             readOnly={operation === 'E'}
//                                             onChange={handleChange}
//                                             maxLength={60}
//                                         />
//                                         {errors.countryOfDestination && (
//                                             <div className="error-message">
//                                                 {errors.countryOfDestination}
//                                             </div>
//                                         )}
//                                     </div>
//                                 </FormGroup>
//                             </Col>
//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="vehicleId">Select Cha</Label><span className='error'>*</span>
//                                     <div style={{ position: 'relative' }}>
//                                         <Select
//                                             options={chas}
//                                             value={selectedCha}
//                                             onChange={handleChaChange}
//                                             className={errors.cha ? 'error-border' : ''}
//                                             placeholder="Select a Cha"
//                                             isClearable
//                                             isDisabled={operation === 'E' || logintype === 'Party' || logintype === 'CHA'}
//                                             id={operation === 'E' ? 'service' : ''}
//                                             styles={{
//                                                 control: (provided, state) => ({
//                                                     ...provided,
//                                                     border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                                                     boxShadow: 'none',
//                                                     '&:hover': {
//                                                         border: '1px solid #ccc'
//                                                     }
//                                                 }),
//                                                 indicatorSeparator: () => ({
//                                                     display: 'none'
//                                                 }),
//                                                 dropdownIndicator: () => ({
//                                                     display: 'none'
//                                                 })
//                                             }}
//                                         />
//                                         {errors.cha && (
//                                             <div className="error-message">
//                                                 {errors.cha}
//                                             </div>
//                                         )}
//                                     </div>
//                                 </FormGroup>
//                             </Col>
//                         </Row>
//                         <Row>
//                             <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="status">Dgdc Status</Label>
//                                     <div style={{ position: 'relative' }}>
//                                         <Input
//                                             type="text"
//                                             name="status"
//                                             placeholder="Dgdc Status"
//                                             className={errors.dgdcStatus ? 'error-border' : ''}
//                                             value={exportData.dgdcStatus}
//                                             id='service'
//                                             readOnly
//                                             tabIndex='-1'
//                                         />
//                                     </div>
//                                 </FormGroup>
//                             </Col>



//                             {/* <Col md={3}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="status">Nsdl Status</Label>
//                                     <div style={{ position: 'relative' }}>
//                                         <Input
//                                             type="select"
//                                             name="nsdlStatus"
//                                             className={errors.nsdlStatus ? 'error-border' : ''}
//                                             value={exportData.nsdlStatus}
//                                             id={operation === 'E' ? 'service' : 'status'}
//                                             disabled={operation === 'E' || (logintype === 'Party' || logintype === 'CHA')}
//                                             onChange={handleChange}


//                                         >
//                                             {nsdlStatusArray.map(option => (
//                                                 <option key={option.value} value={option.value}>
//                                                     {option.label}
//                                                 </option>
//                                             ))}
//                                         </Input>
//                                         {errors.nsdlStatus && (
//                                             <div className="error-message">
//                                                 {errors.nsdlStatus}
//                                             </div>
//                                         )}
//                                     </div>
//                                 </FormGroup>
//                             </Col> */}


//                         </Row>

//                         <div className="text-center mb-3 mt-2">

//                             {operation !== 'E' && (
//                                 <Button
//                                     type="button"
//                                     variant="outline-success"
//                                     style={{ marginLeft: '10px', marginTop: '5px', fontWeight: 'bold' }}
//                                     onClick={handleSave}
//                                 >
//                                     <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
//                                     Submit
//                                 </Button>
//                             )}

//                             {operation !== 'E' && (
//                                 <Button
//                                     type="button"

//                                     variant="outline-danger"
//                                     style={{ marginLeft: '10px', marginTop: '5px', fontWeight: 'bold' }}
//                                     onClick={makefieldEmpty}
//                                 >
//                                     <FontAwesomeIcon icon={faRefresh} style={{ marginRight: '5px' }} />
//                                     Clear
//                                 </Button>
//                             )}

//                             <Button
//                                 type="button"
//                                 className="widthbtn"
//                                 variant="outline-primary"
//                                 style={{ marginLeft: '10px', fontWeight: 'bold' }}
//                                 onClick={Handleback}
//                             >
//                                 <FontAwesomeIcon icon={faBackward} style={{ marginRight: '5px' }} />
//                                 BACK
//                             </Button>

//                         </div>


//                     </CardBody>
//                 </Card>
//             </div>
//         </>
//     );
// }

// export default AddExportExternal;



import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import AuthContext from "../Components/AuthProvider";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from 'react-select';
import Rate_Chart_Service from "../services/Rate_Chart_Service";
import { Card, CardBody, Container, Row, Col, Form, FormGroup, Label, Input, Table } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faClose, faPlaneCircleCheck, faPlaneDeparture, faPlaneUp, faRefresh, faSearch, faTruckArrowRight, faTruckFast, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { useNavigate, useLocation } from "react-router-dom";


function AddExportExternal(props) {
    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };

    const location = useLocation();
    const navigate = useNavigate();
    const requestId = location.state?.requestId;
    const sbNo = location.state?.sbNo;
    const partyId = location.state?.partyId;
    const operationPage = location.state?.operation;
    const searchCriteria = location.state?.searchCriteria;
    const currentPage = location.state?.currentPage;

    const [operation, setOperation] = useState(operationPage);
    const [loading, setLoading] = useState(false);
    const { isAuthenticated } = useContext(AuthContext);
    const [errors, setErrors] = useState({});
    const [consoles, setConsoles] = useState([]);
    const [chas, setCHAS] = useState([]);
    const [parties, setPartiesAll] = useState([]);
    const [selectedParty, setSelectedParty] = useState(null);
    const [selectedConsole, setSelectedConsole] = useState(null);
    const [selectedCha, setSelectedCha] = useState(null);
    const [FlightS, setFlightS] = useState([]);
    // const [nsdlStatusArray, setNsdlStatusArray] = useState([]);







    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);

    const {
        jwtToken,
        userId,
        username,
        branchId,
        companyid,
        role,
        companyname,
        branchname,
        userType,
        logintype,
        logintypeid,
        login,
        logout,
    } = useContext(AuthContext);




    const initialExportData = {
        companyId: companyid,
        branchId: branchId,
        expTransId: '',
        sbNo: '',
        sbRequestId: '',
        sbDate: '',
        iecCode: '',
        entityId: '',
        nameOfExporter: '',
        gateInId: '',
        gateInDate: '',
        serNo: '',
        serDate: '',
        grossWeight: 0.0,
        uomGrossWeight: 'GRAMS',
        countryOfDestination: '',
        portOfDestination: '',
        airwayBillNo: '',
        descriptionOfGoods: '',
        nsdlStatus: 'Pending',
        dgdcStatus: '',
        chaNo: '',
        chaName: '',
        consoleAgent: '',
        fobValueInINR: 0.0,
        noOfPackages: 1,
        uomOfPackages: '',
        status: '',
        createdBy: userId,
        createdDate: new Date(),
        editedBy: userId,
        editedDate: new Date(),
        approvedBy: userId,
        approvedDate: new Date(),
        cartingAgent: '',
        partyRepresentativeId: '',
        qrcodeUrl: '',
        pctmNo: '',
        tpNo: '',
        tpDate: '',
        pctmDate: '',
        mawb: '',
        airlineName: '',
        flightNo: '',
        airlineCode: '',
        flightDate: '',
        holdStatus: 'N',
        scStatus: 'N',
        pcStatus: 'N',
        hpStatus: 'N',
        hppackageno: '',
        hpWeight: 0.0,
        imposePenaltyAmount: 0.0,
        imposePenaltyRemarks: '',
        reasonforOverride: '',
        overrideDocument: '',
        cancelStatus: '',
        cancelRemarks: '',
        gatePassVehicleNo: '',
        pOName: '',
        gatePassStatus: '',
        imagePath: '',
        redepositeRemark: '',
        backtotownRemark: '',
        backtotownFilePath: '',
        epCopyDocument: '',
        noc: 0,
        dgdc_seepz_in_scan: 0,
        dgdc_seepz_out_scan: 0,
        dgdc_cargo_in_scan: 0,
        dgdc_cargo_out_scan: 0,
        outDate: '',
        mopStatus: '',
        pcGatePassDate: '',
        pcGatePassId: '',
        mopId: '',
        airLineDate: '',
        partyName: ''
    };


    const [exportData, setExportData] = useState(initialExportData);


    useEffect(() => {
        if (operationPage && requestId && sbNo && partyId) {
            getExportByPrimary(sbNo, requestId, partyId)
        }
    }, []);


    const getExportByPrimary = async (sbNo, requestId, partyId) => {
        setLoading(true);
        try {
            const response = await Rate_Chart_Service.getExportByPrimary(companyid, branchId, sbNo, requestId, partyId);
            setExportData(response.data);
        }
        catch {
            toast.error("Something went wrong!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1200,
                style: { width: '27vw' },
            });
        }
        finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        const fetchAllData = async () => {
            try {
                await findPartiesAll();
                await findConsoles();
                await findCHA();
                await findAirlines();
                // await findNsdlStatus();
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchAllData();
    }, []);


    const handleBlur = (e) => {
        if (!exportData.countryOfDestination) {
            handleChange({
                target: {
                    name: 'countryOfDestination',
                    value: exportData.portOfDestination
                }
            });
        }
    };


    // const findNsdlStatus = async () => {
    //     const PCKTYPEResponse = await Rate_Chart_Service.getJarByDtl(companyid, 'J00041');
    //     const partyOptions = PCKTYPEResponse.data.map(jar => ({
    //         value: jar[1],
    //         label: jar[1]
    //     }));      
    //     setNsdlStatusArray(partyOptions);
    // };


    const findAirlines = async () => {
        const AirResponse = await Rate_Chart_Service.getAllairline(companyid, branchId);
        setFlightS(AirResponse.data);
    };
    const findConsoles = async () => {
        const partyOptions = await findExternalPartyByType(companyid, branchId, 'console')
        setConsoles(partyOptions);
    };
    const findCHA = async () => {
        const partyOptions = await findExternalPartyByType(companyid, branchId, 'CHA')
        setCHAS(partyOptions);

        if (logintype === 'CHA' && partyOptions.length > 0) {

            setExportData(prevState => ({
                ...prevState,
                chaNo: logintypeid,
            }));



        }
    };

    const findExternalPartyByType = async (compid, branchid, Type) => {
        const partyResponse = await Rate_Chart_Service.getAllActiveExternalParties(compid, branchid, Type);
        const partyOptions = partyResponse.data.map(externalUser => ({
            value: externalUser[0],
            label: externalUser[1]
        }));
        return partyOptions;
    };


    const findPartiesAll = async () => {
        let partyResponse;
        if (logintype === 'Party' || logintype === 'CHA') {
            partyResponse = await Rate_Chart_Service.getAllActivePartiesLoginType(companyid, branchId, logintypeid, logintype);
        }
        else {
            partyResponse = await Rate_Chart_Service.getAllActiveParties(companyid, branchId);
        }

        const partyOptions = partyResponse.data.map(party => ({
            value: party[0],
            label: party[1]
        }));
        setPartiesAll(partyOptions);
        if (logintype === 'Party' && partyOptions.length > 0) {
            await handlePartyChange(partyOptions[0]);
        }
    };


    const handlePartyChange = async (selectedOption) => {
        setSelectedParty(selectedOption ? selectedOption : null);
        if (selectedOption) {
            setExportData(prevState => ({
                ...prevState,
                nameOfExporter: selectedOption ? selectedOption.value : '',
                partyName: selectedOption ? selectedOption.label : ''
            }));
            await getPartyByID(selectedOption ? selectedOption.value : '');
        }
        else {
            setExportData(prevState => ({
                ...prevState,
                nameOfExporter: '',
                iecCode: '',
                entityId: '',
                partyName: ''
            }));
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            party: '',
        }));
    };

    const handleConsoleChange = async (selectedOption) => {
        setSelectedConsole(selectedOption ? selectedOption : null);

        const value = selectedOption ? selectedOption.value : '';

        setExportData(prevState => ({
            ...prevState,
            consoleAgent: value,
        }));

        setErrors(prevErrors => ({
            ...prevErrors,
            console: '',
        }));
    };



    const handleChaChange = async (selectedOption) => {
        setSelectedCha(selectedOption ? selectedOption : null);

        setExportData(prevState => ({
            ...prevState,
            chaNo: selectedOption ? selectedOption.value : '',
            chaName: selectedOption ? selectedOption.label : ''
        }));
        setErrors(prevErrors => ({
            ...prevErrors,
            cha: '',
        }));

    };

    useEffect(() => {
        const foundType = chas.find(type => type.value === exportData.chaNo);
        setSelectedCha(foundType ? foundType : null);

        if (foundType) {
            setErrors(prevErrors => ({
                ...prevErrors,
                cha: '',
            }));
        }

    }, [chas, exportData.chaNo]);

    useEffect(() => {
        const foundType2 = consoles.find(type => type.value === exportData.consoleAgent);
        setSelectedConsole(foundType2 ? foundType2 : null);
        if (foundType2) {
            setErrors(prevErrors => ({
                ...prevErrors,
                console: '',
            }));
        }
    }, [consoles, exportData.consoleAgent]);

    useEffect(() => {
        const foundType2 = parties.find(type => type.value === exportData.nameOfExporter);
        setSelectedParty(foundType2 ? foundType2 : null);
        if (foundType2) {
            setErrors(prevErrors => ({
                ...prevErrors,
                party: '',
            }));
        }

    }, [parties, exportData.nameOfExporter]);




    const getPartyByID = async (partyId) => {
        try {
            const res = await Rate_Chart_Service.getPartyByIdNew(companyid, branchId, partyId, "EXP");
            if (res.data) {
                const party = res.data;

                const foundType = chas.find(type => type.value === party.status);


                const foundType2 = consoles.find(type => type.value === party.createdBy);


                setExportData(prevState => ({
                    ...prevState,
                    iecCode: party.iecNo || '',
                    entityId: party.entityId || '',
                    partyName: party.partyName || '',
                    chaNo: foundType ? foundType.value : party.status,
                    chaName: foundType ? foundType.label : '',
                    consoleAgent: foundType2 ? foundType2.value : party.createdBy,
                }));

                // console.log('foundType ' + foundType + 'foundType2 ' + foundType2);

            } else {
                console.log("No data received from getPartyById API");
            }
        } catch (error) {
            console.error("Error fetching party data:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = formatValue(name, value);

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setExportData({
            ...exportData,
            [name]: formattedValue
        });
    };


    const formatValue = (name, value) => {
        const numericFields = ['sbNo', 'sbRequestId', 'airwayBillNo', 'noOfPackages'];
        const decimalFields = ['grossWeight', 'fobValueInINR'];

        if (numericFields.includes(name)) {
            return value.replace(/\D/g, '');
        }
        if (decimalFields.includes(name)) {
            const match = value.match(/^\d*(\.\d{0,3})?/);
            return match ? match[0] : '';
        }
        return value;
    };



    const handleDateChange = (date, name) => {
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '', // Clear the specific error when the input changes
        }));

        setExportData({
            ...exportData,
            [name]: date
        });
    };

    const handleKeyDown = (event, name) => {
        if (event.key === 'Enter') {
            setExportData((exportData) => ({
                ...exportData,
                [name]: new Date()
            }));
        }
    };



    const handleFlightNumberChange = (event) => {
        const enteredFlightNumber = event.target.value;
        const firstTwoLetters = enteredFlightNumber.substring(0, 2).toUpperCase();
        const matchingAirline = FlightS.find(airline => airline.airlineShortName === firstTwoLetters);

        setExportData(prevState => ({
            ...prevState,
            flightNo: enteredFlightNumber,
            airlineCode: matchingAirline ? matchingAirline.airlineCode : '',
            airlineName: matchingAirline ? matchingAirline.airlineName : ''
        }));
    };

    const Handleback = () => {
        navigate(`/parent/export`, { state: { searchCriteria: searchCriteria, currentPage: currentPage } })
    };



    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     // Validate the form before submitting
    //     if (!validateForm()) {
    //         toast.error('check the required values!', {
    //             position: toast.POSITION.TOP_CENTER,
    //             autoClose: 700,
    //         });
    //         return;
    //     }


    //     setLoading(true);
    //     try {
    //         const response = await Rate_Chart_Service.addExport(companyid, branchId, userId, logintype, exportData);
    //         let toastContent;
    //         if (response.data.serNo) {
    //             toastContent = `Export with SER No  ${response.data.serNo}  Added Successfully!`;
    //         }
    //         else {
    //             toastContent = `Export Data Added Successfully!`;
    //         }
    //         toast.success(<div dangerouslySetInnerHTML={{ __html: toastContent }} />, {
    //             position: toast.POSITION.TOP_CENTER,
    //             autoClose: 1200,
    //             style: { width: '29vw' },
    //         });
    //     } catch (error) {
    //         const errorMessage = error.response ? error.response.data : "An error occurred during export.";
    //         toast.error(errorMessage, {
    //             position: toast.POSITION.TOP_CENTER,
    //             autoClose: 2000,
    //             style: { width: '29vw' },
    //         });
    //         console.error('Error saving export data:', error);
    //     }
    //     finally {
    //         setLoading(false);
    //     }
    // };


    const handleSave = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.error('check the required values!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 700,
            });
            return;
        }
        if (exportData.status) {
            handleUpdate(e);
        } else {
            handleSubmit(e);
        }

    }


    const handleSubmit = async (e) => {
        setLoading(true);
        try {
            const response = await Rate_Chart_Service.addExport(companyid, branchId, userId, logintype, exportData);

            setExportData(response.data);
            // Check if response status is in the success range (2xx)
            if (response.status >= 200 && response.status < 300) {
                setOperation('U');
                let toastContent;
                if (response.data.serNo) {
                    toastContent = `Export with SER No <strong> ${response.data.serNo} </strong> Added Successfully!`;
                }
                else {
                    toastContent = `Export Data Added Successfully!`;
                }
                toast.success(<div dangerouslySetInnerHTML={{ __html: toastContent }} />, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1200,
                    style: { width: '32vw' },
                });
            }
        } catch (error) {
            if (error.response) {
                const errorData = error.response.data;
                console.log(errorData);

                if (errorData.error && errorData.field) {
                    toast.error(errorData.error, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1200,
                        style: { width: '27vw' },
                    });

                    setErrors({ [errorData.field]: errorData.error });
                } else {
                    toast.error("Something went wrong!", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1200,
                        style: { width: '27vw' },
                    });
                }
            } else {
                console.error('Error saving export data:', error);
                toast.error("An error occurred while saving export data",
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1200,
                        style: { width: '31vw' },
                    }
                );
            }
        } finally {
            setLoading(false);
        }
    };


    const makefieldEmpty = () => {

        const partyInitial = {
            companyId: companyid,
            branchId: branchId,
            sbNo: '',
            sbRequestId: '',
            sbDate: '',
            iecCode: exportData.iecCode,
            entityId: exportData.entityId,
            nameOfExporter: selectedParty ? selectedParty.value : '',
            gateInId: '',
            gateInDate: '',
            serNo: '',
            serDate: '',
            grossWeight: 0.0,
            uomGrossWeight: 'GRAMS',
            countryOfDestination: '',
            portOfDestination: '',
            airwayBillNo: '',
            descriptionOfGoods: '',
            nsdlStatus: 'Pending',
            dgdcStatus: '',
            chaNo: selectedCha ? selectedCha.value : '',
            chaName: selectedCha ? selectedCha.label : '',
            consoleAgent: selectedConsole ? selectedConsole.value : '',
            fobValueInINR: 0.0,
            noOfPackages: 1,
            uomOfPackages: '',
            status: '',
            createdBy: userId,
            createdDate: new Date(),
            editedBy: userId,
            editedDate: new Date(),
            approvedBy: userId,
            approvedDate: new Date(),
            cartingAgent: '',
            partyRepresentativeId: '',
            qrcodeUrl: '',
            pctmNo: '',
            tpNo: '',
            tpDate: '',
            pctmDate: '',
            mawb: '',
            airlineName: '',
            flightNo: '',
            airlineCode: '',
            flightDate: '',
            holdStatus: 'N',
            scStatus: 'N',
            pcStatus: 'N',
            hpStatus: 'N',
            hppackageno: '',
            hpWeight: 0.0,
            imposePenaltyAmount: 0.0,
            imposePenaltyRemarks: '',
            reasonforOverride: '',
            overrideDocument: '',
            cancelStatus: '',
            cancelRemarks: '',
            gatePassVehicleNo: '',
            pOName: '',
            gatePassStatus: '',
            imagePath: '',
            redepositeRemark: '',
            backtotownRemark: '',
            backtotownFilePath: '',
            epCopyDocument: '',
            noc: 0,
            dgdc_seepz_in_scan: 0,
            dgdc_seepz_out_scan: 0,
            dgdc_cargo_in_scan: 0,
            dgdc_cargo_out_scan: 0,
            outDate: '',
            mopStatus: '',
            pcGatePassDate: '',
            pcGatePassId: '',
            mopId: '',
            airLineDate: '',
            partyName: selectedParty ? selectedParty.label : ''
        };



        const chaInitial = {
            companyId: companyid,
            branchId: branchId,
            sbNo: '',
            sbRequestId: '',
            sbDate: '',
            iecCode: '',
            entityId: '',
            nameOfExporter: '',
            gateInId: '',
            gateInDate: '',
            serNo: '',
            serDate: '',
            grossWeight: 0.0,
            uomGrossWeight: 'GRAMS',
            countryOfDestination: '',
            portOfDestination: '',
            airwayBillNo: '',
            descriptionOfGoods: '',
            nsdlStatus: 'Pending',
            dgdcStatus: '',
            chaNo: selectedCha ? selectedCha.value : '',
            chaName: selectedCha ? selectedCha.label : '',
            consoleAgent: '',
            fobValueInINR: 0.0,
            noOfPackages: 1,
            uomOfPackages: '',
            status: '',
            createdBy: userId,
            createdDate: new Date(),
            editedBy: userId,
            editedDate: new Date(),
            approvedBy: userId,
            approvedDate: new Date(),
            cartingAgent: '',
            partyRepresentativeId: '',
            qrcodeUrl: '',
            pctmNo: '',
            tpNo: '',
            tpDate: '',
            pctmDate: '',
            mawb: '',
            airlineName: '',
            flightNo: '',
            airlineCode: '',
            flightDate: '',
            holdStatus: 'N',
            scStatus: 'N',
            pcStatus: 'N',
            hpStatus: 'N',
            hppackageno: '',
            hpWeight: 0.0,
            imposePenaltyAmount: 0.0,
            imposePenaltyRemarks: '',
            reasonforOverride: '',
            overrideDocument: '',
            cancelStatus: '',
            cancelRemarks: '',
            gatePassVehicleNo: '',
            pOName: '',
            gatePassStatus: '',
            imagePath: '',
            redepositeRemark: '',
            backtotownRemark: '',
            backtotownFilePath: '',
            epCopyDocument: '',
            noc: 0,
            dgdc_seepz_in_scan: 0,
            dgdc_seepz_out_scan: 0,
            dgdc_cargo_in_scan: 0,
            dgdc_cargo_out_scan: 0,
            outDate: '',
            mopStatus: '',
            pcGatePassDate: '',
            pcGatePassId: '',
            mopId: '',
            airLineDate: '',
            partyName: ''
        };


        setErrors([]);
        if (logintype === 'Party') {
            setExportData(partyInitial);
        }
        else if (logintype === 'CHA') {

            setExportData(chaInitial);
            setSelectedConsole(null);
            setSelectedParty(null);
        }
        else {
            setExportData(initialExportData);
            setSelectedConsole(null);
            setSelectedCha(null);
            setSelectedParty(null);
        }
        setOperation('');
    };

    const validateForm = () => {
        const {
            sbNo,
            sbRequestId,
            sbDate,
            nameOfExporter,
            grossWeight,
            countryOfDestination,
            portOfDestination,
            chaNo,
            consoleAgent,
            fobValueInINR,
            noOfPackages
        } = exportData;

        const newErrors = {};

        // Check if required fields are missing
        if (!sbNo) {
            newErrors.sbNo = 'SB No is required';
        }
        if (!sbRequestId) {
            newErrors.sbRequestId = 'SB Request ID is required';
        }
        if (!sbDate) {
            newErrors.sbDate = 'SB Date is required';
        }
        if (!nameOfExporter) {
            newErrors.party = 'Select Party';
        }
        if (!grossWeight) {
            newErrors.grossWeight = 'Gross Weight is required';
        }
        if (!countryOfDestination) {
            newErrors.countryOfDestination = 'Country of Destination is required';
        }
        if (!portOfDestination) {
            newErrors.portOfDestination = 'Port of Destination is required';
        }
        if (!chaNo) {
            newErrors.cha = 'Select Cha';
        }
        // if (!consoleAgent) {
        //     newErrors.console = 'Select Console';
        // }


        if (!consoleAgent || consoleAgent === 'N') {
            newErrors.console = 'Select Console';
        }

        if (!fobValueInINR) {
            newErrors.fobValueInINR = 'Value is required';
        }
        if (!noOfPackages) {
            newErrors.noOfPackages = 'Number of Packages is required';
        }


        console.log(newErrors);
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };


    const handleUpdate = async (e) => {
        setLoading(true);
        try {
            const response = await Rate_Chart_Service.updateExport(companyid, branchId, userId, logintype, exportData);

            toast.success('Export Data updated Successfully', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1200,
                style: { width: '29vw' },
            });
        } catch (error) {
            if (error.response) {
                const errorData = error.response.data;
                console.log(errorData);

                if (errorData.error && errorData.field) {
                    toast.error(errorData.error, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1200,
                        style: { width: '27vw' },
                    });

                    setErrors({ [errorData.field]: errorData.error });
                } else {
                    toast.error("Something went wrong!", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1200,
                        style: { width: '27vw' },
                    });
                }
            } else {
                console.error('Error saving export data:', error);
                toast.error("An error occurred while saving export data",
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1200,
                        style: { width: '31vw' },
                    }
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const excludedLoginTypes = ['Party', 'CHA', 'Console'];
    return (
        <>
            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}

            <div className="Container" >
                <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-10px' }} > <FontAwesomeIcon
                    icon={faPlaneDeparture}
                    style={{
                        marginRight: '5px',
                        color: 'black',
                    }}
                />Add New Export</h5>


                <Card style={{ backgroundColor: "#F8F8F8" }}>
                    <CardBody>



                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="vehicleId">SB Request Id</Label><span className='error'>*</span>
                                    <div style={{ position: 'relative' }}>
                                        <Input
                                            type="text"
                                            name="sbRequestId"
                                            className={`form-control inputField ${errors.sbRequestId ? 'error-border' : ' '}`}
                                            placeholder="SB Request Id"
                                            value={exportData.sbRequestId}
                                            onChange={handleChange}
                                            maxLength={18}
                                            readOnly={operation}
                                            id={operation ? 'service' : ''}
                                        />
                                        {errors.sbRequestId && (
                                            <div className="error-message">
                                                {errors.sbRequestId}
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="customerName">SB Number</Label><span className='error'>*</span>
                                    <div style={{ position: 'relative' }}>
                                        <Input
                                            type="text"
                                            name="sbNo"
                                            className={`form-control inputField ${errors.sbNo ? 'error-border' : ' '}`}
                                            placeholder="Enter SB Number"
                                            value={exportData.sbNo}
                                            onChange={handleChange}
                                            readOnly={operation === 'E' || (operation === 'U' && !(userType === 'SEEPZ Custodian' || userType === 'ROLE_ADMIN'))}
                                            id={operation === 'E' || (operation === 'U' && !(userType === 'SEEPZ Custodian' || userType === 'ROLE_ADMIN')) ? 'service' : ''}
                                            maxLength={15}
                                        />
                                        {errors.sbNo && (
                                            <div className="error-message">
                                                {errors.sbNo}
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="currentStatus">SB Date</Label><span className='error'>*</span>
                                    <DatePicker
                                        selected={exportData.sbDate}
                                        onChange={(date) => handleDateChange(date, 'sbDate')}
                                        placeholderText="Enter SB Date"
                                        dateFormat="dd/MM/yyyy"
                                        className={`form-control inputField ${errors.sbDate ? 'error-border' : ' '}`}
                                        wrapperClassName="custom-react-datepicker-wrapper"
                                        isClearable={false}
                                        readOnly={operation === 'E' || (operation === 'U' && !(userType === 'SEEPZ Custodian' || userType === 'ROLE_ADMIN'))}
                                        id={operation === 'E' || (operation === 'U' && !(userType === 'SEEPZ Custodian' || userType === 'ROLE_ADMIN')) ? 'service' : ''}
                                        onKeyDown={(event) => handleKeyDown(event, 'sbDate')}
                                    />
                                </FormGroup>
                            </Col>


                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="vehicleId">Ser No</Label>
                                    <div style={{ position: 'relative' }}>
                                        <Input
                                            type="text"
                                            name="Ser No"
                                            className={`form-control inputField`}
                                            placeholder="Ser No"
                                            value={exportData.serNo}
                                            readOnly
                                            id='service'
                                            tabIndex='-1'
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Select Party</Label>

                                    <div style={{ position: 'relative' }}>
                                        <Select
                                            options={parties}
                                            value={selectedParty}
                                            onChange={handlePartyChange}
                                            className={errors.party ? 'error-border' : ''}
                                            placeholder="Select a Party"
                                            isClearable
                                            isDisabled={operation === 'E' || logintype === 'Party'}
                                            id={operation === 'E' ? 'service' : ''}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        border: '1px solid #ccc'
                                                    }
                                                }),
                                                indicatorSeparator: () => ({
                                                    display: 'none'
                                                }),
                                                dropdownIndicator: () => ({
                                                    display: 'none'
                                                })
                                            }}
                                        />
                                        {errors.party && (
                                            <div className="error-message">
                                                {errors.party}
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="customerName">IEC No</Label>
                                    <Input
                                        type="text"
                                        name="iecCode"
                                        className={`form-control inputField`}
                                        placeholder="IEC No"
                                        value={exportData.iecCode}
                                        readOnly
                                        id='service'
                                        tabIndex='-1'
                                    />
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="currentStatus">Entity Id</Label>
                                    <Input
                                        type="text"
                                        name="entityId"
                                        className={`form-control inputField`}
                                        placeholder="Entity Id"
                                        value={exportData.entityId}
                                        readOnly
                                        id='service'
                                        tabIndex='-1'
                                    />
                                </FormGroup>
                            </Col>


                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="vehicleId">Select Console</Label><span className='error'>*</span>

                                    <div style={{ position: 'relative' }}>
                                        <Select
                                            options={consoles}
                                            value={selectedConsole}
                                            onChange={handleConsoleChange}
                                            className={errors.console ? 'error-border' : ''}
                                            placeholder="Select a Console"
                                            isClearable
                                            isDisabled={operation === 'E'}
                                            id={operation === 'E' ? 'service' : ''}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        border: '1px solid #ccc'
                                                    }
                                                }),
                                                indicatorSeparator: () => ({
                                                    display: 'none'
                                                }),
                                                dropdownIndicator: () => ({
                                                    display: 'none'
                                                })
                                            }}
                                        />
                                        {errors.console && (
                                            <div className="error-message">
                                                {errors.console}
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>




                        {!(excludedLoginTypes.includes(logintype) && (!exportData.dgdcStatus || exportData.dgdcStatus === 'Entry Made by Party/CHA')) && (

                            <Row>

                                <Col md={3} >
                                    <FormGroup>
                                        <Label className="forlabel" for="branchId">Flight No</Label>
                                        <div style={{ position: 'relative' }}>
                                            <Input
                                                type="text" name="flightNo"
                                                className={errors.flightNo ? 'error-border' : ''}
                                                placeholder="Enter Flight Number"
                                                value={exportData.flightNo}
                                                id={operation === 'E' ? 'service' : 'mawb'}
                                                readOnly={operation === 'E'}
                                                onChange={handleFlightNumberChange}
                                                maxLength={10}
                                            />
                                            {errors.flightNo && (
                                                <div className="error-message">
                                                    {errors.flightNo}
                                                </div>
                                            )}
                                        </div>
                                    </FormGroup>

                                </Col>
                                <Col md={3} >
                                    <FormGroup>
                                        <Label className="forlabel" for="branchId">Flight Date</Label>
                                        <div>
                                            <DatePicker
                                                selected={exportData.flightDate}
                                                placeholderText="Enter Flight Date"
                                                onChange={(date) => handleDateChange(date, 'flightDate')}
                                                wrapperClassName="custom-react-datepicker-wrapper"
                                                id={operation === 'E' ? 'service' : 'mawb'}
                                                disabled={operation === 'E'}
                                                dateFormat="dd/MM/yyyy"
                                                value={exportData.flightDate}
                                                onKeyDown={(event) => handleKeyDown(event, 'flightDate')}
                                                className="form-control InputField"
                                            />

                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md={3} >
                                    <FormGroup>
                                        <Label className="forlabel" for="branchId">Airline Name</Label>
                                        <Input
                                            type="text" name="airlineName"
                                            className="form-control inputField"
                                            placeholder="Airline"
                                            value={exportData.airlineName}
                                            id='service' readOnly
                                            tabIndex="-1"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={3} >
                                    <FormGroup>
                                        <Label className="forlabel" for="branchId">Airway Bill No</Label>
                                        <div style={{ position: 'relative' }}>
                                            <Input
                                                type="text" name="airwayBillNo"
                                                value={exportData.airwayBillNo}
                                                placeholder="Enter Airway Bill Number"
                                                id={operation === 'E' ? 'service' : 'mawb'}
                                                readOnly={operation === 'E'}
                                                onChange={handleChange}
                                                maxLength={15}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>

                        )}

                        <Row>

                            <Col md={3} >
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">No of packages</Label><span className='error'>*</span>
                                    <div style={{ position: 'relative' }}>
                                        <Input
                                            type="text" name="noOfPackages"
                                            className={errors.noOfPackages ? 'error-border' : ''}
                                            value={exportData.noOfPackages}
                                            placeholder="Enter No of packages"
                                            id={operation === 'E' ? 'service' : 'mawb'}
                                            readOnly={operation === 'E'}
                                            onChange={handleChange}
                                            maxLength={2}
                                        />
                                        {errors.noOfPackages && (
                                            <div className="error-message">
                                                {errors.noOfPackages}
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>

                            <Col md={3} >
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Gross Weight</Label><span className='error'>*</span>
                                    <div style={{ position: 'relative' }}>
                                        <Input
                                            type="text" name="grossWeight"
                                            className={errors.grossWeight ? 'error-border' : ''}
                                            value={exportData.grossWeight}
                                            placeholder="Enter Parcel's Gross Weight"
                                            id={operation === 'E' ? 'service' : 'mawb'}
                                            readOnly={operation === 'E'}
                                            onChange={handleChange}
                                            maxLength={8}
                                        />
                                        {errors.grossWeight && (
                                            <div className="error-message">
                                                {errors.grossWeight}
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>

                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Gross Weight Unit</Label>

                                    <Input
                                        type="select"
                                        name="uomGrossWeight"
                                        className={errors.uomGrossWeight ? 'error-border' : ''}
                                        value={exportData.uomGrossWeight}
                                        id={operation === 'E' ? 'service' : 'mawb'}
                                        disabled={operation === 'E'}
                                        onChange={handleChange}
                                    >
                                        <option value="GRAMS">GRAMS</option>
                                        <option value="KILO GRAMS">KILO GRAMS</option>
                                    </Input>

                                </FormGroup>
                            </Col>


                            <Col md={3} >
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Description Of Goods</Label>

                                    <Input
                                        type="text" name="descriptionOfGoods"
                                        className={errors.descriptionOfGoods ? 'error-border' : ''}
                                        value={exportData.descriptionOfGoods}
                                        placeholder="Enter Description Of Goods"
                                        id={operation === 'E' ? 'service' : 'mawb'}
                                        readOnly={operation === 'E'}
                                        onChange={handleChange}
                                        maxLength={75}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3} >
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">FOB Value In INR</Label><span className='error'>*</span>
                                    <div style={{ position: 'relative' }}>
                                        <Input
                                            type="text" name="fobValueInINR"
                                            className={errors.fobValueInINR ? 'error-border' : ''}
                                            value={exportData.fobValueInINR}
                                            placeholder="Enter Parcel value in INR"
                                            id={operation === 'E' ? 'service' : 'mawb'}
                                            readOnly={operation === 'E'}
                                            onChange={handleChange}
                                            maxLength={15}
                                        />
                                        {errors.fobValueInINR && (
                                            <div className="error-message">
                                                {errors.fobValueInINR}
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>


                            <Col md={3} >
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Port Of Destination</Label><span className='error'>*</span>
                                    <div style={{ position: 'relative' }}>
                                        <Input
                                            type="text" name="portOfDestination"
                                            className={errors.portOfDestination ? 'error-border' : ''}
                                            value={exportData.portOfDestination}
                                            placeholder="Enter Port Of Destination"
                                            id={operation === 'E' ? 'service' : 'mawb'}
                                            readOnly={operation === 'E'}
                                            onChange={handleChange}
                                            maxLength={60}
                                            onBlur={handleBlur}
                                        />
                                        {errors.portOfDestination && (
                                            <div className="error-message">
                                                {errors.portOfDestination}
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={3} >
                                <FormGroup>
                                    <Label className="forlabel" for="branchId">Country Of Destination</Label><span className='error'>*</span>
                                    <div style={{ position: 'relative' }}>
                                        <Input
                                            type="text" name="countryOfDestination"
                                            className={errors.countryOfDestination ? 'error-border' : ''}
                                            value={exportData.countryOfDestination}
                                            placeholder="Enter Country Of Destination"
                                            id={operation === 'E' ? 'service' : 'mawb'}
                                            readOnly={operation === 'E'}
                                            onChange={handleChange}
                                            maxLength={60}
                                        />
                                        {errors.countryOfDestination && (
                                            <div className="error-message">
                                                {errors.countryOfDestination}
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="vehicleId">Select Cha</Label><span className='error'>*</span>
                                    <div style={{ position: 'relative' }}>
                                        <Select
                                            options={chas}
                                            value={selectedCha}
                                            onChange={handleChaChange}
                                            className={errors.cha ? 'error-border' : ''}
                                            placeholder="Select a Cha"
                                            isClearable
                                            isDisabled={operation === 'E' || logintype === 'Party' || logintype === 'CHA'}
                                            id={operation === 'E' ? 'service' : ''}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        border: '1px solid #ccc'
                                                    }
                                                }),
                                                indicatorSeparator: () => ({
                                                    display: 'none'
                                                }),
                                                dropdownIndicator: () => ({
                                                    display: 'none'
                                                })
                                            }}
                                        />
                                        {errors.cha && (
                                            <div className="error-message">
                                                {errors.cha}
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="status">Dgdc Status</Label>
                                    <div style={{ position: 'relative' }}>
                                        <Input
                                            type="text"
                                            name="status"
                                            placeholder="Dgdc Status"
                                            className={errors.dgdcStatus ? 'error-border' : ''}
                                            value={exportData.dgdcStatus}
                                            id='service'
                                            readOnly
                                            tabIndex='-1'
                                        />
                                    </div>
                                </FormGroup>
                            </Col>



                            {/* <Col md={3}>
                                <FormGroup>
                                    <Label className="forlabel" for="status">Nsdl Status</Label>
                                    <div style={{ position: 'relative' }}>
                                        <Input
                                            type="select"
                                            name="nsdlStatus"
                                            className={errors.nsdlStatus ? 'error-border' : ''}
                                            value={exportData.nsdlStatus}
                                            id={operation === 'E' ? 'service' : 'status'}
                                            disabled={operation === 'E' || (logintype === 'Party' || logintype === 'CHA')}
                                            onChange={handleChange}


                                        >
                                            {nsdlStatusArray.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Input>
                                        {errors.nsdlStatus && (
                                            <div className="error-message">
                                                {errors.nsdlStatus}
                                            </div>
                                        )}
                                    </div>
                                </FormGroup>
                            </Col> */}


                        </Row>

                        <div className="text-center mb-3 mt-2">

                            {operation !== 'E' && (
                                <Button
                                    type="button"
                                    variant="outline-success"
                                    style={{ marginLeft: '10px', marginTop: '5px', fontWeight: 'bold' }}
                                    onClick={handleSave}
                                    disabled={loading}
                                >
                                    <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
                                    Submit
                                </Button>
                            )}

                            {operation !== 'E' && (
                                <Button
                                    type="button"
                                    variant="outline-danger"
                                    style={{ marginLeft: '10px', marginTop: '5px', fontWeight: 'bold' }}
                                    onClick={makefieldEmpty}
                                >
                                    <FontAwesomeIcon icon={faRefresh} style={{ marginRight: '5px' }} />
                                    Clear
                                </Button>
                            )}

                            <Button
                                type="button"
                                className="widthbtn"
                                variant="outline-primary"
                                style={{ marginLeft: '10px', fontWeight: 'bold' }}
                                onClick={Handleback}
                            >
                                <FontAwesomeIcon icon={faBackward} style={{ marginRight: '5px' }} />
                                BACK
                            </Button>

                        </div>


                    </CardBody>
                </Card>
            </div>
        </>
    );
}

export default AddExportExternal;
