// const ipaddress = '103.189.88.215';
// const ipaddress ='103.189.88.215:8080/';

// const ipaddress ='3.110.185.139:9090/DGDC/';


// const ipaddress ='13.200.53.157:9090/DGDC/';


const ipaddress ='www.dgdcseepz.in:8443/DGDC/';


// const ipaddress ='103.189.88.215:8081/';


// const ipaddress ='192.168.1.123:8080/';


// const ipaddress = 'rapportsoft.in:8443/DGDCTest/';



// const ipaddress ='13.200.53.157:9090/DGDC/';






// const ipaddress ='test.rapportsoft.in:8443/DGDC/';

// const ipaddress ='127.0.0.1:8080/DGDC/';



// const ipaddress ='13.233.85.133:8080/DGDC/';


//const ipaddress = '52.206.107.131:8080/DGDC/'13.201.217.58



// const ipaddress ='192.168.1.123:8080/DGDC/';
export default ipaddress;